
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tipos-comunicado',
  templateUrl: 'tipos-comunicado.component.html'
})

export class TiposComunicadoComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}