import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UserService } from '../../../service/user-service/user.service'
import { ActivatedRoute, NavigationEnd, Router, PRIMARY_OUTLET, Params } from '@angular/router';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})

export class BreadcrumbComponent implements OnInit {
  config: any = null;
  breadcrumbs: Array<Object> = [];

  constructor(private router: Router, private route: ActivatedRoute, private userService: UserService) { }

  ngOnInit() {
    this.config = this.router.config;
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        this.breadcrumbs = [];
        let currentRoute = this.route.root, url = '';
        do {
          const childrenRoutes = currentRoute.children;
          currentRoute = null;
          childrenRoutes.forEach(route => {
            if (route.outlet === 'primary') {
              const routeSnapshot = route.snapshot;
              url += '/' + routeSnapshot.url.map(segment => segment.path).join('/');
              this.userService.refreshUrl(url);
              let label = null;
              if (route.snapshot.data.breadcrumb) {
                if (typeof route.snapshot.data.breadcrumb === 'string') {
                  label = route.snapshot.data.breadcrumb;
                  if (route.snapshot.url[0].path === 'incluir-organograma') {
                    this.breadcrumbs.push({
                      label: 'Organograma / Perfil',
                      url: url.replace('/incluir-organograma', '/consultar-organograma')
                    });
                  }
                  this.breadcrumbs.push({
                    label: label,
                    url: url
                  });
                } else if (Array.isArray(route.snapshot.data.breadcrumb)) {
                  route.snapshot.data.breadcrumb.forEach(breadcrumb => {
                    this.breadcrumbs.push({
                      label: breadcrumb.title,
                      url: url + '/' + breadcrumb.path
                    });
                  });
                }
              }
              currentRoute = route;
            }
          });
        } while (currentRoute);

        if (this.breadcrumbs.length <= 0) {
          this.breadcrumbs.push({
            label: 'Página Inicial',
            url: '/home'
          })
        }
      });
  }
}