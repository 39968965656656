import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FilterPipe } from '../../../pipes/filter.pipe';
import { CpfCnpjPipe } from '../../../pipes/cpf-cnpj.pipe';
import { PerfilDataService } from '../../../service/perfil/perfil.service';
import { LoginService } from '../../../service/login/login.service';
import { UserService } from '../../../service/user-service/user.service';
import { SessionService } from '../../../service/session/session.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cliente',
  templateUrl: 'cliente.component.html',
  styleUrls: ['./cliente.component.scss']
})
export class ClientesComponent implements OnInit {
  clientes = [];
  cliente = '';
  searchForm: FormGroup;
  searchText = '';
  allow = false;
  userInfo = {};
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private loginService: LoginService,
    private userService: UserService,
    private sessionService: SessionService,
    private data: PerfilDataService
  ) {}

  ngOnInit() {
    this.criarFormulario();
    this.userInfo = this.sessionService.getSession();
    this.loginService
      .clientes()
      .subscribe(
        res => this.handleResponse(res),
        error => this.handleError(error)
      );
  }

  handleResponse(res: any) {
    console.log(' >>> seleção cliente', res);
    this.clientes = res['resultado'];
    this.clientes.forEach(elem => {
      elem['selected'] = false;
    });
  }

  handleError(error: any) {
    Swal.fire(
      '',
      error.error.excecao.recomendacao,
      error.error.excecao.codigo === 'E000' ? 'warning' : 'error'
    ).then(() => {
      this.userService.sendInfo({
        loggedIn: false
      });
      this.sessionService.clearSession();
      this.router.navigate(['']);
    });
  }

  criarFormulario() {
    this.searchForm = this.fb.group({
      prefeitura: ['', Validators.required]
    });
  }

  orderData(val: any) {
    if (val === 'ascendente') {
      const asc = this.clientes;
      asc.sort(this.compare);
    } else {
      const desc = this.clientes;
      desc.sort(this.compare).reverse();
    }
  }

  compare(a, b) {
    const nomeA = a.nome.toUpperCase();
    const nomeB = b.nome.toUpperCase();
    let comparison = 0;
    if (nomeA > nomeB) {
      comparison = 1;
    } else if (nomeA < nomeB) {
      comparison = -1;
    }
    return comparison;
  }

  select(item: any) {
    this.clientes.forEach(elem => {
      if (elem.perfilAcesso.codigo !== item.perfilAcesso.codigo) {
        elem['selected'] = false;
      } else {
        elem['selected'] = true;
      }
    });
    this.allow = true;
  }

  desistir() {
    this.router.navigate(['/entrar']);
    this.userService.sendInfo({ loggedIn: false });
    this.sessionService.clearSession();
  }

  proximo() {
    let obj = this.clientes.filter(perfil => perfil['selected'] === true)[0];
    this.data.storage = obj;
    this.loginService.obterSessao(obj.codigo).then(
      res => {
        if (res['resultado'].perfilAcesso.codigo == 5) {
          this.userService.sendInfo({ loggedIn: true, ...res['resultado'] });
          this.router.navigate(['/caixa-postal/mensagens']);
          return;
        }
        if (res['resultado'].perfilAcesso.codigo !== 3) {
          this.userService.sendInfo({ loggedIn: true, ...res['resultado'] });
          this.router.navigate(['/home']);
        } else {
          this.userService.sendInfo({ loggedIn: false, ...res['resultado'] });
          this.router.navigate(['procuracao']); 
        }
      },
      error => console.log(error)
    );
  }
}
