import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { PerfisAcessoService } from '../../../../../service/configuracao/perfil-acesso.service';
import { Subscription } from 'rxjs';

import Swal from 'sweetalert2';
import { getSwal } from '../../../../../utils/swalObject';
import { PersonalidadeJuridicaService } from '../../../../../service/configuracao/personalidade-juridica.service';


@Component ({
    selector: 'app-editar-personalidade-juridica',
    templateUrl: './editar-personalidade.component.html',
    styleUrls: ['./editar-personalidade.component.scss'],

})
export class EditarPersonalidadeJuridicaComponent implements OnInit {
    routeSub: Subscription;
    editarPersonalidadeJuridicaForm: FormGroup;
    idPersonalidadeJuridica:number = null;
    edicao:boolean = false;
    
    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private personalidadeJuridicaService: PersonalidadeJuridicaService) { }
        
        
        
    

    ngOnInit() {
        this.criarFormulario();
        this.routeSub = this.route.params.subscribe(params => {
            if (params['id']) {
                this.idPersonalidadeJuridica = params['id'];
            }
        });
        if(this.idPersonalidadeJuridica) {      
          this.carregarDados();
        }
    }

    criarFormulario(){
        this.editarPersonalidadeJuridicaForm = this.fb.group({
          descricao: ['', Validators.required],
          ativo: [false, Validators.required]
        })
    }
   
    carregarDados(){
    this.personalidadeJuridicaService.obterPersonalidadeJuridica(this.idPersonalidadeJuridica).subscribe(res => {
        const data = res.resultado;
        for (let key in data) {
        if(this.editarPersonalidadeJuridicaForm.controls[key]) {
            this.editarPersonalidadeJuridicaForm.controls[key].patchValue(data[key]);
        }
        }
    }, error => {
        // Swal.fire(
        //   'Ops!',
        //   'Ocorreu um erro e a forma de acesso não pôde ser carregada.',
        //   'error'
        // );
        Swal.fire(
        getSwal(
            'error',
            'Ops!',
            "Ocorreu um erro e a forma de acesso não pôde ser carregada.",
            'ok',
            null,
        ),
        );      
    });
    }

    isFormInvalid(){
    if(this.editarPersonalidadeJuridicaForm.valid) {
        return false;
    } else {
        return true;
    }
    }

    onSubmit() {
    const value = this.editarPersonalidadeJuridicaForm.getRawValue();
        this.personalidadeJuridicaService.editaPersonalidadeJuridica(value, this.idPersonalidadeJuridica).subscribe(res => this.handleResponse(), error => this.handleError(error));
    }

    handleResponse(){
    // Swal.fire(
    //   'Sucesso!',
    //   'O perfil de acesso foi editado com sucesso.',
    //   'success'
    // )
    Swal.fire(
        getSwal(
        'success',
        'Sucesso!',
        "O perfil de acesso foi editado com sucesso.",
        'ok',
        null,
        ),
    );    
    this.router.navigate(['configuracao/personalidade-juridica/consultar']);
    }

    handleError(error){
    // Swal.fire(
    //   'Ops!',
    //   'Ocorreu um erro e o perfil de acesso não pôde ser editado.',
    //   'error'
    // );
    Swal.fire(
        getSwal(
        'error',
        'Ops!',
        "Ocorreu um erro e o perfil de acesso não pôde ser editado.",
        'ok',
        null,
        ),
    );    
    
    }

}