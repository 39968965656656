import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-configuracao',
  templateUrl: 'configuracao.component.html'
})

export class ConfiguracaoComponent implements OnInit {
  
  constructor() { }

  ngOnInit() { }
}