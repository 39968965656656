import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CoresTiposComunicadosService } from '../../../../../service/configuracao/cores-tipo-comunicado.service';
import { Subscription } from 'rxjs';

import Swal from 'sweetalert2';
import { getSwal } from '../../../../../utils/swalObject';
import { transition } from '@angular/animations';
import { Navigation } from 'selenium-webdriver';

@Component({
  selector: 'app-inserir-cor',
  templateUrl: 'inserir-cor.component.html',
  styleUrls: ['./inserir-cor.component.scss'],
})
export class InserirCorComponent implements OnInit {
  routeSub: Subscription;
  definirCorForm: FormGroup;
  cor: string = '#ffffff';
  idCor: number = null;
  edicao: boolean = false;
  coresSelecionadas: Array<string>;
  initialColorDescription: string = '';
  e;
  navigationData: any;
  isDescricaoValida: any;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private coresTipoComunicadoService: CoresTiposComunicadosService,
    ) {
    this.navigationData = this.router.getCurrentNavigation();
  }
  
  ngOnInit() {
    
    if (!this.navigationData || this.navigationData.initialUrl.toString().length === 0) {
      this.router.navigate([`configuracao/cores/consultar`]);
    }

    this.coresSelecionadas = this.navigationData[0];    

    this.criarFormulario();
    this.routeSub = this.route.params.subscribe(params => {
      if (params['id']) {
        this.idCor = params['id'];
        this.edicao = true;
      }
    });
    if (this.idCor) {
      this.carregarDados();
    }
  }

  criarFormulario() {
    this.definirCorForm = this.fb.group({
      descricao: ['', Validators.required],
      cor: ['', Validators.required],
    });
    this.definirCorForm.get('descricao').valueChanges.subscribe(value => {
      this.isDescricaoValida = value.trim() !== '';
    });
  }

  carregarDados() {
    this.coresTipoComunicadoService.obterCor(this.idCor).subscribe(
      res => {
        const data = res.resultado;
        for (let key in data) {
          if (this.definirCorForm.controls[key]) {
            this.definirCorForm.controls[key].patchValue(data[key]);
          }
        }
        // this.initialColorDescription = data.descricao;
        let descriptionIndex = this.coresSelecionadas.indexOf(
          data.descricao.toLowerCase(),
        );

        this.coresSelecionadas.splice(descriptionIndex, 1);
      },
      error => {
        Swal.fire(
          getSwal(
            'error',
            'Ops!',
            'Ocorreu um erro e sua cor não pôde ser carregada.',
            'ok',
            null,
          ),
        );
      },
    );
  }

  isFormInvalid() {
    const value = this.definirCorForm.value;
    //let descriptions = value.descricao;
    // TODO adicionar vali8dação com espaços em nomes
    if (
      (this.coresSelecionadas &&
        this.coresSelecionadas !== null &&
        this.coresSelecionadas.indexOf(value.descricao.toLowerCase()) !== -1) ||
      !value.descricao
    ) {
      this.definirCorForm.controls['descricao'].setErrors({
        nomeExistente: true,
      });
      return true;
    } else {
      this.definirCorForm.controls['descricao'].setErrors(null);
    }
    if (this.definirCorForm.valid && this.cor !== '') {
      return false;
    } else {
      return true;
    }
  }

  onSubmit() {
    const value = this.definirCorForm.value;
    if (this.edicao === true) {
      this.coresTipoComunicadoService
        .editaCor(value, this.idCor)
        .subscribe(
          res => this.handleResponse(),
          error => this.handleError(error),
        );
    } else {
      this.coresTipoComunicadoService
        .criaCor(value)
        .subscribe(
          res => this.handleResponse(),
          error => this.handleError(error),
        );
    }
  }

  handleResponse() {
    // Swal.fire(
    //   'Sucesso!',
    //   'A nova cor de tipo de comunicado foi definida com êxito.',
    //   'success',
    // );
    Swal.fire(
      getSwal(
        'success',
        'Sucesso!',
        'A nova cor de tipo de comunicado foi definida com êxito.',
        'ok',
        null,
      ),
    );
    this.router.navigate(['configuracao/cores/consultar']);
  }

  handleError(error) {
    // Swal.fire('Ops!', 'Ocorreu um erro e sua cor não pôde ser definida.', 'error');
    Swal.fire(
      getSwal(
        'error',
        'Ops!',
        'Ocorreu um erro e sua cor não pôde ser definida.',
        'ok',
        null,
      ),
    );
  }
}
