import { Component, ViewEncapsulation, HostListener, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserService } from './service/user-service/user.service';
import { SessionService } from './service/session/session.service';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { listenerCount } from 'cluster';
import * as moment from 'moment';
import { CervelloService } from './service/cervello/cervello.service';

@Component({
  selector: 'my-app',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  userInfo: any;
  subscription: Subscription;
  sidebarFechada: boolean;

  @ViewChild('ChatCervelloComponent', {static: false}) chatCervelloComponent;

  scroolLeft: string;

  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    const value = $event.target.scrollingElement.scrollLeft;
    this.scroolLeft = (value > 0 ? -value : 0) + 'px';
  }

  constructor(
    private userService: UserService,
    private sessionService: SessionService,
    private titleService: Title,
    private cervelloService: CervelloService,
    private translate: TranslateService,
  ) {
    moment.locale('pt-br');

    this.sidebarFechada = false;
    this.userInfo = this.sessionService.getSession();

    this.subscription = this.userService.getInfo().subscribe(info => {
      this.sidebarFechada = info.sidebarFechada;
      this.userInfo = info;
      if (
        this.userInfo.loggedIn === true &&
        this.userInfo.firstVisit === true
      ) {
        this.userInfo.firstVisit = false;
        this.userService.sendInfo(this.userInfo);
        
        // this.cervelloService.loginPlugin(this.cervelloService.getDadosUserCervello()).subscribe( (res: any) => {
        //   sessionStorage.setItem('urlCervello', res.url);
        // });
      }
    });

    this.titleService.setTitle(this.translate.instant('GLOBAIS.PAGETITLE'));
  }

  ngOnInit() { 
    
  }

  resizeDaPagina(evento) {
    this.userService.addData(this.sidebarFechada);
    return (this.sidebarFechada = evento.target.innerWidth < 1000);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
