import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { PersonalidadeJuridicaService } from '../../../../../service/configuracao/personalidade-juridica.service';
import { getSwal } from 'src/app/utils/swalObject';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-filtrar-personalidade-juridica',
  templateUrl: './filtro.component.html',
  styleUrls: ['./filtro.component.scss'],
})
export class FiltrarPersonalidadeJuridicaComponent implements OnInit {
  @Input() itemsPerView: number = 10;
  personalidadesJuridicas: Array<any> = []
  searchForm: FormGroup;
  searchParams = {};
  errorMessage = '';
  searchInput: string = '';
  loading: boolean = false;
  filter: string = '';
  debounce: Subject<string> = new Subject<string>();

  visible = '';
  situacao = 'Situação';
  ativo: boolean = null;

  data: Object = {
    resultado: [],
    primeiraPagina: true,
    ultimaPagina: false,
    paginaAtual: 1,
    totalPaginas: 1,
    totalDeItems: 0,
    itemsPorPagina: 10
  };

  // itemsPerView: number = 10;
  filterCurrent: Object = {
    total_pagina: 10,
    pagina: 1,
  }

  allowClearFilter = false;

  constructor(
    private fb: FormBuilder,
    private personalidadeJuridicaService: PersonalidadeJuridicaService,
  ) {
    this.criarFormulario();
  }

  ngOnInit() {
    // this.loading = true;
    this.personalidadeJuridicaService
      .getSituacaoPersonalidadeJuridica(this.filterCurrent)
      .subscribe(res => {
        this.loading = false;
        console.log(res)
      }, 
      error => (this.errorMessage = error));
    this.configurarSubject();
  }

  statusFilter(e: any) {
    const value = e.target.name;
    console.log('setando valor :: ', value);
    this.situacao = value;
    this.ativo = e.target.value;
  }

  setSearch() {
    this.searchParams['ativo'] = this.ativo;
    this.searchParams['pagina'] = 1;
    this.searchParams['total_pagina'] = this.itemsPerView;
    this.search();
  }

  criarFormulario() {
    this.searchForm = this.fb.group({
      assunto: '',
    });
  }

  onSearchByTitle() {
    this.searchParams['descricao'] = this.searchInput;
    this.search();
  }

  onSearchByStatus() {
    this.searchParams['ativo'] = this.ativo;
    this.searchParams['pagina'] = 1;
    this.search();
    this.visible = '';
  }

  search() {
    if (this.searchParams["ativo"] === null) {
      delete this.searchParams["ativo"];
    }
  
    if (!this.searchParams["ativo"]) {
      this.situacao = "Situação";
      this.ativo = null;
    } else {
      this.allowClearFilter = true;
    }
    if (this.searchParams["ativo"] === null) {
      delete this.searchParams["ativo"];
    }
  
    this.visible = '';
    this.loading = true;
    this.searchParams['pagina'] = this.filterCurrent['pagina']; 
    this.searchParams['total_pagina'] = this.itemsPerView;
    this.personalidadeJuridicaService.getSituacaoPersonalidadeJuridica(this.searchParams).subscribe(data => {
        this.loading = false;
        this.data = data;
        console.log('getSituacaoPersonalidadeJuridica: ', data);
      });
  }  

  toggleVisibility(item: string) {
    if (item === this.visible) {
      this.visible = '';
    } else {
      this.visible = item;
    }
  }

  limpar() {
    this.situacao = 'Situação';
    this.ativo = null;
    this.searchParams = {};
    this.searchForm.reset();
    this.loading = true;
  }

  limparFiltro(item) {
    if (item === 'situacao') {
      this.situacao = 'Situação';
      this.ativo = null;
      delete this.searchParams['ativo'];
    } 
    this.searchParams['pagina'] = 1;
    this.data['paginaAtual'] = 1;  
    this.search();
  }  
  
  configurarSubject() {
    this.debounce.pipe(debounceTime(500)).subscribe(filter => {
        this.filter = filter;
        this.onSearchByTitle();
      });
  }
}
