import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "../home/home/home.component";
import { AnaliseDadosComponent } from "./analise-dados.component";
import { AnaliseGeralComponent } from "./analise-geral/analise-geral.component";

import { AuthGuardService } from "../../service/auth-guard/auth-guard.service";
import { OpcoesPowerbiComponent } from "./opcoes-powerbi/opcoes-powerbi.component";
import { AnaliseUsuariosComponent } from "./analise-usuarios/analise-usuarios.component";
import { PowerbiComponent } from "./powerbi/powerbi.component";
import { AnaliseComunicadosComponent } from "./analise-comunicados/analise-comunicados.component";
import { AnaliseProcuradoresComponent } from "./analise-procuradores/analise-procuradores.component";
import { AnaliseEventosComponent } from "./analise-eventos/analise-eventos.component";

const routes: Routes = [
  {
    path: "analise-dados",
    canActivate: [AuthGuardService],
    component: AnaliseDadosComponent,
    data: { breadcrumb: "Análise de Dados", modulo: "1009" },
    children: [
      { path: "", redirectTo: "opcoes", pathMatch: "full" },
      {
        path: "opcoes",
        component: OpcoesPowerbiComponent,
        data: { breadcrumb: "" },
        pathMatch: "full",
      },
      {
        path: "powerbi",
        component: PowerbiComponent,
        children: [
          { path: "", redirectTo: "analise-geral", pathMatch: "full" },
          {
            path: "analise-geral",
            component: AnaliseGeralComponent,
            data: { breadcrumb: "Análise Geral" },
          },
          {
            path: "analise-usuarios",
            component: AnaliseUsuariosComponent,
            data: { breadcrumb: "Análise de Usuários" },
          },
          {
            path: "analise-comunicados",
            component: AnaliseComunicadosComponent,
            data: { breadcrumb: "Análise de Comunicados" },
          },
          {
            path: "analise-procuradores",
            component: AnaliseProcuradoresComponent,
            data: { breadcrumb: "Análise de Procuradores" },
          },
          {
            path: "analise-eventos",
            component: AnaliseEventosComponent,
            data: { breadcrumb: "Análise de Eventos" }
          }
        ],
      },
    ],
  },
];

// const routes: Routes = [
//   {
//     path: '',
//     canActivate: [AuthGuardService],
//     component: AnaliseDadosComponent,
//     data: { breadcrumb: 'Análise de Dados', modulo: '1009' },
//     children: [
//       { path: '', redirectTo: 'powerbi', pathMatch: 'full' },
//       {
//         path: 'powerbi',
//         component: PowerbiComponent,
//         data: { breadcrumb: '', modulo: '1009' },
//         pathMatch: 'full',
//       },
//         ]},
// ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AnaliseDadosRoutingModule {}
