import { EditarOrganogramaComponent } from './ambiente/paginas/editar-organograma/editar-organograma.component';
import { OrganogramaPerfilComponent } from './ambiente/paginas/organograma-perfil/organograma-perfil.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from '../../service/auth-guard/auth-guard.service';

import { OpcoesConfiguracaoComponent } from './opcoes-configuracao/opcoes-configuracao.component';

import { CoresTipoComunicadoComponent } from './cores-tipo-comunicado/cores-tipo-comunicado.component';
import { ConfiguracaoComponent } from './configuracao/configuracao.component';
import { InserirCorComponent } from './cores-tipo-comunicado/paginas/inserir-cor/inserir-cor.component';
import { ConsultarCoresTipoComunicadoComponent } from './cores-tipo-comunicado/paginas/consultar-cores/consultar-cores.component';

import { FormasAcessoComponent } from './forma-acesso/forma-acesso.component';
import { ConsultarFormasAcessoComponent } from './forma-acesso/paginas/consultar-formas-acesso/consultar-formas-acesso.component';
import { EditarFormaAcessoComponent } from './forma-acesso/paginas/editar-forma-acesso/editar-forma-acesso.component';

import { PerfisAcessoComponent } from './perfis-acesso/perfis-acesso.component';
import { ConsultarPerfisAcessoComponent } from './perfis-acesso/paginas/consulta-perfis-acesso/consulta-perfis-acesso.component';
import { EditarPerfilAcessoComponent } from './perfis-acesso/paginas/editar-perfis-acesso/editar-perfil-acesso.component';

import { AmbienteComponent } from './ambiente/ambiente.component';
import { ConsultaAmbienteComponent } from './ambiente/paginas/consulta-ambiente/consulta-ambiente.component';
import { EditarAmbienteComponent } from './ambiente/paginas/editar-ambiente/editar-ambiente.component';

import { ConfigurarAplicacoesComponent } from './gerenciar-sistemas/paginas/configurar-aplicacoes/configurar-aplicacoes.component';
import { ConsultarAplicacoesComponent } from './gerenciar-sistemas/paginas/consultar-aplicacoes/consultar-aplicacoes.component';
import { InserirAplicacaoComponent } from './gerenciar-sistemas/paginas/inserir-aplicacao/inserir-aplicacao.component';
import { ConfigurarFuncionalidadesComponent } from './gerenciar-sistemas/paginas/configurar-funcionalidades/configurar-funcionalidades.component';
import { InserirFuncionalidadesComponent } from './gerenciar-sistemas/paginas/inserir-funcionalidades/inserir-funcionalidade.component';
import { InserirFormaAcessoComponent } from './gerenciar-sistemas/componentes/inserir-forma-acesso/inserir-forma-acesso.component';
import { ConsultarFuncionalidadesComponent } from './gerenciar-sistemas/paginas/consultar-funcionalidades/consultar-funcionalidades.component';
import { ConsumoServicosComponent } from './gerenciar-sistemas/paginas/consumo-servicos/consumo-servicos.component';
import { InserirServicosComponent } from './gerenciar-sistemas/paginas/inserir-servico/inserir-servico.component';
import { ConsultarServicosComponent } from './gerenciar-sistemas/paginas/consultar-servicos/consultar-servicos.component';
import { ConfigurarServicosComponent } from './gerenciar-sistemas/paginas/configurar-servicos/configurar-servicos.component';
import { EditarRegrasUsoComponent } from './ambiente/paginas/editar-regras-uso/editar-regras-uso.component';
import { ConsultarParametroAssinaturaDigitalComponent } from './ambiente/paginas/consultar-parametro-assinatura-digital/consultar-parametro-assinatura-digital.component';
import { EditarParametroAssinaturaDigitalComponent } from './ambiente/paginas/editar-parametro-assinatura-digital/editar-parametro-assinatura-digital.component';
import { ServicoComunicacaoComponent } from './ambiente/paginas/servico-comunicacao/servico-comunicacao.component';
import { EditarIntegracaoComponent } from './ambiente/paginas/editar-integracao/editar-integracao.component';
import { ConsultaPersonalidadeJuridicaComponent } from './personalidade-juridica/paginas/consulta-personalidade-juridica/consulta-personalidade.component';
import { ConsultaAssinaturaEletronicaComponent } from './assinatura-eletronica/paginas/consulta-assinatura-eletronica/consulta-assinatura.component';
import { PersonalidadeJuridicaComponent } from './personalidade-juridica/personalidade.component';
import { SetorAtuacaoComponent } from './setor-atuacao/setor-atuacao.component';
import { ConsultaSetorAtuacaoComponent } from './setor-atuacao/paginas/consulta-setor-atuacao/consulta-setor-atuacao.component';
import { EditarPersonalidadeJuridicaComponent } from './personalidade-juridica/paginas/editar-personalidade-juridica/editar-personalidade.component';
import { EditarSetorAtuacaoComponent } from './setor-atuacao/paginas/editar-setor-atuacao/editar-setor-atuacao.component';
import { AssinaturaEletronicaComponent } from './assinatura-eletronica/assinatura-eletronica.component';
import { EditarAssinaturaEletronicaComponent } from './assinatura-eletronica/paginas/editar-assinatura-eletronica/editar-assinatura.component';
import { ConsultaRegrasAcessoComponent } from './ambiente/paginas/consulta-regras-acesso/consulta-regras-acesso.component';
import { EditarRegrasAcessoComponent } from './ambiente/paginas/editar-regras-acesso/editar-regras-acesso.component';
import { IncluirPersonalidadeComponent } from './personalidade-juridica/paginas/incluir-personalidade-juridica/incluir-personalidade.component';
import { IncluirSetorAtuacaoComponent } from './setor-atuacao/paginas/incluir-setor-atuacao/incluir-setor-atuacao.component';
import { IncluirOrganogramaComponent } from './ambiente/paginas/incluir-organograma/incluir-organograma.component';
import { VisualizarOrganogramaComponent } from './ambiente/paginas/visualizar-organograma/visualizar-organograma.component';
import { ManuaisUsuarioComponent } from './ambiente/paginas/manuais-usuario/manuais-usuario.component';
const routes: Routes = [
  {
    path: 'configuracao',
    canActivate: [AuthGuardService],
    component: ConfiguracaoComponent,
    data: { breadcrumb: 'Configurações', modulo: '1005' },
    children: [
      { path: '', redirectTo: 'opcoes', pathMatch: 'full' },
      {
        path: 'opcoes',
        component: OpcoesConfiguracaoComponent,
      },
      {
        path: 'cores',
        component: CoresTipoComunicadoComponent,
        data: { breadcrumb: 'Cores de Tipo de Comunicado' },
        children: [
          { path: '', redirectTo: 'consultar', pathMatch: 'full' },
          {
            path: 'consultar',
            component: ConsultarCoresTipoComunicadoComponent,
          },
          {
            path: 'definir',
            component: InserirCorComponent,
            data: { breadcrumb: 'Inserir Cor de Tipo de Comunicado' },
          },
          {
            path: 'definir/:id',
            component: InserirCorComponent,
            data: { breadcrumb: 'Editar Cor de Tipo de Comunicado' },
          }
        ],
      },
      {
        path: 'formas-acesso',
        component: FormasAcessoComponent,
        data: { breadcrumb: 'Formas de Acesso' },
        children: [
          { path: '', redirectTo: 'consultar', pathMatch: 'full' },
          {
            path: 'consultar',
            component: ConsultarFormasAcessoComponent,
          },
          {
            path: 'editar/:id',
            component: EditarFormaAcessoComponent,
            data: { breadcrumb: 'Editar Forma de Acesso' },
          },
        ],
      },
      {
        path: 'perfis-acesso',
        component: PerfisAcessoComponent,
        data: { breadcrumb: 'Perfis de Acesso' },
        children: [
          { path: '', redirectTo: 'consultar', pathMatch: 'full' },
          {
            path: 'consultar',
            component: ConsultarPerfisAcessoComponent,
          },
          {
            path: 'editar/:id',
            component: EditarPerfilAcessoComponent,
            data: { breadcrumb: 'Editar Perfil de Acesso' },
          },
        ],
      },
      {
        path: 'ambiente',
        component: AmbienteComponent,
        data: { breadcrumb: 'Gerenciar Ambientes' },
        children: [
          {
            path: '',
            redirectTo: 'consultar',
            pathMatch: 'full',
          },
          {
            path: 'consultar',
            component: ConsultaAmbienteComponent,
          },
          {
            path: 'editar/:id',
            component: EditarAmbienteComponent,
            data: { breadcrumb: 'Institucional' },
          },
          {
            path: 'editar-regras-uso/:id',
            component: EditarRegrasUsoComponent,
            data: { breadcrumb: 'Editar Regras de Uso' },
          },
          {
            path: 'consultar-organograma/:id',
            component: OrganogramaPerfilComponent,
            data: { breadcrumb: 'Organograma / Perfil'},
          },
          {
            path: 'editar-parametro-assinatura-digital/:id',
            component: EditarParametroAssinaturaDigitalComponent,
          data: { breadcrumb: 'Editar Parâmetro Assinatura Digital' },
          },

          {
            path: 'consultar-parametro-assinatura-digital/:id',
            component: ConsultarParametroAssinaturaDigitalComponent,
            data: { breadcrumb: 'Consultar Parâmetro Assinatura Digital' },
          },

          {
            path: 'servico-comunicacao/:id',
            component: ServicoComunicacaoComponent,
            data: { breadcrumb: 'Serviço de comunicação' },
          },

          {
            path: 'consultar-regras-acesso/:id',
            component: ConsultaRegrasAcessoComponent,
            data: { breadcrumb: 'Consultar Regras de Acesso'}
          },
          {
            path: 'editar-regras-acesso/:id',
            component: EditarRegrasAcessoComponent,
            data: { breadcrumb : 'Editar Regras de Acesso'}
          },

          {
            path: 'editar-organograma/:id',
            component: EditarOrganogramaComponent,
            data: { breadcrumb : 'Editar Organograma'}
          },

          {
            path: 'editar-integracao/:id',
            component: EditarIntegracaoComponent,
            data: { breadcrumb: 'Editar Ambiente' },
          },
          {
            path: 'visualizar-organograma/:id',
            component: VisualizarOrganogramaComponent,
            data: { breadcrumb: 'Visualizar Organograma'}
          },
          {
            path: 'incluir-organograma/:id',
            component: IncluirOrganogramaComponent,
            data: { breadcrumb: 'Incluir Organograma' },
          },
          {
            path: 'manuais-usuario/:id',
            component: ManuaisUsuarioComponent,
            data: { breadcrumb: 'Manuais de Usuário'}
          }
        ],
      },
      {
        path: 'configurar-aplicacoes',
        canActivate: [AuthGuardService],
        component: ConfigurarAplicacoesComponent,
        data: { breadcrumb: 'Configurar Aplicações', modulo: '1006' },
        children: [
          { path: '', redirectTo: 'consultar', pathMatch: 'full' },
          {
            path: 'consultar',
            component: ConsultarAplicacoesComponent,
          },
          {
            path: 'editar/novo',
            component: InserirAplicacaoComponent,
            data: { breadcrumb: 'Configurar Aplicação' },
          },
          {
            path: 'editar/:id',
            component: InserirAplicacaoComponent,
            data: { breadcrumb: 'Configurar Aplicação' },
          },
          {
            path: 'editar/:id/consumo-servicos',
            component: ConsumoServicosComponent,
            data: { breadcrumb: 'Gerenciar Consumo de Serviços' },
          },
          {
            path: 'editar/:id/funcionalidade',
            component: ConfigurarFuncionalidadesComponent,
            data: { breadcrumb: 'Gerenciar Funcionalidades' },
            children: [
              { path: '', redirectTo: 'consultar', pathMatch: 'full' },
              {
                path: 'consultar',
                component: ConsultarFuncionalidadesComponent,
              },
              {
                path: ':funcionalidade/editar',
                component: InserirFuncionalidadesComponent,
                data: { breadcrumb: 'Configurar Funcionalidade' },
              },
            ],
          },
          {
            path: 'editar/:id/endpoints',
            component: ConfigurarServicosComponent,
            data: { breadcrumb: 'Gerenciar Endpoints' },
            children: [
              { path: '', redirectTo: 'consultar', pathMatch: 'full' },
              {
                path: 'consultar',
                component: ConsultarServicosComponent,
              },
              {
                path: ':endpoint/editar',
                component: InserirServicosComponent,
                data: { breadcrumb: 'Editar Serviço' },
              },
            ],
          },
        ],
      },
      {
        path: 'personalidade-juridica',
        component: PersonalidadeJuridicaComponent,
        data: {breadcrumb: 'Personalidade Jurídica' },
        children: [
          { path: '', redirectTo: 'consultar', pathMatch: 'full' },
          {
            path: 'consultar',
            component: ConsultaPersonalidadeJuridicaComponent,
          },
          {
            path: 'incluir',
            component: IncluirPersonalidadeComponent,
            data: { breadcrumb: 'Incluir Personalidade Jurídica' }
          },
          {
            path: 'editar/:id',
            component: EditarPersonalidadeJuridicaComponent,
            data: { breadcrumb: 'Editar Personalidade Jurídica' },
          },
        ],
      },
      {
        path: 'assinatura-eletronica',
        component: AssinaturaEletronicaComponent,
        data: {breadcrumb: 'Assinatura Eletrônica'},
        children: [
          { path: '', redirectTo: 'consultar', pathMatch: 'full'},
          {
            path: 'consultar',
            component: ConsultaAssinaturaEletronicaComponent
          },
          {
            path: 'editar/:id',
            component: EditarAssinaturaEletronicaComponent,
            data: { breadcrumb: 'Editar Assinatura Eletrônica' },
          },
        ],
      },
      {
        path: 'setor-atuacao',
        component: SetorAtuacaoComponent,
        data: {breadcrumb: 'Setor de Atuação'},
        children: [
          { path: '', redirectTo: 'consultar', pathMatch: 'full'},
          {
            path: 'consultar',
            component: ConsultaSetorAtuacaoComponent
          },
          {
            path: 'incluir',
            component: IncluirSetorAtuacaoComponent,
            data: { breadcrumb: 'Incluir Setor de Atuação' },
          },
          {
            path: 'editar/:id',
            component: EditarSetorAtuacaoComponent,
            data: { breadcrumb: 'Editar Setor de Atuação' },
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ConfiguracaoRoutingModule {}
