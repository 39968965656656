import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { GlobalVariable } from '../../../model/global-variable';

@Component({
  selector: 'app-registro-acoes',
	templateUrl: './registro-acoes.component.html',
	styleUrls: ['./registro-acoes.component.scss']
})
export class RegistroAcoesComponent implements OnInit {

	private isSidebarClosed: boolean = localStorage.getItem('sidebar_is_closed') == 'true';

	constructor(private router: Router) {}

	ngOnInit() {}

	onSidebarToggle(isClosed: boolean) {
 		this.isSidebarClosed = isClosed;
 	}

}
