import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared.module';
import { HttpClientModule } from '@angular/common/http';
import { DataTableModule } from 'ng2-data-table';
import { CommonAppModule } from '../../features/common/common.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { DpDatePickerModule } from 'ng2-date-picker';

import { OpcoesConfiguracaoComponent } from './opcoes-configuracao/opcoes-configuracao.component';
import { ConfiguracaoRoutingModule } from './configuracao.routing.module';
import { CoresTipoComunicadoComponent } from './cores-tipo-comunicado/cores-tipo-comunicado.component';
import { FiltrarCoresComponent } from './cores-tipo-comunicado/componentes/filtro/filtro.component';
import { ConfiguracaoComponent } from './configuracao/configuracao.component';
import { InserirCorComponent } from './cores-tipo-comunicado/paginas/inserir-cor/inserir-cor.component';
import { ConsultarCoresTipoComunicadoComponent } from './cores-tipo-comunicado/paginas/consultar-cores/consultar-cores.component';
import { FormasAcessoComponent } from './forma-acesso/forma-acesso.component';
import { ConsultarFormasAcessoComponent } from './forma-acesso/paginas/consultar-formas-acesso/consultar-formas-acesso.component';
import { FiltrarFormasAcessoComponent } from './forma-acesso/componentes/filtro/filtro.component';
import { EditarFormaAcessoComponent } from './forma-acesso/paginas/editar-forma-acesso/editar-forma-acesso.component';
import { PerfisAcessoComponent } from './perfis-acesso/perfis-acesso.component';
import { ConsultarPerfisAcessoComponent } from './perfis-acesso/paginas/consulta-perfis-acesso/consulta-perfis-acesso.component';
import { EditarPerfilAcessoComponent } from './perfis-acesso/paginas/editar-perfis-acesso/editar-perfil-acesso.component';
import { FiltrarPerfisAcessoComponent } from './perfis-acesso/componentes/filtro/filtro.component';
import { AmbienteComponent } from './ambiente/ambiente.component';
import { ConsultaAmbienteComponent } from './ambiente/paginas/consulta-ambiente/consulta-ambiente.component';
import { FiltrarAmbienteComponent } from './ambiente/componentes/filtro/filtro.component';
import { EditarAmbienteComponent } from './ambiente/paginas/editar-ambiente/editar-ambiente.component';
import { ConfigurarAplicacoesComponent } from './gerenciar-sistemas/paginas/configurar-aplicacoes/configurar-aplicacoes.component';
import { ConsultarAplicacoesComponent } from './gerenciar-sistemas/paginas/consultar-aplicacoes/consultar-aplicacoes.component';
import { InserirAplicacaoComponent } from './gerenciar-sistemas/paginas/inserir-aplicacao/inserir-aplicacao.component';
import { ConfigurarFuncionalidadesComponent } from './gerenciar-sistemas/paginas/configurar-funcionalidades/configurar-funcionalidades.component';
import { InserirFuncionalidadesComponent } from './gerenciar-sistemas/paginas/inserir-funcionalidades/inserir-funcionalidade.component';
import { TabelasFormaAcessoComponent } from './gerenciar-sistemas/componentes/tabela-formas-acesso/tabela-formas-acesso.component';
import { InserirFormaAcessoComponent } from './gerenciar-sistemas/componentes/inserir-forma-acesso/inserir-forma-acesso.component';
import { ConsultarFuncionalidadesComponent } from './gerenciar-sistemas/paginas/consultar-funcionalidades/consultar-funcionalidades.component';
// import { GraficosFuncionalidadesComponent } from './gerenciar-sistemas/componentes/graficos-funcionalidades/graficos-funcionalidades.component';
import { ConsumoServicosComponent } from './gerenciar-sistemas/paginas/consumo-servicos/consumo-servicos.component';
import { InserirServicosComponent } from './gerenciar-sistemas/paginas/inserir-servico/inserir-servico.component';
import { ConsultarServicosComponent } from './gerenciar-sistemas/paginas/consultar-servicos/consultar-servicos.component';
import { ConfigurarServicosComponent } from './gerenciar-sistemas/paginas/configurar-servicos/configurar-servicos.component';
import { GraficosComunicadosComponent } from './gerenciar-sistemas/componentes/graficos/graficos.component';
import { FiltrarAplicacoesComponent } from './gerenciar-sistemas/componentes/filter/filter.component';
import { TreeviewModule } from 'ngx-treeview';
import { EditarRegrasUsoComponent } from './ambiente/paginas/editar-regras-uso/editar-regras-uso.component';
import { EditarIntegracaoComponent } from './ambiente/paginas/editar-integracao/editar-integracao.component';
import { ConsultarParametroAssinaturaDigitalComponent } from './ambiente/paginas/consultar-parametro-assinatura-digital/consultar-parametro-assinatura-digital.component';
import { EditarParametroAssinaturaDigitalComponent } from './ambiente/paginas/editar-parametro-assinatura-digital/editar-parametro-assinatura-digital.component';
import { ServicoComunicacaoComponent } from './ambiente/paginas/servico-comunicacao/servico-comunicacao.component';
import { NgxSummernoteModule } from 'ngx-summernote';
import { ConsultaPersonalidadeJuridicaComponent } from './personalidade-juridica/paginas/consulta-personalidade-juridica/consulta-personalidade.component';
import { PersonalidadeJuridicaComponent } from './personalidade-juridica/personalidade.component';
import { ConsultaAssinaturaEletronicaComponent } from './assinatura-eletronica/paginas/consulta-assinatura-eletronica/consulta-assinatura.component';
import { SetorAtuacaoComponent } from './setor-atuacao/setor-atuacao.component';
import { ConsultaSetorAtuacaoComponent } from './setor-atuacao/paginas/consulta-setor-atuacao/consulta-setor-atuacao.component';
import { FiltrarPersonalidadeJuridicaComponent } from './personalidade-juridica/componentes/filtro/filtro.component';
import { FiltrarParametrosAssinaturaComponent } from './ambiente/paginas/consultar-parametro-assinatura-digital/filtro/filtro.component';
import { EditarPersonalidadeJuridicaComponent } from './personalidade-juridica/paginas/editar-personalidade-juridica/editar-personalidade.component';
import { FiltrarSetorAtuacaoComponent } from './setor-atuacao/componentes/filtro/filtro.component';
import { EditarSetorAtuacaoComponent } from './setor-atuacao/paginas/editar-setor-atuacao/editar-setor-atuacao.component';
import { AssinaturaEletronicaComponent } from './assinatura-eletronica/assinatura-eletronica.component';
import { EditarAssinaturaEletronicaComponent } from './assinatura-eletronica/paginas/editar-assinatura-eletronica/editar-assinatura.component';
import { FiltrarAssinaturaEletronicaComponent } from './assinatura-eletronica/componentes/filtro/filtro.component';
import { ConsultaRegrasAcessoComponent } from './ambiente/paginas/consulta-regras-acesso/consulta-regras-acesso.component';
import { FiltrarRegrasAcessoComponent } from './ambiente/paginas/consulta-regras-acesso/filtro/filtro.component';
import { EditarRegrasAcessoComponent } from './ambiente/paginas/editar-regras-acesso/editar-regras-acesso.component';
import { TextMaskModule } from 'angular2-text-mask';
import { IncluirPersonalidadeComponent } from './personalidade-juridica/paginas/incluir-personalidade-juridica/incluir-personalidade.component';
import { IncluirSetorAtuacaoComponent } from './setor-atuacao/paginas/incluir-setor-atuacao/incluir-setor-atuacao.component';
import { OrganogramaPerfilComponent } from './ambiente/paginas/organograma-perfil/organograma-perfil.component';
import { EditarOrganogramaComponent } from './ambiente/paginas/editar-organograma/editar-organograma.component';
import { IncluirOrganogramaComponent } from './ambiente/paginas/incluir-organograma/incluir-organograma.component';
import { FiltrarOrganogramaComponent } from './ambiente/paginas/organograma-perfil/filtro/filtro.component';
import { VisualizarOrganogramaComponent } from './ambiente/paginas/visualizar-organograma/visualizar-organograma.component';
import { jqxTreeModule } from 'jqwidgets-ng/jqxtree';
import { jqxTreeGridModule } from 'jqwidgets-ng/jqxtreegrid';
import { jqxButtonModule } from 'jqwidgets-ng/jqxbuttons';
import { ManuaisUsuarioComponent } from './ambiente/paginas/manuais-usuario/manuais-usuario.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  imports: [
    CommonAppModule,
    CommonModule,
    ConfiguracaoRoutingModule,
    DataTableModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    SharedModule,
    ColorPickerModule,
    DpDatePickerModule,
    TreeviewModule.forRoot(),
    NgxSummernoteModule,
    TextMaskModule,
    jqxTreeModule,
    jqxTreeGridModule,
    jqxButtonModule,
    TranslateModule.forRoot(),
    NgxMaskModule.forRoot()
  ],
  exports: [],
  declarations: [
    ConfiguracaoComponent,
    OpcoesConfiguracaoComponent,
    CoresTipoComunicadoComponent,
    InserirCorComponent,
    ConsultarCoresTipoComunicadoComponent,
    FiltrarCoresComponent,
    FormasAcessoComponent,
    ConsultarFormasAcessoComponent,
    FiltrarFormasAcessoComponent,
    EditarFormaAcessoComponent,
    PerfisAcessoComponent,
    ConsultarPerfisAcessoComponent,
    FiltrarPerfisAcessoComponent,
    FiltrarPersonalidadeJuridicaComponent,
    FiltrarParametrosAssinaturaComponent,
    FiltrarSetorAtuacaoComponent,
    FiltrarAssinaturaEletronicaComponent,
    EditarPerfilAcessoComponent,
    AmbienteComponent,
    ConsultaAmbienteComponent,
    FiltrarAmbienteComponent,
    EditarAmbienteComponent,
    EditarRegrasUsoComponent,
    EditarIntegracaoComponent,
    EditarParametroAssinaturaDigitalComponent,
    ConsultarParametroAssinaturaDigitalComponent,
    FiltrarAplicacoesComponent,
    ConfigurarAplicacoesComponent,
    ConsultarAplicacoesComponent,
    GraficosComunicadosComponent,
    InserirAplicacaoComponent,
    ConfigurarFuncionalidadesComponent,
    InserirFuncionalidadesComponent,
    TabelasFormaAcessoComponent,
    InserirFormaAcessoComponent,
    ConsultarFuncionalidadesComponent,
    PersonalidadeJuridicaComponent,
    ConsultaPersonalidadeJuridicaComponent,
    EditarPersonalidadeJuridicaComponent,
    AssinaturaEletronicaComponent,
    ConsultaAssinaturaEletronicaComponent,
    EditarAssinaturaEletronicaComponent,
    SetorAtuacaoComponent,
    ConsultaSetorAtuacaoComponent,
    EditarSetorAtuacaoComponent,
    OrganogramaPerfilComponent,
    ServicoComunicacaoComponent,
    // GraficosFuncionalidadesComponent,
    ConsumoServicosComponent,
    InserirServicosComponent,
    ConsultarServicosComponent,
    ConfigurarServicosComponent,
    ConsultaRegrasAcessoComponent,
    FiltrarRegrasAcessoComponent,
    EditarRegrasAcessoComponent,
    IncluirPersonalidadeComponent,
    IncluirSetorAtuacaoComponent,
    EditarOrganogramaComponent,
    FiltrarOrganogramaComponent,
    IncluirOrganogramaComponent,
    VisualizarOrganogramaComponent,
    ManuaisUsuarioComponent
  ],
  providers: []
})
export class ConfiguracaoModule {}
