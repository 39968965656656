import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import { Component, OnInit, EventEmitter, Input, Output, AfterViewChecked} from "@angular/core";
import {CaixaPostalService} from "../../../../../../service/caixa-postal/caixa-postal.service";
import { ConsultaUsuarioService } from "src/app/service/consulta-usuario/consultar.user.service";
import { getSwal } from '../../../../../../utils/swalObject';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-filtro-remetente',
  templateUrl: 'filtro-remetente.component.html',
  styleUrls: ['./filtro-remetente.component.scss'],
})
export class FiltroRemetenteComponent implements OnInit, AfterViewChecked {
  @Output() onFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClear: EventEmitter<any> = new EventEmitter<any>();

  @Output() onOpen: EventEmitter<any> = new EventEmitter<any>();
  @Output() onPageUpdate: EventEmitter<number> = new EventEmitter<number>();
  @Output() onFilterChange: EventEmitter<number> = new EventEmitter<number>();

  mascara: string = '000.000.000-00';
  tipo: string = 'cpf';
  remetenteForm: FormGroup;
  @Input()
  visible: string;
  remetente: boolean = false;
  codRemetente : '';
  usuariosRemetentes = [];
  sessaoFiltro: any;
  displayCPF: String = 'block';
  displayCombo: String = 'none';
  numero: any;
  searchInput: string = '';
  selecao: any;
  filteredUsuariosRemetentes: any[] = [];
  opcaoSelecionada: any;

  loading: boolean = false;

  data: Object = {
    primeiraPagina: true,
    ultimaPagina: false,
    paginaAtual: 1,
    totalPaginas: 1,
    totalDeItems: 0,
    itemsPorPagina: 10
  };

  constructor(
    private fb: FormBuilder,
    private caixaPostalService: CaixaPostalService,
    private consultaUsuario: ConsultaUsuarioService,
    ) {
  }

  ngAfterViewChecked(): void {
    this.sessaoFiltro = JSON.parse(sessionStorage.getItem('filtro'));

    if(this.sessaoFiltro && (this.sessaoFiltro.cpf_remetente || this.sessaoFiltro.cod_remetente)){
      this.remetente =  true;
      if(this.sessaoFiltro.cpf_remetente) {
        this.remetenteForm.get('numero').setValue(this.sessaoFiltro.cpf_remetente);
      } else if(this.sessaoFiltro.cod_remetente) {
        this.remetenteForm.get('codRemetente').setValue(this.sessaoFiltro.cod_remetente);
      }
    } else {
      this.remetente = false;
    }
  }

  ngOnInit() {
    this.criarFormulario();
    this.getListaUsuariosRemetentes();
  }

  selectOption(user: any) {
    console.log('Opção selecionada:', user);
    this.opcaoSelecionada = user;
    this.selecao = user.codigo;
  }

  criarFormulario() {
    this.remetenteForm = this.fb.group({
      tipo: '',
      numero: [
        '',
        Validators.compose([Validators.required, Validators.minLength(11)]),
      ],
      codRemetente: '',
    });

  }

  consultarCpfValido(cpf) {
    this.loading = true;
      this.consultaUsuario.consultarUsuario(cpf).subscribe(res => {
        this.loading = false;
      }, error => {
        this.loading = false;
        if(error.error.excecao.recomendacao !== '') {
          this.loading = true;
          this.remetenteForm.controls.numero.reset();
          this.limparCpfInvalido()
          !this.remetenteForm.controls.numero.valid
            Swal.fire(
              getSwal(
                'error',
                '',
                error.error.excecao.recomendacao,
                'ok',
                null
              )
            );
            this.remetenteForm.controls.numero.setErrors({invalido: true})
            this.remetenteForm.controls.numero.reset();

        }
        if(error.error.excecao.mensagem == "Dado não encontrado") {
          !this.remetenteForm.controls.numero.valid
          this.remetenteForm.controls.numero.reset();
          this.displayCPF = 'block'
          this.limparCpfInvalido()
          Swal.fire(
            getSwal(
              'error',
              '',
              error.error.excecao.mensagem,
              'ok',
              null
            )
          );
        } else {
          this.remetenteForm.controls.numero.setErrors(null)
        }
      })
      this.remetenteForm.controls.numero.reset();
  }

  getListaUsuariosRemetentes(){

    this.caixaPostalService.getListaUsuariosRemetentes().subscribe(
      response => {
        this.usuariosRemetentes = response.resultado;
        this.filteredUsuariosRemetentes = [...this.usuariosRemetentes];
      },
      error => console.log(error)
    );

    console.log(this.usuariosRemetentes);

  }


  updateFilter() {
    if (!this.searchInput) {
      this.filteredUsuariosRemetentes = [...this.usuariosRemetentes];
      return;
    }

    this.filteredUsuariosRemetentes = this.usuariosRemetentes.filter(
      user => user.nome.toLowerCase().includes(this.searchInput.toLowerCase())
    );
  }

  alterarIdentificador(tipo: string) {
    switch (tipo) {
      case 'cpf':
        this.displayCPF = 'block'
        this.displayCombo = 'none'
        this.mascara = '000.000.000-00';
        this.remetenteForm.controls.numero.setValidators(
          Validators.minLength(11),
        );
        this.remetenteForm.controls.numero.updateValueAndValidity();
        break;
      case 'nome':
        this.remetenteForm.controls.numero.setValue('');
        this.remetenteForm.controls.numero.updateValueAndValidity();
        this.displayCPF = 'none'
        this.displayCombo = 'block'
        break;
    }

  }

  limparFiltros() {
    this.onClear.emit({
      cod_remetente: '',
      cpf_remetente: ''
    });
    this.remetente = false;
    this.remetenteForm.controls.numero.reset();
    this.searchInput = '';
    this.opcaoSelecionada = '';
    this.selecao = '';
    this.filteredUsuariosRemetentes = [...this.usuariosRemetentes]

    this.data['paginaAtual'] = 1

    let obj = {};
    this.onFilter.emit(obj);
    this.onPageUpdate.emit(1);
    this.loading = true;
  }
  limparCpfInvalido() {
    this.onClear.emit({
      cod_remetente: '',
      cpf_remetente: ''
    });

    this.remetenteForm.controls.numero.reset();
    this.searchInput = '';

    this.data['paginaAtual'] = 1

    let obj = {};
    this.onFilter.emit(obj);
    this.onPageUpdate.emit(1);
    this.loading = true;
  }

  resetarParametros() {
    this.codRemetente = null;
    this.numero = null;
    this.selecao = '';
    this.searchInput = '';
    this.opcaoSelecionada = '';
    this.filteredUsuariosRemetentes = [...this.usuariosRemetentes]
    this.opcaoSelecionada = '';
    this.displayCPF = 'block';
    this.displayCombo = 'none';
    this.limparFiltros()
  }

  pesquisarRemetente() {

    this.remetente = true;
    const value = this.remetenteForm.value;
    let obj = {};

    if (value.tipo === 'cpf') {
      this.consultarCpfValido(value.numero)
      obj['cpf_remetente'] = value.numero;
      obj['cod_remetente'] = '';
    } else {
      obj['cpf_remetente'] = '';
      obj['cod_remetente'] = this.selecao;
    }

    this.tipo = "cpf";

    this.onFilter.emit(obj);

    this.onFilter.emit(obj);
    this.onPageUpdate.emit(1);

  }

  botaoSelecionado() {
    this.onOpen.emit('remetente');
    this.sessaoFiltro = JSON.parse(sessionStorage.getItem('filtro'));

    if(this.sessaoFiltro) {
      if(this.sessaoFiltro.cpf_remetente) {
        this.alterarIdentificador('cpf');
      } else if(this.sessaoFiltro.cod_remetente){
        this.alterarIdentificador('nome');
      }
    }

  }

}
