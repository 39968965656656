import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


import { environment } from '../../../environments/environment'
import { SessionService } from '../session/session.service';

import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import { BehaviorSubject } from '../../../../node_modules/rxjs';


@Injectable()
export class AlertasService {

  url: string = environment.BASE_URL;

  private protocolo = new BehaviorSubject<any>('');
  protocolo$ = this.protocolo.asObservable();

  constructor(private http: HttpClient) { }

  marcarComoLido(codigo) {
    this.http.get(`${this.url}/alertas/${codigo}/marcarComoLido`).pipe(map(data => {
      return data;
    }));
  }

  getNaoLidos(token: string) {
    const header = new HttpHeaders();
    header.append('Authorization', token);
    return this.http
      .get(`${this.url}/alertas/naoLidos`, { headers: header });
  }

  setProtocolo(protocolo) {
    this.protocolo.next(parseInt(protocolo, 10));
  }

}
