import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { SetorAtuacaoService } from '../../../../../service/configuracao/setor-atuacao.service';

@Component({
    selector: 'app-consulta-setor-atuacao',
    templateUrl: './consulta-setor-atuacao.component.html',
    styleUrls: ['./consulta-setor-atuacao.component.scss']
        
})
export class ConsultaSetorAtuacaoComponent implements OnInit {
  @ViewChild('childFilter', {static: false}) child;
  @Input() itemsPerView: number = 10;
    // data: Array<any> = null;
    subscription: Subscription;
    setoresDeAtuacao: Array<any> = []
    loading: boolean = false;
    // itemsPerView: number = 10;
    requests: any = [];
    numeroSetores: string = '10'
    setorSelecionado: Array<any> = [];
    visible = '';
    data: Object = {
      primeiraPagina: true,
      ultimaPagina: false,
      paginaAtual: 1,
      totalPaginas: 1,
      totalDeItems: 0,
      itemsPorPagina: 10
    };

    filterCurrent: Object = {
      pagina: 1,
      total_Pagina: 10
    };

    constructor(private router: Router, private setorAtuacaoService: SetorAtuacaoService) {
        this.data = [];
    }

    ngOnInit() {
        this.subscription = this.setorAtuacaoService.setorAtuacao$.subscribe(
          (res) => {
            this.data = res;
            this.filterCurrent = this.child.searchParams;
            this.setoresDeAtuacao = res["resultado"];
            this.data['totalDeItems'] = res.totalRegistros;
            this.data['totalPaginas'] = res['totalPaginas'];
            this.data['primeiraPagina'] = res.primeiraPagina;
            this.data['ultimaPagina'] = res.ultimaPagina;
            this.loading = false;
            console.log(res);
          }, error => console.log(error))
        this.getSetorAtuacao()
    }

    getSetorAtuacao() {
      this.loading = true;
      let parametros = {};
      parametros['pagina'] = this.data['paginaAtual'];
      parametros['total_pagina'] = this.itemsPerView;
      if (this.child.searchParams.descricao) {
        parametros['descricao'] = this.child.searchParams.descricao;
      } else {
        delete parametros['descricao'];
      }
      // this.descricao ? parametros['descricao'] = this.child.searchParams.descricao : delete parametros['descricao'];
      if (this.child.searchParams.ativo) {
        parametros['ativo'] = this.child.searchParams.ativo;
      } else {
        delete parametros['ativo'];
      }
      this.setorAtuacaoService.getSetorAtuacao(parametros).subscribe(res => {
        this.loading = false;
        if(res.tamanhoPagina) {
            this.data = res;
            this.data['totalDeItems'] = res.totalRegistros;
            this.data['totalPaginas'] = res['totalPaginas'];
            this.loading = false;
          }     
      }, error => {
        this.loading = false;
      });
    }

    next() {
      this.data['paginaAtual']++;
      // this.onPageChange(this.filterCurrent);
      this.getSetorAtuacao();
    }
  
    previous() {
      this.data['paginaAtual']--;
      // this.onPageChange(this.filterCurrent);
      this.getSetorAtuacao();
    }

    ultimaPagina() {
      this.data['paginaAtual'] = this.data['totalPaginas'];
      this.onPageChange(this.filterCurrent);
    }

    primeiraPagina() {
      this.data['paginaAtual'] = 1;
      this.onPageChange(this.filterCurrent);
    }

    onPageChange(value) {
      // value['ativo'] = this.data['ativo'];
      value['pagina'] = this.data['paginaAtual'];
      value['total_pagina'] = this.itemsPerView;

      this.filterCurrent = value;
      this.loading = true;

      this.setorAtuacaoService.getSetorAtuacao(value).subscribe(
          res => {
              this.setoresDeAtuacao = res["resultado"];
              this.loading = false;
          },
          error => {
              this.loading = false;
              console.error('Erro ao obter as personalidades jurídicas:', error);
          }
      );
    }

    ativarSetores() {
      this.loading = true;
      let value = {};
      this.visible = '';

      this.setorSelecionado.forEach(res => {
          value = {
              codigo: res.codigo,
              ativo: true,
              descricao: res.descricao
          }
          this.requests.push(this.setorAtuacaoService.editaSetorAtuacao(value, res.codigo))
      })

      this.executarForkJoin();
  }


  inativarSetores() {
      this.loading = true;
      let value = {};
      this.visible = '';

      this.setorSelecionado.forEach(res => {
          value = {
              codigo: res.codigo,
              ativo: false,
              descricao: res.descricao
          }
          this.requests.push(this.setorAtuacaoService.editaSetorAtuacao(value, res.codigo))
      })

      this.executarForkJoin();
  }

  setSelectedEntities(items) {
    this.setorSelecionado = items;
  }

  toggleVisibility(item: string) {
    if (item === this.visible) {
      this.visible = '';
    } else {
      this.visible = item;
    }
  }

  executarForkJoin() {
      forkJoin(this.requests).subscribe(res => {
          this.setorSelecionado = [];
          this.requests = [];
          this.loading = false;
          this.getSetorAtuacao()
          this.setorAtuacaoService.setorAtuacao$.subscribe(
            (res) => {
              this.data = res;
              this.setoresDeAtuacao = res["resultado"];
              this.data['totalDeItems'] = res.totalRegistros;
              this.data['totalPaginas'] = res['totalPaginas'];
              this.loading = false;
              console.log(res);
            }
          );

      }, error => {
          this.setorSelecionado = [];
          this.requests = [];
          this.loading = false;  
      })
  }

    quantidadeSetores(numeroSetores) {
      this.itemsPerView = +numeroSetores;
    
      this.data['paginaAtual'] = 1;
      // this.filterCurrent['pagina'] = 1;
      // this.filterCurrent['total_pagina'] = this.data['itemsPorPagina'];
    
      this.onPageChange(this.filterCurrent);

      this.data['paginaAtual'] = 1;
    
      switch (numeroSetores) {
        case "10":
          this.data['itemsPorPagina'] = 10;
          console.log(this.data['itemsPorPagina']);
          break;
        case "20":
          this.data['itemsPorPagina'] = 20;
          console.log(this.data['itemsPorPagina']);
          break;
        case "50":
          this.data['itemsPorPagina'] = 50;
          console.log(this.data['itemsPorPagina']);
          break;
        case "100":
          this.data['itemsPorPagina'] = 100;
          console.log(this.data['itemsPorPagina']);
          break;
      }
    
      this.loading = true;
      // return this.getSetorAtuacao()
    }
    
    edit(codigo:number) {
        this.router.navigate([`configuracao/setor-atuacao/editar/${codigo}`])
      }

    redirecionar() {
        this.router.navigate(['configuracao/setor-atuacao/incluir'])
    }
}