import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subject, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';


@Injectable()
export class CaixaPostalService {

  private mensagens = new Subject<any>();
  private filtroListaUsuarios = new Subject<any>();
  mensagens$ = this.mensagens.asObservable();

  url: string = environment.BASE_URL;

  constructor(private http: HttpClient) { }

  getMessagesNumbers(): Observable<any> {
    const comResposta = this.http.get(`${this.url}/mensagens/totalMensagensComRespostas`).pipe(map(res => res));
    const enviadas = this.http.get(`${this.url}/mensagens/totalMensagensEnviadas`).pipe(map(res => res));
    const naoRespondidas = this.http.get(`${this.url}/mensagens/totalMensagensSemRespostas`).pipe(map(res => res));
    const lidos = this.http.get(`${this.url}/mensagens/totalMensagensLidas`).pipe(map(res => res));

    return forkJoin([comResposta, enviadas, naoRespondidas, lidos]);
  }


  getMensagens(searchParams): Observable<any> {
    const params = this.setParams(searchParams);
    return this.http.get(`${this.url}/mensagens`, { params })
      .pipe(map(data => {
        const body = data;
        this.mensagens.next(body);
        return body;
      }));
  }
  
  getListaPaginacaoResposta(obj): Observable<any> {
    const params = this.setParams(obj);

    return this.http.get(`${this.url}/mensagens/listaPaginacao`, { params })
      .pipe(map(data => {
        const body = data;
        this.mensagens.next(body);
        return body;
      }));
  }

  getListaUsuariosRemetentes(): Observable<any> {
    return this.http.get(`${this.url}/mensagens/usuariosRemetentes`)
      .map(data => {
        const body = data;
        this.filtroListaUsuarios.next(body);
        return body;
      });
  }

  consultarUsuario(id: string): Observable<any> {
    const InterceptorSkipHeader = 'X-Skip-Interceptor';
    const headers = new HttpHeaders().set(InterceptorSkipHeader, '');
    const params = new HttpParams().set('cpf', id);
    return this.http
      .get(`${this.url}/usuarios`, { headers: headers, params: params })
      .map(data => {
        return data;
      });
  }

  setParams(searchParams: any) {
    let params = new HttpParams();
    if (!searchParams) {
      return params;
    }
    if (searchParams.key && searchParams.value) {
      return params.set(searchParams.key, searchParams.value);
    }
    for (const key of Object.keys(searchParams)) {
      params = params.set(key, searchParams[key]);
    }
    return params;
  }

  consultarMensagem(codigo: any): Observable<any> {
    const protocolo = codigo;
    return this.http.get(`${this.url}/mensagens/${protocolo}`).pipe(map(data => {
      return data;
    }));
  }

  consultarMensagemPadrao(codigo: any): Observable<any> {
    const codigoMensagem = codigo;
    return this.http.get(`${this.url}/comunicadoPadrao/${codigoMensagem}`).pipe(map(data => {
      return data;
    }));
  }

  consultarContribuinte(cpfCnpj: string): Observable<any> {
    const params = new HttpParams().set('cpf_cnpj', cpfCnpj);
    return this.http.get(`${this.url}/contribuintes`, { params }).pipe(map(data => {
      return data;
    }));
  }

  mensagemLida(protocolo): Observable<any> {
    const params = new HttpParams().set('protocolo', protocolo);
    return this.http.get(`${this.url}/mensagens/marcarComoLida`, { params }).pipe(map(data => {
      return data;
    }));
  }

  arquivarMensagem(protocolo): Observable<any> {
    const params = new HttpParams().set('protocolo', protocolo);
    return this.http.get(`${this.url}/mensagens/arquivar`, { params }).pipe(map(data => {
      return data;
    }));
  }

  desarquivarMensagem(protocolo): Observable<any> {
    const params = new HttpParams().set('protocolo', protocolo);
    return this.http.get(`${this.url}/mensagens/desarquivar`, { params }).pipe(map(data => {
      return data;
    }));
  }

  gerarDocumentoCiencia(protocolo, certificado): Observable<any> {
    const fd = new FormData();
    fd.append('certificado', certificado);
    return this.http.post(`${this.url}/mensagens/${protocolo}/gerarDocumentoCiencia`, fd, {
      headers: new HttpHeaders().set('Content-Type', 'multipart/form-data').set('charset', 'utf-8')
    }).pipe(map(data => {
      return data;
    }));
  }

  darCiencia(protocolo, documento): Observable<any> {
    return this.http.post(`${this.url}/mensagens/${protocolo}/darCiencia`, documento)
      .pipe(map(data => {
        const body = data;
        return body;
      }));
  }

  gerarDossie(protocolo): Observable<any> {
    return this.http.get(`${this.url}/mensagens/${protocolo}/dossie`, {
      responseType: 'arraybuffer'
    }).pipe(map(data => {
      return data;
    }));
  }
  
  gerarTermoCiencia(protocolo): Observable<any> {
    return this.http.get(`${this.url}/mensagens/${protocolo}/termoCiencia`, {
      responseType: 'arraybuffer'
    }).pipe(map(data => {
      return data;
    }));
  }

  gerarProtocolo(protocolo): Observable<any> {
    return this.http.get(`${this.url}/mensagens/${protocolo}/protocolo`, {
      responseType: 'arraybuffer'
    });
  }

  nomearAdvogado(protocolo, procuracoes): Observable<any> {
    return this.http.post(`${this.url}/mensagens/${protocolo}/nomearAdvogados`, procuracoes)
      .pipe(map(data => {
        const body = data;
        return body;
      }));
  }

  responderMensagem(protocolo, mensagem, anexos): Observable<any> {
    const fd = new FormData();
    fd.append('protocolo', protocolo);
    fd.append('mensagem', mensagem);
    Array.from(Array(anexos.length).keys()).map(x => {
      fd.append('anexos', anexos[x], anexos[x].name);
    });
    return this.http.post(`${this.url}/mensagens/responder`, fd, {
      headers: new HttpHeaders().set("Content-Type", "multipart/form-data,charset=UTF-8")
        .set("Content-Transfer-Encoding", "charset=UTF-8")
    }).pipe(map(data => {
      return data;
    }));
  }

  baixarAnexo(protocolo, codigoAnexo) {
    return this.http.get(`${this.url}/mensagens/${protocolo}/anexos/${codigoAnexo}`, {
      responseType: 'arraybuffer'
    }).pipe(map(data => {
      return data;
    }));
  }

  baixarTodosAnexos(protocolo) {
    return this.http
      .get(`${this.url}/mensagens/${protocolo}/anexos`, {
        headers: new HttpHeaders().set("Content-Type", "application/zip"),
        responseType: "arraybuffer"
      })
      .map(data => {
        return data;
      });
  }

  enviarMensagem(mensagem) {
    return this.http.post(`${this.url}/mensagens/enviar`, mensagem).pipe(map(data => {
      return data;
    }));
  }

  importarDestinatarios(arquivo: string): Observable<any> {
    const fd = new FormData();
    fd.append('arquivo', arquivo);
    return this.http
      .post(`${this.url}/importacoes/destinatarios`, fd)
      .pipe(map(data => {
        return data;
      }));
  }

  obterComunicados(): Observable<any> {
    return this.http
      .get(`${this.url}/mensagens/graficocomunicados`)
      .map(data => {
        const body = data['resultado'];
        return body;
      });
  }

  obterComunicadosCienciaTacita(): Observable<any> {
    return this.http
      .get(`${this.url}/mensagens/graficocienciatacita`)
      .map(data => {
        const body = data['resultado'];
        return body;
      });
  }

  obterComunicadosExigeResposta(): Observable<any> {
    return this.http
      .get(`${this.url}/mensagens/graficoexigeresposta`)
      .map(data => {
        const body = data['resultado'];
        return body;
      });
  }

  obterComunicadosExigeLeitura(): Observable<any> {
    return this.http
      .get(`${this.url}/mensagens/graficoexigeleitura`)
      .map(data => {
        const body = data['resultado'];
        return body;
      });
  }
}
