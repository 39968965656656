import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-perfis-acesso',
  templateUrl: 'perfis-acesso.component.html',
  styleUrls: ['./perfis-acesso.component.scss']
})

export class PerfisAcessoComponent implements OnInit {

  constructor() {}

  ngOnInit() {}


}