import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class HeaderService {

    changeLogo = new BehaviorSubject(true);

    updateLogo(){
        this.changeLogo.next(true);
    }


}