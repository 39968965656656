import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/map';
import { environment } from '../../../environments/environment';


@Injectable()
export class AssinaturaEletronicaService {

  url: string = environment.BASE_URL;

  private assinaturaEletronica = new Subject<any>();
  assinaturaEletronica$ = this.assinaturaEletronica.asObservable();

  constructor(private http: HttpClient) {}

  getAssinaturaEletronica(searchParams): Observable<any> {
    const params = this.setParams(searchParams);
    return this.http.get(`${this.url}/assinaturasEletronicas`, {params: params})
      .map(data => {
        const body = data;
        this.assinaturaEletronica.next(body);
        return body;
      });
  }

  setParams(searchParams: any){
    let params = new HttpParams();
    if (!searchParams) {
      return params;
    }
    if (searchParams.key && searchParams.value) {
      return params.set(searchParams.key, searchParams.value);
    }
    for (const key of Object.keys(searchParams)) {
      params = params.set(key, searchParams[key]);
    }
    return params;
  }


  obterAssinaturaEletronica(id: number): Observable<any> {
    return this.http.get(`${this.url}/assinaturasEletronicas/${id}`)
      .map(data => {
        const body = data;
        return body;
      });
  }

  editaAssinaturaEletronica(params: any, code): Observable<any> {
    const assinaturaEletronica = params;
    const codigo = code;
    return this.http.put(`${this.url}/assinaturasEletronicas/${codigo}`, assinaturaEletronica)
      .map(data => {
        const body = data;
        return body;
      });
  }

}
