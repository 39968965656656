import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/forkJoin';
import 'rxjs/add/observable/throw';
import { environment } from '../../../environments/environment';

@Injectable()
export class ServicoComunicacaoService {
  url: string = environment.BASE_URL;

  constructor(private http: HttpClient) {}

  obterServidoresComunicacao(codigoCliente:number): Observable<any> {
    return this.http.get(`${this.url}/clientes/${codigoCliente}/servidoresComunicacao`).map(data => data);
  }
  

  atualizarServidoresComunicacao(codigoCliente: number, cliente: any): Observable<any> {
    return this.http.put(`${this.url}/clientes/${codigoCliente}/servidoresComunicacao`, cliente)
      .map(data => {
        const body = data;
        return body;
      });
  }
}
