import { Component, OnInit } from '@angular/core';
import { trigger, transition, animate, style, keyframes } from '@angular/animations';
import { FormGroup, FormBuilder, Validators, MinLengthValidator } from '@angular/forms';
import { Router, NavigationExtras } from '@angular/router';
import { RecuperarSenhaService } from '../../../service/recuperar-senha/recuperar-senha.service';

@Component({
  selector: 'app-recuperar-senha',
  templateUrl: 'recuperar-senha.component.html',
  styleUrls: ['./recuperar-senha.component.scss'],
  animations: [
    trigger('shake', [
      transition(':enter', animate(400, keyframes([
        style({ transform: 'translate(30px)', offset: .0 }),
        style({ transform: 'translate(-30px)', offset: .20 }),
        style({ transform: 'translate(15px)', offset: .40 }),
        style({ transform: 'translate(-15px)', offset: .60 }),
        style({ transform: 'translate(8px)', offset: .80 }),
        style({ transform: 'translate(0px)', offset: 1 }),
      ]))),
    ])
  ]
})

export class RecuperarSenhaComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  opcao = '0';
  usuarioValido = false;
  celular;
  cpf = '';
  errorMessage: string = null;
  hasError = false;
  emailEnviado = false;
  userData: Object = {};
  loading = false;

  constructor(private fb: FormBuilder, private router: Router, private recuperarSenhaService: RecuperarSenhaService) { }

  ngOnInit() {
    this.criarFormulario();
  }

  criarFormulario() {
    this.forgotPasswordForm = this.fb.group({
      cpf: ['', Validators.compose([Validators.required, Validators.minLength(11), Validators.maxLength(11)])],
      email: ['', Validators.compose([Validators.required, Validators.maxLength(60)])],
    });
  }

  verificarUsuario() {
    this.loading = true;
    this.hasError = false;
    const credentials = this.forgotPasswordForm.value;
    this.recuperarSenhaService.solicitaRecuperacaoSenhaAuditor(credentials)
      .subscribe(res => {
        this.loading = false;
        this.router.navigate(['confirmar']);
      }, error => this.handleError(error));
  }

  handleError(err) {
    this.loading = false;
    this.hasError = true;
    if (err.error.excecao.codigo === 'E002') {
      this.errorMessage = 'Usuário não encontrado. Verifique seus dados e tente novamente.';
    } else {
      this.errorMessage = err.error.excecao.mensagem;
    }
  }

}

