import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment'


@Injectable()
export class PermissoesService {

  url: string = environment.BASE_URL;

  constructor(private http: HttpClient) { }

  obterPermissoes(): Observable<any> {
    return this.http.get(`${this.url}/obterPermissoesAcesso`).pipe(map(data => {
      return data;
    }));
  }

}
