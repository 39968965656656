import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

import { DatePickerComponent } from 'ng2-date-picker';
import { DatePipe } from '@angular/common';
import { ComunicadosService } from '../../../../service/comunicados/comunicados.service';

import * as moment from 'moment';
// import { EventEmitter } from 'events';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[app-filtrar-comunicados]',
  templateUrl: 'filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FiltrarComunicadosComponent implements OnInit {
  _itemsPerView: any;

  @Input()
  set itemsPerView(value: any) {
    this._itemsPerView = value;
  }
  @Output() onOpen: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFilter: EventEmitter<any> = new EventEmitter<any>();

  get itemsPerView(): any {
    return this._itemsPerView;
  }
  searchForm: FormGroup;
  visible = '';
  inicio;
  fim;
  prazo;
  dataInicial = 'Data de Inclusão';
  validades = [];
  situacao = 'Situação';
  situacoes = [];
  arquivados = 'Arquivados';
  dataInicialValida = false;
  dataFinalValida = false;
  listaComunicados: Array<any> = [];
  ativo: boolean = null;
  criadoPor: string = 'Criado Por'
  tipo: string = 'nome';
  criadoPorForm: FormGroup;
  sessaoFiltro: any;
  criadorComunicado = false;
  displayCombo: String = 'none';
  usuariosCriadoPor = [];
  codUsuario : '';
  numero: any;

  data: Object = {
    resultado: [],
    primeiraPagina: true,
    ultimaPagina: false,
    paginaAtual: 1,
    totalPaginas: 1,
    totalDeItems: 0
  };

  assunto;

  searchParams: Object = {
    pagina:  1,
    total_pagina: 10
  };

  errorMessage = '';

  allowClearFilter = false;
  loading: boolean = false;
  pesquisa: string = '';
  debounce: Subject<string> = new Subject<string>();
  dataInclusaoValida: boolean = false;
  filtrosAplicados: boolean = false;

  constructor(
    private fb: FormBuilder,
    private comunicados: ComunicadosService,
  ) {
    this.criarFormulario();
  }

  ngOnInit() {
    this.criarFormularioCriadoPor();
    this.getListaUsuariosCriadoPor();
    this.getComunicados();
    this.configurarSubject();
  }

  ngAfterViewChecked(): void {
    this.sessaoFiltro = JSON.parse(sessionStorage.getItem('filtro'));

    if(this.sessaoFiltro && (this.sessaoFiltro.codUsuario)){
      // this.criadorComunicado =  true;
      if(this.sessaoFiltro.codUsuario) {
        this.criadoPorForm.get('codUsuario').setValue(this.sessaoFiltro.codUsuario);
      }else if(this.sessaoFiltro.codUsuario) {
        this.criadoPorForm.get('codUsuario').setValue(this.sessaoFiltro.codUsuario);
      }
    } else {
      // this.criadorComunicado = false;
    }
  }

  alterarIdentificador(tipo: string) {
    switch (tipo) {
      case 'nome':
        this.criadoPorForm.controls.numero.setValue('');
        this.criadoPorForm.controls.numero.updateValueAndValidity();
        this.displayCombo = 'block'
        break;
    }
  }

  getComunicados() {
    this.loading = true;
    this.comunicados.getComunicados({}).subscribe(res => {
      this.loading = false;
      this.handleResponse(res);
      this.search();
    })
  }

  getListaUsuariosCriadoPor(){
    this.comunicados.getListaUsuarios().subscribe(
      response => {
        this.usuariosCriadoPor = response.resultado;
      },
      error => console.log(error)
    );
  }

  criarFormulario() {
    this.searchForm = this.fb.group({
      assunto: '',
    });
  }

  criarFormularioCriadoPor() {
    this.criadoPorForm = this.fb.group({
      tipo: '',
      codUsuario: '',
    });
  }

  handleResponse(res) {
    this.allowClearFilter = false;
  }

  selecionarDataInicial() {
    const dataAtual = new Date();
    const dataSelecionada = moment(this.inicio);

    if (dataSelecionada.isAfter(dataAtual)) {
      this.dataInclusaoValida = false;
      return;
    }
    if (typeof this.inicio !== 'undefined') {
      this.dataInicial = this.inicio;
      this.dataInicialValida = true;
      this.dataInclusaoValida = true;
    }
  }

  toggleVisibility(item: string) {
    if (item === this.visible) {
      this.visible = '';
    } else {
      this.visible = item;
    }
  }

  statusFilter(e: any) {
    const value = e.target.name;
    console.log('setando valor :: ', value);
    this.situacao = value;
    this.ativo = e.target.value;
  }

  onSearchByTitle() {
    this.searchParams['pagina'] = 1;
    this.data['paginaAtual'] = 1;
    if(this.pesquisa.length !== 0) {
      this.searchParams['descricao'] = this.pesquisa;
    } else {
      delete this.searchParams['descricao'];
    }

    this.search();
    this.visible = '';
  }

  onSearchByDate() {
    const dataAtual = new Date();
    const dataSelecionada = moment(this.inicio);

    if (dataSelecionada.isAfter(dataAtual)) {
      this.dataInclusaoValida = false;
      return;
    }

    const date = moment(this.inicio).format('YYYY-MM-DD');
    this.filtrosAplicados = true;
    this.searchParams['data_criacao'] = date;
    this.search();
    this.visible = '';
  }

  onSearchByStatus() {
    this.filtrosAplicados = true;
    this.searchParams['ativo'] = this.ativo;
    this.searchParams['pagina'] = 1;
    this.data['paginaAtual'] = 1;
    this.search();
    this.visible = '';
  }

  onSearchByCreator() {
    this.filtrosAplicados = true;
    this.searchParams['pagina'] = 1;
    this.data['paginaAtual'] = 1;
    const value = this.criadoPorForm.value;
    if (!value.codUsuario) {
      return;
    }

    this.criadorComunicado = true;
    let obj = {
      codUsuario: value.codUsuario,
    };

    this.onFilter.emit(obj);
    this.searchParams['codUsuario'] = value.codUsuario;
    console.log('escolhido: ', value);

    this.search();
    this.visible = '';
  }

  limpar() {
    this.dataInclusaoValida = false;
    this.situacao = 'Situação';
    this.dataInicial = 'Data de Inclusão';
    this.dataInicialValida = false;
    this.inicio = null;
    this.ativo = null;
    this.criadorComunicado = false;
    this.criadoPor = 'Criado Por'
    this.searchForm.reset();
    this.searchParams = {};
    this.loading = true;
    this.displayCombo = 'none';
    this.filtrosAplicados = false;
    this.getComunicadosFiltro();
  }

  limparTodosFiltros() {
    this.dataInclusaoValida = false;
    this.situacao = 'Situação';
    this.ativo = null;
    delete this.searchParams['ativo'];
    this.dataInicial = 'Data de Inclusão';
    this.dataInicialValida = false;
    this.inicio = null;
    delete this.searchParams['data_criacao'];
    this.visible = '';
    this.criadoPor = 'Criado Por'
    this.criadorComunicado = false;
    this.displayCombo = 'none';
    this.filtrosAplicados = false;
    delete this.searchParams['codUsuario'];
    this.search();
  }

  getComunicadosFiltro(){
    var parametros = {};
    parametros['pagina'] = 1;
    // parametros['total_pagina'] = 10;
    this.comunicados
      .getComunicados(parametros)
      .subscribe(
        res => {
          this.loading = false;
          this.data = res;
          this.data['paginaAtual'] = 1;
          this.data['totalPaginas'] = res.totalPaginas;
          this.data['primeiraPagina'] = res.primeiraPagina;
          this.data['ultimaPagina'] = res.ultimaPagina;
          this.handleResponse(res);
        },
        error => (this.errorMessage = error),
      );
  }

  limparFiltro(item) {
    if (item === 'data') {
      this.dataInclusaoValida = false;
      this.dataInicial = 'Data de Inclusão';
      this.inicio = null;
      delete this.searchParams['data_criacao'];
    }
    if (item === 'situacao') {
      this.situacao = 'Situação';
      this.ativo = null;
      delete this.searchParams['ativo'];
    }
    else {
      this.criadorComunicado = false;
      this.displayCombo = 'none';
      this.codUsuario = null
      this.criadoPor = 'Criado Por'
      delete this.searchParams['codUsuario'];
    }
    this.searchParams['pagina'] = 1;
    this.data['paginaAtual'] = 1;
    this.filtrosAplicados = false;
    this.search();
  }

  setSearch() {
    this.searchParams['pagina'] = 1;
    this.data['paginaAtual'] = 1;
    this.search();
  }

  search() {
    this.allowClearFilter = false;

    if (this.searchParams['descricao'] === null) {
      delete this.searchParams['descricao'];
    }

    if (this.searchParams['descricao']) {
      this.allowClearFilter = true;
    }

    if (this.searchParams['data_criacao'] === null) {
      delete this.searchParams['descricao'];
    } else {
    }

    if (!this.searchParams['data_criacao']) {
      this.dataInicial = 'Data de Inclusão';
      this.dataInicialValida = false;
      this.inicio = null;
    } else {
      this.allowClearFilter = true;
    }

      if (this.searchParams["ativo"] === null) {
        delete this.searchParams["ativo"];
      }

      if (!this.searchParams["ativo"]) {
        this.situacao = "Situação";
        this.ativo = null;
      } else {
        this.allowClearFilter = true;
      }
      if (this.searchParams["ativo"] === null) {
        delete this.searchParams["ativo"];
      }

      if(this.searchParams['codUsuario'] === '') {
        delete this.searchParams['codUsuario']
      }

    this.visible = '';
    this.loading = true;

    this.searchParams['pagina'] = 1;
    // this.searchParams['total_pagina'] = 10;

     this.comunicados.getComunicados(this.searchParams).subscribe(res => {
      this.loading = false;
      this.data = res;
    });
  }

  next() {
    if (!this.data['ultimaPagina']) {
      this.searchParams['pagina'] = this.data['paginaAtual'] + 1;
      this.search();
    }
  }


  previous() {
    if(!this.data['primeiraPagina']) {
      this.searchParams['pagina'] = this.data['paginaAtual'] - 1;
      this.search();
    }
  }

  configurarSubject() {
    this.debounce
      .pipe(debounceTime(500))
      .subscribe(filter => {
        this.pesquisa = filter;
        this.onSearchByTitle();
      });
  }

  botaoSelecionado() {
    this.onOpen.emit('criadorComunicado');
    this.sessaoFiltro = JSON.parse(sessionStorage.getItem('filtro'));

    if(this.sessaoFiltro) {
      if(this.sessaoFiltro.codUsuario){
        this.alterarIdentificador('nome');
      }
    }

  }
}
