import { Component, ViewChild, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ParametroAssinaturaDigitalService } from '../../../../../service/parametro-assinatura-digital/parametro-assinatura-digital.service';
import { AmbienteService } from '../../../../../service/ambiente/ambiente.service';
import { Subscription } from '../../../../../../../node_modules/rxjs';

import Swal from 'sweetalert2';
import { getSwal } from '../../../../../utils/swalObject';
import { ActivatedRoute, Router } from '@angular/router';
import { RegrasAcessoService } from "../../../../../service/configuracao/regras-acesso.service";

@Component({
  selector: 'app-editar-regras-acesso',
  templateUrl: './editar-regras-acesso.component.html',
  styleUrls: ['./editar-regras-acesso.component.scss']
})
export class EditarRegrasAcessoComponent implements OnInit {
  @ViewChild('childFilter', {static: false}) child;
  routeSub: Subscription;
  editarRegraAcesso: FormGroup;
  loading: boolean;
  idPerfilAcesso: number = null;
  dadosDeAmbiente: any = {};
  registroSalvo: any = [];
  tiposFormaAcesso = [];
  tiposFormaAcessoLoop = [];
  tiposFormaAcessoLength = 0;
  tiposPersonalidadeJuridica = [];
  tiposPersonalidadeJuridicaLength = 0
  tiposPersonalidadeJuridicaLoop = [];
  tiposSetorAtuacao = [];
  tiposSetorAtuacaoLength = 0
  tiposSetorAtuacaoLoop = [];
  tipoUsuario: any = {
    codigo: -1,
    descricao: 'Selecione'
  };
  tipoAcesso: any = {
    codigo: -1,
    descricao: 'Selecione'
  };

  tipoPersonalidade: any = {
    codigo: -1,
    descricao: 'Selecione'
  };
  autoCadastro: number = null;
  exigeCertificadoCnpj: boolean = false;
  teste: string = 'teste';
  data = [];
  registros = [];

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private ambienteService: AmbienteService,
    private parametroAssinaturaDigitalService: ParametroAssinaturaDigitalService,
    private regrasAcessoService: RegrasAcessoService
  ) {

    this.routeSub = this.route.params.subscribe(params => {
      if (params['id']) {
        this.idPerfilAcesso = params['id'];
      }
    });
  }

  ngOnInit() {
    this.criarFormulario();
    this.regrasAcessoService.obterPersonalidadeJuridica().subscribe(async res => {
      this.tiposPersonalidadeJuridicaLoop = await this.tiposPersonalidadeJuridicaLoop.concat(res.resultado);
    });
    this.regrasAcessoService.obterPersonalidadeJuridica().subscribe(async res => {
      this.tiposPersonalidadeJuridicaLength = await res.resultado.length;
    });

    this.regrasAcessoService.obterFormaAcesso().subscribe(async res => {
        this.tiposFormaAcessoLength = await res.resultado.length;
    })
  }

  ngAfterViewInit() {
    if (this.idPerfilAcesso) {
      this.obterDados();
    }
  }

  obterDados() {
    this.regrasAcessoService.getRegrasAcessoEditar(this.idPerfilAcesso).subscribe(
      res => {
        this.registroSalvo = res.resultado
        console.log('this.registroSalvo:', this.registroSalvo)
      },
      error => {
        Swal.fire(
          getSwal(
            'error',
            'Ops!',
            'Ocorreu um erro e o Ambiente não pôde ser carregado.',
            'ok',
            null
          )
        );
      }
    );
  }


  functionPersonalidade(e) {
    this.tiposSetorAtuacaoLoop = [];
    this.tiposFormaAcessoLoop = [];

    if (e.codigo != -1) {
      if (this.registroSalvo.length === 0) {
        this.regrasAcessoService.obterSetoresAtuacao().subscribe(async res => {
          this.tiposSetorAtuacaoLoop = await this.tiposSetorAtuacaoLoop.concat(res.resultado);
        });
        this.regrasAcessoService.obterFormaAcesso().subscribe(async res => {
          this.tiposFormaAcessoLoop = await this.tiposFormaAcessoLoop.concat(res.resultado);
        });
      } else {
        this.regrasAcessoService.obterSetoresAtuacao().subscribe(async res => {
          var i = 0;
          res.resultado.forEach(element => {
            var qtd = 0
            
            this.registroSalvo.forEach(ele => {
              if (ele.setorAtuacao.codigo === element.codigo && ele.personalidadeJuridica.codigo === e.codigo) {
                qtd++
              }
            });
            
            if (qtd >= this.tiposFormaAcessoLength) {
              res.resultado[i].bloqueado = true
            } else {
              res.resultado[i].bloqueado = false
            }

            i++
          });

          this.tiposSetorAtuacaoLoop = await this.tiposSetorAtuacaoLoop.concat(res.resultado);
        });
        this.regrasAcessoService.obterFormaAcesso().subscribe(async res => {
          this.tiposFormaAcessoLoop = await this.tiposFormaAcessoLoop.concat(res.resultado);
        });
      }
    }
  }

  functionAcesso(e) {
    
    console.log(this.registroSalvo)
    this.tiposFormaAcessoLoop.forEach(element => {
    });

    this.tiposFormaAcesso = []
    this.tiposFormaAcessoLoop.forEach(tipo => {
      tipo.selecionado = false
      tipo.bloqueado = false
      this.registroSalvo.forEach(element => {
        console.log('salvo', element);
        if (this.tipoPersonalidade.codigo === element.personalidadeJuridica.codigo && e.codigo === element.setorAtuacao.codigo) {
          
          console.log('element atuacao', element.formaAcesso.codigo)
          if (element.formaAcesso.codigo === tipo.codigo)
            tipo.bloqueado = true
        }
      });
      this.tiposFormaAcesso = this.tiposFormaAcesso.concat(tipo);
    });
  }

  criarFormulario() {
    this.editarRegraAcesso =  this.fb.group({
      codigo:0,
        formaAcesso: {
          codigo: -1,
          descricao: 'Selecione'
        },
        personalidadeJuridica: {
          codigo: -1,
          descricao: 'Selecione'
        },
        setorAtuacao: {
          codigo: -1,
          descricao: 'Selecione'
        }
    });
  }

  openDialog(item) {
    // Swal.fire(item.tipoEventoTrilha.descricao, item.descricao, 'info');
    Swal.fire(
      getSwal(
        'info',
        item.tipoEventoTrilha.descricao,
        item.descricao,
        'ok',
        null
      )
    );
  }



  checkChanges() {
    let value = this.editarRegraAcesso.getRawValue();
    for (let key in value) {
      if (
        value[key] !== this.dadosDeAmbiente[key]
      ) {
        this.dadosDeAmbiente[key] = value[key];
      }
    }
    return this.dadosDeAmbiente;
  }

  salvarRegraAcesso() {
    const obj = this.checkChanges();
    this.regrasAcessoService
      .inserirRegrasAcesso(this.idPerfilAcesso, obj)
      .subscribe(
        res => {
          Swal.fire(
            getSwal(
              'success',
              'Sucesso',
              'Ambiente atualizado com sucesso.',
              'ok',
              null
            )
          ).then(res => {
            this.router.navigate(['/configuracao/ambiente/consultar-regras-acesso/'+this.idPerfilAcesso]);
          });
        },
        error => {
          console.log(error);
          Swal.fire(
            getSwal(
              'error',
              'Ops!',
              error.error.excecao.recomendacao,
              'ok',
              null
            )
          );
        }
      );
  }

  rotaDesistir(){
    this.router.navigate(['/configuracao/ambiente/consultar-regras-acesso/'+this.idPerfilAcesso]);
  }

  ajuda(valor) {
    let msg1=
    `<ol>
      <li>
        Definir o comportamento da solução quanto a forma do contribuinte acessar a plataforma.
      </li>
    </ol>`;

    switch(valor) {
      case 'regrasAcesso': {
        Swal.fire({
          icon: 'info',
          html:msg1,
          confirmButtonText:'Ok' ,
          
        })
        break;
      }
    }
  }
}