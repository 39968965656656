import { Component, OnInit, ViewChildren, Renderer2 } from '@angular/core';
import { AplicacoesService } from '../../../../service/aplicacoes/aplicacoes.service';
import Swal from 'sweetalert2';
import { getSwal } from '../../../../utils/swalObject';
import { IntegracaoService } from '../../../../service/integracao/integracao.service';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  RequiredValidator,
} from '@angular/forms';

import * as FileSaver from 'file-saver';
import { Router } from '@angular/router';

@Component({
  selector: 'app-exportar-funcionalidades',
  templateUrl: './exportar-funcionalidades.component.html',
  styleUrls: ['./exportar-funcionalidades.component.scss'],
})
export class ExportarFuncionalidadesComponent implements OnInit {
  @ViewChildren('sistemasLista') sistemasLista:any;
  public appData: Array<Object> =  []
  public appDataSelect: Array<Object> =  []
  public appDataSelectCodes: Array<number> =  []
  public loading: boolean = true;
  selectAppsToExport: FormGroup;

  //
  searchParams = {};
  constructor(
    private aplicacoes: AplicacoesService,
    private integraco: IntegracaoService,
    private renderer:Renderer2,
    private fb: FormBuilder,
    private router: Router
  ) {}

  ngOnInit() {
    this.aplicacoes.getAplicacoes(this.searchParams).subscribe(
      data => {
        data.resultado.forEach(item => {
          item.funcionalidades.length>0 ? this.appData.push(item) : console.log(item.funcionalidades.length);
          item.funcionalidades.forEach(item => {
            item["checked"] = false;
          });
        })
        this.loading = false;
      },
      error =>{
        this.loading = false;
        Swal.fire(
          getSwal(
            'error',
            'Ops!',
            'Ocorreu um erro e as aplicações não foram carregadas com sucesso.',
            'ok',
            null,
          ),
        )
      }
    );    
    this.criarFormulario();
    console.log(this.appData);
  }

  public onSubmit():void{
    let fileNameToSave = this.selectAppsToExport.controls['fileNameToDownload'].value 
    if(fileNameToSave < 1){
        Swal.fire(
          getSwal(
            'info',
            'Atenção!',
            'É necessário informar o nome do arquivo.',
            'ok',
            null,
          ),
        )
    }else{
      if(this.appDataSelect.length > 0){
        this.loading = true;
        this.integraco.exportFunctions(this.appDataSelect, fileNameToSave).subscribe(
          data => {
            console.log(data);
          },
          error =>{
            // console.log(error.status)
            if(error.status === 200){
            var blob = new Blob([error.error.text], { type: 'application/csv' });
            FileSaver.saveAs(blob, `${fileNameToSave}.csv`);
            Swal.fire(
              getSwal(
                'success',
                'Sucesso',
                'Arquivo exportado com sucesso',
                'ok',
                null,
              ),
            ).then(res => {
              this.router.navigate([`integracao/historico-funcionalidades/`]);
            });
            }else{
            Swal.fire(
              getSwal(
                'error',
                'Ops!',
                error.error.excecao.mensagem,
                'ok',
                null,
              ),
            )  
            }
            this.loading = false;
          }
        ); 
      }else{
        Swal.fire(
          getSwal(
            'error',
            'Atenção!',
            'Selecione ao menos uma funcionalidade para exportar.',
            'ok',
            null,
          ),
        )          
      }

    }
  }


  onChageApplication(item:Object, event:any, index:number){
    let parentIndex = this.appData.indexOf(item);
    let count: number = 0;
    if(event.target.checked){
      count = 0;
      item['funcionalidades'].forEach(item => {
        if(this.appDataSelectCodes.indexOf(item.codigo)==-1){
          this.appDataSelect.push({"codigo":item.codigo, "perfis":item.perfis});
          this.appDataSelectCodes.push(item.codigo);
          // console.log(this.appData[parentIndex]['funcionalidades'][count]);
          this.appData[parentIndex]['funcionalidades'][count]["checked"] = true;
          count ++;
        }
      });      
    }else{
      count = 0;
      let rowToremove: Array<number> = [];
      item['funcionalidades'].forEach(item => {
        rowToremove.push(item.codigo);
        this.appData[parentIndex]['funcionalidades'][count]["checked"] = false;
        count ++;        
      });   
      this.onRemoveFunctions(rowToremove)
    }
    console.log(this.appDataSelect);
  }

  onChageFunctions(item:Object, funcionalidade:Object, event:any, index:number){
    let parentIndex = this.appData.indexOf(item);
    let childIndex = this.appData[parentIndex]["funcionalidades"].indexOf(funcionalidade);
    let currentCheck = this.sistemasLista._results[0].nativeElement.children[index].children[0].children[0];
    let checkedRow:boolean[] = [];
    //console.log(currentCheck);
    if(event.target.checked){
      if(this.appDataSelectCodes.indexOf(funcionalidade["codigo"])==-1){
        this.appDataSelect.push({"codigo":funcionalidade["codigo"], "perfis":funcionalidade["perfis"]});
        this.appData[parentIndex]["funcionalidades"][childIndex]["checked"] = true;
      }
    }else{
      this.onRemoveFunctions([funcionalidade["codigo"]]);
      this.appData[parentIndex]["funcionalidades"][childIndex]["checked"] = false;
    }
    this.appData[parentIndex]["funcionalidades"].forEach(item => {
      checkedRow.push(item["checked"])
    });       
    checkedRow.includes(true) ? this.renderer.setProperty(currentCheck, 'checked', 'checked') :this.renderer.setProperty(currentCheck, 'checked', '');
    console.log(this.appDataSelect);
  }
  


  onRemoveFunctions(row:Array<number>):void{
    row.forEach(item => {
      let indexToRemove = this.appDataSelectCodes.indexOf(item)
      //
      this.appDataSelect.splice(indexToRemove, 1);
      this.appDataSelectCodes.splice(indexToRemove, 1);
    });   
  }

  criarFormulario() {
    this.selectAppsToExport = this.fb.group({
      fileNameToDownload: ['', Validators.required]
    });
  }  


}

