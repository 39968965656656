import { Component, OnInit, ViewChild } from '@angular/core';
import { IntegracaoService } from '../../../../service/integracao/integracao.service';

@Component({
  selector: 'app-historico-operacoes',
  templateUrl: './historico-operacoes-funcionalidades.component.html',
  styleUrls: ['./historico-operacoes-funcionalidades.component.scss']
})
export class HistoricoOperacoesFuncionalidadesComponent implements OnInit {
  @ViewChild('childFilter', {static: false}) child;

  itemsPerView: number = 10;
  itemsPorPagina: number = 10;
  paginaAtual: number = 1;
  data: Array<any> = [{}];
  registros: Array<any> = [];
  searchParams: Object = {
    pagina: this.paginaAtual,
    total_pagina: this.itemsPorPagina
  };
  public page: Object;
  public loading: boolean = false;

  constructor(private integracaoService: IntegracaoService) {}

  ngOnInit() {
    this.onLoadHistory();
  }

  next() {
    // this.child.next();
    this.paginaAtual++;
    this.searchParams['pagina'] = this.paginaAtual;
    this.onLoadHistory();
  }

  previous() {
    // this.child.previous();
    this.paginaAtual--;
    this.searchParams['pagina'] = this.paginaAtual;
    this.onLoadHistory();
  }

  onLoadHistory() {
    this.loading = true;
    return new Promise((resolve, reject) => {
      this.integracaoService.onGetHistory(this.searchParams).subscribe(
        res => {
          this.registros = res.resultado;
          // Troca de códigos de operações por descritivo:
          this.registros.forEach(item => {
            console.warn(item);
            // Tipo do Processo:
            switch (item['acao']) {
              case 0:
                item['acao'] = 'Importação';
                break;
              case 1:
                item['acao'] = 'Exportação';
            }
            // Etapa Processada
            switch (item['etapa']) {
              case 0:
                item['etapa'] = 'Análise de Estrutura';
                break;
              case 1:
                item['etapa'] = 'Análise de Conteúdo';
                break;
              case 2:
                item['etapa'] = 'Importação';
                break;
              case 3:
                item['etapa'] = 'Exportação';
            }
            // Situação
            switch (item['situacao']) {
              case 0:
                item['situacao'] = 'Falha no Processamento';
                break;
              case 1:
                item['situacao'] = 'Em processamento';
                break;
              case 2:
                item['situacao'] = 'Importação efetuada com sucesso';
            }
          });

          this.page = {
            paginaAtual: res.paginaAtual,
            totalPaginas: res.totalPaginas,
            primeiraPagina: res.primeiraPagina,
            ultimaPagina: res.ultimaPagina
          };
          this.loading = false;
          // this.processGetMessagesResult(res);
          // this.loading = false;
          resolve();
        },
        error => reject(console.log(error))
      );
    });
  }

  public testReturn(event: any): void {
    if (event.data_inicial === undefined) {
      event.data_inicial = '';
    }
    if (event.data_final === undefined) {
      event.data_final = '';
    }
    //
    this.searchParams = {
      data_inicial: event.data_inicial,
      data_final: event.data_final,
      pagina: event.pagina,
      total_pagina: this.itemsPorPagina
    };
    console.log(this.searchParams);
    this.onLoadHistory();
  }
}
