import { Component, OnInit, AfterViewInit, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LacunaWebPKI, CertificateModel, ExceptionModel } from '../../../../../../assets/plugins/web-pki';
import { CertificadoService } from '../../../../../service/certificado/certificado.service';
import { CaixaPostalService } from '../../../../../service/caixa-postal/caixa-postal.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import Swal from 'sweetalert2';
import { getSwal } from '../../../../../utils/swalObject';

@Component({
  selector: 'app-certificados',
  templateUrl: './certificados.component.html',
  styleUrls: ['./certificados.component.scss']
})


export class CertificadosComponent implements OnInit {
  LacunaWebPKI: Object = {};

  private pki: LacunaWebPKI;
  searchResults: Array<any> = [];
  buscando = false;
  serverData: Object = {};
  disabled = false;
  loading = false;
  today: Date = new Date();
  certificado: any = null;
  protocolo: number = null;
  documento: object = null;

  constructor(
    private caixaPostalService: CaixaPostalService,
    private ngZone: NgZone,
    private route: ActivatedRoute,
    private router: Router,
    private config: CertificadoService) {
    this.route.paramMap.subscribe(params => {
      this.protocolo = parseInt(params.get('id'));
    });
    this.pki = new LacunaWebPKI(config.webPkiLicense);
  }

  ngOnInit() {
    this.buscando = true;
    this.pki.init({
      ngZone: this.ngZone,
      ready: this.onWebPkiReady,
      notInstalled: this.onWebPkiNotInstalled
    });
  }

  private onWebPkiReady = () => {
    this.carregarCertificados();
  }

  carregarCertificados() {
    this.buscando = true;
    this.pki.listCertificates({
    }).success((certs) => {
      this.buscando = false;
      this.searchResults = certs;
      this.buscando = false;
    });
  }

  update() {
    this.carregarCertificados();
  }

  private onWebPkiNotInstalled = (status, message) => {
    this.buscando = false;
    this.pki.redirectToInstallPage();
  }

  onSelect(e, certificado) {
    this.loading = true;
    this.certificado = certificado;
    this.disabled = !this.disabled;
    this.pki.readCertificate(certificado.thumbprint).success((certEncoded) => {
      this.handleDocument(certEncoded);
    });
  }

  handleDocument(certificado) {
    this.caixaPostalService.gerarDocumentoCiencia(this.protocolo, certificado).subscribe(res => {
      this.signDocument(res.resultado);
    }, error => {
      // Swal.fire(
      //   'Ops!',
      //   'Ocorreu um erro e a mensagem não pôde ser assinada, tente novamente',
      //   'error'
      // );
      Swal.fire(
        getSwal(
          'error',
          'Ops!',
          "Ocorreu um erro e a mensagem não pôde ser assinada, tente novamente",
          'ok',
          null,
        ),
      )
      this.router.navigate([`caixa-postal/mensagem/${this.protocolo}`]);
    });
  }

  signDocument(response) {
    this.documento = response;
    const thumbprint = this.certificado.thumbprint;
    const hash = response.toSignHash;
    const digestAlgorithm = response.digestAlgorithm;
    this.pki.signHash({
      thumbprint: thumbprint,
      hash: hash,
      digestAlgorithm: digestAlgorithm
    }).success((signature) => {
      this.documento['assinatura'] = signature;
      this.caixaPostalService.darCiencia(this.protocolo, this.documento).subscribe(res => {
        this.loading = false;
        // Swal.fire(
        //   'Sucesso',
        //   'A ciência do comunicado foi efeituada com êxito.',
        //   'success'
        // );
        Swal.fire(
          getSwal(
            'success',
            'Sucesso',
            "A ciência do comunicado foi efeituada com êxito.",
            'ok',
            null,
          ),
        )
        this.router.navigate([`caixa-postal/mensagem/${this.protocolo}`]);
      }, error => {
        this.loading = false;
        // Swal.fire(
        //   'Ops!',
        //   'Ocorreu um erro e o comunicado não pôde ser assinado, tente novamente',
        //   'error');
        Swal.fire(
          getSwal(
            'error',
            'Ops!',
            "Ocorreu um erro e o comunicado não pôde ser assinado, tente novamente",
            'ok',
            null,
          ),
        )
        this.router.navigate([`caixa-postal/mensagem/${this.protocolo}`]);
      });
    });
  }


}
