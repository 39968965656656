import { Component, OnInit } from '@angular/core';
import { RegistroAcoesService } from '../../../../../service/registro-acoes/registro-acoes.service'


@Component({
  selector: 'app-servicos-alertas',
  templateUrl: 'servicos-alertas.component.html',
  styleUrls: ['./servicos-alertas.component.scss']
})



export class ServicosAlertas implements OnInit {

  servicesAlerts: any;
  servicesAlertsLength: any;

  constructor(public registroAcoesService: RegistroAcoesService){}

  ngOnInit(){
       
    this.registroAcoesService.getServicosAlertas().subscribe(data => {      
      this.servicesAlerts = data.resultado;
      console.log(this.servicesAlerts)
      this.servicesAlertsLength = this.servicesAlerts.length
    })
    
  }
  
}
