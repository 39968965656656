import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse  } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable()
export class RecuperarSenhaService {

  url = environment.BASE_URL;
  constructor(private http: HttpClient) { }

  solicitaRecuperacaoSenhaAuditor(credentials): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        cpf : credentials.cpf,
        email: credentials.email,
    }});
    return this.http.get(`${this.url}/solicitarRedefinicaoSenhaAuditor`, {params}).pipe(map(data => {
        const body = data;
        return body;
      }));
  }

  recuperarSenha(codigoUsuario, endpoint): Observable<any> {
    const params = new HttpParams().set('codigoUsuario', codigoUsuario);
    return this.http.get(`${this.url}/${endpoint}`, {params}).pipe(map(data => {
       return data;
      }));
  }

  validarCodigoSMS(codigoUsuario, codigoVerificacao) {
    const params = new HttpParams({
      fromObject: {
        codigoUsuario  : codigoUsuario,
        codigoVerificacao : codigoVerificacao,
    }});
    return this.http.get(`${this.url}/validarCodigoSms`, {params}).pipe(map(data => {
       return data;
      }));
  }

  obterDadosViaToken(token): Observable<any> {
    const params = new HttpParams().set('tokenTemporal', token);
    return this.http.get(`${this.url}/obterDadosTokenTemporal`, {params}).pipe(map(data => {
       return data;
      }));
  }

  redefinirSenha(tokenTemporal, senha) {
    const params = new HttpParams({
      fromObject: {
        tokenTemporal  : tokenTemporal,
        senha : senha,
    }});
    return this.http.get(`${this.url}/redefinirSenha`, {params}).pipe(map(data => {
       return data;
      }));
  }
}
