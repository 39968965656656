import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AplicacoesService } from '../../../service/aplicacoes/aplicacoes.service';
import { CaixaPostalService } from '../../../service/caixa-postal/caixa-postal.service';
import { SessionService } from '../../../service/session/session.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  loading: boolean = true;
  iconesRestritos: Array<any> = [];

  icones: Array<any> = [
    { name: '#cog', id: 0, selected: false },
    { name: '#megaphone', id: 1, selected: false },
    { name: '#monitor', id: 2, selected: false },
    { name: '#folder', id: 3, selected: false },
    { name: '#inbox', id: 4, selected: false },
    { name: '#world', id: 5, selected: false },
    { name: '#user', id: 6, selected: false },
    { name: '#mail', id: 7, selected: false },
    { name: '#tools', id: 8, selected: false },
    { name: '#file', id: 9, selected: false },
    { name: '#eye', id: 10, selected: true },
    { name: '#firewall', id: 11, selected: false },
    { name: '#flag', id: 12, selected: false },
    { name: '#info', id: 13, selected: false },
    { name: '#judge', id: 14, selected: false },
    { name: '#light-bulb', id: 16, selected: false },
    { name: '#molecule', id: 17, selected: false },
    { name: '#network', id: 18, selected: false },
    { name: '#notepad', id: 19, selected: false },
    { name: '#pie-chart', id: 20, selected: true },
    { name: '#open-book', id: 21, selected: false },
    { name: '#podium', id: 22, selected: false },
    { name: '#view', id: 23, selected: false },
    { name: '#telephone', id: 24, selected: false },
    { name: '#team', id: 25, selected: false },
    { name: '#shake-hands', id: 26, selected: false },
    { name: '#notebook', id: 27, selected: false },
    { name: '#coding', id: 28, selected: false },
    { name: '#coin', id: 29, selected: false },
    { name: '#clock', id: 31, selected: false },
    { name: '#upload', id: 33, selected: false },
    { name: '#thumb-up', id: 34, selected: false },
    { name: '#layers', id: 35, selected: false },
    { name: '#info', id: 36, selected: false },
    { name: '#pencil', id: 37, selected: false },
    { name: '#unlocked', id: 38, selected: false },
    { name: '#pie-chart', id: 39, selected: false },
    { name: '#worldwide', id: 40, selected: true },
    { name: '#people-connection', id: 41, selected: false },
    { name: '#children-charity', id: 42, selected: false },
    { name: '#arroba', id: 43, selected: false }
  ];

  userInfo: object = {};

  private isSidebarClosed: boolean =
    localStorage.getItem('sidebar_is_closed') == 'true';
  aplicacoesRestritas: Array<any> = [];

  constructor(
    private router: Router,
    private aplicacoesService: AplicacoesService,
    private sessionService: SessionService,
    private caixaPostalService: CaixaPostalService
  ) {}

  ngOnInit() {
    const token = localStorage.getItem('token');
    if (token) {
      this.isSidebarClosed = false;
    }
    this.obterDados();
    this.userInfo = this.sessionService.getSession();
  }

  obterDados() {
    this.aplicacoesService.obterAplicacaoComAcessoRestrito().subscribe(
      data => {
        this.aplicacoesRestritas = data['resultado'];
        this.loading = false;
        this.iconesAplicacoesRestritas();
      },
      error => {
        this.loading = false;
      }
    );
  }

  iconesAplicacoesRestritas() {
    this.aplicacoesRestritas.forEach(element => {
      element.iconName = this.getIconName(element.icone);
    });
  }

  getIconName(idIcon: string) {
    let iconName: '';
    if (!idIcon) {
      return iconName;
    }
    this.icones.forEach(item => {
      if (
        item.id.toString().toLowerCase() === idIcon.toString().toLowerCase()
      ) {
        iconName = item.name;
      }
    });
    return iconName;
  }

  onSidebarToggle(isClosed: boolean) {
    this.isSidebarClosed = isClosed;
  }
}
