import {
  Component,
  OnInit,
  ViewChild,
  Output,
  Input,
  EventEmitter,
  OnChanges
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DatePickerComponent } from 'ng2-date-picker';
import { DatePipe } from '@angular/common';
import { Valida } from '../../../../form-utils/valida';

import * as FileSaver from 'file-saver';

import * as moment from 'moment-timezone';
import { Moment } from 'moment';

import { RegistroAcoesService } from '../../../../service/registro-acoes/registro-acoes.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-filter',
  templateUrl: 'filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit, OnChanges {
  @Output() onDataChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFilterChange: EventEmitter<any> = new EventEmitter<any>();
  @Input('itemsPerView') itemsPerView;

  mascara: string = '000.000.000-00';
  tipo: string = 'cpf';

  searchForm: FormGroup;
  searchFilterForm: FormGroup;
  visible = '';
  inicio;
  doc: string = '';
  // dataOcorrencia = 'Data Inicial';
  // dataOcorrenciaFinal = 'Data Final';
  dataFiltro = null;
  dataInicialValida: boolean = false;
  dataFinalValida: boolean = false;
  data: Object = {};
  searchParams: Object = {
    pagina: 1
  };
  dates: any = {
    data_final: null,
    data_inicial: null
  };
  perfisAcesso: Array<any> = [];
  tiposRegistro: Array<any> = [];
  tipoRegistro = 'Tipo de Registro';
  perfilAcesso = 'Perfil';
  perfilSelecionado = '';
  registroSelecionado = '';
  periodo: boolean = false;
  thisNow;
  configuracaoData = {
    format: 'YYYY-DD-MM'
  };

  cont = 1;
  filter: string = '';
  debounce: Subject<string> = new Subject<string>();
  filtroSelecionado = false;

  constructor(
    private fb: FormBuilder,
    private registroAcoesService: RegistroAcoesService
  ) {
    this.criarFormulario();
  }

  ngOnInit() {
    // this.search();
    this.thisNow = moment(new Date()).format('YYYY-DD-MM');

    this.registroAcoesService.getData().subscribe(
      res => {
        this.handleResponse(res);
      },
      error => console.log(error)
    );
    this.configurarSubject();
  }

  ngOnChanges() {
    if (this.cont === 1) {
      this.cont = this.cont + 1;
    } else {
      this.search(1);
    }
  }

  alterarIdentificador(tipo: string) {
    this.searchFilterForm.controls.numero.setValue('');

    switch (tipo) {
      case 'cpf':
        this.mascara = '000.000.000-00';
        this.searchFilterForm.controls.numero.setValidators(
          Validators.minLength(8)
        );
        this.searchFilterForm.controls.numero.updateValueAndValidity();
        break;
      case 'cnpj':
        this.mascara = '00.000.000/0000-00';
        this.searchFilterForm.controls.numero.setValidators(
          Validators.minLength(8)
        );
        this.searchFilterForm.controls.numero.updateValueAndValidity();
        break;
      case 'inscricao':
        this.mascara = 'A*';
        this.searchFilterForm.controls.numero.setValidators(
          Validators.minLength(1)
        );
        this.searchFilterForm.controls.numero.updateValueAndValidity();
        break;
      case 'nome_razao_social':
        this.mascara = '';
        this.searchFilterForm.controls.numero.setValidators(
          Validators.minLength(1)
        );
        this.searchFilterForm.controls.numero.updateValueAndValidity();
        break;
    }

    delete this.searchParams['cpf_cnpj'];
    delete this.searchParams['inscricao'];
    delete this.searchParams['nome_razao_social'];
  }

  handleResponse(response) {
    this.perfisAcesso = response[0].resultado;
    this.tiposRegistro = response[1].resultado;
    this.perfisAcesso.forEach(elem => (elem['checked'] = false));
    this.tiposRegistro.forEach(elem => (elem['checked'] = false));
    this.pesquisarOcorrencia(true);
  }

  next() {
    if (!this.data['ultimaPagina']) {
      this.searchParams['pagina'] = this.data['paginaAtual'] + 1;
      this.search(0);
    }
  }

  previous() {
    if (!this.data['primeiraPagina']) {
      this.searchParams['pagina'] = this.data['paginaAtual'] - 1;
      this.search(0);
    }
  }

  exportar() {
    this.visible = '';
    const params = {};
    for(let key in this.searchParams) {
      if(key !== 'total_pagina' && key !== 'pagina') {
        params[key] = this.searchParams[key];
      }
    }
    this.onDataChange.emit(true);
    this.registroAcoesService
      .exportarRegistros(params)
      .subscribe(res => {
        const blob = new Blob([res], { type: 'application/pdf' });
        FileSaver.saveAs(blob, `registros${new Date(Date.now())}`);
        this.onDataChange.emit(false);
      });
  }

  numeroChange($event) {
    if ($event !== undefined && $event.length == 0) {
      setTimeout(() => {
        this.setSearch();
      }, 150);
    }
  }

  setSearch() {
    this.searchParams['pagina'] = 1;
    this.data['paginaAtual'] = 1;
    this.search(1);
  }

  criarFormulario() {
    this.searchForm = this.fb.group({
      assunto: ''
    });

    this.searchFilterForm = this.fb.group({
      tipo: '',
      numero: [
        '',
        Validators.compose([Validators.required, Validators.minLength(11)])
      ]
    });
  }

  selecionarDataInicial(event) {
    const dataSelecionada = moment(event, 'DD-MM-YYYY');
    const dataAtual = moment();

    if (dataSelecionada.isAfter(dataAtual)) {
      this.dataInicialValida = false;
      return;
    } if ((this.dates['data_inicial'].isAfter(dataAtual))) {
      this.dataInicialValida = false;
    } else {
      this.dataInicialValida = true;
    }
    //this.searchParams['data_inicial'] = event; // moment(event, 'DD-MM-YYYY').format();
    if (this.dates['data_inicial'] > this.dates['data_final']) {
      // this.dataInicialValida = false;
      setTimeout(() => {
        this.dates['data_final'] = null;
      }, 100);
    }
  }
  selecionarDataFinal(event) {
    const dataSelecionada = moment(event, 'DD-MM-YYYY');
    const dataAtual = moment();

    if (dataSelecionada.isAfter(dataAtual)) {
      this.dataFinalValida = false;
      return;
    } if ((this.dates['data_final'].isAfter(dataAtual))) {
      this.dataFinalValida = false;
    } else {
      this.dataFinalValida = true;
    }
  }


  pesquisarOcorrencia(search: boolean) {
    if (!search) {
      this.visible = '';
      this.dataFiltro = null;
      this.dates = {
        data_final: null,
        data_inicial: null
      };
      this.dataFinalValida = false;
      this.dataInicialValida = false;
      delete this.searchParams['data_inicial'];
      delete this.searchParams['data_final'];
    }
    if (this.dates['data_inicial'] != null) {
      this.searchParams['data_inicial'] = moment(
        this.dates['data_inicial'].valueOf()
      ).format('YYYY-MM-DD');
      this.dataFiltro = moment(this.dates['data_inicial'].valueOf()).format(
        'DD/MM'
      );
    }
    if (this.dates['data_final'] != null) {
      this.searchParams['data_final'] = moment(
        this.dates['data_final'].valueOf()
      ).format('YYYY-MM-DD');
      this.dataFiltro =
      this.dataFiltro +
      ' - ' +
      moment(this.dates['data_final'].valueOf()).format('DD/MM');
    }
    this.search(1);
    this.visible = '';
  }

  toggleVisibility(item: string) {
    if (item === this.visible) {
      this.visible = '';
    } else {
      this.visible = item;
    }
  }

  alteracaoFilter(index: number) {
    this.tipoRegistro = this.tiposRegistro[index].descricao;
    this.searchParams['codigo_tipo_registro'] = this.tiposRegistro[
      index
    ].codigo;
  }

  perfilFilter(index: number) {
    this.perfilAcesso = this.perfisAcesso[index].descricao;
    this.searchParams['codigo_perfil'] = this.perfisAcesso[index].codigo;
  }

  resetarFiltros(filter: string) {
    if (filter === 'registro') {
      this.tipoRegistro = 'Tipo de Registro';
      this.registroSelecionado = '';
      delete this.searchParams['codigo_tipo_registro'];
    } else if(filter === 'perfil'){
      this.perfilAcesso = 'Perfil';
      this.perfilSelecionado = '';
      delete this.searchParams['codigo_perfil'];
    } else if(filter === 'periodo'){
      this.visible = '';
      this.dataFiltro = null;
      this.dates = {
        data_final: null,
        data_inicial: null
      };
      delete this.searchParams['data_inicial'];
      delete this.searchParams['data_final'];
      this.dataFinalValida = false;
      this.dataInicialValida = false;
    }

    if(filter === 'todos'){
      this.tipoRegistro = 'Tipo de Registro';
      this.registroSelecionado = '';
      delete this.searchParams['codigo_tipo_registro'];

      this.perfilAcesso = 'Perfil';
      this.perfilSelecionado = '';
      delete this.searchParams['codigo_perfil'];

      this.visible = '';
      this.dataFiltro = null;
      this.dates = {
        data_final: null,
        data_inicial: null
      };
      delete this.searchParams['data_inicial'];
      delete this.searchParams['data_final'];
    }
    this.search(1);
    this.visible = '';
  }

  inputEmpty() {
    if (this.doc === '') {
      // this.onFilter.emit({ protocolo: this.protocolo });
    }
  }

  search(number) {
    const formValue = this.searchFilterForm.value;
    if (formValue.tipo === 'cpf' || formValue.tipo === 'cnpj') {
      this.searchParams['cpf_cnpj'] = formValue.numero;
      if (formValue.numero === '' || typeof formValue.numero === 'undefined') {
        delete this.searchParams['cpf_cnpj'];
      }
    } else {
      this.searchParams[formValue.tipo] = formValue.numero;
      if (formValue.numero === '') {
        delete this.searchParams[formValue.tipo];
      }
    }

    if(number === 1){
      this.searchParams['pagina'] = 1;
      this.data['paginaAtual'] = 1;
    }

    this.visible = '';
    this.searchParams['total_pagina'] = this.itemsPerView;
    this.onDataChange.emit(true);
    this.onFilterChange.emit(this.searchParams);

    if(this.searchParams['codigo_perfil'] || this.searchParams['codigo_perfil'] == 0 || this.searchParams['data_inicial']
      || this.searchParams['data_final'] || this.searchParams['codigo_tipo_registro'] == 0) {
      this.filtroSelecionado = true;
    }else{
      this.filtroSelecionado = false;
    }

    this.registroAcoesService.getRegistrosdeAcoes(this.searchParams).subscribe(data => {
      this.data = data;
      this.onDataChange.emit(false);
    });
  }

  configurarSubject() {
    this.debounce
      .pipe(debounceTime(500))
      .subscribe(filter => {
        this.filter = filter;
        this.setSearch();
      })
  }
}
