import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { AplicacoesService } from '../../../../../service/aplicacoes/aplicacoes.service';
import Swal from 'sweetalert2';
import * as moment from 'moment-timezone';
import { Moment } from 'moment';

@Component({
  selector: 'app-inserir-forma-acesso',
  templateUrl: 'inserir-forma-acesso.component.html',
  styleUrls: ['./inserir-forma-acesso.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateY(100%)', opacity: 0 }),
        animate(
          '300ms ease-in',
          style({ transform: 'translateY(0%)', opacity: 1 }),
        ),
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0%)', opacity: 1 }),
        animate(
          '300ms ease-in',
          style({ transform: 'translateY(100%)', opacity: 0 }),
        ),
      ]),
    ]),
  ],
})
export class InserirFormaAcessoComponent implements OnInit {
  @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() onInsert: EventEmitter<any> = new EventEmitter<any>();
  @Input('perfil') perfil: any = null;
  @Input() aplicacao: any = null;
  @Input() idFuncionalidade: any = null;

  definirFormaAcessoForm: FormGroup;
  dataInicio: any = new Date(Date.now());
  dataFim: any = null;

  formasAcesso: Array<any> = [];
  perfisAcesso: Array<any> = [];
  perfisUsuario: Array<any> = [];
  perfisAcessoFiltered: Array<any> = [];
  perfisUsuarioFiltered: Array<any> = [];

  formaAcesso: Object = null;
  perfilAcesso: Object = null;
  tipoProcuracao: Object = null;

  constructor(
    private aplicacaoService: AplicacoesService,
    private fb: FormBuilder,
  ) {}

  ngOnInit() {
    this.obterDadosFormaAcesso();
  }

  criarFormulario() {
    this.definirFormaAcessoForm.controls.dataInicio.disable();
  }

  obterDadosFormaAcesso() {
    this.aplicacaoService.obterDadosFormaAcesso().subscribe(response => {
      this.formasAcesso = response[0].resultado;
      this.perfisAcesso = response[1].resultado;
      this.perfisUsuario = response[2].resultado;

      if (this.perfil !== null) {
        this.carregarDadosEdicao();
      }
    });
  }

  carregarDadosEdicao() {
    this.formaAcesso = this.perfil.formaAcesso;
    this.perfilAcesso = this.perfil.perfilAcesso;
    if (this.perfilAcesso['codigo'] === 3) {
      this.tipoProcuracao = this.perfil.tipoProcuracao;
    }
    this.validatePerfilExisteParaPerfilAcesso().then(res => {
      return this.validatePerfilUsuarioExisteParaPerfilAcesso();
    });
  }

  selectFormaAcesso(formaAcesso) {
    this.formaAcesso = this.formasAcesso.filter(
      item => item.codigo === formaAcesso.codigo,
    )[0];
    this.validatePerfilExisteParaPerfilAcesso().then(res => {
      if (this.perfilAcesso) {
        // this.selectPerfilAcesso(this.formaAcesso);
        this.perfilAcesso = null;
        this.tipoProcuracao = null;
      }
    });
  }

  validatePerfilExisteParaPerfilAcesso() {
    return new Promise((resolve, reject) => {
      let perfisUtilizadosParaFormaDeAcesso: Array<any> = [];
      const novoID = parseInt(
        this.idFuncionalidade.toString().replace('novo', ''),
      );
      this.aplicacao.funcionalidades.forEach(funcionalidade => {
        if (
          funcionalidade.codigo === this.idFuncionalidade ||
          funcionalidade.codigo === novoID
        ) {
          funcionalidade.perfis.forEach(perfil => {
            if (perfil.formaAcesso.codigo === this.formaAcesso['codigo']) {
              if (
                (this.perfil !== null &&
                  this.perfil.perfilAcesso.codigo !==
                    perfil.perfilAcesso.codigo) ||
                this.perfil === null
              ) {
                if (perfil.perfilAcesso.codigo.toString() !== '3') {
                  perfisUtilizadosParaFormaDeAcesso.push(
                    perfil.perfilAcesso['codigo'],
                  );
                }
              }
            }
          });
        }
      });
      this.perfisAcessoFiltered = [];
      this.perfisAcesso.forEach(perfil => {
        if (perfisUtilizadosParaFormaDeAcesso.indexOf(perfil.codigo) === -1) {
          this.perfisAcessoFiltered.push(perfil);
        }
      });
      resolve();
    });
  }

  selectPerfilAcesso(perfilAcesso) {
    this.perfilAcesso = this.perfisAcesso.filter(
      item => item.codigo === perfilAcesso.codigo,
    )[0];

    if (perfilAcesso.codigo !== 3 && this.tipoProcuracao !== null) {
      this.tipoProcuracao = null;
    }

    if (perfilAcesso.codigo === 3) {
      this.validatePerfilUsuarioExisteParaPerfilAcesso();
    }
  }

  selectPerfilUsuario(perfilUsuario) {
    this.tipoProcuracao = this.perfisUsuario.filter(
      item => item.codigo === perfilUsuario.codigo,
    )[0];
  }

  validatePerfilUsuarioExisteParaPerfilAcesso() {
    return new Promise((resolve, reject) => {
      let tipoProcuracaoUtilizados: Array<any> = [];
      const novoID = parseInt(
        this.idFuncionalidade.toString().replace('novo', ''),
      );
      this.aplicacao.funcionalidades.forEach(funcionalidade => {
        if (
          funcionalidade.codigo === this.idFuncionalidade ||
          funcionalidade.codigo === novoID
        ) {
          funcionalidade.perfis.forEach(perfil => {
            if (
              ((this.perfil !== null &&
                this.perfil.tipoProcuracao !== null &&
                perfil.tipoProcuracao !== null &&
                this.perfil.tipoProcuracao.codigo !==
                  perfil.tipoProcuracao.codigo) ||
                this.perfil === null) &&
              perfil.formaAcesso.codigo === this.formaAcesso['codigo']
            ) {
              if (
                (perfil.tipoProcuracao !== null &&
                  this.tipoProcuracao !== null &&
                  perfil.tipoProcuracao.codigo ===
                    this.tipoProcuracao['codigo']) ||
                (this.tipoProcuracao === null && perfil.tipoProcuracao !== null)
              ) {
                tipoProcuracaoUtilizados.push(perfil.tipoProcuracao.codigo);
              }
            }
          });
        }
      });

      this.perfisUsuarioFiltered = [];

      this.perfisUsuario.forEach(perfil => {
        if (tipoProcuracaoUtilizados.indexOf(perfil.codigo) === -1) {
          this.perfisUsuarioFiltered.push(perfil);
        }
      });

      resolve();
    });
  }

  isFormValid() {
    if (this.perfilAcesso !== null && this.perfilAcesso['codigo'] !== 3) {
      return false;
    } else if (
      this.perfilAcesso !== null &&
      this.perfilAcesso['codigo'] === 3 &&
      this.tipoProcuracao !== null
    ) {
      return false;
    } else {
      return true;
    }
  }

  desistir() {
    this.onCancel.emit(false);
  }

  onSubmit() {
    let obj = {};
    if (this.perfil != null && this.perfil.codigo) {
      obj['codigo'] = this.perfil.codigo;
    }
    obj['formaAcesso'] = this.formaAcesso;
    obj['perfilAcesso'] = this.perfilAcesso;
    obj['tipoProcuracao'] = this.tipoProcuracao;
    this.onCancel.emit(false);
    this.onInsert.emit(obj);
  }
}
