import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-exportar-usuarios',
  templateUrl: 'exportar-usuarios.component.html',
  styleUrls: ['./exportar-usuarios.component.scss'],
})
export class ExportarUsuariosComponent implements OnInit {
  mockData = [
    {
      label: 'App teste 1',
      funcionalidades: [
        { label: 'funcionalidade teste 1' },
        { label: 'funcionalidade teste 1' },
        { label: 'funcionalidade teste 1' },
        { label: 'funcionalidade teste 1' },
      ],
    },
    {
      label: 'App teste 1',
      funcionalidades: [
        { label: 'funcionalidade teste 1' },
        { label: 'funcionalidade teste 1' },
        { label: 'funcionalidade teste 1' },
        { label: 'funcionalidade teste 1' },
      ],
    },
    {
      label: 'App teste 1',
      funcionalidades: [
        { label: 'funcionalidade teste 1' },
        { label: 'funcionalidade teste 1' },
        { label: 'funcionalidade teste 1' },
        { label: 'funcionalidade teste 1' },
      ],
    },
    {
      label: 'App teste 1',
      funcionalidades: [
        { label: 'funcionalidade teste 1' },
        { label: 'funcionalidade teste 1' },
        { label: 'funcionalidade teste 1' },
        { label: 'funcionalidade teste 1' },
      ],
    },
  ];

  constructor() {}

  ngOnInit() {}
}
