import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription, forkJoin } from 'rxjs';
import { RegrasAcessoService } from 'src/app/service/configuracao/regras-acesso.service';

@Component({
  selector: 'app-filtrar-regras-acesso',
  templateUrl: './filtro.component.html',
  styleUrls: ['./filtro.component.scss']
})
export class FiltrarRegrasAcessoComponent implements OnInit {
  @Input() itemsPerView: number = 10;
  visible = '';
  routeSub: Subscription;
  personalidadesJuridicas: Array<any> = [];
  setoresAtuacao: Array<any> = [];
  formasAcesso: Array<any> = [];
  personalidadeBtnAtivo: boolean = false;
  searchForm: FormGroup;
  personalidades = [];
  setores = [];
  acessos = [];
  personalidade = 'Personalidade Jurídica';
  setorAtuacao = 'Setor de atuação';
  formaAcesso = 'Forma de acesso';
  personalidadeSelecionada: any = null;
  setorSelecionado: any = null;
  formaAcessoSelecionada: any = null;
  filtroSelecionado = false;
  loading: boolean = false;
  idPerfilAcesso: number = null;
  allowClearFilter = false;
  errorMessage = '';
  searchParams = {};
  data: Object = {
    primeiraPagina: true,
    ultimaPagina: false,
    paginaAtual: 1,
    totalPaginas: 1,
    totalDeItems: 0,
    itemsPorPagina: 10
  };
  filterCurrent: Object = {
    total_pagina: 10,
    pagina: 1,
  }

  labels: any;
  personalidadeBotao: boolean;
  setorBotao: boolean;
  formaAcessoBotao: boolean;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private regrasAcessoService: RegrasAcessoService,
    ) {
      this.criarFormulario();
      this.criarFormularioSetor();
      this.criarFormularioAcesso();

      this.routeSub = this.route.params.subscribe(params => {
        if (params['id']) {
          this.idPerfilAcesso = params['id'];
        }
      });
    }

  ngOnInit() {
    this.regrasAcessoService
      .getRegrasAcesso(this.idPerfilAcesso, this.filterCurrent)
      .subscribe(res => {
        this.loading = false;
        console.log(res)
      },
      error => (this.errorMessage = error));

    forkJoin([
      this.regrasAcessoService.obterPersonalidadeJuridica(),
      this.regrasAcessoService.obterSetoresAtuacao(),
      this.regrasAcessoService.obterFormaAcesso()
    ]).subscribe(
      ([personalidadesJuridicas, setoresAtuacao, formasAcesso]) => {
        this.personalidadesJuridicas = personalidadesJuridicas.resultado;
        this.setoresAtuacao = setoresAtuacao.resultado;
        this.formasAcesso = formasAcesso.resultado;
      },
      error => console.error(error)
    );
  }

  pesquisa(){
    if(this.visible === 'personalidade'){
      this.visible = '';
    } else {
      this.visible = 'personalidade';
    }
  }

  pesquisaSetor(){
    if(this.visible === 'setorAtuacao'){
      this.visible = '';
      this.searchParams['codSetorAtuacao'] = this.setorSelecionado;
    } else {
      this.visible = 'setorAtuacao';
    }
  }
  pesquisaFormaAcesso(){
    if(this.visible === 'formaAcesso'){
      this.visible = '';
      this.searchParams['codFormaAcesso'] = this.formaAcessoSelecionada;
    } else {
      this.visible = 'formaAcesso';
    }
  }

  pesquisarListaPersonalidade(){
    var parametros = {};
    parametros['codPersonalidade'] = this.personalidadeSelecionada;
    let personalidade = this.personalidadesJuridicas.filter(res => res.codigo == parametros['codPersonalidade']);

    this.personalidade = personalidade[0].descricao;
    this.searchParams['codPersonalidade'] = this.personalidadeSelecionada;
    this.searchParams['pagina'] = 1;
    this.personalidadeBotao = true;
    this.search();
    this.visible = '';
  }

  pesquisarListaSetor(){
    var parametros = {};
    parametros['codSetorAtuacao'] = this.setorSelecionado;
    let setorAtuacao = this.setoresAtuacao.filter(res => res.codigo == parametros['codSetorAtuacao']);
    this.setorAtuacao = setorAtuacao[0].descricao;

    this.searchParams['codSetorAtuacao'] = this.setorSelecionado;
    this.searchParams['pagina'] = 1;
    this.setorBotao = true;
    this.search();
    this.visible = '';
  }

  pesquisarListaAcesso(){
    var parametros = {};
    parametros['codFormaAcesso'] = this.formaAcessoSelecionada;
    let formaAcesso = this.formasAcesso.filter(res => res.codigo == parametros['codFormaAcesso']);
    this.formaAcesso = formaAcesso[0].descricao;

    this.searchParams['codFormaAcesso'] = this.formaAcessoSelecionada;
    this.searchParams['pagina'] = 1;
    this.formaAcessoBotao = true;
    this.search();
    this.visible = '';
  }

  search() {
    if (this.searchParams["codPersonalidade"] === null) {
      delete this.searchParams["codPersonalidade"];
    }

    if (this.searchParams["codPersonalidade"] == null) {
      this.personalidade = "Personalidade Jurídica";
      this.personalidadeSelecionada = null;
    } else {
      this.allowClearFilter = true;
    }

    if (this.searchParams["codSetorAtuacao"] === null) {
      delete this.searchParams["codSetorAtuacao"];
    }
    if (this.searchParams["codSetorAtuacao"] == null) {
      this.setorAtuacao = "Setor de Atuação";
      this.setorSelecionado = null;
    } else {
      this.allowClearFilter = true;
    }

    if (this.searchParams["codFormaAcesso"] === null) {
      delete this.searchParams["codFormaAcesso"];
    }
    if (this.searchParams["codFormaAcesso"] == null) {
      this.formaAcesso = "Forma de acesso";
      this.formaAcessoSelecionada = null;
    } else {
      this.allowClearFilter = true;
    }

    if (
      this.searchParams['codPersonalidade'] !== undefined ||
      this.searchParams['codSetorAtuacao'] !== undefined ||
      this.searchParams['codFormaAcesso'] !== undefined
    ) {
      this.filtroSelecionado = true;
    } else {
      this.filtroSelecionado = false;
    }

    this.visible = '';
    this.loading = true;
    this.searchParams['pagina'] = this.filterCurrent['pagina'];
    this.searchParams['total_pagina'] = this.itemsPerView;
    this.regrasAcessoService.getRegrasAcesso(this.idPerfilAcesso, this.searchParams).subscribe(data => {
        this.loading = false;
        this.data = data;
      });
  }

  toggleVisibility(item: string) {
    if (item === this.visible) {
      this.visible = '';
    } else {
      this.visible = item;
    }
  }

  criarFormulario() {
    this.searchForm = this.fb.group({
      personalidadeSelecionada: '',
    });
  }
  criarFormularioSetor() {
    this.searchForm = this.fb.group({
      setorSelecionado: '',
    });
  }
  criarFormularioAcesso() {
    this.searchForm = this.fb.group({
      formaAcessoSelecionada: '',
    });
  }

  limparFiltros(tipoFiltro: string) {
    switch (tipoFiltro) {
      case 'personalidade':
        this.personalidade = 'Personalidade Jurídica';
        delete this.searchParams['codPersonalidade'];
        this.personalidadeBotao = false;
        break;
      case 'setor':
        this.setorAtuacao = 'Setor de Atuação';
        delete this.searchParams['codSetorAtuacao'];
        this.setorBotao = false;
        break;
      case 'acesso':
        this.formaAcesso = 'Forma de acesso';
        delete this.searchParams['codFormaAcesso'];
        this.formaAcessoBotao = false;
        break;
      default:
        break;
    }
    this.searchParams['pagina'] = 1;
    this.data['paginaAtual'] = 1;
    this.search();
  }

  limparTodosFiltros() {
    this.personalidadeSelecionada = null;
    this.personalidade = 'Personalidade Jurídica';
    delete this.searchParams['codPersonalidade'];
    this.personalidadeBotao = false;
    this.setorAtuacao = 'Setor de atuação';
    this.setorSelecionado = null;
    delete this.searchParams['codSetorAtuacao'];
    this.setorBotao = false;
    this.visible = '';
    this.formaAcesso = 'Forma de acesso'
    this.formaAcessoSelecionada = false;
    delete this.searchParams['codFormaAcesso'];
    this.formaAcessoBotao = false;
    this.search();
  }

  resetarParametros() {
    this.personalidadeSelecionada = null;
    this.setorSelecionado = null;
    this.formaAcessoSelecionada = null;

    this.data['paginaAtual'] = 1
  }

}
