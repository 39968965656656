import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PerfilDataService } from '../../../service/perfil/perfil.service';
import { VersaoService } from '../../../service/versao/versao.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: '[app-rodape]',
  templateUrl: './rodape.component.html',
  styleUrls: ['./rodape.component.scss'],
})
export class RodapeComponent {
  private data: string;
  private versao: string;

  constructor(
    private httpClient: HttpClient,
    private versaoService: VersaoService,
  ) {
    this.versao = environment.version;
  }
}
