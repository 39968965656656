import { Component, OnInit } from '@angular/core';
import { AplicacoesService } from '../../../../../service/aplicacoes/aplicacoes.service';
import { graphicCollors } from '../../../../../../environments/version';

@Component({
  selector: '[app-graficos]',
  templateUrl: 'graficos.component.html',
  styleUrls: ['./graficos.component.scss'],
})
export class GraficosComunicadosComponent implements OnInit {
  aplicacoesRegistradas: number = 0;
  aplicacoesContribuinte: number = 0;
  aplicacoesAuditor: number = 0;
  total: number = 0;

  gColors = graphicCollors;

  selected = 'ativos';

  constructor(private aplicacoesService: AplicacoesService) {}

  ngOnInit() {
    this.aplicacoesService.obterDadosGraficos().subscribe(response => {
      this.aplicacoesRegistradas = response[0].resultado;
      this.aplicacoesContribuinte = response[1].resultado;
      this.aplicacoesAuditor = response[2].resultado;
      this.total =
        this.aplicacoesAuditor +
        this.aplicacoesContribuinte +
        this.aplicacoesRegistradas;
    });
  }
}
