import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CoresTiposComunicadosService } from '../../../../../service/configuracao/cores-tipo-comunicado.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { OrganogramaService } from 'src/app/service/organograma/organograma.service';


@Component({
  selector: 'app-consultar-cores',
  templateUrl: 'consultar-cores.component.html',
  styleUrls: ['./consultar-cores.component.scss']
})
export class ConsultarCoresTipoComunicadoComponent implements OnInit {
  @ViewChild('childFilter', {static: false}) child;
  @Input() itemsPerView: number = 10;

  subscription: Subscription;
  colorNames: Array<string> = [];
  list: Array<any> = [];
  // data: Array<any> = [];
  cores: Array<any> = [];
  searchParams: any;
  paginaAtual: number = 1;
  numeroCores: string = '10'
  loading: boolean = false;
  filterCurrent: Object = {
    pagina: 1,
    total_pagina: 10
  }

  data: Object = {
    primeiraPagina: true,
    ultimaPagina: false,
    paginaAtual: 1,
    totalPaginas: 1,
    totalDeItems: 0,
    itemsPorPagina: 10
  };

  constructor(
    private coresTipoComunicadoService: CoresTiposComunicadosService,
    private router: Router,
    private organogramaService: OrganogramaService
  ) {
    this.data = [];
  }

  ngOnInit() {
    this.coresTipoComunicadoService.cores$.subscribe(
      (res) => {
      this.data = res;
      this.filterCurrent = this.child.searchParams;
      this.cores = res['resultado']
      this.data['totalDeItems'] = res.totalRegistros;
      this.data['totalPaginas'] = res['totalPaginas'];
      this.data['primeiraPagina'] = res.primeiraPagina;
      this.data['ultimaPagina'] = res.ultimaPagina;
      this.loading = false;
      console.log(res);
    });
    this.getCores();
  }

  updateSearchParams(params) {
    this.searchParams = params;

  }

  getCores() {
    let parametros = {};
    parametros['pagina'] = this.paginaAtual;
    parametros['total_pagina'] = this.itemsPerView;

    this.coresTipoComunicadoService.getCores(parametros).subscribe(res => {
      this.loading = false;
        if(res.tamanhoPagina) {
            this.data = res;
            this.data['totalDeItems'] = res.totalRegistros;
            this.data['totalPaginas'] = this.itemsPerView;
            this.loading = false;
          }

      res.resultado.forEach(element => {
        this.colorNames.push(element.descricao.toLowerCase());
      })
    });
  }

  quantidadeCores(numeroCores) {

    this.itemsPerView = +numeroCores;

    this.data['paginaAtual'] = 1;
    this.paginaAtual = 1;

    this.onPageChange(this.filterCurrent);

    this.data['paginaAtual'] = 1;
    this.data['itemsPorPagina'] = this.itemsPerView;

    switch (numeroCores) {
      case "10":
        this.data['itemsPorPagina'] = 10;
        console.log(this.data['itemsPorPagina']);
        break;
      case "20":
        this.data['itemsPorPagina'] = 20;
        console.log(this.data['itemsPorPagina']);
        break;
      case "50":
        this.data['itemsPorPagina'] = 50;
        console.log(this.data['itemsPorPagina']);
        break;
      case "100":
        this.data['itemsPorPagina'] = 100;
        console.log(this.data['itemsPorPagina']);
        break;
    }

    this.loading = true;
    // return this.getCores()
  }

  next() {
    this.data['paginaAtual']++;
    this.onPageChange(this.filterCurrent);
  }

  previous() {
    this.data['paginaAtual']--;
    this.onPageChange(this.filterCurrent);
  }

  ultimaPagina() {
    this.data['paginaAtual'] = this.data['totalPaginas'];
    this.onPageChange(this.filterCurrent);
  }

  primeiraPagina() {
    this.data['paginaAtual'] = 1;
    this.onPageChange(this.filterCurrent);
  }

  onPageChange(value) {
    value['pagina'] = this.data['paginaAtual'];
    value['total_pagina'] = this.itemsPerView;

    this.filterCurrent = value;
    this.loading = true;

    this.coresTipoComunicadoService.getCores(value).subscribe(
        res => {
            this.cores = res["resultado"];
            this.loading = false;
        },
        error => {
            this.loading = false;
            console.error('Erro ao obter as cores:', error);
        }
    );
}

  edit(codigo: number) {
    // this.router.navigate([`configuracao/cores/definir/${codigo}`]);
    this.router.navigateByUrl(`configuracao/cores/definir/${codigo}`,
      {state: [this.colorNames]
    });
  }

  novo() {
    // this.router.navigate([`configuracao/cores/definir/${codigo}`]);
    this.router.navigateByUrl(`configuracao/cores/definir`,
      {state: [this.colorNames]
    });
  }
}
