import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CpfCnpjPipe } from '../../../pipes/cpf-cnpj.pipe';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PasswordValidation } from '../../../utils/password-validation';
import { RecuperarSenhaService } from '../../../service/recuperar-senha/recuperar-senha.service';

import Swal from 'sweetalert2';
import { getSwal } from '../../../utils/swalObject';

@Component({
  selector: 'app-resetar-senha',
  templateUrl: 'resetar-senha.component.html',
  styleUrls: ['./resetar-senha.component.scss'],
})
export class ResetarSenhaComponent implements OnInit {
  token: any;
  data: Object = {};
  forgotPasswordForm: FormGroup;
  private routeSubscription: any;

  loading = true;

  constructor(
    private route: ActivatedRoute,
    private recuperarSenhaService: RecuperarSenhaService,
    private fb: FormBuilder,
    private router: Router,
  ) {}

  ngOnInit() {
    this.criarFormulario();
    this.routeSubscription = this.route.params.subscribe(
      params => {
        this.token = params['token'];
        if (!this.token) {
          this.handleError();
          return;
        }
        this.recuperarSenhaService
          .obterDadosViaToken(this.token)
          .subscribe(
            res => this.handleResponse(res),
            error => this.handleError(),
          );
      },
      error => {
        this.handleError();
      },
    );
  }

  handleResponse(res) {
    this.loading = false;
    this.data = res.resultado;
    this.data['usuario'].celular =
      this.data['usuario'].ddd &&
      this.data['usuario'].celular &&
      this.data['usuario'].ddd !== null &&
      this.data['usuario'].celular !== null
        ? this.data['usuario'].ddd + ' ' + this.data['usuario'].celular
        : '';
  }

  handleError() {
    this.loading = false;
    /*Swal.fire(
      'Ops!',
      'Ocorreu um erro e seus dados não foram recuperados, tente novamente.',
      'error',
    )*/
    Swal.fire(
      getSwal(
        'error',
        'Ops!',
        "Ocorreu um erro e seus dados não foram recuperados, tente novamente.",
        'ok',
        null,
      ),
    ).then(val => {
      this.router.navigate(['/recuperar-senha']);
    });
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }

  criarFormulario() {
    this.forgotPasswordForm = this.fb.group(
      {
        password: [
          '',
          Validators.compose([Validators.minLength(8), Validators.required]),
        ],
        passwordConfirm: [
          '',
          Validators.compose([Validators.minLength(8), Validators.required]),
        ],
      },
      {
        validator: [PasswordValidation.MatchPassword, PasswordValidation.ValidatorPassWord]
      },
    );
  }

  alterarSenha() {
    this.loading = true;
    let senha = this.forgotPasswordForm.controls.passwordConfirm.value;
    senha = senha.toString();
    this.token = this.token.toString();
    this.recuperarSenhaService.redefinirSenha(this.token, senha).subscribe(
      res => {
        this.loading = false;
        // Swal.fire(
        //   'Senha Alterada com sucesso',
        //   'Você será redirecionado para a página de login, informe seu CPF/CNPJ e senha atual para acessar o portal',
        //   'success',
        // );
        Swal.fire(
          getSwal(
            'success',
            'Senha Alterada com sucesso',
            "Você será redirecionado para a página de login, informe seu CPF/CNPJ e senha atual para acessar o portal",
            'ok',
            null,
          ),
        )        
        this.router.navigate(['/entrar']);
      },
      err => {
        this.loading = false;
        // Swal.fire('Ops!', err.error.excecao.recomendacao, 'error');
        Swal.fire(
          getSwal(
            'error',
            'Ops!',
            err.error.excecao.recomendacao,
            'ok',
            null,
          ),
        )        
      },
    );
  }
}
