import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";

import Swal from 'sweetalert2';
import { getSwal } from '../../../../../utils/swalObject';
import { AssinaturaEletronicaService } from "../../../../../service/configuracao/assinatura-eletronica.service";

@Component({
    selector: 'app-editar-assinatura-eletronica',
    templateUrl: './editar-assinatura.component.html',
    styleUrls: ['./editar-assinatura.component.scss']
})
export class EditarAssinaturaEletronicaComponent implements OnInit {
    routeSub: Subscription;
    editarAssinaturaEletronicaForm: FormGroup;
    idAssinaturaEletronica:number = null;
    edicao:boolean = false;

    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private assinaturaEletronicaService: AssinaturaEletronicaService) { } 

    ngOnInit() {
        this.criarFormulario();
        this.routeSub = this.route.params.subscribe(params => {
          if (params['id']) {
            this.idAssinaturaEletronica = params['id'];
          }
        });
        if(this.idAssinaturaEletronica) {      
          this.carregarDados();
        }
    }
    
    criarFormulario(){
        this.editarAssinaturaEletronicaForm = this.fb.group({
        descricao: ['', Validators.required],
        ativo: [false, Validators.required]
        })
    }

    carregarDados(){
    this.assinaturaEletronicaService.obterAssinaturaEletronica(this.idAssinaturaEletronica).subscribe(res => {
        const data = res.resultado;
        for (let key in data) {
        if(this.editarAssinaturaEletronicaForm.controls[key]) {
            this.editarAssinaturaEletronicaForm.controls[key].patchValue(data[key]);
        }
        }
    }, error => {
        // Swal.fire(
        //   'Ops!',
        //   'Ocorreu um erro e a forma de acesso não pôde ser carregada.',
        //   'error'
        // );
        Swal.fire(
        getSwal(
            'error',
            'Ops!',
            "Ocorreu um erro e a forma de acesso não pôde ser carregada.",
            'ok',
            null,
        ),
        );      
    });
    }

    isFormInvalid(){
        if(this.editarAssinaturaEletronicaForm.valid) {
        return false;
        } else {
        return true;
        }
    }

    onSubmit() {
        const value = this.editarAssinaturaEletronicaForm.getRawValue();
        this.assinaturaEletronicaService.editaAssinaturaEletronica(value, this.idAssinaturaEletronica).subscribe(res => this.handleResponse(), error => this.handleError(error));
    }

    handleResponse(){
    // Swal.fire(
    //   'Sucesso!',
    //   'O perfil de acesso foi editado com sucesso.',
    //   'success'
    // )
    Swal.fire(
        getSwal(
        'success',
        'Sucesso!',
        "O perfil de acesso foi editado com sucesso.",
        'ok',
        null,
        ),
    );    
    this.router.navigate(['configuracao/assinatura-eletronica/consultar']);
    }

    handleError(error){
    // Swal.fire(
    //   'Ops!',
    //   'Ocorreu um erro e o perfil de acesso não pôde ser editado.',
    //   'error'
    // );
    Swal.fire(
        getSwal(
        'error',
        'Ops!',
        "Ocorreu um erro e o perfil de acesso não pôde ser editado.",
        'ok',
        null,
        ),
    );    
    }

    
}