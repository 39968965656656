import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AssinaturaEletronicaService } from '../../../../../service/configuracao/assinatura-eletronica.service';

@Component ({
    selector: 'app-consulta-assinatura-eletronica',
    templateUrl: './consulta-assinatura.component.html',
    styleUrls: ['./consulta-assinatura.component.scss']

})
export class ConsultaAssinaturaEletronicaComponent implements OnInit{
    data: Array<any> = null;
    subscription: Subscription;

    constructor(private router: Router, private assinaturaEletronicaService: AssinaturaEletronicaService) {
        this.data = [];
    }

    ngOnInit() {
        this.subscription = this.assinaturaEletronicaService.assinaturaEletronica$.subscribe(res => {
            this.data = res.resultado;
          }, error => console.log(error))
    }

    edit(codigo:number) {
        this.router.navigate([`configuracao/assinatura-eletronica/editar/${codigo}`])
      }

}