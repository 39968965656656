import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "firstLetterUpperCase",
})
export class firstLetterUpperCasePipe implements PipeTransform {
  transform(value: string): string {
    let stringMinuscula = value.toLowerCase();
    let primeiraLetraMaiuscula = value[0].toUpperCase();
    let res = primeiraLetraMaiuscula + stringMinuscula.substring(1);
    return res;
  }
}
