import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardService } from '../../service/auth-guard/auth-guard.service';

import { HistoricoOperacoesFuncionalidadesComponent } from './paginas/historico-operacoes-funcionalidades/historico-operacoes-funcionalidades.component';
import { ImportarFuncionalidadesComponent } from './paginas/importar-funcionalidades/importar-funcionalidades.component';
import { ExportarFuncionalidadesComponent } from './paginas/exportar-funcionalidades/exportar-funcionalidades.component';
import { IntegracaoComponent } from './paginas/integracao/integracao.component';
import { OpcoesIntegracaoComponent } from './paginas/opcoes-integracao/opcoes-integracao.component';
import { HistoricoOperacoesUsuariosComponent } from './paginas/historico-operacoes-usuarios/historico-operacoes-usuarios.component';
import { ImportarUsuariosComponent } from './paginas/importar-usuarios/importar-usuarios.component';
import { ExportarUsuariosComponent } from './paginas/exportar-usuarios/exportar-usuarios.component';

const routes: Routes = [
  {
    path: 'integracao',
    canActivate: [AuthGuardService],
    component: IntegracaoComponent,
    data: { breadcrumb: 'Integração de Funcionalidades', modulo: '1006' },
    children: [
      { path: '', redirectTo: 'opcoes', pathMatch: 'full' },
      //{path: '', component: OpcoesIntegracaoComponent, data: { breadcrumb: "", modulo: '1006'	}, pathMatch: 'full'},
      {
        path: 'opcoes',
        component: OpcoesIntegracaoComponent,
        data: { breadcrumb: '', modulo: '1006' },
        pathMatch: 'full',
      },
      {
        path: 'historico-funcionalidades',
        component: HistoricoOperacoesFuncionalidadesComponent,
        data: { breadcrumb: 'Histórico de Operações', modulo: '1006' },
        pathMatch: 'full',
      },
      {
        path: 'importar-funcionalidades',
        component: ImportarFuncionalidadesComponent,
        data: { breadcrumb: 'Importar Funcionalidades', modulo: '1006' },
        pathMatch: 'full',
      },
      {
        path: 'exportar-funcionalidades',
        component: ExportarFuncionalidadesComponent,
        data: { breadcrumb: 'Exportar Funcionalidades', modulo: '1006' },
        pathMatch: 'full',
      },
      {
        path: 'historico-usuarios',
        component: HistoricoOperacoesUsuariosComponent,
        data: { breadcrumb: 'Histórico', modulo: '1006' },
        pathMatch: 'full',
      },
      {
        path: 'importar-usuarios',
        component: ImportarUsuariosComponent,
        data: { breadcrumb: 'Importar Usuários', modulo: '1006' },
        pathMatch: 'full',
      },
      {
        path: 'exportar-usuarios',
        component: ExportarUsuariosComponent,
        data: { breadcrumb: 'Exportar', modulo: '1006' },
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class IntegracaoRoutingModule {}
