import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { PerfisAcessoService } from '../../../../../service/configuracao/perfil-acesso.service';

@Component({
  selector: 'app-consultar-perfis-acesso',
  templateUrl: 'consulta-perfis-acesso.component.html',
  styleUrls: ['./consulta-perfis-acesso.component.scss']
})

export class ConsultarPerfisAcessoComponent implements OnInit {
  data: Array<any> = null;
  subscription: Subscription;

  constructor(private router: Router, private perfilAcessoService: PerfisAcessoService) {
    this.data = [];
  }

  ngOnInit() {
    this.subscription = this.perfilAcessoService.perfisAcesso$.subscribe(res => {
      this.data = res.resultado;
    }, error => console.log(error))
  }

   edit(codigo:number) {
     this.router.navigate([`configuracao/perfis-acesso/editar/${codigo}`])
   }
}
