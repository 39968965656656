import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ListaTiposComunicadosComponent } from './pages/lista-tipos-comunicados/lista-tipos-comunicados.component';
import { IncluirTiposComunicadosComponent } from './pages/incluir-comunicados/incluir-comunicados.component';
import { TiposComunicadoComponent } from './pages/tipos-comunicado/tipos-comunicado.component';
import { AuthGuardService } from '../../service/auth-guard/auth-guard.service';


const routes: Routes = [
  {
    path: 'tipos-comunicados',
    canActivate: [AuthGuardService],
    component: TiposComunicadoComponent,
    data: { breadcrumb: 'Configurar Comunicados', modulo: '1007' },
    children: [
      { path: '', redirectTo: 'consultar', pathMatch: 'full' },
      {
        path: 'consultar',
        component: ListaTiposComunicadosComponent,
      },
      {
        path: 'incluir',
        component: IncluirTiposComunicadosComponent,
        data: { breadcrumb: 'Incluir Tipo de Comunicado' }
      },
      {
        path: ':id',
        component: IncluirTiposComunicadosComponent,
        data: { breadcrumb: 'Editar Tipo de Comunicado' }
      },
      {
        path: 'visualizar/:id',
        component: IncluirTiposComunicadosComponent,
        data: { breadcrumb: 'Visualizar Tipo de Comunicado' }
      }
    ]
  }
];
@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class GerenciarComunicadosRoutingModule {

}
