import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CaixaPostalService } from '../../../../../service/caixa-postal/caixa-postal.service';


@Component({
  selector: 'app-pop-up-cpf-contribuinte',
  templateUrl: './pop-up-cpf-contribuinte.component.html',
  styleUrls: ['./pop-up-cpf-contribuinte.component.scss']
})
export class PopUpCpfContribuinteComponent implements OnInit {
  // @Input() dataContribuinte: Object = {};
  @Input() data:  any[] = [];
  @Output() onClosePopUp: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSelectContribuinte: EventEmitter<any> = new EventEmitter<any>();
  // data:''
  selectedEntities: any[] = [];
  active:number = 0;
  // objeto:{}
  // @Input() objeto: Object = {};

  constructor() { }

  ngOnInit() {
      console.log('entrou poup ',this.data)    
   }

   selectRow(contribuinte) {
     this.data['resultado'].forEach(item => {
      item['codigo'] === contribuinte.codigo
        ? (item['checked'] = !item['checked'])
        : (item['checked'] = false);
    });
    this.selectedEntities = this.data['resultado'].filter(
      item => item.checked === true
    );
  }

  proximo() {
    console.log('selecionou o contribuinte', this.selectedEntities)
    this.onSelectContribuinte.emit(this.selectedEntities);
  }

   fecharPopUp() {
    this.onClosePopUp.emit(false);
  }
}
