import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared.module';
import { HttpClientModule } from '@angular/common/http';
import { DataTableModule } from 'ng2-data-table';
import { CommonAppModule } from '../../features/common/common.module';

import { GerenciarUsuariosComponent } from './gerenciar-usuarios/gerenciar-usuarios.component';
import { AdicionarUsuarioComponent } from './gerenciar-usuarios/paginas/adicionar-usuario/adicionar-usuario.component';
import { FiltrarGerenciarUsuariosComponent } from './gerenciar-usuarios/componentes/filtro/filtro.component';
import { ConsultarUsuariosComponent } from './gerenciar-usuarios/paginas/consultar-usuarios/consultar-usuarios.component';
import { TreeviewComponent } from './gerenciar-usuarios/componentes/treeview/treeview.component';
import { FormularioContribuinteComponent } from './gerenciar-usuarios/paginas/formulario-contribuinte/formulario-contribuinte.component';
import { FormularioAdministradorAuditor } from './gerenciar-usuarios/paginas/formulario-adm-auditor/formulario-adm-auditor.component';
import { FormularioProcurador } from './gerenciar-usuarios/paginas/formulario-procurador/formulario-procurador.component';
import { EditarUsuarioComponent } from './gerenciar-usuarios/paginas/editar-usuario/editar-usuario.component';
import { TreeviewModule } from 'ngx-treeview';
import { TextMaskModule } from 'angular2-text-mask';
import { jqxTreeModule } from 'jqwidgets-ng/jqxtree';
import { jqxTreeGridModule } from 'jqwidgets-ng/jqxtreegrid';
import { jqxButtonModule } from 'jqwidgets-ng/jqxbuttons';
import { GestaoUsuarioRoutingModule } from './gestao-usuario.routing.module';
import { OpcoesUsuarioComponent } from './gerenciar-usuarios/paginas/opcoes-usuario/opcoes-usuario.component';
import { ProcuracaoComponent } from './gerenciar-usuarios/paginas/procuracao/procuracao.component';
import { PopUpCpfContribuinteComponent } from './gerenciar-usuarios/componentes/pop-up-cpf-contribuinte/pop-up-cpf-contribuinte.component';
import { AuthGuardService } from 'src/app/service/auth-guard/auth-guard.service';

@NgModule({
  imports: [
    CommonAppModule,
    CommonModule,
    GestaoUsuarioRoutingModule,
    DataTableModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    SharedModule,
    TreeviewModule.forRoot(),
    TextMaskModule,
    jqxTreeModule,
    jqxTreeGridModule,
    jqxButtonModule
  ],
  exports: [],
  declarations: [
    GerenciarUsuariosComponent,
    AdicionarUsuarioComponent,
    FiltrarGerenciarUsuariosComponent,
    ConsultarUsuariosComponent,
    TreeviewComponent,
    FormularioContribuinteComponent,
    FormularioAdministradorAuditor,
    FormularioProcurador,
    EditarUsuarioComponent,
    OpcoesUsuarioComponent,
    ProcuracaoComponent,
    PopUpCpfContribuinteComponent
  ],
  providers: [AuthGuardService]
})
export class GestaoUsuarioModule {}
