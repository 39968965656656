
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FormasAcessoService } from '../../../../../service/configuracao/forma-acesso.service';
import { Subscription } from 'rxjs';

import Swal from 'sweetalert2';
import { getSwal } from '../../../../../utils/swalObject';

@Component({
  selector: 'app-editar-forma-acesso',
  templateUrl: 'editar-forma-acesso.component.html',
  styleUrls: ['./editar-forma-acesso.component.scss']
})

export class EditarFormaAcessoComponent implements OnInit {
  routeSub: Subscription;
  editarFormaAcessoForm: FormGroup;
  idFormaAcesso:number = null;
  edicao:boolean = false;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private formasAcessoService: FormasAcessoService) { }

  ngOnInit() {
    this.criarFormulario();
    this.routeSub = this.route.params.subscribe(params => {
      if (params['id']) {
        this.idFormaAcesso = params['id'];
      }
    });
    if(this.idFormaAcesso) {      
      this.carregarDados()
    }
   }

   criarFormulario(){
     this.editarFormaAcessoForm = this.fb.group({
       descricao: ['', Validators.required],
       ativo: [false, Validators.required]
     })
   }

   carregarDados(){
    this.formasAcessoService.obterformaAcesso(this.idFormaAcesso).subscribe(res => {
      const data = res.resultado;
      for (let key in data) {
        if(this.editarFormaAcessoForm.controls[key]) {
          this.editarFormaAcessoForm.controls[key].patchValue(data[key]);
        }
      }
      this.editarFormaAcessoForm.controls.descricao.disable();
    }, error => {
      // Swal.fire(
      //   'Ops!',
      //   'Ocorreu um erro e a forma de acesso não pôde ser carregada.',
      //   'error'
      // );
      Swal.fire(
        getSwal(
          'error',
          'Ops!',
          "Ocorreu um erro e a forma de acesso não pôde ser carregada.",
          'ok',
          null,
        ),
      );
    })
   }

   isFormInvalid(){
     if(this.editarFormaAcessoForm.valid) {
       return false;
     } else {
       return true;
     }
   }

   onSubmit() {
     const value = this.editarFormaAcessoForm.getRawValue();
      this.formasAcessoService.editaformaAcesso(value, this.idFormaAcesso).subscribe(res => this.handleResponse(), error => this.handleError(error));
   }

   handleResponse(){
    // Swal.fire(
    //   'Sucesso!',
    //   'A forma de acesso foi editada com sucesso.',
    //   'success'
    // )
    Swal.fire(
      getSwal(
        'success',
        'Sucesso!',
        "A forma de acesso foi editada com sucesso.",
        'ok',
        null,
      ),
    );    
    this.router.navigate(['configuracao/formas-acesso/consultar']);
   }

   handleError(error){
    // Swal.fire(
    //   'Ops!',
    //   'Ocorreu um erro e a forma de acesso não pôde ser editada.',
    //   'error'
    // );
    Swal.fire(
      getSwal(
        'error',
        'Ops!',
        "Ocorreu um erro e a forma de acesso não pôde ser editada.",
        'ok',
        null,
      ),
    );    
   }
}