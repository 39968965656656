import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Router } from '@angular/router';

import { OpcoesLoginComponent } from './opcoes-login/opcoes-login.component';
import { CertificadoComponent } from './certificado/certificado.component';
import { RecuperarSenhaComponent } from './recuperar-senha/recuperar-senha.component';
import { ResetarSenhaComponent } from './resetar-senha/resetar-senha.component';
import { ConfirmacaoComponent } from './confirmacao/confirmacao.component';
import { ClientesComponent } from './cliente/cliente.component';
import { ProcuracaoComponent } from './procuracao/procuracao.component';
import { ECnpjComponent } from './e-cnpj/e-cnpj.component';

const routes: Routes = [
  { path: '', redirectTo: '/entrar', pathMatch: 'full' },
  { path: 'entrar', component: OpcoesLoginComponent },
  { path: 'clientes', component: ClientesComponent },
  { path: 'empresas', component: ECnpjComponent },
  { path: 'procuracao', component: ProcuracaoComponent },
  { path: 'certificado', component: CertificadoComponent },
  { path: 'recuperar-senha', component: RecuperarSenhaComponent },
  { path: 'alterar-senha/:token', component: ResetarSenhaComponent },
  { path: 'alterar-senha', component: ResetarSenhaComponent },
  { path: 'confirmar', component: ConfirmacaoComponent },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LoginRoutingModule {}
