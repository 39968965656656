import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { SetorAtuacaoService } from "../../../../../service/configuracao/setor-atuacao.service";

import Swal from 'sweetalert2';
import { getSwal } from '../../../../../utils/swalObject';

@Component({
    selector: 'app-editar-setor-atuacao',
    templateUrl: './editar-setor-atuacao.component.html',
    styleUrls: ['./editar-setor-atuacao.component.scss']
})
export class EditarSetorAtuacaoComponent implements OnInit {
    routeSub: Subscription;
    editarSetorAtuacaoForm: FormGroup;
    idSetorAtuacao:number = null;
    edicao:boolean = false;

    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private setorAtuacaoService: SetorAtuacaoService) { } 

    ngOnInit() {
        this.criarFormulario();
        this.routeSub = this.route.params.subscribe(params => {
          if (params['id']) {
            this.idSetorAtuacao = params['id'];
          }
        });
        if(this.idSetorAtuacao) {      
          this.carregarDados();
        }
    }
    
    criarFormulario(){
        this.editarSetorAtuacaoForm = this.fb.group({
        descricao: ['', Validators.required],
        ativo: [false, Validators.required]
        })
    }

    carregarDados(){
    this.setorAtuacaoService.obterSetorAtuacao(this.idSetorAtuacao).subscribe(res => {
        const data = res.resultado;
        for (let key in data) {
        if(this.editarSetorAtuacaoForm.controls[key]) {
            this.editarSetorAtuacaoForm.controls[key].patchValue(data[key]);
        }
        }
    }, error => {
        // Swal.fire(
        //   'Ops!',
        //   'Ocorreu um erro e a forma de acesso não pôde ser carregada.',
        //   'error'
        // );
        Swal.fire(
        getSwal(
            'error',
            'Ops!',
            "Ocorreu um erro e a forma de acesso não pôde ser carregada.",
            'ok',
            null,
        ),
        );      
    });
    }

    isFormInvalid(){
        if(this.editarSetorAtuacaoForm.valid) {
        return false;
        } else {
        return true;
        }
    }

    onSubmit() {
        const value = this.editarSetorAtuacaoForm.getRawValue();
        this.setorAtuacaoService.editaSetorAtuacao(value, this.idSetorAtuacao).subscribe(res => this.handleResponse(), error => this.handleError(error));
    }

    handleResponse(){
    // Swal.fire(
    //   'Sucesso!',
    //   'O perfil de acesso foi editado com sucesso.',
    //   'success'
    // )
    Swal.fire(
        getSwal(
        'success',
        'Sucesso!',
        "O perfil de acesso foi editado com sucesso.",
        'ok',
        null,
        ),
    );    
    this.router.navigate(['configuracao/setor-atuacao/consultar']);
    }

    handleError(error){
    // Swal.fire(
    //   'Ops!',
    //   'Ocorreu um erro e o perfil de acesso não pôde ser editado.',
    //   'error'
    // );
    Swal.fire(
        getSwal(
        'error',
        'Ops!',
        "Ocorreu um erro e o perfil de acesso não pôde ser editado.",
        'ok',
        null,
        ),
    );    
    }

    
}