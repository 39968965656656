import { Component, ViewEncapsulation, Input } from '@angular/core';
import { UserService } from '../../../service/user-service/user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: '[app-sidebar]',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  host: { class: 'sidebarBox' },
  encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent {
  subscription: Subscription;
  private termoPesquisa: string;
  private selectedItem = 0;
  private estadoMenu = {
    sidebarFechada: false,
  };

  // _scrollLeft;
  // @Input()
  // set scroolLeft(value) {
  //   console.log('set scrolling', value, -value);
  //   this._scrollLeft = (value > 0 ? -value : 0) + 'px';
  //   // this._scrollLeft = value > 0 ? '-' + value + 'px' : '0px';
  // }
  // get scroolLeft() {
  //   return this._scrollLeft;
  // }

  private icon = '#menu';
  constructor(private userService: UserService) {
    this.subscription = this.userService.getInfo().subscribe(info => {
      this.estadoMenu.sidebarFechada = info.sidebarFechada;
    });
  }

  fecharSidebar() {
    this.estadoMenu.sidebarFechada = !this.estadoMenu.sidebarFechada;
    this.userService.addData(this.estadoMenu);
  }
}
