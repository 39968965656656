import { Component, OnInit } from '@angular/core';
import { appClient } from './../../../../environments/version';

@Component({
  selector: 'app-opcoes-configuracao',
  templateUrl: 'opcoes-configuracao.component.html',
  styleUrls: ['./opcoes-configuracao.component.scss'],
})
export class OpcoesConfiguracaoComponent implements OnInit {
  constructor() {}

  showEnviarButton: boolean = appClient.indexOf('sefaz') === -1;

  ngOnInit() {}
}
