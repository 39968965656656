import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from '../../../../../../../node_modules/rxjs';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { getSwal } from '../../../../../utils/swalObject';
import { RegrasAcessoService } from "../../../../../service/configuracao/regras-acesso.service";

@Component({
  selector: 'app-consulta-regras-acesso',
  templateUrl: './consulta-regras-acesso.component.html',
  styleUrls: ['./consulta-regras-acesso.component.scss']

})
export class ConsultaRegrasAcessoComponent implements OnInit{
  @ViewChild('childFilter', {static: false}) child;
  @Input() itemsPerView: number = 10;
  // data: Array<any> = [];
  dataRegraAcesso: Array<any> = [];
  // dataRegraAcesso: Array<any> = null;

  routeSub: Subscription;
  regrasAcessoForm: FormGroup;
  searchForm: FormGroup;
  dialogMessage = {};
  viewDialog = false;
  itemsPage: number = 1;
  labels: any;
  numeroRegras: string = '10'

  codPersonalidade = '';
  loading: boolean;
  idPerfilAcesso: number = null;
  dadosDeAmbiente: any = {};
  registroAmbiente: any = [];
  registroSalvo: any = [];
  errorMessage = '';
  searchParams = {};
  searchInput: string = '';
  codigoCliente: number = 1;
  subscription: Subscription;
  data: Object = {
    primeiraPagina: true,
    ultimaPagina: false,
    paginaAtual: 1,
    totalPaginas: 1,
    totalDeItems: 0,
    itemsPorPagina: 10
  };
  paginaAtual: number = 1;
  personalidadesJuridicas: Array<any> = []
  filterCurrent: Object = {
    pagina: 1,
    total_pagina: 10
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private regrasAcessoService: RegrasAcessoService,
    private translate: TranslateService
  ) {
    this.routeSub = this.route.params.subscribe(params => {
      if (params['id']) {
        this.idPerfilAcesso = params['id'];
      }
    });
    this.data = [];
    this.setLabels();
  }

  ngOnInit() {
    this.subscription =
        this.regrasAcessoService.dataRegraAcesso$.subscribe(
          (res) => {
            this.data = res;
            this.filterCurrent = this.child.searchParams;
            this.dataRegraAcesso = res["resultado"];
            this.data['totalDeItems'] = res.totalRegistros;
            this.data['totalPaginas'] = res['totalPaginas'];
            this.data['primeiraPagina'] = res.primeiraPagina;
            this.data['ultimaPagina'] = res.ultimaPagina;
            this.loading = false;
            console.log(res);
          }
        );
    this.obterDados();
  }

  obterDados() {
    this.loading = true;
    let parametros = {};
    parametros['pagina'] = this.paginaAtual;
    parametros['total_pagina'] = this.itemsPerView;

    if (this.child.searchParams.codPersonalidade) {
      parametros['codPersonalidade'] = this.child.searchParams.codPersonalidade;
    } else {
      delete parametros['codPersonalidade'];
    }
    // this.codPersonalidade ? parametros['codPersonalidade'] = this.child.searchParams.codPersonalidade : delete parametros['codPersonalidade'];
    if (this.child.searchParams.codSetorAtuacao) {
      parametros['codSetorAtuacao'] = this.child.searchParams.codSetorAtuacao;
    } else {
      delete parametros['codSetorAtuacao'];
    }
    if (this.child.searchParams.codFormaAcesso) {
      parametros['codFormaAcesso'] = this.child.searchParams.codFormaAcesso;
    } else {
      delete parametros['codFormaAcesso'];
    }

    this.regrasAcessoService.getRegrasAcesso(this.idPerfilAcesso, parametros)
      .subscribe(res => {
        this.loading = false;
        if (res.tamanhoPagina) {
          this.data = res;
          this.data['totalDeItems'] = res.totalRegistros;
          this.data['totalPaginas'] = this.itemsPerView;
          // this.dataRegraAcesso = res["resultado"];
          this.loading = false;
        }
      }, error => {
        this.loading = false;
      });
  }

  rotaEditar() {
    this.router.navigate(['/configuracao/ambiente/editar-regras-acesso/' + this.idPerfilAcesso]);
  }

  excluir(codigo) {
    console.log('codigo', codigo)
    this.regrasAcessoService
      .desabilitaRegrasAcesso(this.idPerfilAcesso, codigo)
      .subscribe(
        res => {
          Swal.fire(
            getSwal(
              'success',
              'Sucesso',
              'Regras de acesso ao portal atualizado com sucesso.',
              'ok',
              null
            )
          ).then(res => {
            this.ngOnInit();
            this.obterDados();
          });
        },
        error => {
          this.ngOnInit();
          this.obterDados();
          console.log(error);
          Swal.fire(
            getSwal(
              'error',
              'Ops!',
              error.error.excecao.recomendacao,
              'ok',
              null
            )
          );
        }
      );
  }

  ajuda(valor) {
    let msg1=
    `<ol>
      <li>
        Definir o comportamento da solução quanto a forma do contribuinte acessar a plataforma.
      </li>
    </ol>`;

    switch(valor) {
      case 'regraAcesso': {
        Swal.fire({
          icon: 'info',
          html:msg1,
          confirmButtonText:'Ok' ,

        })
        break;
      }
    }
  }

  next() {
    this.data['paginaAtual']++;
    this.onPageChange(this.filterCurrent);
  }

  previous() {
    this.data['paginaAtual']--;
    this.onPageChange(this.filterCurrent);
  }

  ultimaPagina() {
    this.data['paginaAtual'] = this.data['totalPaginas'];
    this.onPageChange(this.filterCurrent);
  }

  primeiraPagina() {
    this.data['paginaAtual'] = 1;
    this.onPageChange(this.filterCurrent);
  }

    onPageChange(value) {
      value['pagina'] = this.data['paginaAtual'];
      value['total_pagina'] = this.itemsPerView;

      this.filterCurrent = value;
      this.loading = true;

      this.regrasAcessoService.getRegrasAcesso(this.idPerfilAcesso, value).subscribe(
          res => {
            this.dataRegraAcesso = res["resultado"];
              this.loading = false;
          },
          error => {
              this.loading = false;
              console.error('Erro ao obter as Regras:', error);
          }
      );
  }

    quantidadeRegras(numeroRegras) {

      this.itemsPerView = +numeroRegras;

      this.data['paginaAtual'] = 1;
      this.data['itemsPorPagina'] = this.itemsPerView;
      this.filterCurrent['total_pagina'] = this.itemsPerView;

      this.onPageChange(this.filterCurrent);

      this.data['paginaAtual'] = 1;
      this.data['itemsPorPagina'] = this.itemsPerView;

      switch (numeroRegras) {
        case "10":
          this.data['itemsPorPagina'] = 10;
          console.log(this.data['itemsPorPagina']);
          break;
        case "20":
          this.data['itemsPorPagina'] = 20;
          console.log(this.data['itemsPorPagina']);
          break;
        case "50":
          this.data['itemsPorPagina'] = 50;
          console.log(this.data['itemsPorPagina']);
          break;
        case "100":
          this.data['itemsPorPagina'] = 100;
          console.log(this.data['itemsPorPagina']);
          break;
      }

      this.loading = true;
      // return this.obterDados()
    }

  setLabels() {

    this.labels = {
      pagina: this.translate.instant('GLOBAIS.PAGINA')
    }
  }
}
