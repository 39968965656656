import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gerenciar-usuarios',
  templateUrl: 'gerenciar-usuarios.component.html',
  styleUrls: ['./gerenciar-usuarios.component.scss']
})

export class GerenciarUsuariosComponent implements OnInit {

  constructor() {}

  ngOnInit() {
  }


}