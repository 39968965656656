import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewChildren,
} from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { CaixaPostalService } from "../../../../../service/caixa-postal/caixa-postal.service";
import { ActivatedRoute, Router } from "@angular/router";
import { SessionService } from "src/app/service/session/session.service";

@Component({
  selector: "[app-filter]",
  templateUrl: "filter.component.html",
  styleUrls: ["./filter.component.scss"],
})
export class FilterComponent implements OnInit {
  @ViewChildren("filtros") filtros;
  searchParams: Object = null;
  filtrosAplicados: boolean = false;
  limparFiltro: boolean = false;
  validade = null;
  filtroGraficoAtivo = window.location.href;
  filtroAtivo = false;
  filtroMensagens: any;
  isAdmnistrativo: boolean = false;
  session: any;

  @Output() onDataChange: EventEmitter<any> = new EventEmitter<any>();
  _canVisible: number;
  @Input()
  set canVisible(value: number) {
    this._canVisible = value;
    if (value > 0) {
      this.visible = "";
    }
  }

  get canVisible() {
    return this._canVisible;
  }

  _visible: string = "";
  @Input()
  set visible(value: string) {
    this._visible = value;
    if (this._visible === "") {
      this._canVisible = 0;
    }
  }

  get visible() {
    return this._visible;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private caixaPostalService: CaixaPostalService,
    private sessionService: SessionService
  ) {}

  ngOnInit() {
    if (this.route.paramMap) {
      this.route.paramMap.subscribe((params) => {
        this.filtroMensagens = params.get("filtroMensagens");
      });
    }
    this.searchParams = JSON.parse(sessionStorage.getItem("filtro"))
      ? JSON.parse(sessionStorage.getItem("filtro"))
      : {};
    this.verificaFiltrosAplicados()
    
    this.session = this.sessionService.getSession();
    this.isAdmnistrativo = this.session['perfilAcesso'].codigo == 5;
  }

  clear(params) {
    let paginacao = {
      pagina: this.searchParams["pagina"],
      total_pagina: this.searchParams["total_pagina"],
    };

    if (params === "todos") {
      this.router.navigate([`/caixa-postal`]);
      this.searchParams = {
        pagina: paginacao.pagina,
        total_pagina: paginacao.total_pagina,
      };
      this.filtros._results.forEach((res) => {
        res.resetarParametros();
      });
    } else if (params.tipo && params.tipo === "cpf_cnpj") {
      delete this.searchParams[params.tipo];
    } else if (params.tipo && params.tipo === "inscricao") {
      delete this.searchParams[params.tipo];
    } else if (params == "validade") {
      delete this.searchParams["a_vencer"];
      delete this.searchParams["vencidos"];
      delete this.searchParams["respostasVencidas"];
      delete this.searchParams["respostaAVencer"];
      delete this.searchParams["respondidas"];
    } else if (params.data_inicial) {
      delete this.searchParams["data_inicial"];
      delete this.searchParams["data_final"];
    } else if (params.arquivados !== undefined) {
      delete this.searchParams["arquivados"];
    } else if (
      params.cpf_remetente !== undefined &&
      params.cod_remetente !== undefined
    ) {
      delete this.searchParams["cpf_remetente"];
      delete this.searchParams["cod_remetente"];
    } else if (params.codigo_tipo_comunicado !== undefined) {
      delete this.searchParams["codigo_tipo_comunicado"];
    }

    sessionStorage.setItem("filtro", JSON.stringify(this.searchParams));
    this.onDataChange.emit(this.searchParams);
    this.visible = "";
    this.validade = this.filtros._results[1].validadeSelecionada;
    this.verificaFiltrosAplicados();
  }

  onFilterOpen($event) {
    this.visible = this.visible === $event ? "" : $event;
  }

  search(params) {
    for (let key in params) {
      if (params[key] === "") {
        delete this.searchParams[key];
      } else {
        this.searchParams[key] = params[key];
      }
    }

    this.validade = this.filtros._results[1].validadeSelecionada;
    sessionStorage.setItem("filtro", JSON.stringify(this.searchParams));
    this.onDataChange.emit(this.searchParams);
    this.visible = "";

    this.verificaFiltrosAplicados();
  }

  verificaFiltrosAplicados() {
    let filtro = this.searchParams;
    delete filtro["pagina"];
    delete filtro["total_pagina"];
    this.filtrosAplicados =
      Object.keys(filtro).length !== 0 || this.filtroMensagens !== null;
  }

  tipoComunicado(dados) {
    this.onDataChange.emit(dados);
  }
}
