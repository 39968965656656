import { Component, OnInit, Output, Input, EventEmitter, AfterViewChecked } from '@angular/core';
import * as moment from 'moment-timezone';
import 'moment/locale/pt-br'

@Component({
  selector: 'app-filtro-periodo',
  templateUrl: 'filtro-periodo.component.html',
  styleUrls: ['./filtro-periodo.component.scss'],
})
export class FiltroPeriodoComponent implements OnInit, AfterViewChecked {
  @Output() onFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClear: EventEmitter<any> = new EventEmitter<any>();
  @Output() onOpen: EventEmitter<any> = new EventEmitter<any>();
  @Output() onPageUpdate: EventEmitter<number> = new EventEmitter<number>();

  paginaAtual: number = 1;
  periodo: boolean = false;
  dataInicial: any = null;
  dataFinal: any = null;
  @Input() visible: string;
  inicio: moment.Moment;
  fim: moment.Moment;
  dataInclusaoValida: boolean = false;
  dataFimInclusaoValida: boolean = false;

  searchParams: Object = {
    pagina: 1
  };

  loading: boolean = true;
  dateformat: string = 'YYYY-MM-DD';

  obj = {
    data_inicial: moment(),
    data_final: moment(),
  };

  data: Object = {
    primeiraPagina: true,
    ultimaPagina: false,
    paginaAtual: 1,
    totalPaginas: 1,
    totalDeItems: 0,
    itemsPorPagina: 10
  };

  constructor() {}

  ngAfterViewChecked(): void {

    const sessaofiltro = JSON.parse(sessionStorage.getItem('filtro'));

    if(sessaofiltro && sessaofiltro.data_inicial){
      this.inicio = moment(sessaofiltro.data_inicial);
      this.fim = moment(sessaofiltro.data_final);
      this.periodo = true;
    } else {
      this.periodo = false;
    }

  }

  ngOnInit() {
    moment.locale('pt-br');
  }

  selecionarDataInicial(event) {
    const dataAtual = moment();
    const dataSelecionada = moment(this.inicio);

    if (dataSelecionada.isAfter(dataAtual, 'day')) {
      this.dataInclusaoValida = false;
      return;
    }

    if (this.inicio) {
      this.dataInicial = this.inicio;
      this.dataInclusaoValida = true;
    }
    this.obj['data_inicial'] = this.inicio
      ? this.inicio.format(this.dateformat)
      : null; //moment(this.inicio, 'DD-MM-YYYY').format();
    if (this.inicio && this.fim && this.inicio > this.fim) {
      setTimeout(() => {
        this.obj['data_final'] = null; //  moment.Moment;
        this.fim = null; //moment.Moment;
      }, 100);
    }
  }

  selecionarDataFinal(event) {
    const dataAtual = moment();
    const dataSelecionadaFim = moment(this.fim);

    if (dataSelecionadaFim.isAfter(dataAtual, 'day')) {
      this.dataFimInclusaoValida = false;
      return;
    }

    if (this.fim) {
      this.dataFinal = this.fim;
      this.dataFimInclusaoValida = true;
    }
    this.obj['data_final'] = this.fim ? this.fim.format(this.dateformat) : null;
  }

  handlePageUpdate(event: number) {
    this.paginaAtual = 1;
  }


  pesquisarPeriodo() {
    this.loading = true;
    // this.visible = false;
    this.onFilter.emit(this.obj);
    this.periodo = true;

    this.onFilter.emit(this.obj);
    this.onPageUpdate.emit(1);
    this.data['pagina'] = 1
  }

  limparFiltros() {
    // this.resetarParametros()
    this.searchParams['pagina'] = 1;
    this.dataInclusaoValida = false;
    // this.onFilter.emit(this.obj);
    this.onPageUpdate.emit(1);
    this.onClear.emit(this.obj);
    this.obj = {
      data_inicial: moment.Moment,
      data_final: moment.Moment,
    };
    this.fim = moment.Moment;
    this.inicio = moment.Moment;
    this.periodo = false;
    // this.filtroAtivo = false;

    let storage = JSON.parse(sessionStorage.getItem('filtro'));
    if(storage.data_inicial && storage.data_final) {
      delete storage.data_inicial
      delete storage.data_final
    }

    this.periodo = false;
    this.dataInclusaoValida = false;

    sessionStorage.setItem('filtro', JSON.stringify(storage));
  }

  resetarParametros() {
    let obj = {}
    this.onFilter.emit(obj);
    this.onPageUpdate.emit(1);
    this.searchParams['pagina'] = 1;
    // this.limparFiltros()
    this.fim = moment.Moment;
    this.inicio = moment.Moment;


    // this.searchParams['pagina'] = 1;
  }
}
