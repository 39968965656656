import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subject, forkJoin } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { ExpectedConditions } from 'protractor';
import { tap, map } from 'rxjs/operators';

@Injectable()
export class GerenciarUsuariosService {
  url: string = environment.BASE_URL;

  private usuarios = new Subject<any>();
  usuarios$ = this.usuarios.asObservable();

  usuario = new BehaviorSubject<any>('');
  usuario$ = this.usuario.asObservable();

  constructor(private http: HttpClient) {}

  exportarUsuarios(searchParams) {
    const params = this.setParams(searchParams);
    return this.http
      .get(`${this.url}/usuarios/exportar`, {
        params: params,
        responseType: 'arraybuffer'
      })
      .pipe( map(data => {
        const body = data;
        return body;
      }));
  }

  obterUsuario(codigoUsuario): Observable<any> {
    return this.http.get(`${this.url}/usuarios/${codigoUsuario}`).pipe(map(data => {
      const body = data['resultado'];
      return body;
    }));
  }

  getData(): Observable<any> {
    const sistemas = this.http
      .get(`${this.url}/aplicacoes/funcionalidadesAutorizadasProcuracao`)
      .pipe( map(res => res) );
    const tiposProcuracao = this.http
      .get(`${this.url}/tiposProcuracao/ativos`)
      .pipe( map(res => res));
    return forkJoin([sistemas, tiposProcuracao]);
  }

  getFuncionalidadeAutorizadasProcuracao(codigoTipoProcuracao): Observable<any> {
    const params = new HttpParams().set('codigoTipoProcuracao', codigoTipoProcuracao);
    return this.http
      .get(`${this.url}/aplicacoes/funcionalidadesAutorizadasProcuracao`, { params: params })
      .pipe( map(data => {
        return data;
      }));
  }


  getProcuracoes(codigoUsuario): Observable<any> {
    return this.http.get(`${this.url}/procuracoes/${codigoUsuario}`).pipe( map(data => {
      const body = data['resultado'];
      return body;
    }));
  }

  obterUsuarios(searchParams): Observable<any> {
    const params = this.setParams(searchParams);
    return this.http.get(`${this.url}/usuarios`, {params: params})
      .map(data => {
        const body = data;
        this.usuarios.next(body);
        return body;
      });
  }

  obterQuantidadeUsuariosPorPerfilAcesso(searchParams): Observable<any> {
    const params = this.setParams(searchParams);
    return this.http
      .get(`${this.url}/usuarios/quantidadeUsuariosPorPerfilAcesso`,{params : params})
      .pipe( map(data => {
        const body = data['resultado'];
        return body;
      }));
  }

  // GRAFICO - Procurações por Tipo de Procuração x Situação
  obterquantidadeTipoProcuracaoPorSituacao(searchParams): Observable<any> {
    const params = this.setParams(searchParams);
    return this.http
      .get(`${this.url}/tiposProcuracao/quantidadeTipoProcuracaoPorSituacao`, { params: params })
      .pipe( map(data => {
        const body = data['resultado'];
        return body;
      }));
  }
  // FIM GRAFICO - Procurações por Tipo de Procuração x Situação


  obterQuantidadeUsuariosPorSituacao(searchParams): Observable<any> {
    const params = this.setParams(searchParams);
    return this.http
      .get(`${this.url}/usuarios/quantidadeUsuariosPorSituacao`,{params : params})
      .pipe( map(data => {
        const body = data['resultado'];
        return body;
      }));
  }

  obterQuantidadeAcessosDiarios(searchParams): Observable<any> {
    const params = this.setParams(searchParams);
    return this.http
      .get(`${this.url}/sessoes/quantidadeAcessosDiarios`,{params : params})
      .pipe( map(data => {
        const body = data['resultado'];
        return body;
      }));
  }

  obterQuantidadeUsuariosPorTipoProcuracao(searchParams): Observable<any> {
    const params = this.setParams(searchParams);
    return this.http
      .get(`${this.url}/usuarios/quantidadeUsuariosPorTipoProcuracao`,{params : params})
      .pipe( map(data => {
        const body = data['resultado'];
        return body;
      }));
  }

  obterUsuariosExportar(codigoUsuario): Observable<any> {
    const params = new HttpParams().set("codigoUsuario", codigoUsuario);

    return this.http
      .get(`${this.url}/eventos/usuarios/ativacaoCadastro/exportar`, {
        params: params,
        responseType: 'arraybuffer'
      })
      .map(data => {
        const body = data;
        return body;
      });
  }

  setParams(searchParams: any) {
    let params = new HttpParams();
    if (!searchParams) {
      return params;
    }
    if (searchParams.key && searchParams.value) {
      return params.set(searchParams.key, searchParams.value);
    }
    for (const key of Object.keys(searchParams)) {
      params = params.set(key, searchParams[key]);
    }
    return params;
  }

  setUser(user) {
    this.usuario.next(user);
  }

  adicionarContribuinte(contribuinte): Observable<any> {
    return this.http
      .post(`${this.url}/usuarios/contribuinte`, contribuinte)
      .pipe( map(data => {
        const body = data;
        return body;
      }));
  }

  adicionarProcurador(procurador): Observable<any> {
    return this.http
      .post(`${this.url}/usuarios/procurador`, procurador)
      .pipe( map(data => {
        const body = data;
        return body;
      }));
  }

  adicionarAdmAuditor(administradorAuditor): Observable<any> {
    return this.http
      .post(`${this.url}/usuarios`, administradorAuditor)
      .pipe( map(data => {
        const body = data;
        return body;
      }));
  }

  obterPerfisAcesso(): Observable<any> {
    return this.http.get(`${this.url}/perfisAcesso`).pipe( map(data => {
      const body = data['resultado'];
      return body;
    }));
  }

  editarUsuario(codigoUsuario, recuperaSenha, usuario): Observable<any> {
    return this.http
      .put(
        `${this.url}/usuarios/${codigoUsuario}?recuperarSenha=${recuperaSenha}`,
        usuario
      )
      .pipe( map(data => {
        const body = data['resultado'];
        return body;
      }));
  }

  getRepresetanteLegal(cpfCnpj): Observable<any> {
    return this.http.get(`${this.url}/usuarios?cpfCnpj=${cpfCnpj}&pagina=1&total_pagina=1`).map(data => {
      const body = data['resultado'][0];
      return body;
    });
  }

  getApiExterna(cpfCnpj): Observable<any> {
    return this.http.get(`${this.url}/contribuintes/autocadastro?cpfCnpj=${cpfCnpj}`).map(data => {
      const body = data;
      return body;
    });
  }

  getAutoCadastro(): Observable<any> {
    return this.http.get(`${this.url}/clientes/verificacaoIntegracaoAutocadastro`).map(data => {
      const body = data;
      return body;
    });
  }

  consultarUsuario(id: string): Observable<any> {
    const params = new HttpParams().set('cpfCnpj', id);
    return this.http.get(`${this.url}/usuarios?pagina=1&total_pagina=1`, { params: params }).map(data => {
      return data;
    });
  }
  
  consultarUsuarioUnico(id: string): Observable<any> {
    const InterceptorSkipHeader = 'X-Skip-Interceptor';
    const headers = new HttpHeaders().set(InterceptorSkipHeader, '');
    const params = new HttpParams().set('cpfCnpj', id);
    return this.http.get(`${this.url}/usuarios`, {headers: headers, params: params }).map(data => {
      return data;
    });
  }
  
  
}
