import { Component, OnInit } from '@angular/core';
import { ComunicadosService } from '../../../../service/comunicados/comunicados.service';
import { AmChartsService, AmChart } from '@amcharts/amcharts3-angular';
import { AfterViewInit } from '@angular/core';
import { graphicCollors } from '../../../../../environments/version';

@Component({
  selector: '[app-graficos]',
  templateUrl: 'graficos.component.html',
  styleUrls: ['./graficos.component.scss'],
})
export class GraficosComunicadosComponent implements OnInit {
  // numServicosAtivos: number;
  // numServicosAtivosInoperantes: number;
  // numServicosAtivosAlertas: number;
  // max = 0;
  // selected = 'ativos';

  private chart: AmChart;
  gColors = graphicCollors;

  quantidadeAno: any = [];
  quantidadeMes: any = [];
  quantidadeDia: any = [];

  constructor(
    private AmCharts: AmChartsService,
    private comunicados: ComunicadosService,
  ) {}

  ngOnInit() {
    this.obterQuantidadePorAno();
    this.obterQuantidadePorMes();
    this.obterQuantidadePorDia();
  }

  ngAfterViewInit() {}

  obterQuantidadePorAno() {
    this.comunicados.obterDadosAno().subscribe(response => {
      const quantidade = response.resultado.map(element => {
        element.rotulo =
          element.rotulo.length > 25
            ? element.rotulo.substring(0, 25) + '...'
            : element.rotulo;
        return element;
      });

      const result = quantidade.reduce((defValue, elem) => {
        return defValue + elem.total;
      }, 0);
      if (result == 0) {
        this.quantidadeAno = [{ rotulo: 'Sem dados', total: 0.00001 }];
      } else {
        this.quantidadeAno = quantidade;
      }

      console.log(this.quantidadeAno);

      this.chart = this.AmCharts.makeChart('quantidadeAno', {
        type: 'pie',
        radius: '30%',
        labelsEnabled: false,
        legend: {
          enabled: false,
          align: 'center',
          markerType: 'circle',
        },
        titles: [
          {
            text: 'Ano',
          },
        ],
        baseColor: this.gColors.baseColor,
        titleField: 'rotulo',
        valueField: 'total',
        dataProvider: this.quantidadeAno,
      });
    });
  }

  obterQuantidadePorMes() {
    this.comunicados.obterDadosMes().subscribe(response => {
      // const quantidade = response.resultado;

      const quantidade = response.resultado.map(element => {
        element.rotulo =
          element.rotulo.length > 25
            ? element.rotulo.substring(0, 25) + '...'
            : element.rotulo;
        return element;
      });

      const result = quantidade.reduce((defValue, elem) => {
        return defValue + elem.total;
      }, 0);

      if (result == 0) {
        this.quantidadeMes = [{ rotulo: 'Sem dados mensais', total: 0.00001 }];
      } else {
        this.quantidadeMes = quantidade;
      }

      console.log(this.quantidadeMes);

      this.chart = this.AmCharts.makeChart('quantidadeMes', {
        type: 'pie',
        radius: '30%',
        labelsEnabled: false,
        legend: {
          enabled: false,
          align: 'center',
          markerType: 'circle',
        },
        titles: [
          {
            text: 'Mês',
          },
        ],
        baseColor: this.gColors.baseColor,
        titleField: 'rotulo',
        valueField: 'total',
        dataProvider: this.quantidadeMes,
        precision: 0,
      });
    });
  }

  obterQuantidadePorDia() {
    this.comunicados.obterDadosDia().subscribe(response => {
      // const quantidade = response.resultado;

      const quantidade = response.resultado.map(element => {
        element.rotulo =
          element.rotulo.length > 25
            ? element.rotulo.substring(0, 25) + '...'
            : element.rotulo;
        return element;
      });

      const result = quantidade.reduce((defValue, elem) => {
        return defValue + elem.total;
      }, 0);

      if (result == 0) {
        this.quantidadeDia = [{ rotulo: 'Sem dados diários', total: 0.00001 }];
      } else {
        this.quantidadeDia = quantidade;
      }

      console.log(this.quantidadeDia);

      this.chart = this.AmCharts.makeChart('quantidadeDia', {
        type: 'pie',
        radius: '30%',
        labelsEnabled: false,
        legend: {
          enabled: false,
          align: 'center',
          markerType: 'circle',
        },
        titles: [
          {
            text: 'Dia',
          },
        ],
        baseColor: this.gColors.baseColor,
        titleField: 'rotulo',
        valueField: 'total',
        dataProvider: this.quantidadeDia,
        precision: 0,
      });
    });
  }

  ngOnDestroy() {
    if (this.chart) {
      this.AmCharts.destroyChart(this.chart);
    }
  }
}
