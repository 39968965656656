import { Directive, ElementRef, HostListener, Output, EventEmitter } from '@angular/core';
@Directive({
	selector: '[clickOut]',
})
export class ClickOutDirective {
    @Output() onClickOut: EventEmitter<any> = new EventEmitter<any>();
    constructor(
        private elementRef: ElementRef
    ) {}    
    @HostListener('document:click', ['$event'])
    clickout(event:any) {
        if(!this.elementRef.nativeElement.contains(event.target) && !JSON.stringify(event.target.className).split("-").includes("\"dp")){
            this.onClickOut.emit()
        }
    }  
}
