import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { FormasAcessoService } from '../../../../../service/configuracao/forma-acesso.service';

@Component({
  selector: 'app-consultar-formas-acesso',
  templateUrl: 'consultar-formas-acesso.component.html',
  styleUrls: ['./consultar-formas-acesso.component.scss']
})

export class ConsultarFormasAcessoComponent implements OnInit {

  data: Array<any> = null;
  subscription: Subscription;


  constructor(private formasAcessoService: FormasAcessoService, private router: Router) { 
    this.data = [];
  }

  ngOnInit() {
      this.subscription = this.formasAcessoService.formasAcesso$.subscribe(res => {
        this.data = res.resultado;
      }, error => console.log(error))
   }

   edit(codigo:number) {
     this.router.navigate([`configuracao/formas-acesso/editar/${codigo}`])
   }
}