import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared.module';
import { HttpClientModule } from '@angular/common/http';
import { RegistroAcoesRoutingModule } from './registro-acoes.routing.module';
import { DataTableModule } from 'ng2-data-table';
import { DpDatePickerModule } from 'ng2-date-picker';
import { CommonAppModule } from '../../features/common/common.module';
import { RegistroAcoesComponent } from './registro-acoes/registro-acoes.component';
import { GridRegistroAcoesComponent } from './grid-registro-acoes/grid-registro-acoes.component';
import { FilterComponent } from './grid-registro-acoes/components/filter.component';
import { GraficoComponent } from './grid-registro-acoes/components/graficos/grafico.component';
import { ServicosAtivos } from './grid-registro-acoes/pages/servicos-ativos/servicos-ativos.component';
import { GridViewRegistroAcoesComponent } from './grid-registro-acoes/pages/grid-view-registro-acoes/grid-view-registro-acoes.component';
import { ServicosInoperantes } from './grid-registro-acoes/pages/servicos-inoperantes/servicos-inoperantes.component';
import { ServicosAlertas } from './grid-registro-acoes/pages/servicos-alertas/servicos-alertas.component';
import { firstLetterUpperCasePipe } from 'src/app/pipes/first-letter-upper-case'; 
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  imports: [
    CommonAppModule,
    CommonModule,
    DataTableModule,
    DpDatePickerModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    RegistroAcoesRoutingModule,
    SharedModule,
    NgxMaskModule.forRoot()
  ],
  exports: [GridRegistroAcoesComponent, RegistroAcoesComponent],
  declarations: [
    GridRegistroAcoesComponent,
    RegistroAcoesComponent,
    FilterComponent,
    GraficoComponent,
    ServicosAtivos,
    ServicosInoperantes,
    ServicosAlertas,
    GridViewRegistroAcoesComponent
  ],
  providers: [firstLetterUpperCasePipe]
})
export class RegistroAcoesModule {}
