import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AplicacoesService } from '../../../../../service/aplicacoes/aplicacoes.service';
import Swal from 'sweetalert2';
import { getSwal } from '../../../../../utils/swalObject';
import { Router, NavigationExtras } from '@angular/router';
import {
  gerenciarSistemasRouterPath,
  graphicCollors,
} from '../../../../../../environments/version';

@Component({
  selector: 'app-consultar-funcionalidades',
  templateUrl: 'consultar-funcionalidades.component.html',
  styleUrls: ['./consultar-funcionalidades.component.scss'],
})
export class ConsultarFuncionalidadesComponent
  implements OnInit, AfterViewInit {
  funcionalidades: Array<any> = [];
  aplicacao: any;
  action: string;
  loading = false;
  total: number = 0;
  funcionalidadesAtivas: number = 0;
  navigationData: any;

  gColors = graphicCollors;

  constructor(
    private router: Router,
    private aplicacoesService: AplicacoesService,
  ) {
    this.navigationData = this.router.getCurrentNavigation();
  }

  ngOnInit() {
    window.scrollTo(0, 0);

    if (!this.navigationData.extras.state || (!this.navigationData.extras.state[1] || this.navigationData.extras.state[1] == null)) {      
      this.router.navigate([`${gerenciarSistemasRouterPath}/`]);
      return;
    } else {
      this.aplicacao = this.navigationData.extras.state[1];
      this.action = this.navigationData.extras.state[0];
      this.funcionalidades = this.aplicacao.funcionalidades;

      this.total = this.funcionalidades.length;
      this.funcionalidades.forEach(item => {
        if (item.ativo) {
          this.funcionalidadesAtivas += 1;
        }
      });
      this.aplicacoesService.funcionalidade$.subscribe(funcionalidade => {
        if (funcionalidade) {
        }
      });
    }
  }

  ngAfterViewInit() {
    if (this.aplicacoesService.newKeyAtAppFunctions) {
      this.aplicacoesService.newKeyAtAppFunctions = false;
      this.onSubmit();
    }
  }

  adicionarNovo() {
    let newCodigo: string;
    let newCode: string;

    this.funcionalidades.forEach(element => {
      console.log(element);
    });
  }

  edit(codigo) {
    if (codigo === 'novo') {
      let lastCode = 0;
      this.aplicacao['funcionalidades'].forEach(element => {
        lastCode = lastCode > element.codigo ? lastCode : element.codigo + 1;
      });
      codigo = lastCode + 'novo';

      const newFuncionalidade = {
        ativo: false,
        codigo: codigo,
        dataCriacao: new Date().toDateString(),
        descricao: '',
        modulo: '',
        perfis: [],
      };

      this.aplicacao.funcionalidades.push(newFuncionalidade);
    }

    const urlCode = codigo.toString().indexOf('novo')
      ? codigo.toString().replace('novo', '')
      : codigo;

    this.router.navigateByUrl(`${gerenciarSistemasRouterPath}/editar/${
          this.aplicacao['codigo'] !== null ? this.aplicacao['codigo'] : 'novo'
        }/funcionalidade/${urlCode}/editar`, {state : [codigo, this.action, this.aplicacao]});
  }

  onSubmit() {
    this.aplicacao['funcionalidades'].forEach(element => {
      element.codigo =
        element.codigo !== null &&
        element.codigo.toString().indexOf('novo') !== -1
          ? null
          : element.codigo;
    });

    if (
      this.aplicacao['funcionalidades'] !== null &&
      this.aplicacao['funcionalidades'].length === 0
    ) {
      this.aplicacao['funcionalidades'] = null;
    }

    if (
      this.aplicacao['consumos'] !== null &&
      this.aplicacao['consumos'].length === 0
    ) {
      this.aplicacao['consumos'] = null;
    }

    if (
      this.aplicacao['endpoints'] !== null &&
      this.aplicacao['endpoints'].length === 0
    ) {
      this.aplicacao['endpoints'] = null;
    }

    if (this.action === 'editar') {
      this.aplicacoesService
        .editaAplicacao(this.aplicacao['codigo'], this.aplicacao)
        .subscribe(
          res => this.handleResponse(),
          error => this.handleError(error),
        );
    } else {
      this.aplicacoesService
        .criaAplicacao(this.aplicacao)
        .subscribe(
          res => this.handleResponse(),
          error => this.handleError(error),
        );
    }
  }

  handleResponse() {
    // Swal.fire('Sucesso!', 'Aplicação Criada com sucesso', 'success');
    Swal.fire(
      getSwal(
        'success',
        'Sucesso!',
        'Aplicação Criada com sucesso',
        'ok',
        null,
      ),
    );
    this.router.navigate([`${gerenciarSistemasRouterPath}`]);
  }

  handleError(error) {
    this.loading = false;
    Swal.fire(
      getSwal(
        'error',
        'Ops!',
        'Ocorreu um erro e a aplicação não pôde ser criada.',
        'ok',
        null,
      ),
    );
  }

  desistir() {
    this.router.navigate([`${gerenciarSistemasRouterPath}/consultar`]);
  }
}
