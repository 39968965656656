
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-configurar-aplicacoes',
  templateUrl: 'configurar-aplicacoes.component.html'
})

export class ConfigurarAplicacoesComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}