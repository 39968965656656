import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable()
export class RegrasAcessoService {
  url: string = environment.BASE_URL;

  private dataRegraAcesso = new Subject<any>();
  dataRegraAcesso$ = this.dataRegraAcesso.asObservable();

  constructor(private http: HttpClient) {}

  getRegrasAcesso(codigoCliente: number, searchParams?: any): Observable<any> {
    const params = searchParams ? this.setParams(searchParams) : new HttpParams();

    return this.http.get(`${this.url}/clientes/${codigoCliente}/regrasAcesso`, { params: params })
      .pipe(
        map(data => {
          const body = data;
          this.dataRegraAcesso.next(body);
          return body;
        })
      );
  }



  getRegrasAcessoEditar(codigoCliente): Observable<any> {
    return this.http.get(`${this.url}/clientes/${codigoCliente}/regrasAcesso`, {})
      .map(data => {
        const body = data;
        return body;
      });
  }

  setParams(searchParams: any) {
    let params = new HttpParams();
    if (!searchParams) {
      return params;
    }
    if (searchParams.key && searchParams.value) {
      return params.set(searchParams.key, searchParams.value);
    }
    for (const key of Object.keys(searchParams)) {
      params = params.set(key, searchParams[key]);
    }
    return params;
  }

  obterDadosAmbiente(codigoCliente: number): Observable<any> {
    return this.http.get(`${this.url}/clientes/${codigoCliente}/regrasAcesso`)
      .map(data => {
        const body = data;
        return body;
      });
  }

  inserirRegrasAcesso(codigoCliente: number, cliente: any): Observable<any> {
    return this.http.post(`${this.url}/clientes/${codigoCliente}/regrasAcesso`, cliente)
      .map(data => {
        const body = data;
        return body;
      });
  }


  desabilitaRegrasAcesso(codigoCliente: number, codigoRegraAcesso:number): Observable<any> {
    console.log('cliente', codigoCliente)
    console.log('codigoRegraAcesso', codigoRegraAcesso)
    return this.http.get(`${this.url}/clientes/${codigoCliente}/regrasAcesso/${codigoRegraAcesso}/desabilitar`)
      .map(data => {
        const body = data;
        return body;
      });
  }

  obterPersonalidadeJuridica(): Observable<any> {
    return this.http.get(`${this.url}/personalidadesJuridicas/ativos`);
  }

  obterSetoresAtuacao(): Observable<any> {
    return this.http.get(`${this.url}/setoresAtuacao/ativos`);
  }

  obterFormaAcesso(): Observable<any> {
    return this.http.get(`${this.url}/formasAcesso/ativos`);
  }

}

