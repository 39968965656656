import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { PersonalidadeJuridicaService } from '../../../../../service/configuracao/personalidade-juridica.service';

@Component ({
    selector: 'app-consulta-personalidade-juridica',
    templateUrl: './consulta-personalidade.component.html',
    styleUrls: ['./consulta-personalidade.component.scss'],
})

export class ConsultaPersonalidadeJuridicaComponent implements OnInit {
    @ViewChild('childFilter', {static: false}) child;
    @Input() itemsPerView: number = 10;
    subscription: Subscription;
    personalidadeSelecionada: Array<any> = [];
    loading: boolean = false;
    searchParams: {}
    requests: any = [];
    // data: Array<any> = [];
    data: Object = {
      primeiraPagina: true,
      ultimaPagina: false,
      paginaAtual: 1,
      totalPaginas: 1,
      totalDeItems: 0,
      itemsPorPagina: 10
    };
    personalidadesJuridicas: Array<any> = []
    // itemsPerView: number = 10;
    descricao: '';
    filterCurrent: Object = {
        pagina: 1,
        total_pagina: 10
      }
    numeroPersonalidades: string = '10'
    visible = '';

    constructor(
      private router: Router, 
      private personalidadeJuridicaService: PersonalidadeJuridicaService) {
        this.data = [];
    }

    ngOnInit() {
      this.subscription =
        this.personalidadeJuridicaService.personalidadeJuridica$.subscribe(
          (res) => {
            this.data = res;
            // this.onPageChange(this.filterCurrent);
            this.filterCurrent = this.child.searchParams;
            this.personalidadesJuridicas = res["resultado"];
            this.data['totalDeItems'] = res.totalRegistros;
            this.data['totalPaginas'] = res['totalPaginas'];
            this.data['primeiraPagina'] = res.primeiraPagina;
            this.data['ultimaPagina'] = res.ultimaPagina;
            this.loading = false;
            console.log(res);
          }
        );
        this.getPersonalidades()
    }
    
    getPersonalidades() {
      this.loading = true;
      let parametros = {};
      parametros['pagina'] = this.data['paginaAtual'];
      parametros['total_pagina'] = this.itemsPerView;
      if (this.child.searchParams.descricao) {
        parametros['descricao'] = this.child.searchParams.descricao;
      } else {
        delete parametros['descricao'];
      }
      // this.descricao ? parametros['descricao'] = this.child.searchParams.descricao : delete parametros['descricao'];
      if (this.child.searchParams.ativo) {
        parametros['ativo'] = this.child.searchParams.ativo;
      } else {
        delete parametros['ativo'];
      }
      this.personalidadeJuridicaService.getSituacaoPersonalidadeJuridica(parametros).subscribe(res => {
        this.loading = false;
        if(res.tamanhoPagina) {
            this.data = res;
            this.data['totalDeItems'] = res.totalRegistros;
            // this.data['totalPaginas'] = this.itemsPerView;
            this.loading = false;
          }     
      }, error => {
        this.loading = false;
      });
    }

    next() {
      this.data['paginaAtual']++;
      this.onPageChange(this.filterCurrent);
      // this.getPersonalidades();
    }
  
    previous() {
      this.data['paginaAtual']--;
      this.onPageChange(this.filterCurrent);
      // this.getPersonalidades();
    }

    ultimaPagina() {
      this.data['paginaAtual'] = this.data['totalPaginas'];
      this.onPageChange(this.filterCurrent);
    }

    primeiraPagina() {
      this.data['paginaAtual'] = 1;
      this.onPageChange(this.filterCurrent);
    }

    edit(codigo: number) {
        this.router.navigate([`configuracao/personalidade-juridica/editar/${codigo}`])
    }

    redirecionar() {
        this.router.navigate(['configuracao/personalidade-juridica/incluir'])
    }

    ativarPersonalidades() {
        this.loading = true;
        let value = {};
        this.visible = '';
 
        this.personalidadeSelecionada.forEach(res => {
            value = {
                codigo: res.codigo,
                ativo: true,
                descricao: res.descricao
            }
            this.requests.push(this.personalidadeJuridicaService.editaPersonalidadeJuridica(value, res.codigo))
        })

        this.executarForkJoin();
    }


    inativarPersonalidades() {
        this.loading = true;
        let value = {};
        this.visible = '';

        this.personalidadeSelecionada.forEach(res => {
            value = {
                codigo: res.codigo,
                ativo: false,
                descricao: res.descricao
            }
            this.requests.push(this.personalidadeJuridicaService.editaPersonalidadeJuridica(value, res.codigo))
        })

        this.executarForkJoin();
    }

    executarForkJoin() {
        forkJoin(this.requests).subscribe(res => {
            this.personalidadeSelecionada = [];
            this.requests = [];
            this.loading = false;
            this.getPersonalidades()
            this.personalidadeJuridicaService.personalidadeJuridica$.subscribe(
              (res) => {
                this.data = res;
                this.personalidadesJuridicas = res["resultado"];
                this.data['totalDeItems'] = res.totalRegistros;
                this.data['totalPaginas'] = res['totalPaginas'];
                this.loading = false;
                console.log(res);
              }
            );

        }, error => {
            this.personalidadeSelecionada = [];
            this.requests = [];
            this.loading = false;  
        })
    }

    toggleVisibility(item: string) {
      if (item === this.visible) {
        this.visible = '';
      } else {
        this.visible = item;
      }
    }

    onPageChange(value) {
      // value['ativo'] = this.data['ativo'];
      value['pagina'] = this.data['paginaAtual'];
      value['total_pagina'] = this.itemsPerView;

      this.filterCurrent = value;
      this.loading = true;

      this.personalidadeJuridicaService.getSituacaoPersonalidadeJuridica(value).subscribe(
          res => {
              this.personalidadesJuridicas = res["resultado"];
              this.loading = false;
          },
          error => {
              this.loading = false;
              console.error('Erro ao obter as personalidades jurídicas:', error);
          }
      );
  }
  
  quantidadePersonalidades(numeroPersonalidades) {

    this.itemsPerView = +numeroPersonalidades;
    
    this.data['paginaAtual'] = 1;
    this.data['itemsPorPagina'] = this.itemsPerView;
    this.filterCurrent['total_pagina'] = this.itemsPerView;
  
    this.onPageChange(this.filterCurrent);

    this.data['paginaAtual'] = 1;
    this.data['itemsPorPagina'] = this.itemsPerView;
  
    switch (numeroPersonalidades) {
      case "10":
        this.data['itemsPorPagina'] = 10;
        console.log(this.data['itemsPorPagina']);
        break;
      case "20":
        this.data['itemsPorPagina'] = 20;
        console.log(this.data['itemsPorPagina']);
        break;
      case "50":
        this.data['itemsPorPagina'] = 50;
        console.log(this.data['itemsPorPagina']);
        break;
      case "100":
        this.data['itemsPorPagina'] = 100;
        console.log(this.data['itemsPorPagina']);
        break;
    }
  
    this.loading = true;
    return this.getPersonalidades()
  }
  

    setSelectedEntities(items) {
      this.personalidadeSelecionada = items;
    }
}