import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AplicacoesService } from '../../../../../service/aplicacoes/aplicacoes.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { getSwal } from '../../../../../utils/swalObject';
import * as moment from 'moment';
import { gerenciarSistemasRouterPath } from '../../../../../../environments/version';

@Component({
  selector: 'app-inserir-funcionalidades',
  templateUrl: 'inserir-funcionalidade.component.html',
  styleUrls: ['./inserir-funcionalidade.component.scss'],
})
export class InserirFuncionalidadesComponent implements OnInit {
  definirFuncionalidadeForm: FormGroup;
  dataCriacao: any = null;
  subscription: Subscription;
  routeSubscription: Subscription;
  title = 'Incluir Funcionalidade';
  perfis: Array<any> = [];
  perfil: Object = null;
  novaFormaAcesso: boolean = false;
  edicao: boolean = null;
  funcionalidade: Object = null;
  idFuncionalidade: any = null;
  aplicacao: any;
  actions: string;
  disabledPerfis: boolean = false;
  navigationData: any;

  constructor(
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private aplicacoesService: AplicacoesService,
    private fb: FormBuilder,
  ) {
    this.dataCriacao = new Date(Date.now());
    this.navigationData = this.router.getCurrentNavigation();
  }

  ngOnInit() {
    window.scrollTo(0, 0);

    if (
      this.navigationData.extras.state instanceof Object &&
      Object.keys(this.navigationData.extras.state).length === 0
    ) {
      this.navigationData.extras.state = null;
    }

    if (!this.navigationData.extras.state) {
      this.router.navigate([`${gerenciarSistemasRouterPath}/`]);
      return;
    }
    this.idFuncionalidade = this.navigationData.extras.state[0];
    this.actions = this.navigationData.extras.state[1];
    this.aplicacao = this.navigationData.extras.state[2];

    // if (this.idFuncionalidade.toString().indexOf('novo') !== -1) {
    //   // this.idFuncionalidade = this.idFuncionalidade.replace("novo", "");
    //   this.criarFormulario();
    // } else {
    // }
    let currentFuncionalidade: any;
    this.aplicacao['funcionalidades'].forEach(funcio => {
      if (funcio.codigo == this.idFuncionalidade) {
        currentFuncionalidade = funcio;
      }
    });
    this.editarFuncionalidade(currentFuncionalidade);
  }

  verifyPerfis() {
    console.log(this.perfis.length);
    // if(this.perfis.length >= 12){
    // 	this.disabledPerfis = true;
    // }else{
    // 	this.disabledPerfis = false;
    // }
  }

  ngOnDestroy() {
    // this.routeSubscription.unsubscribe();
    // this.subscription.unsubscribe();
  }

  criarFormulario() {
    this.definirFuncionalidadeForm = this.fb.group({
      descricao: ['', Validators.required],
      modulo: ['', Validators.required],
      dataCriacao: ['', Validators.required],
      ativo: [true, Validators.required],
    });

    this.definirFuncionalidadeForm.controls.dataCriacao.disable();
  }

  async editarFuncionalidade(funcionalidade) {
    let criaFormulario = await this.criarFormulario();
    this.carregarDadosFormulario(funcionalidade);
  }

  carregarDadosFormulario(funcionalidade) {
    this.dataCriacao = moment(funcionalidade.dataCriacao).format('DD/MM/YYYY');

    for (let key in funcionalidade) {
      if (
        typeof key !== 'undefined' &&
        this.definirFuncionalidadeForm.controls[key]
      ) {
        this.definirFuncionalidadeForm.controls[key].patchValue(
          funcionalidade[key],
        );
      }
    }
    this.perfis = funcionalidade.perfis;
    this.verifyPerfis();
  }

  isFormValid() {
    if (this.definirFuncionalidadeForm.valid && this.perfis.length >= 1) {
      return false;
    } else if (
      !this.definirFuncionalidadeForm.valid &&
      this.perfis.length < 1
    ) {
      return true;
    } else {
      return true;
    }
  }

  exibirFormaAcesso(val) {
    console.log('exibirFormaAcesso > ', val, ' << ');
    this.novaFormaAcesso = val;
  }

  relacionarFormaAcesso(obj) {
    if (this.definirFuncionalidadeForm.controls.ativo) {
      obj['dataFim'] = new Date(Date.now());
    }
    if (obj.codigo) {
      this.perfis[this.perfis.findIndex(el => el.codigo === obj.codigo)] = obj;
    } else {
      obj['dataInicio'] = new Date(Date.now());
      let lastCodeFormaAcesso = 0;

      this.perfis.forEach(element => {
        console.log(element, ' << ');
        lastCodeFormaAcesso =
          element.codigo > lastCodeFormaAcesso
            ? element.codigo
            : lastCodeFormaAcesso;
      });
      obj.codigo = lastCodeFormaAcesso + 'novo';
      this.perfis.push(obj);
    }

    this.updateAplicacao();
    // this.perfis = [...this.perfis];
    console.log(
      ' >> relacionarFormaAcesso ::',
      this.perfis,
      this.aplicacao,
      this.idFuncionalidade,
    );
  }

  removeDuplicates(myArr, prop) {
    return myArr.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }

  editarFormaAcesso(obj) {
    console.log('editarFormaAcesso', obj);
    if (obj['dataFim']) {
      if (this.definirFuncionalidadeForm.controls.ativo) {
        obj['dataFim'] = new Date(Date.now());
      } else {
        delete obj['dataFim'];
      }
    }
    this.exibirFormaAcesso(true);
    this.perfil = obj;
  }

  inserirNovaFormaAcesso() {
    this.perfil = null;
    this.exibirFormaAcesso(true);
  }

  updateAplicacao() {
    console.log('updateAplicacao');
    const form = this.definirFuncionalidadeForm.getRawValue();
    if (this.funcionalidade == null) {
      // form['dataCriacao'] = this.dataCriacao; // moment(this.dataCriacao, 'DD-MM-YYYY').format();
      form['dataCriacao'] = moment(this.dataCriacao, 'DD-MM-YYYY').format();
      this.funcionalidade = form;
    } else {
      if (this.funcionalidade['codigo']) {
        for (let key in form) {
          if (form[key] !== this.funcionalidade[key]) {
            this.funcionalidade[key] = form[key];
          }
        }
      }
    }
    this.funcionalidade['perfis'] = this.perfis;
    let funcionalidadeAdded = false;
    this.aplicacao['funcionalidades'].map(funcionalidade => {
      if (funcionalidade.codigo == this.idFuncionalidade) {
        funcionalidade = Object.assign(funcionalidade, this.funcionalidade);
        funcionalidadeAdded = true;
      }

      funcionalidade.perfis.forEach(element => {
        console.log(element.codigo);
        element.codigo.toString().indexOf('novo');
        if (element.codigo.toString().indexOf('novo') != -1) {
          element.codigo = '';
        }
      });
    });

    if (!funcionalidadeAdded) {
      console.log('adicionando funcionalidade :: ');
      this.aplicacao['funcionalidades'].push(this.funcionalidade);
    }
  }

  onSubmit() {
    console.log('submit');
    this.updateAplicacao();

    // const form = this.definirFuncionalidadeForm.getRawValue();
    // if (this.funcionalidade == null) {
    //   form['dataCriacao'] = moment(this.dataCriacao, 'DD-MM-YYYY').format();
    //   this.funcionalidade = form;
    // } else {
    //   if (this.funcionalidade['codigo']) {
    //     for (let key in form) {
    //       if (form[key] !== this.funcionalidade[key]) {
    //         this.funcionalidade[key] = form[key];
    //       }
    //     }
    //   }
    // }
    // this.funcionalidade['perfis'] = this.perfis;

    // if (this.idFuncionalidade.toString().indexOf('novo') !== -1) {
    //   form.codigo = Number(this.idFuncionalidade.replace('novo', ''));
    //   form.isNew = true;
    //   this.aplicacao['funcionalidades'].push(this.funcionalidade);
    // } else {
    //   this.aplicacao['funcionalidades'].map(funcionalidade => {
    //     if (funcionalidade.codigo == this.idFuncionalidade) {
    //       funcionalidade = Object.assign(funcionalidade, this.funcionalidade);
    //     }
    //   });
    // }

    // Swal.fire(
    //   'Sucesso!',
    //   'Funcionalidade criada e associada a aplicação com sucesso',
    //   'success',
    // );
    this.aplicacoesService.newKeyAtAppFunctions = true;
    this.definirFuncionalidadeForm.reset();
    this.router.navigateByUrl(`${gerenciarSistemasRouterPath}/editar/${this.aplicacao['codigo'] ||
          'novo'}/funcionalidade/consultar`, {state: [this.actions, this.aplicacao]});
  }

  desistir() {
    let perfilResult = [];
    let funcionalidadeResult = [];
    this.aplicacao['funcionalidades'].forEach(funcionalidad => {
      if (funcionalidad.codigo.toString().indexOf('novo') !== -1) {
      } else {
        if (funcionalidad.codigo === this.idFuncionalidade) {
          funcionalidad['perfis'].forEach(perfil => {
            console.log(perfil);
            if (perfil.codigo.toString().indexOf('novo') === -1) {
              perfilResult.push(perfil);
            }
          });
          funcionalidad['perfis'] = perfilResult;
        }
        funcionalidadeResult.push(funcionalidad);
      }
    });

    this.aplicacao['funcionalidades'] = funcionalidadeResult;

    console.log('desistir incluir funcionalidade >> :: ', this.aplicacao);

    this.router.navigateByUrl(`${gerenciarSistemasRouterPath}/editar/${this.aplicacao['codigo'] ||
          'novo'}/funcionalidade/consultar`, {state: [this.actions, this.aplicacao]});
  }
}
