import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-opcoes-usuario',
  templateUrl: 'opcoes-usuario.component.html',
  styleUrls: ['./opcoes-usuario.component.scss']
})

export class OpcoesUsuarioComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}