import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpParams,
  HttpErrorResponse,
  HttpHeaders,
  HttpParameterCodec,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

@Injectable()
export class LoginAstraService {
  url: string = environment.BASE_URL;

  constructor(private http: HttpClient) {}

  loginViaJwt(tokenJwt): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        tokenJwt: tokenJwt,
      },
    });
    return this.http
      .get(`${this.url}/autenticarJwtSefaz`, { params: params })
      .pipe( map(data => {
        return data;
      }));
  }

  clientes(): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        sistema: '1',
      },
    });
    return this.http
      .get(`${this.url}/listarClientes`, { params: params })
      .pipe( map(data => {
        return data;
      }));
  }

  procuracoes(): Observable<any> {
    return this.http
      .get(`${this.url}/obterProcuracoesAtivasDoProcurador`)
      .pipe( map(data => {
        return data;
      }));
  }

  obterSessao(codigo) {
    return new Promise((resolve, reject) => {
      this.getSession(codigo).subscribe(res => {
        console.log(' >> ', res);
        resolve(res);
      });
    });
  }

  getSession(codigo): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        codigoUsuarioCliente: codigo,
        sistema: '1',
      },
    });
    return this.http
      .get(`${this.url}/obterSessaoUsuario`, { params: params })
      .pipe( map(data => {
        console.log(' obter seção >> ', data);
        return data;
      }));
  }

  atualizarSessao(codigoProcuracao): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        codigoProcuracao: codigoProcuracao,
      },
    });
    return this.http
      .get(`${this.url}/atualizarSessaoUsuario`, { params: params })
      .pipe( map(data => {
        return data;
      }));
  }
}
