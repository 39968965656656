import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";

import { AmbienteService } from "src/app/service/ambiente/ambiente.service";
import { OrganogramaService } from "src/app/service/organograma/organograma.service";
import * as moment from 'moment'
import { DatePipe } from "@angular/common";
import { Subscription } from "rxjs";

@Component({
  providers: [DatePipe],
  selector: "app-organograma-perfil",
  templateUrl: "./organograma-perfil.component.html",
  styleUrls: ["./organograma-perfil.component.scss"],
})
export class OrganogramaPerfilComponent implements OnInit {
  @ViewChild("childFilter", { static: false }) child;
  @Input() itemsPerView: number = 10;
  editarOrganogramaForm: FormGroup;
  subscription: Subscription;
  registroOrganograma: any = [];
  dadosDeOrganograma: any = [];
  idPerfilAcesso: number = null;
  teste: any;
  dataCriacaoFormatada: any;
  dataFinalizacaoFormatada: any;
  index: number = 0;
  totalPaginas: number = 10;
  loading: boolean = false;
  organogramas: Array<any> = []
  numeroOrganogramas: string = '10'

  data: Object = {
    tamanhoPagina: 10,
    paginaAtual: 1,
    total_pagina:  10,
    pagina: 1
  }

  filterCurrent: Object = {
    pagina: 1,
    total_pagina: 10
  }

  constructor(
    private ambienteService: AmbienteService,
    private router: Router,
    private organogramaService: OrganogramaService,
    private datePipe: DatePipe
  ) {
    this.data = []
  }

  ngOnInit() {
    this.subscription =
        this.organogramaService.organograma$.subscribe(
          (res) => {
            this.data = res;
            // this.onPageChange(this.filterCurrent);
            this.filterCurrent = this.child.searchParams;
            this.organogramas = res["resultado"];
            this.data['totalDeItems'] = res.totalRegistros;
            this.data['totalPaginas'] = res['totalPaginas'];
            this.data['primeiraPagina'] = res.primeiraPagina;
            this.data['ultimaPagina'] = res.ultimaPagina;
            this.loading = false;
            console.log(res);
          }
        );
      this.getOrganogramas()
  }

  getOrganogramas() {
    this.loading = true;
    let parametros = {};
    parametros['pagina'] = this.data['paginaAtual'];
    parametros['total_pagina'] = this.itemsPerView;
    if (this.child.searchParams.descricao) {
      parametros['descricao'] = this.child.searchParams.descricao;
    } else {
      delete parametros['descricao'];
    }
    if (this.child.searchParams.ativo) {
      parametros['ativo'] = this.child.searchParams.ativo;
    } else {
      delete parametros['ativo'];
    }
    this.organogramaService.getOrganogramas(parametros).subscribe(res => {
      this.loading = false;
          this.data = res;
          this.organogramas = res["resultado"];
          this.loading = false;
    }, error => {
      this.loading = false;
    });
  }

  rotaOrganograma() {
    this.router.navigate([
      "/configuracao/ambiente/incluir-organograma/" + this.idPerfilAcesso,
    ]);
  }

  rotaEditar(codigo: number) {
    sessionStorage.setItem('statusOrganograma', 'ativo');
    this.router.navigate([
      `/configuracao/ambiente/editar-organograma/${codigo}`
    ]);
  }

  rotaVisualizar(codigo: number) {
    sessionStorage.setItem('statusOrganograma', 'inativo');
    this.router.navigate([
      `/configuracao/ambiente/visualizar-organograma/${codigo}`
    ]);
  }

  next() {
    this.data['paginaAtual']++;
    this.onPageChange(this.filterCurrent);
  }

  previous() {
    this.data['paginaAtual']--;
    this.onPageChange(this.filterCurrent);
  }

  ultimaPagina() {
    this.data['paginaAtual'] = this.data['totalPaginas'];
    this.onPageChange(this.filterCurrent);
  }

  primeiraPagina() {
    this.data['paginaAtual'] = 1;
    this.onPageChange(this.filterCurrent);
  }

  onPageChange(value) {
    // value['ativo'] = this.data['ativo'];
    value['pagina'] = this.data['paginaAtual'];
    value['total_pagina'] = this.itemsPerView;

    this.filterCurrent = value;
    this.loading = true;

    this.organogramaService.getOrganogramas(value).subscribe(
        res => {
            this.organogramas = res["resultado"];
            this.loading = false;
        },
        error => {
            this.loading = false;
            console.error('Erro ao obter os organogramas:', error);
        }
    );
}

  quantidadeOrganogramas(numeroOrganogramas) {

    this.itemsPerView = +numeroOrganogramas;

    this.data['paginaAtual'] = 1;
    this.data['itemsPorPagina'] = this.itemsPerView;
    this.filterCurrent['total_pagina'] = this.itemsPerView;

    this.onPageChange(this.filterCurrent);

    this.data['paginaAtual'] = 1;
    this.data['itemsPorPagina'] = this.itemsPerView;

    switch (numeroOrganogramas) {
      case "10":
        this.data['itemsPorPagina'] = 10;
        console.log(this.data['itemsPorPagina']);
        break;
      case "20":
        this.data['itemsPorPagina'] = 20;
        console.log(this.data['itemsPorPagina']);
        break;
      case "50":
        this.data['itemsPorPagina'] = 50;
        console.log(this.data['itemsPorPagina']);
        break;
      case "100":
        this.data['itemsPorPagina'] = 100;
        console.log(this.data['itemsPorPagina']);
        break;
    }

    this.loading = true;
    return this.getOrganogramas()
  }
}
