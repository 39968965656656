import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  ViewEncapsulation,
  AfterViewInit,
} from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { PerfilDataService } from '../../../service/perfil/perfil.service';
import { AmbienteService } from '../../../service/ambiente/ambiente.service';
import { SessionService } from '../../../service/session/session.service';
import { UserService } from '../../../service/user-service/user.service';
import { TranslateService } from '@ngx-translate/core';
import { HeaderService } from 'src/app/service/header/header.service';
import * as FileSaver from 'file-saver';
import { ManualService } from 'src/app/service/manual/manual.service';
import Swal from 'sweetalert2';
import { getSwal } from 'src/app/utils/swalObject';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[app-header]',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // tslint:disable-next-line:use-host-property-decorator
  host: { class: 'headerBox' },
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)' })),
      ]),
      transition(':leave', [
        animate('400ms ease-in', style({ transform: 'translateX(100%)' })),
      ]),
    ]),
  ],
})
export class HeaderComponent implements OnInit{
  urlLogo: string;

  @Input() hasSession: boolean;
  @Input() subItem: number;
  @Output() onSidebarToggle = new EventEmitter<boolean>();
  subscription: Subscription;
  user = false;
  userImg: SafeUrl;
  clientImg: SafeUrl;
  userData: Object = null;
  hasImg: boolean = false;
  hasClientImg: boolean = null;
  exibirAlertas: boolean = false;
  imgLogo: SafeUrl;
  hasLogo: boolean = false;
  dadosCliente: any = {}
  codigoCliente: any = null;
  nomeManualAuditor: any = null;

  labels: any;

  constructor(
    private _sanitizer: DomSanitizer,
    private router: Router,
    private domSanitizer: DomSanitizer,
    private userService: UserService,
    private translate: TranslateService,
    private sessionService: SessionService,
    private ambienteService: AmbienteService,
    private headerService: HeaderService,
    private manualService: ManualService
  ) {
    this.hasImg = false;
    this.hasClientImg = false;
    this.hasLogo = false;
    this.setLabels();
  }

  ngOnInit() {
    
    this.verifySession(this.sessionService.getSession());
    this.subscription = this.userService.getInfo().subscribe(data => {
      this.verifySession(data);
    });

  }

  obterImagem(){
    this.ambienteService.obterImagem().subscribe(
      res => {
        this.clientImg = 'data:image/jpeg;base64,' + res.resultado;
      }, err => {
        console.log(err.error.text);
      });
  }

  obterLogo() {
    
    this.dadosCliente = JSON.parse(localStorage.getItem('session'));
    this.codigoCliente = this.dadosCliente.cliente.codigo;

    this.ambienteService.obterDadosAmbiente(this.codigoCliente).subscribe(
      res => {
        this.imgLogo = 'data:image/jpeg;base64,' + res.resultado.configuracao.logoDte;
      }, err => {
        console.log(err.error.text);
      });
  }

  verifySession(data) {
    this.userData = data;
    if (data.usuario) {
      if (data.usuario.tipoIdentificacao == 1) {
        this.labels.cpfCnpf = 'CPF';
        this.labels.nomeRazaoSocial = 'Nome';
        this.labels.inscricao = '';
      }
      else {
        this.labels.cpfCnpf = 'CNPJ';
        this.labels.nomeRazaoSocial = 'Razão Social';
        this.labels.inscricao = this.translate.instant('GLOBAIS.INSCRICAO');
      }
    }

    if (data.usuario && data.usuario.imagem) {
      this.hasImg = true;
      this.userImg = this.domSanitizer.bypassSecurityTrustUrl(
        data['usuario'].imagem.toString(),
      );
    } else {
      this.hasImg = false;
    }

    if(data.loggedIn === true){
      this.hasClientImg = true;
      this.hasLogo = true;
      this.obterImagem();
      this.headerService.changeLogo.subscribe(res => {
        if (res){
          this.obterLogo();
        } 
      });
    } else {
      this.hasClientImg = false;
      this.hasLogo = false;
    }
  }

  redirect() {
    if (this.userData['loggedIn']) {
      this.router.navigate(['home']);
      this.obterImagem();
    } else {
      this.userService.sendInfo({ loggedIn: false });
      this.sessionService.clearSession();
      this.router.navigate(['entrar']);
    }   
  }

  clickUsuario() {
    this.user = !this.user;
  }

  onSignOut(val: boolean) {
    this.user = val;
  }

  baixarManual(){  
    this.manualService.baixarManualAuditor(this.codigoCliente)
    .subscribe((res: any) => {
        const blob = new Blob([res], { type: `application/pdf}` });
        FileSaver.saveAs(blob, `manualAuditor.pdf`);
      }, error => {
        Swal.fire(
          getSwal(
            'error',
            'Ops!',
            'O Manual não foi definido!',
            'ok',
            null
          )
        )
      }
    )
  }

  setLabels() {
    this.labels = {
      logo: this.translate.instant('GLOBAIS.LOGO'),
      titulo: this.translate.instant('GLOBAIS.TITULO'),
    };
  }
}
