import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, MinLengthValidator } from '@angular/forms';
//import { CepService } from '../../../../../service/cep/cep.service';
import { GerenciarUsuariosService } from '../../../../../service/configuracao/gerenciar-usuarios.service';
import Swal from 'sweetalert2';
import { getSwal } from '../../../../../utils/swalObject';
import { ConsultaUsuarioService } from 'src/app/service/consulta-usuario/consultar.user.service';
import { WizardService } from 'src/app/service/wizard/wizard.service';
import { AmbienteService } from 'src/app/service/ambiente/ambiente.service';
import { Router } from '@angular/router';
import { CadastroService } from 'src/app/service/cadastro/cadastro.service';

@Component({
  selector: 'app-formulario-procurador',
  templateUrl: 'formulario-procurador.component.html',
  styleUrls: ['formulario-procurador.component.scss']
})

export class FormularioProcurador implements OnInit {

  procuradorForm: FormGroup;
  pessoaFisica: boolean = true;
  loading: boolean = false;
  title:string = 'Criar Procurador';
  cadastroIntegrado:boolean= false;
  exibirPopUp: boolean = false;
  data: Array<any> = [];

  cpfCnpjProcurador:any;
  //cepProcurador:any;
  telefoneCelular:any;
  nomeRepresentanteLegal:''
  emailRepresentanteLegal:''
  celularRepresentanteLegal:''
  setorAtuacao: any;
  personalidadeJuridica: any;
  dadosCliente: any;
  codigoCliente: any;
  exigeCertificadoCnpj: any;
  servicoSms: number

  //situacaoUsuario: any;


  public semMask = [/\d/, /\d/, /\d/, /\d/, /\d/];
  public menasMask = [
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ];
  public cpfMask = [
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/
  ];
  public cnpjMask = [
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '/',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/
  ];

  constructor(
    private fb: FormBuilder,
    private gerenciarUsuariosService: GerenciarUsuariosService,
    private consultaUsuario: ConsultaUsuarioService,
    private wizardService: WizardService,
    private router: Router,
    private ambienteService: AmbienteService,
    private cadastroService: CadastroService
  ) { }

  ngOnInit() {
    this.verificarCertificadoCnpj();
    this.criarFormulario();
    this.obterConfiguracoesCliente();
    this.setorAtuacaoAtivos();
    this.personalidadesJuridicasAtivos();
  }

  criarFormulario() {
    this.procuradorForm = this.fb.group({
      pessoaFisica: [true, Validators.required],
      cpfCnpjProcurador: ['', Validators.compose([Validators.required, Validators.minLength(11), Validators.maxLength(14)])],
      inscricao: [''],
      nomeRazaoSocialProcurador: ['', Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(160)])],
      telefoneCelular: ['', Validators.compose([Validators.required, Validators.minLength(11)])],
      email: ['', Validators.compose([Validators.required, Validators.maxLength(60)])],
      comboPersonalidadeJuridicaProcurador: ["0", Validators.compose([Validators.required])],
      comboSetorAtuacaoProcurador: ["0", Validators.compose([Validators.required])],
      personalidadeJuridicaProcurador:{codigo:''},
      setorAtuacaoProcurador:{codigo:''},
      cpfRepresentanteLegal: null,
      nomeRepresentanteLegal: null,
      emailRepresentanteLegal: null,
      celularRepresentanteLegal: null,
      situacaoUsuario: [0, Validators.required],
      comboPersonalidadeJuridicaRepresentanteLegal: [""],
      comboSetorAtuacaoRepresentanteLegal: [""],
      personalidadeJuridicaRepresentante:{codigo:''},
      setorAtuacaoRepresentante:{codigo:''}
    });
  }

  ajuda() {
    Swal.fire({
      html:'Se estiver em Sim, o usuário, assim como os comunicados trocados por este, não irão ser contabilizados em relatórios e consultas.',
      confirmButtonText:'Fechar'
    })
  }

  handleErrors(error) {
    this.loading = false;
  }

  adicionarUsuario() {
    this.loading = true;
    let obj = this.procuradorForm.getRawValue();

    obj['dddTelefoneCelular'] = obj['telefoneCelular'].substring(0, 2);
    obj['telefoneCelular'] = obj['telefoneCelular'].substr(2, 9);

    obj.setorAtuacaoProcurador.codigo = obj['comboSetorAtuacaoProcurador']
    obj.personalidadeJuridicaProcurador.codigo = obj['comboPersonalidadeJuridicaProcurador']
    
    obj.personalidadeJuridicaRepresentante.codigo = obj['comboPersonalidadeJuridicaRepresentanteLegal'];
    obj.setorAtuacaoRepresentante.codigo = obj['comboSetorAtuacaoRepresentanteLegal'];

    if(!this.pessoaFisica) {
      obj['cpfRepresentanteLegal'] = obj['cpfRepresentanteLegal'].replace(/\D+/g, '');
    }

    if(this.exigeCertificadoCnpj == 0) {
      obj.setorAtuacaoProcurador.codigo = "0";
      obj.personalidadeJuridicaProcurador.codigo = "0";
    }

    if (!this.pessoaFisica) { 
      obj['dddCelularRepresentanteLegal'] = obj['celularRepresentanteLegal'].substring(0, 2);
      obj['celularRepresentanteLegal'] = obj['celularRepresentanteLegal'].substr(2, 9);

      if(this.exigeCertificadoCnpj == 0) {
        obj.personalidadeJuridicaRepresentante.codigo = "0";
        obj.setorAtuacaoRepresentante.codigo = "0";
      }
  
    }

    this.gerenciarUsuariosService.adicionarProcurador(obj).subscribe(res => {
      this.loading = false;
      // Swal('Sucesso!', 'Usuário criado com êxito', 'success')
      Swal.fire(
        getSwal(
          'success',
          'Sucesso!',
          "Usuário criado com êxito",
          'ok',
          null,
        )
      ).then(() => {
        this.router.navigate(['gestao-usuario/consultar']);
      })
    }, err => {
      this.loading = false;
      // Swal('Ops!', err.error.excecao.recomendacao, 'error')
      Swal.fire(
        getSwal(
          'error',
          'Ops!',
          err.error.excecao.recomendacao,
          'ok',
          null,
        ),
      );
    })
  }

  consultarUsuario(id: string) {
    if (this.procuradorForm.controls.cpfCnpjProcurador.valid) {
      this.consultaUsuario.consultarUsuario(this.procuradorForm.controls.cpfCnpjProcurador.value).subscribe(
        res => {
          this.loading = false;
          let data = res.resultado;
          this.wizardService.userData['id'] = data.cpfCnpj;
          this.wizardService.userData['codigo'] = data.codigo;

          if (data.situacao === 0) {
            this.procuradorForm.patchValue({
              cpfCnpjProcurador: data.cpfCnpj,
              nomeRazaoSocialProcurador: data.nomeRazaoSocial,
              telefoneCelular:
              data.detalhe &&
              data.detalhe.dddTelefoneCelular &&
                data.detalhe.telefoneCelular
                  ? data.detalhe.dddTelefoneCelular.replace(/\D+/g, '').concat(
                    data.detalhe.telefoneCelular,
                  )
                  : '',
              email: data.email,
            });

          } else {
            Swal.fire(
              getSwal(
                'success',
                'Usuário já cadastrado',
                'Você já está cadastrado em nosso sistema, defina sua senha para acessá-lo',
                'ok',
                null,
              )
            ).then(() => this.procuradorForm.get('cpfCnpjProcurador').setValue(null));
          }
        },
        error => {
          if (error.error.excecao.codigo && error.error.excecao.codigo == 'E000') {
            Swal.fire(
              getSwal(
                'error',
                '',
                error.error.excecao.recomendacao,
                'ok',
                null
              )
            ).then(() => this.procuradorForm.get('cpfCnpjProcurador').setErrors({invalido: true}));
          } else {
            this.procuradorForm.get('cpfCnpjProcurador').setErrors(null);
          }
          this.loading = false;
        },
      );
    }
  }

  consultarRepresentanteLegal(cpfCnpj) {
    this.loading = true;
      this.consultaUsuario.consultarUsuario(cpfCnpj).subscribe(res => {
        this.loading = false;
        const data = res['resultado']; 

        this.procuradorForm.controls.nomeRepresentanteLegal.setValue(data.nomeRazaoSocial);
        this.procuradorForm.controls.nomeRepresentanteLegal.updateValueAndValidity();
        this.procuradorForm.controls.nomeRepresentanteLegal.disable();
  
        this.procuradorForm.controls.emailRepresentanteLegal.setValue(data.email);
        this.procuradorForm.controls.emailRepresentanteLegal.updateValueAndValidity();
        this.procuradorForm.controls.emailRepresentanteLegal.disable();
  
        this.procuradorForm.controls.celularRepresentanteLegal.setValue(data.ddd + data.celular);
        this.procuradorForm.controls.celularRepresentanteLegal.updateValueAndValidity();
        this.procuradorForm.controls.celularRepresentanteLegal.disable();
        
        this.procuradorForm.controls.comboPersonalidadeJuridicaRepresentanteLegal.setValue(data.detalhe.personalidadeJuridica.codigo);
        this.procuradorForm.controls.comboPersonalidadeJuridicaRepresentanteLegal.updateValueAndValidity();
        this.procuradorForm.controls.comboPersonalidadeJuridicaRepresentanteLegal.disable();
        this.procuradorForm.controls.comboSetorAtuacaoRepresentanteLegal.setValue(data.detalhe.setorAtuacao.codigo);
        this.procuradorForm.controls.comboSetorAtuacaoRepresentanteLegal.updateValueAndValidity();
        this.procuradorForm.controls.comboSetorAtuacaoRepresentanteLegal.disable();
        
      }, error => {
        this.loading = false;
        if(error.error.excecao.recomendacao !== '') {
          if(this.procuradorForm.controls.cpfRepresentanteLegal.valid) {
            Swal.fire(
              getSwal(
                'error',
                '',
                error.error.excecao.recomendacao,
                'ok',
                null
              )
            );
            this.procuradorForm.controls.cpfRepresentanteLegal.setErrors({invalido: true})
          }
          this.habilitarRepLegal();
        } else {
          this.procuradorForm.controls.cpfRepresentanteLegal.setErrors(null)
          this.habilitarRepLegal();
        }
      })
  }

  habilitarRepLegal() {
    this.procuradorForm.controls.nomeRepresentanteLegal.enable();
    this.procuradorForm.controls.emailRepresentanteLegal.enable();
    this.procuradorForm.controls.celularRepresentanteLegal.enable();
    this.procuradorForm.controls.comboPersonalidadeJuridicaRepresentanteLegal.enable();
    this.procuradorForm.controls.comboSetorAtuacaoRepresentanteLegal.enable();
  }

  contribuiteSelecionado(event){
    this.exibirPopUp = false;
    event = event[0];
    console.log('event retornado: ', event);
    this.procuradorForm.patchValue({
    nomeRazaoSocialProcurador: event.nomeRazaoSocialProcurador,
    email: event.email,
    telefoneCelular: event.dddTelefoneCelular + event.telefoneCelular,


    });

  }

  contribuiteInterno(event){
    this.exibirPopUp = false;
    event = event[0];
    // console.log('event retornado: ', event);
    this.procuradorForm.patchValue({
    razaoSocialContribuinte: event.nomeRazaoSocial,
    email: event.email,
    telefoneCelular: event.dddTelefoneCelular + event.telefoneCelular,

    });

    if(event.detalhe){

    this.procuradorForm.patchValue({
      email: event.detalhe.email,
      telefoneCelular: event.detalhe.dddTelefoneCelular + event.detalhe.telefoneCelular,
      });

    }
  }

  isTelefone(): boolean{
    return this.telefoneCelular == null ? true : this.telefoneCelular.length < 11 ? true : false;
  }

  getTelefone(): string{
      return this.isTelefone() ? '(00) 0000-00009' : '(00) 00000-0000';
  }

  tornarCamposObrigatorios() {
    for (let key in this.procuradorForm.controls) {
      if (
        key !== "pessoaFisica" &&
        key !== "situacaoUsuario" &&
        key !== "personalidadeJuridicaProcurador" &&
        key !== "setorAtuacaoProcurador" &&
        key !== "personalidadeJuridicaRepresentante" &&
        key !== "setorAtuacaoRepresentante"
      ) {
        this.procuradorForm.controls[key].reset();
      }
    }

    if (this.pessoaFisica == false) {
      //REPRESENTANTE LEGAL
      this.procuradorForm.controls.cpfRepresentanteLegal.setValidators(
        Validators.compose([
          Validators.required,
          Validators.minLength(14),
          Validators.maxLength(18),
        ])
      );
      this.procuradorForm.controls.cpfRepresentanteLegal.updateValueAndValidity();

      this.procuradorForm.controls.nomeRepresentanteLegal.setValidators(
        Validators.compose([
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(160)
        ])
      );
      this.procuradorForm.controls.nomeRepresentanteLegal.updateValueAndValidity();

      this.procuradorForm.controls.emailRepresentanteLegal.setValidators(Validators.required);
      this.procuradorForm.controls.emailRepresentanteLegal.updateValueAndValidity();

      this.procuradorForm.controls.celularRepresentanteLegal.setValidators(Validators.required);
      this.procuradorForm.controls.celularRepresentanteLegal.updateValueAndValidity();

      //PROCURADOR
      this.procuradorForm.controls.cpfCnpjProcurador.setValidators(
        Validators.compose([Validators.required, Validators.minLength(14)])
      );
      this.procuradorForm.controls.comboPersonalidadeJuridicaProcurador.setValue("");
      this.procuradorForm.controls.comboPersonalidadeJuridicaProcurador.updateValueAndValidity();

      this.procuradorForm.controls.comboSetorAtuacaoProcurador.setValue("");
      this.procuradorForm.controls.comboSetorAtuacaoProcurador.updateValueAndValidity();

    } else {

      for (let key in this.procuradorForm.controls) {
        if(
          key == "cpfRepresentanteLegal" ||
          key == "nomeRepresentanteLegal" ||
          key == "emailRepresentanteLegal" ||
          key == "celularRepresentanteLegal" ||
          key == "comboPersonalidadeJuridicaRepresentanteLegal" || 
          key == "comboSetorAtuacaoRepresentanteLegal"
        ){
          this.procuradorForm.controls[key].setValidators([]);
          this.procuradorForm.controls[key].updateValueAndValidity();
        }
      }
      
      this.procuradorForm.controls.cpfCnpjProcurador.setValidators(
        Validators.compose([
          Validators.required,
          Validators.minLength(11),
          Validators.maxLength(11),
        ])
      );
      this.procuradorForm.controls.cpfCnpjProcurador.updateValueAndValidity();
      this.procuradorForm.controls.comboPersonalidadeJuridicaProcurador.setValue(0);
      this.procuradorForm.controls.comboPersonalidadeJuridicaProcurador.updateValueAndValidity();

      this.procuradorForm.controls.comboSetorAtuacaoProcurador.setValue(0);
      this.procuradorForm.controls.comboSetorAtuacaoProcurador.updateValueAndValidity();
    }
  }

  buscaRepresentanteLegal(cpfCnpj) {
    console.log('teste', cpfCnpj);
    cpfCnpj = cpfCnpj.replace(/\D+/g,'')
    this.gerenciarUsuariosService.getRepresetanteLegal(cpfCnpj).subscribe(async res => {
      console.log('cpf: ', res);
      this.nomeRepresentanteLegal = res.nomeRazaoSocial;
      this.emailRepresentanteLegal = res.email;
      this.celularRepresentanteLegal = res.ddd + res.celular;
    })
  }

  mask(): any {
    if(this.pessoaFisica) {
      return "000.000.000-00";
    } else {
      return "00.000.000/0000-00";
    }
  }

  maskRepresentante(): any {
    return {
      mask: value => {
        value = value.replace(/[^\d]+/g, '');
        if (value.length <= 5) {
          return this.semMask;
        } else if (value.length <= 9) {
          return this.menasMask;
        } else if (value.length >= 10 && value.length <= 11) {
          return this.cpfMask;
        } else if (value.length >= 13 && value.length <= 16) {
          return this.cnpjMask;
        } else if (value.length <= 11) {
          return this.cpfMask;
        }
      },
      guide: false
    };
  }

  verificarCertificadoCnpj() {
    this.dadosCliente = JSON.parse(localStorage.getItem('session'));
    this.codigoCliente = this.dadosCliente.cliente.codigo;

    this.ambienteService.obterDadosAmbiente(this.codigoCliente).subscribe(
      res => {
        this.exigeCertificadoCnpj = res.resultado.configuracao.exigeCertificadoCnpj;

        if(this.exigeCertificadoCnpj == 0) {
          this.procuradorForm.removeControl('comboPersonalidadeJuridicaProcurador');
          this.procuradorForm.removeControl('comboSetorAtuacaoProcurador');
        }
      }
    )
  }

  setorAtuacaoAtivos() {
    this.consultaUsuario.getSetoresAtuacaoAtivos().subscribe(
      res => {
        this.setorAtuacao = res.resultado;
      },
      error => {
        Swal.fire(
          getSwal(
            'error',
            'Ops!',
            'Ocorreu um erro ao carregar personalidades jurídicas.',
            'ok',
            null
          )
        );
      }
    );
  }

  personalidadesJuridicasAtivos() {
    this.consultaUsuario.getPersonalidadesJuridicasAtivos().subscribe(
      res => {
        this.personalidadeJuridica = res.resultado;
      },
      error => {
        Swal.fire(
          getSwal(
            'error',
            'Ops!',
            "Ocorreu um erro ao carregar personalidades juridicas.",
            'ok',
            null
          )
        );
      }
    );
  }

  obterConfiguracoesCliente() {
    this.cadastroService.clientes()
      .subscribe(res => {
        this.servicoSms = res.resultado[0].configuracao.servicoSms;
      })
  }

  validarNome() {
    if(this.procuradorForm.controls.nomeRazaoSocialProcurador.value &&
      this.procuradorForm.controls.nomeRazaoSocialProcurador.value.trim() == 0) {
      this.procuradorForm.controls.nomeRazaoSocialProcurador.setValue('');
    }

    if(this.procuradorForm.controls.nomeRepresentanteLegal.value && 
      this.procuradorForm.controls.nomeRepresentanteLegal.value.trim() == 0) {
      this.procuradorForm.controls.nomeRepresentanteLegal.setValue('');
    }
  }
}
