import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-historico-operacoes-usuarios',
  templateUrl: 'historico-operacoes-usuarios.component.html'
})
export class HistoricoOperacoesUsuariosComponent implements OnInit {
  @ViewChild('childFilter', {static: false}) child;

  itemsPerView: number = 10;
  data: Array<any> = [];
  registros: Array<any> = [];

  constructor() {}

  ngOnInit() {}

  next() {
    this.child.next();
  }

  previous() {
    this.child.previous();
  }
}
