import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  Inject,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Subscription } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { UserService } from '../../../service/user-service/user.service';
import { SessionService } from '../../../service/session/session.service';
import { PerfilDataService } from '../../../service/perfil/perfil.service';
import { ConsultaUsuarioService } from '../../../service/consulta-usuario/consultar.user.service';
import { TranslateService } from '@ngx-translate/core';
import { appClient } from './../../../../environments/version';
import { environment } from './../../../../environments/environment';
import { userInfo } from 'os';

@Component({
  selector: '[app-user-sidebar]',
  templateUrl: 'user-sidebar.component.html',
  styleUrls: ['./user-sidebar.component.scss'],
})
export class UserSidebarComponent implements OnInit {
  @Output() onSignOut = new EventEmitter<boolean>();
  @Input() userData: string;
  subscription: Subscription;
  userInfo = {};
  selectedCompany = 0;
  fileToUpload: string = null;
  hasImg = false;
  imageToUpload: any;
  userImg: SafeUrl;
  showImg = false;
  labels: any;

  constructor(
    private router: Router,
    private userService: UserService,
    private session: SessionService,
    private consultaUsuario: ConsultaUsuarioService,
    private domSanitizer: DomSanitizer,
    private perfilService: PerfilDataService,
    private translate: TranslateService,
    @Inject(DOCUMENT) private document: any,
  ) {
    this.setLabels();
  }

  ngOnInit() {
    this.verifySession(this.userData);
    // this.setImage();
    this.subscription = this.userService.getInfo().subscribe(data => {
      this.verifySession(data);
    });
  }

  verifySession(data) {
    this.userInfo = data;
    if (data.usuario && data.usuario.imagem) {
      this.hasImg = true;
      this.userImg = this.domSanitizer.bypassSecurityTrustUrl(
        data['usuario'].imagem.toString(),
      );
    } else {
      this.hasImg = false;
    }
  }

  // setImage() {
  //   if (this.userInfo['usuario'] && this.userInfo['usuario'].imagem) {
  //     this.userImg = this.userInfo['usuario'].imagem;
  //     this.userImg = this.domSanitizer.bypassSecurityTrustUrl(
  //       'data:image/jpeg;base64,' + this.userImg,
  //     );
  //   }
  // }

  fileChangeEvent(event) {
    this.convertToJpg(event.target.files[0]).then((res)=> {
      this.imageToUpload = res;
      if (event.target.files && event.target.files[0]) {
        const reader = new FileReader();
        reader.readAsDataURL(this.imageToUpload);
        reader.onload = evt => {
          this._handleReaderLoaded(evt);
        };
      }
    })
  }

  _handleReaderLoaded(readerEvt) {
    const binaryString = readerEvt.target.result;
    this.perfilService.alterarFoto(this.imageToUpload).subscribe(res => {
      this.showImg = true;
      this.userService.updateDataWithPhoto(binaryString);
    });
  }

  convertToJpg(image){
    return new Promise((resolve, reject) => {
      const file = image;
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = (event: any) => {
        const img = new Image();
        img.src = event.target.result;

        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;

          const ctx = canvas.getContext('2d');
          ctx.fillStyle = '#ffffff';
          ctx.fillRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(img, 0, 0);

          canvas.toBlob((blob) => {
            const convertedFile = new File([blob], `foto_perfil`, { type: 'image/jpeg' });

            resolve(convertedFile);
          }, 'image/jpeg', 1);
        };
      };
    })
  }
  
  signOut() {
    this.userService.sendInfo({ loggedIn: false }).then(res => {
      this.session.clearSession();
      if (appClient.indexOf('sefaz') !== -1) {
        this.document.location.href = environment['URL_ASTRA'];
      } else {
        this.onSignOut.emit(false);
        this.router.navigate(['/entrar']);
      }
    });
  }

  clickUsuario() {
    this.onSignOut.emit(false);
  }

  setLabels() {
    this.labels = {
      alterarFoto: this.translate.instant('CONFIGURACOES.USUARIOS.ALTERARFOTO'),
    };
  }
}
