import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-setor-atuacao',
    templateUrl: './setor-atuacao.component.html',
    styleUrls: ['./setor-atuacao.component.scss']
})
export class SetorAtuacaoComponent implements OnInit {

    constructor() { }

    ngOnInit() { }
}