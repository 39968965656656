import {
  Component,
  OnInit,
  ViewChild,
  Output,
  Input,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DatePickerComponent } from 'ng2-date-picker';
import { DatePipe } from '@angular/common';
import { Valida } from '../../../../form-utils/valida';

import * as FileSaver from 'file-saver';

import * as moment from 'moment-timezone';
import { Moment } from 'moment';

import { RegistroAcoesService } from '../../../../service/registro-acoes/registro-acoes.service';

@Component({
  selector: 'app-filter',
  templateUrl: 'filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit, OnChanges {
  @Output() onDataChange: EventEmitter<any> = new EventEmitter<any>();
  @Input('itemsPerView') itemsPerView;

  searchForm: FormGroup;
  visible = '';
  inicio;
  doc: string = '';
  // dataOcorrencia = 'Data Inicial';
  // dataOcorrenciaFinal = 'Data Final';
  dataFiltro = null;
  dataInicialValida = false;
  dataFinalValida = false;
  data: Object = {};
  searchParams: Object = {
    pagina: 1,
  };
  dates: any = {
    data_final: null,
    data_inicial: null,
  };
  perfisAcesso: Array<any> = [];
  tiposRegistro: Array<any> = [];
  tipoRegistro = 'Tipo de Registro';
  perfilAcesso = 'Perfil';
  perfilSelecionado = '';
  registroSelecionado = '';
  periodo: boolean = false;
  thisNow;
  configuracaoData = {
    format: 'YYYY-DD-MM',
  };

  cont = 1;
  constructor(
    private fb: FormBuilder,
    private registroAcoesService: RegistroAcoesService,
  ) {
    this.criarFormulario();
  }

  ngOnInit() {
    // this.search();
    this.thisNow = moment(new Date()).format('YYYY-DD-MM');

    // this.registroAcoesService.getData().subscribe(
    //   res => {
    //     console.log('FilterComponent on init: ', res);
    //     this.handleResponse(res);
    //   },
    //   error => console.log(error),
    // );
  }

  ngOnChanges() {
    if (this.cont === 1) {
      this.cont = this.cont + 1;
    } else {
      this.search();
    }
  }

  handleResponse(response) {
    this.perfisAcesso = response[0].resultado;
    this.tiposRegistro = response[1].resultado;
    this.perfisAcesso.forEach(elem => (elem['checked'] = false));
    this.tiposRegistro.forEach(elem => (elem['checked'] = false));
    this.pesquisarOcorrencia(true);
  }

  next() {
    if (!this.data['ultimaPagina']) {
      this.searchParams['pagina'] = this.data['paginaAtual'] + 1;
      this.search();
    }
  }

  previous() {
    if (!this.data['primeiraPagina']) {
      this.searchParams['pagina'] = this.data['paginaAtual'] - 1;
      this.search();
    }
  }

  exportar() {
    this.visible = '';
    const searchParams = this.searchParams;
    delete searchParams['total_pagina'];
    delete searchParams['pagina'];
    this.onDataChange.emit(true);
    this.registroAcoesService
      .exportarRegistros(this.searchParams)
      .subscribe(res => {
        const blob = new Blob([res], { type: 'application/pdf' });
        FileSaver.saveAs(blob, `registros${new Date(Date.now())}`);
        this.onDataChange.emit(false);
      });
  }

  criarFormulario() {
    this.searchForm = this.fb.group({
      assunto: '',
    });
  }

  selecionarDataInicial(event) {
    //this.searchParams['data_inicial'] = event; // moment(event, 'DD-MM-YYYY').format();
    if (this.dates['data_inicial'] > this.dates['data_final']) {
      setTimeout(() => {
        this.dates['data_final'] = null;
      }, 100);
    }
  }

  selecionarDataFinal(event) {
    //this.searchParams['data_final'] = event; // moment(event, 'DD-MM-YYYY').format();
    console.log(this.searchParams['data_final']);
  }

  pesquisarOcorrencia(search: boolean) {
    // console.log('pesquisarOcorrencia    ', search, this.searchParams);
    if (!search) {
      this.visible = '';
      this.dataFiltro = null;
      this.dates = {
        data_final: null,
        data_inicial: null,
      };
      delete this.searchParams['data_inicial'];
      delete this.searchParams['data_final'];
    } else {
      if (this.dates['data_inicial'] != null) {
        this.searchParams['data_inicial'] = moment(
          this.dates['data_inicial'].valueOf(),
        ).format('YYYY-MM-DD');
        this.dataFiltro = moment(this.dates['data_inicial'].valueOf()).format(
          'DD/MM',
        );
      }

      if (this.dates['data_final'] != null) {
        this.searchParams['data_final'] = moment(
          this.dates['data_final'].valueOf(),
        ).format('YYYY-MM-DD');
        this.dataFiltro =
          this.dataFiltro +
          ' - ' +
          moment(this.dates['data_final'].valueOf()).format('DD/MM');
      }
    }
    this.search();
    this.visible = '';
  }

  toggleVisibility(item: string) {
    console.log(item, this.visible);
    if (item === this.visible) {
      this.visible = '';
    } else {
      this.visible = item;
    }
    console.log(item, this.visible);
  }

  alteracaoFilter(index: number) {
    this.tipoRegistro = this.tiposRegistro[index].descricao;
    this.searchParams['codigo_tipo_registro'] = this.tiposRegistro[
      index
    ].codigo;
  }

  perfilFilter(index: number) {
    this.perfilAcesso = this.perfisAcesso[index].descricao;
    this.searchParams['codigo_perfil'] = this.perfisAcesso[index].codigo;
  }

  resetarFiltros(filter: string) {
    if (filter === 'registro') {
      this.tipoRegistro = 'Tipo de Registro';
      this.registroSelecionado = '';
      delete this.searchParams['codigo_tipo_registro'];
    } else {
      this.perfilAcesso = 'Perfil';
      this.perfilSelecionado = '';
      delete this.searchParams['codigo_perfil'];
    }
    this.search();
    this.visible = '';
  }

  inputEmpty() {
    // console.log('inputEmpty  > ', this.protocolo, this.searchForm.value);
    if (this.doc === '') {
      // this.onFilter.emit({ protocolo: this.protocolo });
    }
  }

  search() {
    // console.log('search >>', this.searchParams, this.doc);
    // this.visible = '';
    // this.searchParams['total_pagina'] = this.itemsPerView;
    // this.onDataChange.emit(true);
    // this.registroAcoesService
    //   .getRegistrosdeAcoes(this.searchParams)
    //   .subscribe(data => {
    //     this.data = data;
    //     console.log(this.data);
    //     this.onDataChange.emit(false);
    //   });
    this.onDataChange.emit(this.searchParams);
  }
}
