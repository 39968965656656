import { Component, OnInit } from "@angular/core";
import { CaixaPostalService } from "../../../../service/caixa-postal/caixa-postal.service";
import { graphicCollors } from "../../../../../environments/version";
import { Router, NavigationExtras } from "@angular/router";

@Component({
  selector: "[app-graficos]",
  templateUrl: "graficos.component.html",
  styleUrls: ["./graficos.component.scss"],
})
export class GraficosMensagensComponent {
  comResposta: number;
  enviadas: number;
  naoRespondidas: number;
  lidos: number;
  max = 0;
  selected = "ativos";
  gColors = graphicCollors;
  searchParams: any = {};

  constructor(
    private caixaPostalService: CaixaPostalService,
    private router: Router
  ) {}

  ngOnInit() {
    this.caixaPostalService.getMessagesNumbers().subscribe((res) => {
      this.comResposta = res[0].resultado;
      this.enviadas = res[1].resultado;
      this.naoRespondidas = res[2].resultado;
      this.lidos = res[3].resultado;
      this.max = this.comResposta + this.naoRespondidas;
    });
  }

  carregarEnviadas() {
    this.router.navigate([`/caixa-postal`]);
  }
  carregarComRespostas() {
    this.router.navigate([`/caixa-postal/mensagens/comrespostas`]);
  }
  carregarSemRespostas() {
    this.router.navigate([`/caixa-postal/mensagens/naoRespondidas`]);
  }
  carregarLidos() {
    this.router.navigate([`/caixa-postal/mensagens/lidos`]);
  }
}
