import { Component, ViewChild, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Valida } from '../../../../../form-utils/valida';
import { ParametroAssinaturaDigitalService } from '../../../../../service/parametro-assinatura-digital/parametro-assinatura-digital.service';
import { AmbienteService } from '../../../../../service/ambiente/ambiente.service';
import { Subscription } from '../../../../../../../node_modules/rxjs';
import * as moment from 'moment';

import Swal from 'sweetalert2';
import { getSwal } from '../../../../../utils/swalObject';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-consultar-parametro-assinatura-digital',
  templateUrl: './consultar-parametro-assinatura-digital.component.html',
  styleUrls: ['./consultar-parametro-assinatura-digital.component.scss']
})
export class ConsultarParametroAssinaturaDigitalComponent implements OnInit {
  @ViewChild('childFilter', {static: false}) child;
  subscription: Subscription;
  editarAmbienteForm: FormGroup;
  searchForm: FormGroup;
  dialogMessage = {};
  viewDialog = false;
  itemsPerView: number = 10;
  loading: boolean;
  idPerfilAcesso: number = null;
  dadosDeAmbiente: any = {};
  registroAmbiente: any = [];
  registroSalvo: any = [];
  tiposAssinaturaEletronica = [];
  tiposPersonalidadeJuridica = [];
  tiposSetorAtuacao = [];
  tipoUsuario: any = {
    codigo: -1,
    descricao: 'Selecione'
  };
  autoCadastro: number = null;
  exigeCertificadoCnpj: boolean = false;
  teste:string='teste';
  data: Object = {
    primeiraPagina: true,
    ultimaPagina: false,
    paginaAtual: 1,
    totalPaginas: 1,
    totalDeItems: 0,
    itemsPorPagina: 10
  };
  registros = [];
  searchParams: {};
  paginaAtual: number = 1;
  totalPaginas: number = 1;
  assinaturasDigitais: Array<any> = [];
  numeroAssinaturas: number = 10;
  filterCurrent: Object = {
    total_pagina: 10,
    pagina: 1
  }

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private ambienteService: AmbienteService,
    private parametroAssinaturaDigitalService: ParametroAssinaturaDigitalService
  ) {

    this.subscription = this.route.params.subscribe(params => {
      if (params['id']) {
        this.idPerfilAcesso = params['id'];
      }
      this.data = [];
    });
  }


  ngOnInit() {
    this.criarFormulario();
    this.subscription =
    this.ambienteService.assinaturasDigitais$.subscribe(
      (res) => {
        this.data = res;
        this.filterCurrent = this.child.searchParams;
        this.assinaturasDigitais = res['resultado'];
        this.data['totalDeItems'] = res.totalRegistros;
        this.data['totalPaginas'] = res['totalPaginas'];
        this.data['primeiraPagina'] = res.primeiraPagina;
        this.data['ultimaPagina'] = res.ultimaPagina;
        this.loading = false;
        console.log(res)
      }
    );
    // this.getConsultas(this.data)
  }

  getConsultas(params) {
    let parametros = {};
    parametros['pagina'] = this.data['paginaAtual'];
    parametros['total_pagina'] = this.itemsPerView;
    if (this.child.searchParams.descricao) {
      parametros['descricao'] = this.child.searchParams.descricao;
    } else {
      delete parametros['descricao'];
    }

    this.ambienteService.listarAssinaturasEletronicas(parametros).subscribe(res => {
      this.loading = false;
        if(res.tamanhoPagina) {
            this.data = res;
            this.assinaturasDigitais = res['resultado'];
            this.data['totalDeItems'] = res.totalRegistros;
            // this.data['totalPaginas'] = this.itemsPerView;
            this.loading = false;
          }
      }, error => {
        this.loading = false;
      });
    }

  ngAfterViewInit() {
    if (this.idPerfilAcesso) {
      this.obterDados();
    }
  }

  criarFormulario() {
    this.editarAmbienteForm = this.fb.group({
      razaoSocial:  [''],
      ativo: [false],
      inicioContrato: '',
      fimContrato: '',
      inicioContratoFormatado: '',
      fimContratoFormatado: '',
      configuracao: this.fb.group({
        tipoAprovacaoUsuario: [
          {
            codigo: -1,
            descricao: 'Selecione'
          }
        ],
        url: '',
        apiAlfresco: [''],
        urlAuditor: [''],
        urlContribuinte: [''],
        usuarioEscrita: [''],
        senhaEscrita: [''],
        pastaAlfresco: [''],
        hostSmtp: [''],
        portaSmtp: [''],
        usuarioSmtp: [''],
        senhaSMTP: [''],
        endpointSms: [''],
        contaSms: [''],
        codigoSms: [''],
        alertaProcuracao: [''],
        prazoProcuracao: [0],
        autoCadastro: null,
        exigeCertificadoCnpj: null,
        exigeTermoAdesao: false,
        tituloDte: [''],
        logoDte:''
      }),
      regrasAssinatura: this.fb.group({
        assinaturaEletronica: {
          codigo: -1,
          descricao: 'Selecione'
        },
        personalidadeJuridica: {
          codigo: -1,
          descricao: 'Selecione'
        },
        setorAtuacao: {
          codigo: -1,
          descricao: 'Selecione'
        }
      })
    });
  }

  childFilterChange(value) {
    this.loading = value;
  }

  openDialog(item) {
    // Swal.fire(item.tipoEventoTrilha.descricao, item.descricao, 'info');
    Swal.fire(
      getSwal(
        'info',
        item.tipoEventoTrilha.descricao,
        item.descricao,
        'ok',
        null
      )
    );
  }

  exportarRegistros() {
    this.child.exportar();
  }

  quantidadeAssinaturas(numeroAssinaturas){

    this.itemsPerView = +numeroAssinaturas;

    this.data['paginaAtual'] = 1;
    this.paginaAtual = 1;

    this.onPageChange(this.filterCurrent);

    switch (numeroAssinaturas) {
      case "10":
        this.data['itemsPorPagina'] = 10;
        console.log(this.data['itemsPorPagina']);
        break;
      case "20":
        this.data['itemsPorPagina'] = 20;
        console.log(this.data['itemsPorPagina']);
        break;
      case "50":
        this.data['itemsPorPagina'] = 50;
        console.log(this.data['itemsPorPagina']);
        break;
      case "100":
        this.data['itemsPorPagina'] = 100;
        console.log(this.data['itemsPorPagina']);
        break;
    }
    this.loading = true;
    console.log('data:', this.data)
  }

  next() {
    this.data['paginaAtual']++;
    this.onPageChange(this.filterCurrent);
    // this.obterDados();
  }

  previous() {
    this.data['paginaAtual']--;
    this.onPageChange(this.filterCurrent);
    // this.obterDados();
  }

  primeiraPagina() {
    this.data['paginaAtual'] = 1;
    this.onPageChange(this.filterCurrent);
    // this.obterDados();
  }

  ultimaPagina() {
    this.data['paginaAtual'] = this.data['totalPaginas'];
    this.onPageChange(this.filterCurrent);
    // this.obterDados();
  }

  onPageChange(value) {
    value['pagina'] = this.data['paginaAtual'];
    value['total_pagina'] = this.itemsPerView;

    this.filterCurrent = value;
    this.loading = true;

    this.ambienteService.listarAssinaturasEletronicas(value).subscribe(
      res => {
        // this.data['totalDeItems'] = res.totalRegistros;
        this.assinaturasDigitais = res['resultado'];
        this.loading = false;
      },
      error => {
        this.loading = false;
        console.error('Erro ao obter as parametros de assinatura:', error);
        // this.assinaturasDigitais = [];
      }
    )
  }

  carregarDadosFormulario(data) {
    for (let key in data) {
      if (this.editarAmbienteForm.controls[key] && data[key] !== null) {
        this.editarAmbienteForm.controls[key].patchValue(data[key]);
      }
    }

    if (data.configuracao !== null) {
      this.tipoUsuario = data.configuracao.tipoAprovacaoUsuario;
      this.editarAmbienteForm
        .get('configuracao')
        ['controls'].tipoAprovacaoUsuario.patchValue(this.tipoUsuario);
      this.editarAmbienteForm.controls.configuracao[
        'controls'
      ].senhaSMTP.setValue(data.configuracao.senhaSmtp);
      this.editarAmbienteForm.controls.configuracao[
        'controls'
      ].urlContribuinte.setValue(data.configuracao.urlContribuinte);

      this.editarAmbienteForm.controls.configuracao[
        'controls'
        ].autoCadastro.setValue(data.configuracao.autoCadastro);

      this.editarAmbienteForm.controls.configuracao[
        'controls'
        ].exigeCertificadoCnpj.setValue(data.configuracao.exigeCertificadoCnpj);

      this.autoCadastro = data.configuracao.autoCadastro;

      this.exigeCertificadoCnpj = data.configuracao.exigeCertificadoCnpj




    } else {
      this.tipoUsuario = 0;
    }
  }

  obterDados() {
    this.ambienteService.obterDadosAmbiente(this.idPerfilAcesso).subscribe(
      res => {
        //USE TO TEST: res['resultado'].fimContrato = "";
        res.resultado.fimContratoFormatado =
          res.resultado.fimContrato && res.resultado.fimContrato !== null
            ? moment(res.resultado.fimContrato).format('DD/MM/YYYY')
            : '';
        if (
          res.resultado.fimContratoFormatado.toLowerCase() == 'invalid date'
        ) {
          res.resultado.fimContratoFormatado = ' ';
        }

        this.dadosDeAmbiente = res.resultado;
        this.registroAmbiente = this.dadosDeAmbiente.regrasAssinatura
        this.registroSalvo = this.dadosDeAmbiente.regrasAssinatura

        this.getConsultas(this.filterCurrent)
        this.carregarDadosFormulario(this.dadosDeAmbiente);
      },
      error => {
        Swal.fire(
          getSwal(
            'error',
            'Ops!',
            'Ocorreu um erro e o Ambiente não pôde ser carregado.',
            'ok',
            null
          )
        );
      }
    );
  }

  checkChanges() {
    let value = this.editarAmbienteForm.getRawValue();
    const updatedValue = {};
    for (let key in value) {
      if (
        value[key] !== this.dadosDeAmbiente[key] &&
        value[key] !== 'configuracao'
      ) {
        this.dadosDeAmbiente[key] = value[key];
      }
    }

    for (let key in value.configuracao) {
      if (
        value.configuracao[key] !== this.dadosDeAmbiente.configuracao[key] &&
        value[key] !== 'tipoAprovacaoUsuario'
      ) {
        this.dadosDeAmbiente.configuracao[key] = value.configuracao[key];
      }

      if (
        value.configuracao[key] &&
        value.configuracao[key] !== null &&
        value.configuracao[key].toString().length === 0
      ) {
        delete this.dadosDeAmbiente.configuracao[key];
      }
    }

    var regraUso = {};


    if(this.dadosDeAmbiente.regraUso){
      regraUso = {
        tipoComunicado: {codigo: this.dadosDeAmbiente.regraUso.tipoComunicado.codigo}
      }
      this.dadosDeAmbiente.regraUso = regraUso;
    } else {
      this.dadosDeAmbiente.regraUso = null;
    }

    this.dadosDeAmbiente.regrasAssinatura = this.registroSalvo

    delete this.dadosDeAmbiente.inicioContratoFormatado;
    delete this.dadosDeAmbiente.fimContratoFormatado;


    return this.dadosDeAmbiente;

  }

  rotaEditar(){
    this.router.navigate(['/configuracao/ambiente/editar-parametro-assinatura-digital/'+this.idPerfilAcesso]);
  }


  excluir(ndx) {
    const obj = this.checkChanges();
    var teste = [];
    obj.regrasAssinatura.forEach((element,index)=>{
      if(index != ndx ){
        if(obj.regrasAssinatura[index].assinaturaEletronica !== undefined && obj.regrasAssinatura[index].assinaturaEletronica.codigo !== -1)
        teste.push(obj.regrasAssinatura[index])
      }
   });
    obj.regrasAssinatura = teste
    this.ambienteService
      .atualizarDadosAmbiente(this.dadosDeAmbiente.codigo, obj)
      .subscribe(
        res => {
          Swal.fire(
            getSwal(
              'success',
              'Sucesso',
              'Ambiente atualizado com sucesso.',
              'ok',
              null
            )
          ).then(res => {
            // this.ngOnInit();
            this.getConsultas(this.filterCurrent)
            this.obterDados();
            this.criarFormulario();
          });
        },
        error => {
          // this.ngOnInit();
            this.getConsultas(this.filterCurrent)
            this.obterDados();
            this.criarFormulario();
          console.log(error);
          Swal.fire(
            getSwal(
              'error',
              'Ops!',
              error.error.excecao.recomendacao,
              'ok',
              null
            )
          );
        }
      );
  }

  ajuda(valor) {
    let msg1=
    `<ol>
      <li>
        Definir o comportamento da solução quanto a solicitação de assinatura de comunicados e documentos gerados na plataforma.
      </li>
    </ol>`;

    switch(valor) {
      case 'assinaturaDigital': {
        Swal.fire({
          icon: 'info',
          html:msg1,
          confirmButtonText:'Ok' ,

        })
        break;
      }
    }
  }

}

