import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

import * as moment from 'moment-timezone';
import { Moment } from 'moment';

@Component({
  selector: 'app-filtro-historico-funcionalidade',
  templateUrl: 'filtro-funcionalidades.component.html',
  styleUrls: ['./filtro-funcionalidades.component.scss']
})

export class FiltroHistoricoFuncionalidadeComponent implements OnInit {
  @Output() onFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClear: EventEmitter<any> = new EventEmitter<any>();

  @Output() onOpen: EventEmitter<any> = new EventEmitter<any>();

  periodo: boolean = false;
  dataInicial: any = null;
  dataFinal: any = null;
  @Input()
  visible: string;
  inicio: moment.Moment;
  fim: moment.Moment;

  dateformat: string = 'YYYY-MM-DD';

  obj = {
    data_inicial: moment.Moment,
    data_final: moment.Moment,
  };

  constructor() {}

  ngOnInit() {
    window.alert("Teste");
  }

  selecionarDataInicial(event) {
    this.obj['data_inicial'] = this.inicio
      ? this.inicio.format(this.dateformat)
      : null; //moment(this.inicio, 'DD-MM-YYYY').format();
    if (this.inicio && this.fim && this.inicio > this.fim) {
      setTimeout(() => {
        this.obj['data_final'] = null; //  moment.Moment;
        this.fim = null; //moment.Moment;
      }, 100);
    }
  }

  selecionarDataFinal(event) {
    this.obj['data_final'] = this.fim ? this.fim.format(this.dateformat) : null; //moment(this.fim, 'DD-MM-YYYY').format();
  }

  pesquisarPeriodo() {
    // this.visible = false;
    this.onFilter.emit(this.obj);
    this.periodo = true;
  }

  limparFiltros() {
    this.onClear.emit(this.obj);
    this.obj = {
      data_inicial: moment.Moment,
      data_final: moment.Moment,
    };
    this.fim = moment.Moment;
    this.inicio = moment.Moment;
    // this.visible = false;
    this.periodo = false;
  }
}
