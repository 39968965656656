import { AnaliseProcuradoresComponent } from './analise-procuradores/analise-procuradores.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AnaliseDadosRoutingModule } from './analise-dados-routing.module';
import { HomeComponent } from '../home/home/home.component';
import { AnaliseDadosComponent } from './analise-dados.component';
import { AnaliseGeralComponent } from './analise-geral/analise-geral.component';
import { SharedModule } from 'src/app/shared.module';
import { OpcoesPowerbiComponent } from './opcoes-powerbi/opcoes-powerbi.component';
import { AnaliseUsuariosComponent } from './analise-usuarios/analise-usuarios.component';
import { PowerbiComponent } from './powerbi/powerbi.component';
import { AnaliseComunicadosComponent } from './analise-comunicados/analise-comunicados.component';
import { AnaliseEventosComponent } from './analise-eventos/analise-eventos.component';


@NgModule({

  imports: [
    CommonModule,
    SharedModule,
    AnaliseDadosRoutingModule
  ],
  exports: [],
  declarations: [
    AnaliseDadosComponent,
    AnaliseUsuariosComponent,
    AnaliseComunicadosComponent,
    AnaliseProcuradoresComponent,
    AnaliseGeralComponent,
    AnaliseEventosComponent,
    OpcoesPowerbiComponent,
    PowerbiComponent
  ],

})
export class AnaliseDadosModule { }
