import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable()
export class CervelloService {

    dados = {};
    url: string = environment.BASE_URL;

    constructor(private http: HttpClient) { }

    // salvarUsuario(dados) {
    //     this.http.put('http://186.237.202.19/Eicon/api/Usuario/SalvarUsuario', dados);
    // }


    getDadosUserCervello(): any{
        const sessao = JSON.parse(localStorage.getItem('session'));
        this.dados = {
            Nome: sessao.usuario.nomeRazaoSocial ,
            Login: `${sessao.cliente.numIbge}.${sessao.usuario.cpfCnpj}`,
            Email: sessao.usuario.email,
            Telefone: `(${sessao.usuario.ddd})${sessao.usuario.celular}`,
            Empresa: `EICON API`,
            Localidade: `${sessao.cliente.razaoSocial}`,
            Departamento: `Usuário`,
            tp: `chat`,
            TemaChat: `DTE/DEC` // GissOnline
          };
        return this.dados
    }

    loginPlugin(dados) {
        return this.http.post(`${this.url}/cervello/url-chat`, dados);
    }

    // getLocalidades() {
    //     return this.http.get('http://186.237.202.19/Eicon/api/Usuario/GetListaLocalidades');
    // }

    // getEmpresas() {
    //     this.http.get('http://186.237.202.19/Eicon/api/Usuario/GetListaEmpresas');
    // }
}