import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subject, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

@Injectable()
export class ManualService {

  url: string = environment.BASE_URL;
  constructor(private http: HttpClient) { }

  obterManuais(codigoCliente){
    return this.http.get(`${this.url}/clientes/${codigoCliente}/manualUsuario/listar`);
  }

  baixarManualAuditor(codigoCliente: number){
    return this.http.get(`${this.url}/manuais/${codigoCliente}/manualAuditor`, {
      responseType: 'arraybuffer'
    }).pipe( map(data => {
      return data;  
    }));

  }

  obterManualPortal(codigoCliente: number): Observable<any>{
    return this.http.get(`${this.url}/manuais/${codigoCliente}/manualPortal`, {
      responseType: 'arraybuffer'
    });

  }

  inserirManualAuditor(codigoCliente, anexo){
      const fd = new FormData();
      fd.append('anexo', anexo);
      return this.http.post(`${this.url}/clientes/${codigoCliente}/manualUsuario/auditor`, fd)
        .pipe(map(data => {
          return data;
      }));
  }

  inserirManualPortal(codigoCliente, anexo){
      const fd = new FormData();
      fd.append('anexo', anexo);
      return this.http.post(`${this.url}/clientes/${codigoCliente}/manualUsuario/portal`, fd)
        .pipe(map(data => {
          return data;
      }));
  }

  excluirManualAuditor(codigoCliente, anexo){
    const fd = new FormData();
    fd.append('anexo', anexo);
    return this.http.put(`${this.url}/clientes/${codigoCliente}/manualUsuario/alterarAuditor`, fd)
      .pipe(map(data => {
        const body = data;
        return body;
    }));
  }

  excluirManualPortal(codigoCliente, anexo){
    const fd = new FormData();
    fd.append('anexo', anexo);
    return this.http.put(`${this.url}/clientes/${codigoCliente}/manualUsuario/alterarPortal`, fd)
      .pipe(map(data => {
        const body = data;
        return body;
    }));
  }
}