import { Component, OnInit } from '@angular/core';
import { RegistroAcoesService } from '../../../../../service/registro-acoes/registro-acoes.service'


@Component({
  selector: 'app-servicos-ativos',
  templateUrl: 'servicos-ativos.component.html',
  styleUrls: ['./servicos-ativos.component.scss']
})



export class ServicosAtivos implements OnInit {

  servicesActives: any;
  servicesActivesLength: any;

  constructor(public registroAcoesService: RegistroAcoesService){}

  ngOnInit(){
       
    this.registroAcoesService.getServicosAtivos().subscribe(data => {      
      this.servicesActives = data.resultado;
      this.servicesActivesLength = this.servicesActives.length
    })
    
  }
  
}
