import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AssinaturaEletronicaService } from '../../../../../service/configuracao/assinatura-eletronica.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[app-filtrar-assinatura-eletronica]',
  templateUrl: './filtro.component.html',
  styleUrls: ['./filtro.component.scss'],
})
export class FiltrarAssinaturaEletronicaComponent implements OnInit {
  searchForm: FormGroup;
  searchParams = {};
  errorMessage = '';
  searchInput: string = '';
  loading: boolean = false;
  filter: string = '';
  debounce: Subject<string> = new Subject<string>();

  constructor(
    private fb: FormBuilder,
    private assinaturaEletronicaService: AssinaturaEletronicaService,
  ) {
    this.criarFormulario();
  }

  ngOnInit() {
    this.loading = true;
    this.assinaturaEletronicaService
      .getAssinaturaEletronica({})
      .subscribe(res => {
        this.loading = false;
        console.log(res)
      }, 
      error => (this.errorMessage = error));
    this.configurarSubject();
  }

  criarFormulario() {
    this.searchForm = this.fb.group({
      assunto: '',
    });
  }

  onSearchByTitle() {
    this.searchParams['descricao'] = this.searchInput;
    this.search();
  }

  search() {
    this.loading = true;
    this.assinaturaEletronicaService
      .getAssinaturaEletronica(this.searchParams)
      .subscribe(data => {
        this.loading = false;
        console.log(data)
      });
  }

  configurarSubject() {
    this.debounce
      .pipe(debounceTime(500))
      .subscribe(filter => {
        this.filter = filter;
        this.onSearchByTitle();
      });
  }
}
