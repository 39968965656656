import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { Subject } from "rxjs/Subject";

import "rxjs/add/operator/catch";
import "rxjs/add/operator/map";
import "rxjs/add/observable/forkJoin";
import "rxjs/add/observable/throw";

import { environment } from "../../../environments/environment";

@Injectable()
export class ComunicadosService {
  url: string = environment.BASE_URL;
  private comunicados = new Subject<any>();
  comunicados$ = this.comunicados.asObservable();

  constructor(private http: HttpClient) {}

  getComunicados(searchParams): Observable<any> {
    const params = this.setParams(searchParams);
    if (!params) {
      return;
    }
    return this.http
      .get(`${this.url}/tiposComunicado`, { params: params })
      .map((data) => {
        const body = data;
        this.comunicados.next(body);
        return body;
      });
  }

  getListaUsuarios(): Observable<any> {
    return this.http
      .get<any>(`${this.url}/usuarios/usuariosComunicados`);
  }
  
  getComunicadosAtivoInativo(sessaoUsuario): Observable<any> {
    return this.http
      .get<any>(`${this.url}/tiposComunicado/tipoComRelatorio`);
  }
  
  setParams(searchParams: any) {
    let params = new HttpParams();
    if (!searchParams) {
      return params;
    }
    if (searchParams.key && searchParams.value) {
      return params.set(searchParams.key, searchParams.value);
    }
    for (const key of Object.keys(searchParams)) {
      params = params.set(key, searchParams[key]);
    }
    return params;
  }

  getData(): Observable<any> {
    const tiposPrazo = this.http
      .get(`${this.url}/tiposPrazo/ativos`)
      .map((res) => res);
    const aplicacoes = this.http
      .get(`${this.url}/aplicacoes/ativos`)
      .map((res) => res);
    return Observable.forkJoin([tiposPrazo, aplicacoes]);
  }

  obterCores(): Observable<any> {
    return this.http.get(`${this.url}/coresTipoComunicado`).map((res) => res);
  }

  obterComunicadosPlataformaDTe(searchParams): Observable<any> {
    const params = this.setParams(searchParams);
    return this.http
      .get(`${this.url}/tiposComunicado/autorizadosPlataformaDTe`, {
        params: params,
      })
      .map((res) => res);
  }

  obterDadosGraficos(): Observable<any> {
    const registradas = this.http
      .get(`${this.url}/mensagens/quantidadeComunicadosPorAno`)
      .map((res) => res);
    const contribuinte = this.http
      .get(`${this.url}/mensagens/quantidadeComunicadosPorMes`)
      .map((res) => res);
    const auditor = this.http
      .get(`${this.url}/mensagens/quantidadeComunicadosPorDia`)
      .map((res) => res);
    return Observable.forkJoin([registradas, contribuinte, auditor]);
  }

  obterDadosMes(): Observable<any> {
    return this.http
      .get(`${this.url}/mensagens/quantidadeComunicadosPorMes`)
      .map((data) => {
        const body = data;
        return body;
      });
  }

  obterDadosAno(): Observable<any> {
    return this.http
      .get(`${this.url}/mensagens/quantidadeComunicadosPorAno`)
      .map((data) => {
        const body = data;
        return body;
      });
  }

  obterDadosDia(): Observable<any> {
    return this.http
      .get(`${this.url}/mensagens/quantidadeComunicadosPorDia`)
      .map((data) => {
        const body = data;
        return body;
      });
  }

  criaComunicado(comunicado: any): Observable<any> {
    console.log("REQUEST >>>");
    console.log(comunicado);
    const tipoComunciado = comunicado;
    return this.http
      .post(`${this.url}/tiposComunicado`, tipoComunciado)
      .map((data) => {
        const body = data;
        return body;
      });
  }

  editaComunicado(comunicado, id: any): Observable<any> {
    const tipoComunicado = comunicado;
    return this.http
      .put(`${this.url}/tiposComunicado/${id}`, tipoComunicado)
      .map((data) => {
        const body = data;
        return body;
      });
  }

  obterComunicado(id: number): Observable<any> {
    return this.http.get(`${this.url}/tiposComunicado/${id}`).map((data) => {
      const body = data;
      return body;
    });
  }

  getComunicadoEmail(): Observable<any> {
    return this.http
      .get(`${this.url}/clientes/verificacaoServicoEmail`)
      .map((data) => {
        const body = data;
        return body;
      });
  }

  getComunicadoSms(): Observable<any> {
    return this.http
      .get(`${this.url}/clientes/verificacaoServicoSms`)
      .map((data) => {
        const body = data;
        return body;
      });
  }

  getServidorComunicacao(): Observable<any> {
    const idCliente = JSON.parse(window.localStorage.getItem('session')).cliente.codigo;
    return this.http
      .get(`${this.url}/clientes/${idCliente}/servidoresComunicacao`)
      .map((data) => {
        const body = data;
        return body;
      });
  }

  desativarComunicado(id: any): Observable<any> {
    return this.http
      .get(`${this.url}/tiposComunicado/desativar/${id}`)
      .map((data) => {
        const body = data;
        return body;
      });
  }
}
