import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProcuracoesService } from 'src/app/service/procuracoes/procuracoes.service';
import Swal from 'sweetalert2';
import { getSwal } from '../../../../../utils/swalObject';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-treeview',
  templateUrl: 'treeview.component.html',
  styleUrls: ['./treeview.component.scss'],
})
export class TreeviewComponent implements OnInit {
  @Input() items;
  @Output() onItemChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private procuracoesService: ProcuracoesService,
  ) {}

  ngOnInit() {
    console.log(this.items);
  }

  openItens(itens) {
    if (!itens.show) {
      itens.show = true;
    } else {
      itens.show = !itens.show;
    }
  }

  perfilSelectionChange(value) {
    value.perfilAtivo = !value.perfilAtivo;
    console.log('perfilSelectionChange', value);
  }

  edit(item: any) {
    console.log('user::::',item);
    this.router.navigate([
      `gestao-usuario/procuracao/${item.procuracao}`
    ]);
  }

  pdf(codigoProcuracao) {
    Swal.fire({
      text: 'Deseja fazer download do arquivo anexo à outorga de procuração?',
      icon: 'warning',
      buttonsStyling: false,
      showCancelButton: true,
      customClass: {
        confirmButton: 'btn btn-primary mt-2',
        cancelButton: 'btn btn-outline-danger mt-2',
      },
      cancelButtonText: 'Desistir',
      confirmButtonText: 'Baixar PDF',
    }).then(res => {
      if(res.value === true){
        this.exportarProcuracoes(codigoProcuracao.procuracao);
      }
    });
  }

  exportarProcuracoes(codigoProcuracao: string) {
    console.log("codigo procuracao: ", codigoProcuracao)
    this.procuracoesService.exportarProcuracoes(codigoProcuracao).subscribe(
      (res: any) => {
        const blob = new Blob([res], { type: `application/pdf` });
        FileSaver.saveAs(blob, `procuracao`);
      }, error => {
        Swal.fire(
          getSwal(
            'error',
            'Ops!',
            'Esse arquivo não foi encontrado. Possivelmente por ter sido criado antes dessa implementação da rotina.',
            'ok',
            null,
          )
        )
      }
    );
  }
}
