import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';


@Injectable()
export class IntegracaoService {

  private mensagens = new Subject<any>();
  mensagens$ = this.mensagens.asObservable();

  url: string = environment.BASE_URL;

  constructor(private http: HttpClient) { }

  public onTestThisService():string{
    return new Date(Date.now()).toISOString();
  }

  public onGetHistory(searchParams: Object): Observable<any>{
    const params = this.setParams(searchParams);
    return this.http.get(`${this.url}/importacoesExportacoes`, {params: params})
    .pipe(map(data => {
      console.table(data);
      const body = data;
      this.mensagens.next(body);
      return body;
    }));
  }

  public exportFunctions(documento, nomeDoArquivo): Observable<any> {
    return this.http.post(`${this.url}/exportacoes/funcionalidades/?nomeArquivo=${nomeDoArquivo}`, documento)
    .pipe(map(data => {
      return data;
    }));
  }

  public importFunctions(arquivo: string): Observable<any> {
    const fd = new FormData();
    fd.append('arquivo', arquivo);
    return this.http
      .post(`${this.url}/importacoes/funcionalidades`, fd)
      .pipe(map(data => {
        return data;
      }));
  }

  public importUsers(arquivo: string): Observable<any> {
    const fd = new FormData();
    fd.append('arquivo', arquivo);
    return this.http
      .post(`${this.url}/importacoes/usuarios`, fd)
      .map(data => {
        return data;
      });
  }

  setParams(searchParams: any) {
    let params = new HttpParams();
    if (!searchParams) {
      return params;
    }
    if (searchParams.key && searchParams.value) {
      return params.set(searchParams.key, searchParams.value);
    }
    for (const key of Object.keys(searchParams)) {
      params = params.set(key, searchParams[key]);
    }
    return params;
  }
}
