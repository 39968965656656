import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/forkJoin';
import 'rxjs/add/observable/throw';
import { environment } from '../../../environments/environment';

@Injectable()
export class ParametroAssinaturaDigitalService {
  url: string = environment.BASE_URL;

  private parametrosDeAssinatura = new Subject<any>();
  parametrosDeAssinatura$ = this.parametrosDeAssinatura.asObservable();

  constructor(private http: HttpClient) {}

  obterAssinturaDigital(): Observable<any> {
    return this.http.get(`${this.url}/tiposAprovacaoUsuario`).map(data => data);
  }

  
  obterPersonalidadeJuridica(): Observable<any> {
    return this.http.get(`${this.url}/personalidadesJuridicas/ativos`);
  }

  obterSetoresAtuacao(): Observable<any> {
    return this.http.get(`${this.url}/setoresAtuacao/ativos`);
  }

  obterAssinaturasEletronicas(): Observable<any> {
    return this.http.get(`${this.url}/assinaturasEletronicas/ativos`);
  }
}
