import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { SharedModule } from '../../shared.module';

import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { HeaderComponent } from './header/header.component';
import { RodapeComponent } from './rodape/rodape.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SidebarMenuComponent } from './sidebar/components/sidebar-menu.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { UserSidebarComponent } from './user-sidebar/user-sidebar.component';
import { AlertasComponent } from './alertas/alertas.component';
import { ChatCervelloComponent } from './chat-cervello/chat-cervello.component';

@NgModule({
  imports: [CommonModule, RouterModule, FontAwesomeModule, SharedModule],
  exports: [
    HeaderComponent,
    RodapeComponent,
    SidebarComponent,
    UserSidebarComponent,
    SidebarMenuComponent,
    BreadcrumbComponent,
    ChatCervelloComponent
  ],
  declarations: [
    HeaderComponent,
    RodapeComponent,
    SidebarComponent,
    SidebarMenuComponent,
    BreadcrumbComponent,
    UserSidebarComponent,
    AlertasComponent,
    ChatCervelloComponent
  ],
  providers: [],
})
export class CommonAppModule {}
