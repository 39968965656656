import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import * as pbi from "powerbi-client";
import { PowerbiService } from "src/app/service/powerbi/powerbi.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-analise-geral",
  templateUrl: "./analise-geral.component.html",
  styleUrls: ["./analise-geral.component.scss"],
})

export class AnaliseGeralComponent implements OnInit, AfterViewInit {
  public screenHeight: number;
  @ViewChild("reportContainer", { static: false }) reportContainer: ElementRef;

  loading: boolean = false;
  token: string;

  constructor(private powerbiService: PowerbiService) {

  }

  ngOnInit() {
    this.getToken();
  }

  ngAfterViewInit() {
    this.loading = true;
    setTimeout(() => {
      this.showReport(this.token);    
    }, 5000);
    
  }

  showReport(accessToken) {
    let embedUrl = "https://app.powerbi.com/reportEmbed?reportId="+environment.analiseGeral+"&groupId="+environment.groupId+"&filter=cliente/id_municipio eq " + environment.powerbi+"&schema="+environment.schema;
    let embedReportId = environment.analiseGeral;
    let config = {
      type: "report",
      accessToken: accessToken,
      embedUrl: embedUrl,
      id: embedReportId,
      settings: {},
    };
    let reportContainer = this.reportContainer.nativeElement;
    let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
    let report = powerbi.embed(reportContainer, config);
    report.off("loaded");
    report.on("loaded", () => {
      console.log("Loaded");
    });
    report.on("error", () => {
      this.getToken();
    });
    this.loading = false;
  }


  getToken(){
    this.powerbiService.getToken().subscribe(
      res => {
        this.token = res['resultado'];
      }
    )
  }
 
}
