import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable()
  //providedIn: "root",

export class PowerbiService {
  dados = {};
  url: string = environment.BASE_URL;

  constructor(private http: HttpClient) {}

  getToken() {
    return this.http.post(`${this.url}/powerbi/gerarToken`, {})
  }

  /*getToken(dados) {
    return this.http.post(`https://login.microsoftonline.com/common/oauth2/token`, dados)
  }*/
}
