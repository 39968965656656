import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SetorAtuacaoService } from '../../../../../service/configuracao/setor-atuacao.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-filtrar-setor-atuacao',
  templateUrl: './filtro.component.html',
  styleUrls: ['./filtro.component.scss'],
})
export class FiltrarSetorAtuacaoComponent implements OnInit {
  @Input() itemsPerView: number = 10;
  searchForm: FormGroup;
  searchParams = {};
  situacao = 'Situação';
  errorMessage = '';
  searchInput: string = '';
  loading: boolean = false;
  filter: string = '';
  debounce: Subject<string> = new Subject<string>();
  // data: Array<any> = null;
  visible = '';
  allowClearFilter = false;
  ativo: boolean = null;

  data: Object = {
    resultado: [],
    primeiraPagina: true,
    ultimaPagina: false,
    paginaAtual: 1,
    totalPaginas: 1,
    totalDeItems: 0
  };

  filterCurrent: Object = {
    total_pagina: 10,
    pagina: 1,
  }

  constructor(
    private fb: FormBuilder,
    private setorAtuacaoService: SetorAtuacaoService,
  ) {
    this.criarFormulario();
  }

  ngOnInit() {
    // this.loading = true;
    this.setorAtuacaoService
      .getSetorAtuacao(this.filterCurrent)
      .subscribe(res => {
        this.loading = false;
        console.log(res)
      }, 
      error => (this.errorMessage = error));
    this.configurarSubject();
  }

  getSetorAtuacao() {
    let parametros = {};

    parametros['pagina'] = 1
    // parametros['total_pagina'] = 10;

    this.setorAtuacaoService.getSetorAtuacao(parametros).subscribe(res => {
          this.data = res;
          
    }, error => {
      console.log(error)
    });
  }

  limpar() {
    this.situacao = 'Situação';
    this.ativo = null;
    this.searchParams = {};
    this.searchForm.reset();
    this.loading = true;
  }

  criarFormulario() {
    this.searchForm = this.fb.group({
      assunto: '',
    });
  }

  toggleVisibility(item: string) {
    if (item === this.visible) {
      this.visible = '';
    } else {
      this.visible = item;
    }
  }

  handleResponse(res) {
    this.allowClearFilter = false;
  }

  statusFilter(e: any) {
    const value = e.target.name;
    console.log('setando valor :: ', value);
    this.situacao = value;
    this.ativo = e.target.value;
  }

  inputEmpty() {
    if (
      this.searchInput === '' &&
      this.searchParams['descricao'] &&
      this.searchParams['descricao'].length > 0
    ) {
      delete this.searchParams['descricao'];
      this.search();
    }
  }

  onSearchByTitle() {
    this.searchParams['descricao'] = this.searchInput;
    this.search();
  }

  onSearchByStatus() {
    this.searchParams['ativo'] = this.ativo;
    this.searchParams['pagina'] = 1;
    this.search();
    this.visible = '';
  }

  getComunicadosFiltro(){
    var parametros = {};
    parametros['pagina'] = 1;
    // parametros['total_pagina'] = 10;
    this.setorAtuacaoService
      .getSetorAtuacao(parametros)
      .subscribe(
        res => {
          this.loading = false;
          this.data = res;
          this.data['paginaAtual'] = 1;
          this.data['totalPaginas'] = res.totalPaginas;
          this.data['primeiraPagina'] = res.primeiraPagina;
          this.data['ultimaPagina'] = res.ultimaPagina;
          this.handleResponse(res);
        },
        error => (this.errorMessage = error),
      );
  }

  limparFiltro(item) {
    if (item === 'situacao') {
      this.situacao = 'Situação';
      this.ativo = null;
      delete this.searchParams['ativo'];
    } 
    this.searchParams['pagina'] = 1;
    this.data['paginaAtual'] = 1;  
    this.search();
  }  

  setSearch() {
    this.searchParams['ativo'] = this.ativo;
    this.searchParams['pagina'] = 1;
    this.searchParams['total_pagina'] = this.itemsPerView;
    this.search();
  }

  // next() {
  //   if (!this.data['ultimaPagina']) {
  //     this.searchParams['pagina'] = this.data['paginaAtual'] + 1;
  //     this.search();
  //   }
  // }
  

  // previous() {
  //   if(!this.data['primeiraPagina']) {
  //     this.searchParams['pagina'] = this.data['paginaAtual'] - 1;
  //     this.search();
  //   }
  // }

  search() {
    if (this.searchParams["ativo"] === null) {
      delete this.searchParams["ativo"];
    }
  
    if (!this.searchParams["ativo"]) {
      this.situacao = "Situação";
      this.ativo = null;
    } else {
      this.allowClearFilter = true;
    }
    if (this.searchParams["ativo"] === null) {
      delete this.searchParams["ativo"];
    }

    this.visible = '';
    this.loading = true;
    this.searchParams['pagina'] = this.filterCurrent['pagina']; 
    this.searchParams['total_pagina'] = this.itemsPerView;
    this.setorAtuacaoService.getSetorAtuacao(this.searchParams).subscribe(data => {
        this.loading = false;
        this.data = data;
        console.log('getSituacaoPersonalidadeJuridica: ', data);
      });
  }

  configurarSubject() {
    this.debounce
      .pipe(debounceTime(500))
      .subscribe(filter => {
        this.filter = filter;
        this.onSearchByTitle();
      });
  }
}
