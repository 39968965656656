import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { SetorAtuacaoService } from "src/app/service/configuracao/setor-atuacao.service";
import { getSwal } from "src/app/utils/swalObject";
import Swal from "sweetalert2";

@Component({
    selector: 'app-incluir-setor-atuacao',
    templateUrl: './incluir-setor-atuacao.component.html',
    styleUrls: ['./incluir-setor-atuacao.component.scss']
})
export class IncluirSetorAtuacaoComponent implements OnInit{

    @ViewChild('codigo', {static: false}) codigo: ElementRef

    incluirSetorAtuacaoForm: FormGroup;
    isDescricaoValida: any;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private setorAtuacaoService: SetorAtuacaoService
    ) {}


    ngOnInit() {
        this.criarFormulario();
    }

    criarFormulario() {
        this.incluirSetorAtuacaoForm = this.formBuilder.group({
          codigo: ['', [Validators.minLength(1), Validators.required]],
          descricao: ['', [Validators.minLength(5), Validators.maxLength(60), Validators.required]]
        });
    
        this.incluirSetorAtuacaoForm.get('descricao').valueChanges.subscribe(value => {
          this.isDescricaoValida = value.trim() !== '';
        });
      }

    consultarCodigo(codigo) {
        if(codigo == null){
            this.codigo.nativeElement.focus();
        }else{
            this.setorAtuacaoService.consultaCodigo(codigo)
            .subscribe(
                res => {
                    if(res) {
                        Swal.fire(
                            getSwal(
                                'warning',
                                'Codigo já em uso! Favor tentar outro valor',
                                '',
                                'ok',
                                null
                            )
                        )
                        .then(() => {
                            this.incluirSetorAtuacaoForm.reset();
                            this.codigo.nativeElement.focus();
                        })
                    }
                }
            )
        }
    }

    incluir() {
        const obj = this.incluirSetorAtuacaoForm.getRawValue();
        this.setorAtuacaoService.incluirPersonalidadeJuridica(obj)
            .subscribe(res => {
                Swal.fire(
                    getSwal(
                        'success',
                        'Setor de Atuação criado com sucesso',
                        '',
                        'ok',
                        null
                    )
                ).then(() => this.router.navigate(['configuracao/setor-atuacao/consultar']));
            },
            err => {
                Swal.fire(
                    getSwal(
                        'error',
                        'Ops!',
                        err.error.excecao.recomendacao,
                        'ok',
                        null
                    )
                )
            }
        )
    }

    retornar() {
        this.router.navigate(['configuracao/setor-atuacao/consultar'])
    }
}