import { Component, OnInit, Output, EventEmitter, Input, AfterViewInit, AfterViewChecked } from '@angular/core';

@Component({
  selector: 'app-filtro-arquivados',
  templateUrl: 'filtro-arquivados.component.html',
  styleUrls: ['./filtro-arquivados.component.scss'],
})
export class FiltroArquivadosComponent implements OnInit, AfterViewChecked {
  @Output() onFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClear: EventEmitter<any> = new EventEmitter<any>();
  @Output() onOpen: EventEmitter<any> = new EventEmitter<any>();
  @Output() onPageUpdate: EventEmitter<number> = new EventEmitter<number>();

  arquivados: boolean = false;
  @Input()
  visible: string;

  obj: Object = {
    arquivados: false,
  };

  constructor() {}
  
  ngAfterViewChecked(): void {
    const sessaoFiltro = JSON.parse(sessionStorage.getItem('filtro'));
    
    if(sessionStorage.getItem('filtro') && sessaoFiltro.arquivados){
      this.arquivados = true;
    } else {
      this.arquivados = false;
    }
  }

  ngOnInit() {}

  limparFiltros() {
    this.onClear.emit(this.obj);
    this.arquivados = false;

    let obj = {};
      this.onFilter.emit(obj);
      this.onPageUpdate.emit(1);
    // this.visible = false;
  }

  pesquisarArquivados() {
    this.arquivados = true;
    // this.visible = false;
    this.obj['arquivados'] = true;
    this.onFilter.emit(this.obj);

    this.onFilter.emit(this.obj);
    this.onPageUpdate.emit(1);
  }

}