import { Component, OnInit } from '@angular/core';
import { RegistroAcoesService } from '../../../../../service/registro-acoes/registro-acoes.service'


@Component({
  selector: 'app-servicos-inoperantes',
  templateUrl: 'servicos-inoperantes.component.html',
  styleUrls: ['./servicos-inoperantes.component.scss']
})



export class ServicosInoperantes implements OnInit {

  servicesInactives: any;
  servicesInactivesLength: number;

  constructor(public registroAcoesService: RegistroAcoesService){}

  ngOnInit(){
       
    this.registroAcoesService.getServicosInativos().subscribe(data => {            
      this.servicesInactives = data.resultado;
      this.servicesInactivesLength = this.servicesInactives.length      
    })
    
  }
  
}
