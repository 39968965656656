import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PerfilDataService } from '../../../service/perfil/perfil.service'
import { FilterPipe } from '../../../pipes/filter.pipe'
import { UserService } from '../../../service/user-service/user.service';
import { LoginService } from '../../../service/login/login.service'
import { SessionService } from '../../../service/session/session.service';

@Component({
  selector: 'app-procuracao',
  templateUrl: 'procuracao.component.html',
  styleUrls: ['./procuracao.component.scss']
})

export class ProcuracaoComponent implements OnInit {
  procuracoes = []
  cliente: Object = {}
  procuracao: Object = {}
  searchForm: FormGroup;
  searchText = ''
  allow: boolean = false;
  userInfo: Object = {};
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private data: PerfilDataService,
    private userService: UserService,
    private sessionService: SessionService,
    private loginService: LoginService) {
  }

  ngOnInit() {
    this.criarFormulario();
    this.cliente = this.data.storage;
    this.userInfo = this.sessionService.getSession();
    this.loginService.procuracoes().subscribe(res => this.handleResponse(res), error => this.handleError(error))
  }

  handleResponse(res: any) {
    if (res['resultado'].length === 1) {
      this.procuracoes = res['resultado'];
      this.procuracao = res['resultado'][0];
      this.proximo();
    } else {
      this.procuracoes = res['resultado'];
      this.procuracoes.forEach(elem => {
        elem['selected'] = false;
      })
    }
  }

  handleError(error: any) {
    console.log(error)
  }

  criarFormulario() {
    this.searchForm = this.fb.group({
      prefeitura: ['', Validators.required]
    });
  }

  orderData(val: any) {
    if (val === 'ascendente') {
      let asc = this.procuracoes
      asc.sort(this.compare);
    } else {
      let desc = this.procuracoes;
      desc.sort(this.compare).reverse();
    }
  }

  compare(a, b) {
    const nomeA = a.nome.toUpperCase();
    const nomeB = b.nome.toUpperCase();

    let comparison = 0;
    if (nomeA > nomeB) {
      comparison = 1;
    } else if (nomeA < nomeB) {
      comparison = -1;
    }
    return comparison;
  }

  select(perfil: any) {
    perfil.selected = true;
    this.procuracao = perfil;
    this.procuracoes.map(elem => {
      if (elem.id !== perfil.id) elem['selected'] = false
    })
    this.allow = true;
  }


  desistir() {
    this.userService.sendInfo({ loggedIn: false })
    this.sessionService.clearSession();
    this.router.navigate(['/index']);
  }

  proximo() {
    let codigoProcuracao = this.procuracao['codigo'];
    this.loginService.atualizarSessao(codigoProcuracao).subscribe(res => {
      this.userService.sendInfo({ loggedIn: true, ...res.resultado })
      this.router.navigate(['/home']);
    }, error => {
      this.router.navigate(['/']);
    })

  }
}