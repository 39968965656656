import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subject, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';


import { environment } from '../../../environments/environment';

@Injectable()
export class RegistroAcoesService {

  private registros = new Subject<any>();
  registros$ = this.registros.asObservable();

  url: string = environment.BASE_URL;

  constructor(private http: HttpClient) { }

  getData(): Observable<any> {
    const perfisAcesso = this.http.get(`${this.url}/perfisAcesso`).pipe(map( res => res));
    const tiposEvento = this.http.get(`${this.url}/tiposEvento/usuarios/ativos`).pipe(map( res => res));
    return forkJoin([perfisAcesso, tiposEvento]);
  }

  getRegistrosdeAcoes(searchParams): Observable<any> {
    const params = this.setParams(searchParams);
    return this.http.get(`${this.url}/eventos/usuarios`, { params: params })
      .pipe(map( data => {
        const body = data;
        this.registros.next(body);
        return body;
      }));
  }

  getAtivacaoCadastro(codigoUsuario): Observable<any> {
    let params;
    if (codigoUsuario != null || codigoUsuario != undefined) {
      params = new HttpParams().set("codigoUsuario", codigoUsuario);
    }

    return this.http
      .get(`${this.url}/eventos/usuarios/ativacaoCadastro`, { params: params })
      .map((data) => {
        const body = data;
        return body;
      });
  }

  getRegistrosAtivos(): Observable<any> {
    return this.http.get(`${this.url}/servicos/totalAtivos`)
      .pipe(map( data => {
        const body = data;
        return body;
      }));
  }

  getServicosAtivos(): Observable<any>{
    return this.http.get(`${this.url}/servicos/ativos`)
      .pipe(map( data => {
        const body = data
        return body
      }));
  }

  getServicosInativos(): Observable<any>{
    return this.http.get(`${this.url}/servicos/inativos`)
      .pipe(map( data => {
        const body = data
        return body
      }));
  }

  getRegistrosInativos(): Observable<any> {
    return this.http.get(`${this.url}/servicos/totalInativos`)
      .pipe(map( data => {
        const body = data;
        return body;
      }));
  }

  getRegistrosAlertas(): Observable<any> {
    return this.http.get(`${this.url}/servicos/totalAlertas`)
      .pipe(map( data => {
        const body = data;
        return body;
      }));
  }

  getServicosAlertas(): Observable<any>{
    return this.http.get(`${this.url}/servicos/eventosRecentes`)
      .pipe(map( data => {
        const body = data
        return body
      }));
  }

  exportarRegistros(searchParams): Observable<any> {
    const params = this.setParams(searchParams);
    return this.http.get(`${this.url}/eventos/usuarios/exportar`, {
      params: params,
      responseType: 'arraybuffer'
    })
      .pipe(map( data => {
        const body = data;
        return body;
      }));
  }

  setParams(searchParams: any) {
    let params = new HttpParams();
    if (!searchParams) {
      return params;
    }
    if (searchParams.key && searchParams.value) {
      return params.set(searchParams.key, searchParams.value);
    }
    for (const key of Object.keys(searchParams)) {
      params = params.set(key, searchParams[key]);
    }
    return params;
  }

}
