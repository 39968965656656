
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { PerfisAcessoService } from '../../../../../service/configuracao/perfil-acesso.service';
import { Subscription } from 'rxjs';

import Swal from 'sweetalert2';
import { getSwal } from '../../../../../utils/swalObject';

@Component({
  selector: 'app-editar-perfil-acesso',
  templateUrl: 'editar-perfil-acesso.component.html',
  styleUrls: ['./editar-perfil-acesso.component.scss']
})

export class EditarPerfilAcessoComponent implements OnInit {
  routeSub: Subscription;
  editarPerfilAcessoForm: FormGroup;
  idPerfilAcesso:number = null;
  edicao:boolean = false;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private perfisAcessoService: PerfisAcessoService) { }

  ngOnInit() {
    this.criarFormulario();
    this.routeSub = this.route.params.subscribe(params => {
      if (params['id']) {
        this.idPerfilAcesso = params['id'];
      }
    });
    if(this.idPerfilAcesso) {      
      this.carregarDados();
    }
   }

   criarFormulario(){
     this.editarPerfilAcessoForm = this.fb.group({
       descricao: ['', Validators.required],
       ativo: [false, Validators.required]
     })
   }

   carregarDados(){
    this.perfisAcessoService.obterPerfilAcesso(this.idPerfilAcesso).subscribe(res => {
      const data = res.resultado;
      for (let key in data) {
        if(this.editarPerfilAcessoForm.controls[key]) {
          this.editarPerfilAcessoForm.controls[key].patchValue(data[key]);
        }
      }
    }, error => {
      // Swal.fire(
      //   'Ops!',
      //   'Ocorreu um erro e a forma de acesso não pôde ser carregada.',
      //   'error'
      // );
      Swal.fire(
        getSwal(
          'error',
          'Ops!',
          "Ocorreu um erro e a forma de acesso não pôde ser carregada.",
          'ok',
          null,
        ),
      );      
    });
   }

   isFormInvalid(){
     if(this.editarPerfilAcessoForm.valid) {
       return false;
     } else {
       return true;
     }
   }

   onSubmit() {
     const value = this.editarPerfilAcessoForm.getRawValue();
      this.perfisAcessoService.editaPerfilAcesso(value, this.idPerfilAcesso).subscribe(res => this.handleResponse(), error => this.handleError(error));
   }

   handleResponse(){
    // Swal.fire(
    //   'Sucesso!',
    //   'O perfil de acesso foi editado com sucesso.',
    //   'success'
    // )
    Swal.fire(
      getSwal(
        'success',
        'Sucesso!',
        "O perfil de acesso foi editado com sucesso.",
        'ok',
        null,
      ),
    );    
    this.router.navigate(['configuracao/perfis-acesso/consultar']);
   }

   handleError(error){
    // Swal.fire(
    //   'Ops!',
    //   'Ocorreu um erro e o perfil de acesso não pôde ser editado.',
    //   'error'
    // );
    Swal.fire(
      getSwal(
        'error',
        'Ops!',
        "Ocorreu um erro e o perfil de acesso não pôde ser editado.",
        'ok',
        null,
      ),
    );    
   }
}