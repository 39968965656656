import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-integracao',
  templateUrl: 'integracao.component.html'
})

export class IntegracaoComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}