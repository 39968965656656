import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CoresTiposComunicadosService } from '../../../../../service/configuracao/cores-tipo-comunicado.service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: '[app-filtrar-cores]',
  templateUrl: 'filtro.component.html',
  styleUrls: ['./filtro.component.scss']
})

export class FiltrarCoresComponent implements OnInit {
  _itemsPerView: any;
  @Input()
  set itemsPerView(value: any) {
    this._itemsPerView = value;
  }

  get itemsPerView(): any {
    return this._itemsPerView;
  }

  searchForm: FormGroup;
  errorMessage = '';
  searchInput: string = '';

  searchParams: Object = {
    pagina: 1,
    total_pagina: 10
  };

  data: Object = {
    resultado: [],
    primeiraPagina: true,
    ultimaPagina: false,
    paginaAtual: 1,
    totalPaginas: 1,
    totalDeItems: 0
  };

  loading: boolean = false;
  filter: string = '';
  debounce: Subject<string> = new Subject<string>();

  constructor(
    private fb: FormBuilder, 
    private coresService: CoresTiposComunicadosService
  ) {
    this.criarFormulario();
  }

  ngOnInit() {
    this.getCores();
    this.configurarSubject();
  }

  getCores() {
    this.loading = true;
    this.coresService
      .getCores({})
      .subscribe(
        res => {
          this.loading = false;
          this.search()
        }
      )
  }


  criarFormulario() {
    this.searchForm = this.fb.group({
      assunto: '',
    });
  }

  inputEmpty() {
    if (
      this.searchInput === '' &&
      this.searchParams['descricao'] &&
      this.searchParams['descricao'].length > 0
    ) {
      delete this.searchParams['descricao'];
      this.search();
    }
  }

  onSearchByTitle() {
    this.searchParams['descricao'] = this.searchInput;
    this.search();
  }

  setSearch() {
    this.searchParams['pagina'] = 1;
    this.data['paginaAtual'] = 1;
    this.search();
  }

  search() {
    this.searchParams['pagina'] = 1;
    this.data['paginaAtual'] = 1;
    this.loading = true;
    this.coresService
      .getCores(this.searchParams)
      .subscribe(res => {
        this.loading = false;
        this.data = res;
      });
  }

  next() {
    if(!this.data['ultimaPagina']) {
      this.searchParams['pagina'] = this.data['paginaAtual'] + 1;
      this.search();
    }
  }

  previous() {
    if(!this.data['primeiraPagina']) {
      this.searchParams['pagina'] = this.data['paginaAtual'] - 1;
      this.search();
    }
  }

  configurarSubject() {
    this.debounce
      .pipe(debounceTime(500))
      .subscribe(filter => {
        this.filter = filter;
        this.onSearchByTitle();
      });
  }
}
