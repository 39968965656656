import { AfterViewInit, Component, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { jqxTreeComponent } from "jqwidgets-ng/jqxtree";
import { OrganogramaService } from "src/app/service/organograma/organograma.service";
import Swal from "sweetalert2";

@Component({
    selector: 'app-incluir-organograma',
    templateUrl: './incluir-organograma.component.html',
    styleUrls: ['./incluir-organograma.component.scss']
})

export class IncluirOrganogramaComponent implements AfterViewInit {
    @ViewChild('myTree', {static: true}) myTree: jqxTreeComponent;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private organogramaService: OrganogramaService) {
    }
    hidden: boolean = true;
    resultado: Array<any> = [];
    filhos: Array<any> = [];
    encontrado: Array<any> = [];
    obj: any = {}
    isFirstClick: boolean = true;
    loading = false;
    incluirOrganogramaForm = this.formBuilder.group({
        nome: ['', Validators.compose([Validators.required, Validators.minLength(5)])],
        novoItem: ['', Validators.compose([Validators.minLength(5), Validators.maxLength(60)])]


    })

    ngAfterViewInit(): void {
        // this.loadData();
    }

    data: any[] = []
    source = {
        datatype: 'json',
        datafields: [
            { name: 'id' },
            { name: 'parentid' },
            { name: 'text' }
        ],
        id: 'id',
        localdata: null
    };

    dataAdapter = new jqx.dataAdapter(this.source);

    records: any = this.dataAdapter.getRecordsHierarchy('id', 'parentid', 'items', [{ name: 'text', map: 'label' }]);

    AddOnClick(): void {
        let selectedItem = this.myTree.getSelectedItem();
        if(selectedItem == null && this.data.length == 0) {
            this.myTree.addTo({ label: this.incluirOrganogramaForm.controls.novoItem.value, id: this.data.length + 1}, null);
            this.data.push({'id': this.data.length + 1, 'text': this.incluirOrganogramaForm.controls.novoItem.value, 'parentid': null});
            this.myTree.render();
        }
        else if(selectedItem != null && this.incluirOrganogramaForm.controls.novoItem.value.length >= 5) {
            this.myTree.addTo({ label: this.incluirOrganogramaForm.controls.novoItem.value, id: this.data.length + 1}, selectedItem.element);
            this.data.push({'id': this.data.length + 1, 'text': this.incluirOrganogramaForm.controls.novoItem.value, 'parentid': selectedItem['id']});
            this.myTree.render();
        } else if(selectedItem == null) {
            Swal.fire({
                title: 'Selecione um item antes de adicionar',
                icon: 'warning',
                confirmButtonText: 'OK'
            })
        } else if(this.incluirOrganogramaForm.controls.novoItem.value.length <= 5) {
            Swal.fire({
                title: 'Nome do item deve ter mais de 5 letras',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    };

    RemoveOnClick(): void {
        let selectedItem = this.myTree.getSelectedItem();
        if(selectedItem != null) {
            this.resultado = this.data.filter(element => element.parentid == selectedItem.element.id);
            let elemento = this.data.filter(element => element.id == selectedItem.element.id);
            for(let i = 0; i < this.data.length; i++) {
                if(elemento[0].id == this.data[i].id) {
                    this.data.splice(i, 1);
                }
            }
            if(this.resultado) {
                this.filhos = this.filhos.concat(this.resultado);
                this.resultado = [];
                for(let i = 0; i < this.filhos.length; i++) {
                    this.resultado = this.data.filter(element => element.parentid == this.filhos[i].id);
                    this.filhos = this.filhos.concat(this.resultado);
                    this.resultado = [];
                }
                for(let i = 0; i < this.filhos.length; i++) {
                    for(let j = 0; j < this.data.length; j++) {
                        if(this.filhos[i].id == this.data[j].id) {
                            this.data.splice(j, 1);
                        }
                    }
                }
                this.resultado = [];
                this.filhos = [];
            }

            this.myTree.removeItem(selectedItem.element);
        }
    }

    toggleFunction() {
      if (this.isFirstClick) {
        this.loadData();
        this.isFirstClick = false;
      } else {
        this.salvar();
      }
    }

    ExpandAllOnClick(): void {
        this.myTree.expandAll();
    }

    CollapseOnClick(): void {
        this.myTree.collapseAll();
    }

    dragStart(item): boolean {
        return true;
    }

    dragEnd(item, dropItem, args, dropPosition, tree): boolean {
        for(let i = 0; i < this.data.length; i++) {
            if(this.data[i].id == item.owner.owner.selectedItem.id) {
                this.data[i].parentid = item.owner.owner.selectedItem.parentId;
            }
        }

        return true;
    }

    ajudaTitulo() {
        Swal.fire({
            text: 'Digite o título para disponibilizar o organograma',
            icon: 'info',
            confirmButtonText: 'OK'
        })
    }

    ajuda() {
        Swal.fire({
            text: 'Digite o nome do item a ser criado e selecione um item no organograma antes de adicionar.',
            icon: 'info',
            confirmButtonText: 'OK'
        })
    }

    isFormInvalid(){
        if(this.incluirOrganogramaForm.valid) {
        return false;
        } else {
        return true;
        }
    }

    loadData() {
        this.hidden = false;
        if(this.incluirOrganogramaForm.controls.nome.value) {
            this.data = [
                {
                    'id': 1,
                    'text': this.incluirOrganogramaForm.controls.nome.value,
                    'parentid': null
                }
            ]
            this.source.localdata = [
                {
                    'id': 1,
                    'text': this.incluirOrganogramaForm.controls.nome.value,
                    'parentid': null,
                }
            ]
            this.dataAdapter = new jqx.dataAdapter(this.source, { autoBind: true});
            this.records = this.dataAdapter.getRecordsHierarchy('id', 'parentid', 'items', [{ name: 'text', map: 'label' }]);
            this.myTree.refresh();
        }
    }

    desistir() {
        Swal.fire({
            text: 'Tem certeza que deseja desistir? Todos os dados serão perdidos',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Não',
            confirmButtonText: 'Sim'
        }).then(res => {
            if(res.value) {
                this.router.navigate(['/configuracao/ambiente/consultar-organograma/1'])
            }
        });
    }

    salvar() {
        this.loading = true;
        if(this.data.length > 0) {
            for(let i = 0; i < this.data.length; i++) {
                if(this.data[i].text.length <= 5) {
                    this.loading = false;
                    console.log("load", this.loading)
                    Swal.fire({
                        title: 'Os campos precisam ter mais de 5 caracteres',
                        icon: 'error',
                        confirmButtonText: 'OK'
                    });
                    return
                }
            }
            this.obj = this.data;
            this.organogramaService.addOrganograma(this.obj).subscribe(
                res => {
                    this.loading = false;
                    Swal.fire({
                        title: 'Alterações salvas!',
                        icon: 'success',
                        confirmButtonText: 'OK'
                    })
                    .then(res => {
                        this.router.navigate(['/configuracao/ambiente/consultar-organograma/1']);
                    });
                },
                err => {
                    this.loading = false;
                    Swal.fire({
                        title: 'Ops!',
                        text: 'Não possível salvar o organograma',
                        icon: 'error',
                        confirmButtonText: 'OK'
                    });
                }
            )
        } else {
            this.loading = false;
            Swal.fire({
                title: 'Ops!',
                text: 'O organograma deve conter pelo menos 1 item',
                icon: 'error',
                confirmButtonText: 'OK'
            })
        }
    }

}
