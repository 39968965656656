import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

@Injectable()
export class WizardService {
  public userData:any = 
    {
      infoBasica: {},
      representanteLegal: {},
      procurador: {},
      prefeituras: [],
      tipo: '',
      perfil: ''
    }
  

  private index = new Subject<any>();
  private data = new Subject<any>();
  
  index$ = this.index.asObservable();
  data$ = this.data.asObservable();

  constructor() { }

  updateIndex(idx:any) {
    this.index.next(idx)
  }

  resetData(){
    this.userData = {
      infoBasica: {},
      representanteLegal: {},
      procurador: {},
      prefeituras: []
    }
  }

}