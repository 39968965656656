import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AplicacoesService } from '../../../../../service/aplicacoes/aplicacoes.service';
import { gerenciarSistemasRouterPath } from '../../../../../../environments/version';
import Swal from 'sweetalert2';
import { getSwal } from '../../../../../utils/swalObject';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-consultar-aplicacoes',
  templateUrl: './consultar-aplicacoes.component.html',
  styleUrls: ['./consultar-aplicacoes.component.scss']
})
export class ConsultarAplicacoesComponent implements OnInit {
  @ViewChild('childFilter', {static: false}) child;

  errorMessage = '';
  list: Array<any> = [];
  aplicacoes: Array<any> = [];
  data: Array<any> = [];
  subscription: Subscription;
  searchParams: any;
  itemsPerView: number = 10;
  paginaAtual: number = 1;
  totalPaginas: number = 1;
  aplicacaoSelecionada: Array<any> = [];
  totalDeItems: 0;
  loading: boolean = false;
  numeroAplicacoes: string = "10";
  requests: any = [];
  filterCurrent: Object = {
    total_pagina: 10,
    pagina: 1
  }
  
  constructor(
    private router: Router,
    private aplicacoesService: AplicacoesService
  ) {
    this.data = [];
  }

  ngOnInit() {
    this.loading = true;
    this.aplicacoesService.aplicacoes$.subscribe(res => {
      this.loading = false;
      if(res.tamanhoPagina) {
        this.data = res;
        this.aplicacoes = res['resultado'];
        this.data['totalDeItems'] = res.totalRegistros;
        this.totalPaginas = res['totalPaginas'];
        this.filterCurrent = this.child.searchParams;
      }       
    });
    this.getAplicacoes(this.filterCurrent);
  }

  getAplicacoes(params) {
    this.aplicacoesService.getAplicacoes(params).subscribe(res => {
      this.data = res;
      this.aplicacoes = res['resultado'];
    });
  }

  quantidadeAplicacoes(numeroAplicacoes){

    this.itemsPerView = +numeroAplicacoes;

    this.data['paginaAtual'] = 1;
    this.paginaAtual = 1;

    this.onPageChange(this.filterCurrent);
    
    switch (numeroAplicacoes) {
      case "10":
        this.data['itemsPorPagina'] = 10;
        console.log(this.data['itemsPorPagina']);
        break;
      case "20":
        this.data['itemsPorPagina'] = 20;
        console.log(this.data['itemsPorPagina']);
        break;
      case "50":
        this.data['itemsPorPagina'] = 50;
        console.log(this.data['itemsPorPagina']);
        break;
      case "100":
        this.data['itemsPorPagina'] = 100;
        console.log(this.data['itemsPorPagina']);
        break;
    }
    this.loading = true;
  }

  next() {
    this.data['paginaAtual']++;
    // this.getAplicacoes(this.data);
    this.onPageChange(this.filterCurrent);
  }

  previous() {
    this.data['paginaAtual']--;
    // this.getAplicacoes(this.data);
    this.onPageChange(this.filterCurrent);
  }

  primeiraPagina() {
    this.data['paginaAtual'] = 1;
    // this.getAplicacoes(this.data);
    this.onPageChange(this.filterCurrent);
  }

  ultimaPagina() {
    this.data['paginaAtual'] = this.data['totalPaginas'];
    // this.getAplicacoes(this.data);
    this.onPageChange(this.filterCurrent);
  }

  onPageChange(value) {
    value['pagina'] = this.data['paginaAtual'];
    value['total_pagina'] = this.itemsPerView;

    this.filterCurrent = value;
    this.loading = true;

    this.aplicacoesService.getAplicacoes(value).subscribe(
      res => {
        this.aplicacoes = res['resultado'];
        this.loading = false;
      },
      error => {
        this.loading = false;
        //this.aplicacoes = [];
      }
    )
  }

  endpoint(apps) {
    this.router.navigateByUrl(`${gerenciarSistemasRouterPath}/editar/${
          apps.codigo
        }/endpoints/consultar`
      ,
      {state: ['editar', apps]
    });
  }

  funcionalidades(apps) {
    this.router.navigateByUrl(`${gerenciarSistemasRouterPath}/editar/${
          apps.codigo
        }/funcionalidade/consultar`
      ,
      {state: ['editar', apps]
    });
  }

  consumos(apps) {
    this.router.navigateByUrl(`${gerenciarSistemasRouterPath}/editar/${apps.codigo}/consumo-servicos`,
      {state: ['editar', apps]});
  }

  

  incluir() {
    this.router.navigateByUrl(`${gerenciarSistemasRouterPath}/editar/novo`, {state: [
        'definir',
        {
          ativo: true,
          cliente: null,
          codigo: null,
          consumos: [],
          dataCriacao: null,
          dataInativacao: null,
          descricao: '',
          detalhe: {
            situacao: 0,
            titulo: '',
            funcionalidade: '',
            icone: 0,
            url: '',
            acessoRestrito: true
          },
          endpoints: [],
          funcionalidades: [],
          senha: null,
          urlServicos: null,
          usoInterno: false
        }
      ]
    });
  }

  edit(apps) {
    this.router.navigateByUrl(`${gerenciarSistemasRouterPath}/editar/${apps.codigo}`,
      {state: ['editar', apps]});
  }

}
