import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Address } from '../../model/cep.model';
import { environment } from '../../../environments/environment';

@Injectable()
export class CepService {
  url: string = environment.BASE_URL;
  constructor(private http: HttpClient) {}

  getAddressByZipCode(zipcode: string): Observable<Address> {
    const InterceptorSkipHeader = 'X-Skip-Interceptor';
    const headers = new HttpHeaders().set(InterceptorSkipHeader, '');
    return this.http.get<Address>(`https://viacep.com.br/ws/${zipcode}/json/`, {
      headers,
    });
  }

  getCep(cep: string): Observable<any> {
    return this.http.get(`${this.url}/cep/${cep}`).pipe(map(data => {
      return data;
    }));
  }
}
