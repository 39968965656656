import { Component, OnInit, Input, Output, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CepService } from '../../../../../service/cep/cep.service';
import { GerenciarUsuariosService } from '../../../../../service/configuracao/gerenciar-usuarios.service';
import Swal from 'sweetalert2';
import { getSwal } from '../../../../../utils/swalObject';
import { AmbienteService } from '../../../../../service/ambiente/ambiente.service';
import { Router } from '@angular/router';
import { ConsultaUsuarioService } from 'src/app/service/consulta-usuario/consultar.user.service';
import { WizardService } from 'src/app/service/wizard/wizard.service';
import { CadastroService } from 'src/app/service/cadastro/cadastro.service';

@Component({
  selector: 'app-formulario-contribuinte',
  templateUrl: 'formulario-contribuinte.component.html',
  styleUrls: ['./formulario-contribuinte.component.scss']
})

export class FormularioContribuinteComponent implements OnInit, AfterViewInit {
  @Input() view = 0;
  // @Output() data=[];
  data: Array<any> = [];
  // data = {}
  contribuinteForm:FormGroup;
  pessoaFisica: boolean = true;
  //situacaoUsuario: any;
  loading:boolean = false;
  title:string = 'Criar Contribuinte'
  nomeRepresentanteLegal = null;
  emailRepresentanteLegal = null;
  celularRepresentanteLegal = null;
  cadastroIntegrado:boolean = false
  exibirPopUp: boolean = false;
  perfilContribuinte = false;
  cadastroContribuinte = false;
  setorAtuacao: any;
  personalidadeJuridica: any;
  dadosCliente: any;
  codigoCliente: any;
  exigeCertificadoCnpj: any;
  servicoSms: number;

  telefoneCelular: any;
  cnpjContribuinte:any;
  cpfRepresentanteLegal:any;
  // data = []

  public semMask = [/\d/, /\d/, /\d/, /\d/, /\d/];
  public menasMask = [
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ];
  public cpfMask = [
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/
  ];
  public cnpjMask = [
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '/',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/
  ];

  constructor(
    private fb: FormBuilder, private cepService: CepService,
    private gerenciarUsuariosService: GerenciarUsuariosService,
    private router: Router,
    private consultaUsuario: ConsultaUsuarioService,
    private wizardService: WizardService,
    private ambienteService: AmbienteService,
    private cadastroService: CadastroService
  ) { }

  ngOnInit() {
    this.verificarCertificadoCnpj();
    this.criarFormulario();
    this.data = this.wizardService.userData;
    this.obterConfiguracoesCliente();
    this.setorAtuacaoAtivos();
    this.personalidadesJuridicasAtivos();
  }
  ngAfterViewInit() {
    this.buscaApi();

  }

  ajuda() {
    Swal.fire({
      html:'Se estiver em Sim, o usuário, assim como os comunicados trocados por este, não irão ser contabilizados em relatórios e consultas.',
      confirmButtonText:'Fechar'
    })
  }

  criarFormulario() {

    this.contribuinteForm = this.fb.group({
      pessoaFisica: [true, Validators.required],
      cnpjContribuinte: ['', Validators.compose([Validators.required, Validators.minLength(11), Validators.maxLength(11)])],
      inscricao: [''],
      razaoSocialContribuinte: ['', Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(160)])],
      telefoneCelular: ['', Validators.compose([Validators.required, Validators.minLength(11)])],
      email: ['', Validators.compose([Validators.required, Validators.maxLength(60)])],
      comboPersonalidadeJuridicaContribuinte: ["0"],
      comboSetorAtuacaoContribuinte: ["0"],
      personalidadeJuridicaContribuinte:{codigo:''},
      setorAtuacaoContribuinte:{codigo:''},
      cpfRepresentanteLegal: '',
      nomeRepresentanteLegal: '',
      emailRepresentanteLegal: '',
      celularRepresentanteLegal: '',
      situacaoUsuario: [0, Validators.required],
      comboPersonalidadeJuridicaRepresentanteLegal: [""],
      comboSetorAtuacaoRepresentanteLegal: [""],
      personalidadeJuridicaRepresentante:{codigo:''},
      setorAtuacaoRepresentante:{codigo:''}
    });
  }
  
  buscaApi() {

    this.gerenciarUsuariosService.getAutoCadastro().subscribe(
      res => {
        console.log('dados:', res.resultado);
        this.cadastroIntegrado = res.resultado;
      },
      error => {
        Swal.fire(
          getSwal(
            'error',
            'Ops!',
            'Ocorreu um erro e o Ambiente não pôde ser carregado.',
            'ok',
            null
          )
        );
      }
    );
  }

  handleErrors(error) {
    this.loading = false;
  }

  adicionarUsuario(){
    this.loading = true;
    let obj = this.contribuinteForm.getRawValue();

    obj.setorAtuacaoContribuinte.codigo = obj['comboSetorAtuacaoContribuinte'];
    obj.personalidadeJuridicaContribuinte.codigo = obj['comboPersonalidadeJuridicaContribuinte'];
    
    

    obj['dddTelefoneCelular'] = obj['telefoneCelular'].substring(0, 2);
    obj['telefoneCelular'] = obj['telefoneCelular'].substr(2, 9);

    obj['cpfRepresentanteLegal'] = obj['cpfRepresentanteLegal'].replace(/[^\d]+/g, "");

    if(this.exigeCertificadoCnpj == 0) {
      obj.setorAtuacaoContribuinte.codigo = "0";
      obj.personalidadeJuridicaContribuinte.codigo = "0";
    }

    if (!this.pessoaFisica) {
      obj['dddCelularRepresentanteLegal'] = obj['celularRepresentanteLegal'].substring(0, 2);
      obj['celularRepresentanteLegal'] = obj['celularRepresentanteLegal'].substr(2, 9);

      obj.personalidadeJuridicaRepresentante.codigo = obj['comboPersonalidadeJuridicaRepresentanteLegal'];
      obj.setorAtuacaoRepresentante.codigo = obj['comboSetorAtuacaoRepresentanteLegal'];
      
      if(this.exigeCertificadoCnpj == 0) {
        obj.personalidadeJuridicaRepresentante.codigo = "0";
        obj.setorAtuacaoRepresentante.codigo = "0";
      }

    }
    this.gerenciarUsuariosService.adicionarContribuinte(obj).subscribe(res => {
      this.loading = false;
      // Swal('Sucesso!', 'Usuário criado com êxito', 'success')
      Swal.fire(
        getSwal(
          'success',
          'Sucesso!',
          "Usuário criado com êxito",
          'ok',
          null,
        ),
      ).then(res => {
        this.router.navigate(['gestao-usuario/consultar']);
      });
    }, err => {
      this.loading = false;
      // Swal('Ops!', err.error.excecao.recomendacao, 'error')
      Swal.fire(
        getSwal(
            'error',
            err.error.excecao.recomendacao,
            '',
            'ok',
            null,
        ),
      );
    })
  }

  consultarUsuario(id: string) {
    console.log("erro ", this.contribuinteForm)
    if (this.cadastroIntegrado === true) {
      this.gerenciarUsuariosService.getApiExterna(id).subscribe(async res => {
        if (res.resultado.length > 1) {
          this.exibirPopUp = true;
          this.data = res;
          var codigo = 1
          this.data['resultado'].map(item => {
            item['codigo'] = codigo;
            item['checked'] = false;
            codigo++;
          });
        } else {
          this.contribuiteSelecionado(res['resultado'])
        }
      }
        , error => {
          Swal.fire(
            getSwal(
              'error',
              error.error.excecao.recomendacao,
              '',
              'ok',
              null
            )
          );
        }
      );

    } else {

      if (this.contribuinteForm.controls.cnpjContribuinte.valid) {
        this.loading = true;

        if (this.pessoaFisica) {
          this.consultaUsuario.consultarUsuario(id).subscribe(
            (res) => {
              this.loading = false;
              let data = res.resultado;
              this.wizardService.userData["id"] = data.cpfCnpj;
              this.wizardService.userData["codigo"] = data.codigo;
              const docs = this.transformDoc(data.cpfCnpj);

              let perfis = res.resultado.perfis;

              perfis.forEach((element) => {
                if (element.codigo === 2) {
                  this.perfilContribuinte = true;
                  return;
                } else {
                  this.perfilContribuinte = false;
                }
              });

              if (this.perfilContribuinte) {
                // contribuinte === 2

                Swal
                  .fire(
                    getSwal(
                      "success",
                      "Usuário já cadastrado",
                      "Você já está cadastrado em nosso sistema, defina sua senha para acessá-lo",
                      "ok",
                      null
                    )
                  )
                  .then((res) => {
                    this.router.navigate(["gestao-usuario/consultar"]);
                  });
              } else if (data.situacao == 2) {
                // contribuinte === 2

                Swal
                  .fire(
                    getSwal(
                      "error",
                      "Usuário Inativo",
                      "Você já está cadastrado em nosso sistema, mas está com acesso inativo, favor solicitar apoio ao administrador",
                      "ok",
                      null
                    )
                  )
                  .then((res) => {
                    this.router.navigate(["gestao-usuario/consultar"]);
                  });
              } else {
                // procurador === 1
                Swal
                  .fire(
                    getSwal(
                      "success",
                      `CPF ${docs} já cadastrado`,
                      "Você já está cadastrado como Procurador. Deseja também ter um cadastro de Contribuinte ?",
                      "Sim",
                      "Não"
                    )
                  )
                  .then((res) => {
                    if (res.value) {
                      this.cadastroContribuinte = true;
                      setTimeout(() => {
                        if (data.nomeRazaoSocial) {
                          this.contribuinteForm.controls.razaoSocialContribuinte.setValue(
                            data.nomeRazaoSocial
                          );
                          this.contribuinteForm.controls[
                            "razaoSocialContribuinte"
                          ].disable();
                        }
                        if (data.detalhe.dddTelefoneCelular & data.detalhe.telefoneCelular) {
                          this.contribuinteForm.controls.telefoneCelular.setValue(
                            data.detalhe.dddTelefoneCelular + data.detalhe.telefoneCelular
                          );
                          this.contribuinteForm.controls["telefoneCelular"].disable();
                        }
                        if (data.detalhe.email) {
                          this.contribuinteForm.controls.email.setValue(data.detalhe.email);
                          this.contribuinteForm.controls["email"].disable();
                        }
                      }, 150);
                    } else {
                      setTimeout(() => {
                        this.router.navigate(["gestao-usuario/consultar"]);
                      }, 150);
                    }
                  });
              }
            },
            (error) => {
              if (error.error.excecao.codigo && error.error.excecao.codigo == 'E000') {
                Swal.fire(
                  getSwal(
                    'error',
                    '',
                    error.error.excecao.recomendacao,
                    'ok',
                    null
                  )
                )
              }
              this.loading = false;
            }
          );
        } else {
          this.consultaUsuario.consultarUsuario(id).subscribe(
            (res) => {
              this.loading = false;
              let data = res.resultado;
              this.wizardService.userData["id"] = data.cpfCnpj;
              this.wizardService.userData["codigo"] = data.codigo;
              if (data.situacao === 0) {
                this.contribuinteForm.patchValue({
                  cnpjContribuinte: data.cpfCnpj,
                  razaoSocialContribuinte: data.nomeRazaoSocial,
                  telefoneCelular:
                    data.detalhe &&
                      data.detalhe.dddTelefoneCelular &&
                      data.detalhe.telefoneCelular
                      ? data.detalhe.dddTelefoneCelular
                        .replace(/\D+/g, "")
                        .concat(
                          data.detalhe.telefoneCelular.replace(/\D+/g, "")
                        )
                      : "",
                  email:
                    data.detalhe && data.detalhe.email
                      ? data.detalhe.email
                      : "",
                });
              } else {
                const docs = this.transformDoc(data.cpfCnpj);
                Swal
                  .fire(
                    getSwal(
                      "success",
                      `Contribuinte ${docs} - ${data.nomeRazaoSocial} já cadastrado`,
                      "Favor acessar com Certificado Digital ou através de seu representante legal.",
                      "ok",
                      null
                    )
                  )
                  .then((res) => {
                    this.router.navigate(["gestao-usuario/consultar"]);
                  });
              }
            },
            (error) => {
              if (error.error.excecao.codigo && error.error.excecao.codigo == 'E000') {

                Swal.fire(
                  getSwal(
                    'error',
                    '',
                    error.error.excecao.recomendacao,
                    'ok',
                    null
                  )
                )
              }
              this.loading = false;
            }
          );
        }
      }
    }
  }

  consultarRepresentanteLegal(cpfCnpj) {
    this.loading = true;
      this.consultaUsuario.consultarUsuario(cpfCnpj).subscribe(res => {
        this.loading = false;
        const data = res['resultado']; 

        this.contribuinteForm.controls.nomeRepresentanteLegal.setValue(data.nomeRazaoSocial);
        this.contribuinteForm.controls.nomeRepresentanteLegal.updateValueAndValidity();
        this.contribuinteForm.controls.nomeRepresentanteLegal.disable();
  
        this.contribuinteForm.controls.emailRepresentanteLegal.setValue(data.email);
        this.contribuinteForm.controls.emailRepresentanteLegal.updateValueAndValidity();
        this.contribuinteForm.controls.emailRepresentanteLegal.disable();
  
        this.contribuinteForm.controls.celularRepresentanteLegal.setValue(data.ddd + data.celular);
        this.contribuinteForm.controls.celularRepresentanteLegal.updateValueAndValidity();
        this.contribuinteForm.controls.celularRepresentanteLegal.disable();
        
        this.contribuinteForm.controls.comboPersonalidadeJuridicaRepresentanteLegal.setValue(data.detalhe.personalidadeJuridica.codigo);
        this.contribuinteForm.controls.comboPersonalidadeJuridicaRepresentanteLegal.updateValueAndValidity();
        this.contribuinteForm.controls.comboPersonalidadeJuridicaRepresentanteLegal.disable();
        this.contribuinteForm.controls.comboSetorAtuacaoRepresentanteLegal.setValue(data.detalhe.setorAtuacao.codigo);
        this.contribuinteForm.controls.comboSetorAtuacaoRepresentanteLegal.updateValueAndValidity();
        this.contribuinteForm.controls.comboSetorAtuacaoRepresentanteLegal.disable();
        
      }, error => {
        this.loading = false;
        if(error.error.excecao.recomendacao !== '') {
          if(this.contribuinteForm.controls.cpfRepresentanteLegal.valid) {
            Swal.fire(
              getSwal(
                'error',
                '',
                error.error.excecao.recomendacao,
                'ok',
                null
              )
            );
            this.contribuinteForm.controls.cpfRepresentanteLegal.setErrors({invalido: true})
          }
          this.habilitarRepLegal();
        } else {
          this.contribuinteForm.controls.cpfRepresentanteLegal.setErrors(null)
          this.habilitarRepLegal();
        }
      })
  }

  habilitarRepLegal() {
    this.contribuinteForm.controls.nomeRepresentanteLegal.enable();
    this.contribuinteForm.controls.emailRepresentanteLegal.enable();
    this.contribuinteForm.controls.celularRepresentanteLegal.enable();
    this.contribuinteForm.controls.comboPersonalidadeJuridicaRepresentanteLegal.enable();
    this.contribuinteForm.controls.comboSetorAtuacaoRepresentanteLegal.enable();
  }

  transformDoc(value: any): any {
    if (value && value.length === 11) {
      return `${value.substr(0, 3)}.${value.substr(3, 3)}.${value.substr(
        6,
        3
      )}-${value.substr(9, 2)}`;
    } else if (value && value.length === 14) {
      return `${value.substr(0, 2)}.${value.substr(2, 3)}.${value.substr(
        5,
        3
      )}/${value.substr(8, 4)}-${value.substr(12, 2)}`;
    }

    return value;
  }

  fecharPopUp(event) {
    this.exibirPopUp = event;
  }

  contribuiteSelecionado(event){
    this.exibirPopUp = false;
    event = event[0];
    console.log('event retornado: ', event);
    this.contribuinteForm.patchValue({
    razaoSocialContribuinte: event.razaoSocialContribuinte,
    email: event.email,
    telefoneCelular: event.dddTelefoneCelular + event.telefoneCelular,

    cpfRepresentanteLegal: event.cpfRepresentanteLegal,
    celularRepresentanteLegal: event.dddCelularRepresentanteLegal + event.celularRepresentanteLegal,
    emailRepresentanteLegal: event.emailRepresentanteLegal,
    nomeRepresentanteLegal: event.nomeRepresentanteLegal

    });

  }



  contribuiteInterno(event){
    this.exibirPopUp = false;
    event = event[0];
    // console.log('event retornado: ', event);
    this.contribuinteForm.patchValue({
    razaoSocialContribuinte: event.nomeRazaoSocial,
    email: event.email,
    telefoneCelular: event.dddTelefoneCelular + event.telefoneCelular,

    });

    if(event.detalhe){

    this.contribuinteForm.patchValue({
      emailComercial: event.detalhe.emailComercial,
      telefoneCelular: event.detalhe.dddTelefoneCelular + event.detalhe.telefoneCelular,
      });

    }
  }

  isTelefone(): boolean{
    return this.telefoneCelular == null ? true : this.telefoneCelular.length < 11 ? true : false;
  }

  getTelefone(): string{
      return this.isTelefone() ? '(00) 0000-00009' : '(00) 00000-0000';
  }

  tornarCamposObrigatorios() {
    for (let key in this.contribuinteForm.controls) {
      if (
        key !== "pessoaFisica" &&
        key !== "situacaoUsuario" &&
        key !== "personalidadeJuridicaContribuinte" &&
        key !== "setorAtuacaoContribuinte" &&
        key !== "personalidadeJuridicaRepresentante" &&
        key !== "setorAtuacaoRepresentante"
      ) {
        this.contribuinteForm.controls[key].reset();
      }
    }

    if (this.pessoaFisica == false) {
      //REPRESENTANTE LEGAL
      this.contribuinteForm.controls.cpfRepresentanteLegal.setValidators(
        Validators.compose([
          Validators.required,
          Validators.minLength(14),
          Validators.maxLength(18),
        ])
      );
      this.contribuinteForm.controls.cpfRepresentanteLegal.updateValueAndValidity();

      this.contribuinteForm.controls.nomeRepresentanteLegal.setValidators(
        Validators.compose([
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(160)
        ])
      );
      this.contribuinteForm.controls.nomeRepresentanteLegal.updateValueAndValidity();

      this.contribuinteForm.controls.emailRepresentanteLegal.setValidators(Validators.required);
      this.contribuinteForm.controls.emailRepresentanteLegal.updateValueAndValidity();

      this.contribuinteForm.controls.celularRepresentanteLegal.setValidators(Validators.required);
      this.contribuinteForm.controls.celularRepresentanteLegal.updateValueAndValidity();

      //CONTRIBUINTE
      this.contribuinteForm.controls.cnpjContribuinte.setValidators(
        Validators.compose([Validators.required, Validators.minLength(14)])
      );
      this.contribuinteForm.controls.comboPersonalidadeJuridicaContribuinte.setValue("");
      this.contribuinteForm.controls.comboPersonalidadeJuridicaContribuinte.updateValueAndValidity();

      this.contribuinteForm.controls.comboSetorAtuacaoContribuinte.setValue("");
      this.contribuinteForm.controls.comboSetorAtuacaoContribuinte.updateValueAndValidity();

    } else {
      for (let key in this.contribuinteForm.controls) {
        if(
          key == "cpfRepresentanteLegal" ||
          key == "nomeRepresentanteLegal" ||
          key == "emailRepresentanteLegal" ||
          key == "celularRepresentanteLegal" ||
          key == "comboPersonalidadeJuridicaRepresentanteLegal" || 
          key == "comboSetorAtuacaoRepresentanteLegal"
        ){
          this.contribuinteForm.controls[key].setValidators([]);
          this.contribuinteForm.controls[key].updateValueAndValidity();
        }
      }
      this.contribuinteForm.controls.cnpjContribuinte.setValidators(
        Validators.compose([
          Validators.required,
          Validators.minLength(11),
          Validators.maxLength(11),
        ])
      );
      this.contribuinteForm.controls.cnpjContribuinte.updateValueAndValidity();
      this.contribuinteForm.controls.comboPersonalidadeJuridicaContribuinte.setValue(0);
      this.contribuinteForm.controls.comboPersonalidadeJuridicaContribuinte.updateValueAndValidity();

      this.contribuinteForm.controls.comboSetorAtuacaoContribuinte.setValue(0);
      this.contribuinteForm.controls.comboSetorAtuacaoContribuinte.updateValueAndValidity();
    }
  }

  mask(): any {
    if(this.pessoaFisica) {
      return "000.000.000-00";
    } else {
      return "00.000.000/0000-00";
    }
  }

  maskRepresentante() {
    return {
      mask: value => {
        value = value.replace(/[^\d]+/g, '');
        if (value.length <= 5) {
          return this.semMask;
        } else if (value.length <= 9) {
          return this.menasMask;
        } else if (value.length >= 10 && value.length <= 11) {
          return this.cpfMask;
        } else if (value.length >= 13 && value.length <= 16) {
          return this.cnpjMask;
        } else if (value.length <= 11) {
          return this.cpfMask;
        }
      },
      guide: false
    };
  }

  verificarCertificadoCnpj() {
    this.dadosCliente = JSON.parse(localStorage.getItem('session'));
    this.codigoCliente = this.dadosCliente.cliente.codigo;

    this.ambienteService.obterDadosAmbiente(this.codigoCliente).subscribe(
      res => {
        this.exigeCertificadoCnpj = res.resultado.configuracao.exigeCertificadoCnpj;
        if(this.exigeCertificadoCnpj == 0) {
          this.contribuinteForm.removeControl('comboPersonalidadeJuridicaContribuinte');
          this.contribuinteForm.removeControl('comboSetorAtuacaoContribuinte');
        }
      }
    )
  }

  setorAtuacaoAtivos() {
    this.consultaUsuario.getSetoresAtuacaoAtivos().subscribe(
      res => {
        this.setorAtuacao = res.resultado;
      },
      error => {
        Swal.fire(
          getSwal(
            'error',
            'Ops!',
            'Ocorreu um erro ao carregar personalidades jurídicas.',
            'ok',
            null
          )
        );
      }
    );
  }

  personalidadesJuridicasAtivos() {
    this.consultaUsuario.getPersonalidadesJuridicasAtivos().subscribe(
      res => {
        this.personalidadeJuridica = res.resultado;
      },
      error => {
        Swal.fire(
          getSwal(
            'error',
            'Ops!',
            'Ocorreu um erro ao carregar personalidades juridicas.',
            'ok',
            null
          )
        );
      }
    );
  }

  obterConfiguracoesCliente() {
    this.cadastroService.clientes()
      .subscribe(res => {
        this.servicoSms = res.resultado[0].configuracao.servicoSms;
      })
  }

  validarNome() {
    if(this.contribuinteForm.controls.razaoSocialContribuinte.value &&
      this.contribuinteForm.controls.razaoSocialContribuinte.value.trim() == 0) {
      this.contribuinteForm.controls.razaoSocialContribuinte.setValue('');
    }

    if(this.contribuinteForm.controls.nomeRepresentanteLegal.value && 
      this.contribuinteForm.controls.nomeRepresentanteLegal.value.trim() == 0) {
      this.contribuinteForm.controls.nomeRepresentanteLegal.setValue('');
    }
  }
}
