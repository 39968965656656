import { Component, ViewChild, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RegistroAcoesService } from '../../../../../service/registro-acoes/registro-acoes.service';

import Swal from 'sweetalert2';
import { getSwal } from '../../../../../utils/swalObject';
import { Subscription } from 'rxjs';
import { firstLetterUpperCasePipe } from 'src/app/pipes/first-letter-upper-case';

@Component({
  // tslint:disable-next-line:indent
  selector: '[app-grid-view-registro-acoes]',
  templateUrl: './grid-view-registro-acoes.component.html',
  styleUrls: ['./grid-view-registro-acoes.component.scss']
})
export class GridViewRegistroAcoesComponent implements OnInit {
  @ViewChild('childFilter', {static: false}) child;

  subscription: Subscription;
  searchForm: FormGroup;
  dialogMessage = {};
  viewDialog = false;
  itemsPerView: number = 10;
  loading: boolean;

  data: Object = {};
  registros = [];
  paginacao: Object = {};
  filterCurrent: Object = {
    pagina: 1,
    total_pagina: 10
  }
  searchParams = {
    nome_razao_social: '',
    cpf_cnpj: '',
    data_final: '',
    data_inicial: '',
  }
  pesquisaInvalida: boolean = false;
  mensagemErro: string = 'Nenhum resultado disponível';

  constructor(
    private registroAcoesService: RegistroAcoesService,
    private firstLetterUpperCase: firstLetterUpperCasePipe
  ) {}

  ngOnInit() {
    this.subscription = this.registroAcoesService.registros$.subscribe(res => {
      this.loading = false;
      this.data = res;
      this.registros = res.resultado;
      this.itemsPerView = res.tamanhoPagina;
      this.filterCurrent = this.child.searchParams;
    });
  } 

  next() {
    this.filterCurrent['pagina']++;
    this.onPageChange(this.filterCurrent);
  }

  previous() {
    this.filterCurrent['pagina']--;
    this.onPageChange(this.filterCurrent);
  }

  primeiraPagina() {
    this.filterCurrent['pagina'] = 1;
    this.onPageChange(this.filterCurrent);
  }

  ultimaPagina() {
    this.filterCurrent['pagina'] = this.data['totalPaginas'];
    this.onPageChange(this.filterCurrent);
  }

  onPageChange(value) {
    this.loading = true;

    this.registroAcoesService.getRegistrosdeAcoes(value).subscribe(
      res => {
        this.loading = false;
      },
      error => {
        this.loading = false;
        this.registros = [];
      }
    )
  }

  alteraTamanhoPagina(event) {
    this.loading = true;
    this.filterCurrent['total_pagina'] = event.target.value;
    this.filterCurrent['pagina'] = 1;

    this.registroAcoesService.getRegistrosdeAcoes(this.filterCurrent).subscribe(res => {
    }, error => {
      this.loading = false;
    });
  }

  childFilterChange(value) {
    this.loading = value;
  }

  filterChange(value){
    this.searchParams = {
      nome_razao_social: value.nome_razao_social,
      cpf_cnpj: value.cpf_cnpj,
      data_final: value.data_final,
      data_inicial: value.data_inicial
    };

    if(this.searchParams['nome_razao_social'] !== undefined || this.searchParams['cpf_cnpj'] !== undefined){
      if(this.searchParams['data_final'] == undefined && this.searchParams['data_inicial'] == undefined){
        this.pesquisaInvalida = true;
        this.mensagemErro = 'Para pesquisar o acesso de um usuário, você deverá informar qual o período deseja pesquisar';
      }else{
        this.pesquisaInvalida = false;
        this.mensagemErro = 'Nenhum resultado disponível';
      }
    }else{
      this.mensagemErro = 'Nenhum resultado disponível'
        this.pesquisaInvalida = false;
    }
  }

  openDialog(item) {
    item.tipoEventoTrilha.descricao = this.firstLetterUpperCase.transform(item.tipoEventoTrilha.descricao);
    Swal.fire(
      getSwal(
        'info',
        item.tipoEventoTrilha.descricao,
        item.descricao,
        'ok',
        null
      )
    );
  }

  exportarRegistros() {
    this.child.exportar();
  }
}
