import { Component, OnInit, Output, Input, EventEmitter, AfterViewChecked } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-filtro-validade',
  templateUrl: 'filtro-validade.component.html',
  styleUrls: ['./filtro-validade.component.scss']
})
export class FiltroValidadeComponent implements OnInit {
  @Output() onFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClear: EventEmitter<any> = new EventEmitter<any>();
  @Output() onOpen: EventEmitter<any> = new EventEmitter<any>();
  @Output() onPageUpdate: EventEmitter<number> = new EventEmitter<number>();

  @Input() visible: String;

  searchForm: FormGroup;
  validades = [];
  validade: String = 'Validade';
  
  validadeSelecionada = null;
  validadeDescricao: String = '';
  labels: any;
  obj: {
    a_vencer: boolean;
    vencidos: boolean;
    respostaAVencer: boolean;
    respostasVencidas: boolean;
    respondidas: boolean
  };

  data: Object = {
    primeiraPagina: true,
    ultimaPagina: false,
    paginaAtual: 1,
    totalPaginas: 1,
    totalDeItems: 0,
    itemsPorPagina: 10
  };

  constructor(private fb: FormBuilder, private translate: TranslateService) {
    this.setLabels();
  }

  ngOnInit() {
    this.criarFormulario();
    this.montarValidades();
  }

  criarFormulario() {
    this.searchForm = this.fb.group({
      validadeSelecionada: [null]
    });
  }

  montarValidades() { 
    this.validades = [
      {
        descricao: 'Prazo da ciência a vencer',
        id: 'a_vencer'
      },
      {
        descricao: 'Prazo vencido de ciência',
        id: 'vencidos'
      },
      {
        descricao: 'Prazo para a resposta vencer',
        id: 'respostaAVencer'
      },
      {
        descricao: 'Prazo vencido para resposta',
        id: 'respostasVencidas'
      },
    ]
  } 

  validadeFilter(event, descricao) {
    this.validadeDescricao = descricao;
    this.validadeSelecionada = event.target.id;
  }

  limparFiltros() {
    this.obj = {
      a_vencer: false,
      vencidos: false,
      respostaAVencer: false,
      respostasVencidas: false,
      respondidas: false
    };
    this.onFilter.emit(this.obj);
    this.onPageUpdate.emit(1);
    this.data['paginaAtual'] = 1

    this.validade = 'Validade';

    this.validadeSelecionada = null;
    this.onClear.emit('validade');
  }

  resetarParametros() {
    this.obj = {
      a_vencer: false,
      vencidos: false,
      respostaAVencer: false,
      respostasVencidas: false,
      respondidas: false
    };
      this.onFilter.emit(this.obj);
      this.onPageUpdate.emit(1);
      this.data['paginaAtual'] = 1

    this.validadeSelecionada = null;
    this.validade = 'Validade';
  }

  pesquisarValidade() {
    this.validade = this.validadeDescricao;
    this.obj = {
      a_vencer: false,
      vencidos: false,
      respostaAVencer: false,
      respostasVencidas: false,
      respondidas: false
    };
    
    switch(this.validadeSelecionada) {
      case 'a_vencer': {
        this.obj['a_vencer'] = true;
        break
      }
      case 'vencidos': { 
        this.obj['vencidos'] = true;
        break
      }
      case 'respostaAVencer': {
        this.obj['respostaAVencer'] = true;
        break
      }
      case 'respostasVencidas': {
        this.obj['respostasVencidas'] = true;
        break
      }
    };

    this.onFilter.emit(this.obj);

    this.onFilter.emit(this.obj);
    this.onPageUpdate.emit(1);
    this.data['pagina'] = 1
  }

  setLabels() {
    this.labels = {
      prazosAVencer:
        this.translate.instant('GLOBAIS.PRAZOS') +
        ' ' +
        this.translate.instant('GLOBAIS.AVENCER'),
      prazosVencidos:
        this.translate.instant('GLOBAIS.PRAZOS') +
        ' ' +
        this.translate.instant('GLOBAIS.VENCIDOS')
    };
  }
}
