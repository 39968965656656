import { Injectable } from '@angular/core';

@Injectable()
export class SessionService {
  private session: any;

  constructor() {
    if (window.localStorage.getItem('session')) {
      this.session = JSON.parse(window.localStorage.getItem('session'));
    } else {
      this.session = false;
    }
  }

  public setSession(val: object): void {
    let session = JSON.stringify(val);
    window.localStorage.setItem('session', session);
    let token = JSON.parse(session).token;
    this.setToken(token);
    this.session = { ...val };
  }

  public setToken(token): void {
    window.localStorage.setItem('token', token);
  }

  public getToken(): string {
    return window.localStorage.getItem('token');
  }

  public setTokenJwt(token): void {
    window.localStorage.setItem('tokenJwt', token);
  }

  public getTokenJwt(): string {
    return window.localStorage.getItem('tokenJwt');
  }

  public hasSession(): object {
    this.session = window.localStorage.getItem('session');
    return this.session;
  }

  public getSession(): object {
    return this.session;
  }

  public clearSession(): any {
    this.session = false;
    window.localStorage.removeItem('tokenJwt');
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('session');
  }
}

// import { Injectable } from '@angular/core';

// @Injectable()
// export class SessionService {
//   private session: any;

//   constructor() {
//     if(window.localStorage.getItem('session')) {
//       this.session = JSON.parse(window.localStorage.getItem('session'));
//     }else{
//       this.session = false;
//     }
//   }

//   public setSession(val: object): void {
//     let session = JSON.stringify(val);
//     window.localStorage.setItem('session', session);
//     let token = JSON.parse(session).token;
//     this.setToken(token);
//     this.session = { ...val };
//   }

//   public setToken(token):void {
//     window.localStorage.setItem('token', token);
//   }

//   public getToken():string {
//     return window.localStorage.getItem('token');
//   }

//   public hasSession(): object {
//     this.session = window.localStorage.getItem('session');
//     return this.session;
//   }

//   public getSession(): object {
//     return this.session;
//   }

//   public clearSession(): any {
//     this.session = false;
//     window.localStorage.removeItem('token');
//     window.localStorage.removeItem('session');
//   }
// }
