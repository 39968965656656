import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment'

@Injectable()

export class ProcuracoesService {

  private procuracoes = new Subject<any>();
  procuracoes$ = this.procuracoes.asObservable();
  url: string = environment.BASE_URL;

  constructor(private http: HttpClient) { }

  getProcuracoes(searchParams): Observable<any> {
    let params = this.setParams(searchParams)
    return this.http.get(`${this.url}/procuracoes`, { params: params })
      .pipe(map(data => {
        let body = data;
        this.procuracoes.next(body)
        return body;
      }));
  }

  setParams(searchParams: any) {
    let params = new HttpParams();
    if (!searchParams) return params;
    if (searchParams.key && searchParams.value) {
      return params.set(searchParams.key, searchParams.value);
    }
    for (let key of Object.keys(searchParams)) {
      params = params.set(key, searchParams[key]);
    }
    return params;
  }

  getOutorgadasAdvogados(): Observable<any> {
    return this.http.get(`${this.url}/procuracoes/outorgadasParaAdvogados`).pipe(map(data => {
      let body = data;
      return body;
    }));
  }

  getRepresentanteLegal(cnpj) {
    return this.http.get(`${this.url}/procuracoes/` + cnpj + '/representanteLegal')
  }

  exportarProcuracoes(codigoProcuracao) {
    return this.http.get(`${this.url}/procuracoes/${codigoProcuracao}/documentoOutorga`, {
      responseType: 'arraybuffer'
    }).pipe( map(data => {
      return data;
    }));
  }

}