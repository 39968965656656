import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { RegistroAcoesComponent } from './registro-acoes/registro-acoes.component'
import { AuthGuardService } from '../../service/auth-guard/auth-guard.service';
import { ServicosAtivos } from './grid-registro-acoes/pages/servicos-ativos/servicos-ativos.component';
import { GridViewRegistroAcoesComponent } from './grid-registro-acoes/pages/grid-view-registro-acoes/grid-view-registro-acoes.component';
import { ServicosInoperantes } from './grid-registro-acoes/pages/servicos-inoperantes/servicos-inoperantes.component';
import { ServicosAlertas } from './grid-registro-acoes/pages/servicos-alertas/servicos-alertas.component';


const routes: Routes = [
	{   
		path: 'registro', 
		canActivate: [AuthGuardService], 
		component: RegistroAcoesComponent, 
		data: { breadcrumb: "Histórico de Acessos", modulo: '1002'	} ,
		children:[
			{
				path: '',
				component: GridViewRegistroAcoesComponent,
				data: { breadcrumb: "", modulo: '1002'	},
				pathMatch: 'full'
			},			
			{
				path: 'servicos-ativos',
				component: ServicosAtivos,
				data: { breadcrumb: "Serviços Ativos", modulo: '1002'},
				pathMatch: 'full'
			},
			{
				path: 'servicos-inoperantes',
				component: ServicosInoperantes,
				data: { breadcrumb: "Serviços Inoperantes", modulo: '1002'},
				pathMatch: 'full'
			},
			{
				path: 'servicos-alertas',
				component: ServicosAlertas,
				data: { breadcrumb: "Serviços Alertas", modulo: '1002'},
				pathMatch: 'full'
			}	

			
		]
	}
];
@NgModule({
	imports: [
		RouterModule.forChild(routes)
	],
	exports: [
		RouterModule
	]
})

export class RegistroAcoesRoutingModule {}
