import { Injectable, NgModule } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { UserService } from '../user-service/user.service';
import { Router } from '@angular/router';
import { SessionService } from '../session/session.service';

export const InterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable()

export class HttpsRequestInterceptor implements HttpInterceptor {

  constructor(public router: Router, private sessionService: SessionService, private userService: UserService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = this.sessionService.getToken();
    let headers = new HttpHeaders();
    if (token && !req.headers.has(InterceptorSkipHeader)) {
      headers = headers.append("Authorization", token).append('Cache-Control', 'no-cache').append('Pragma', 'no-cache').append('Content-Transfer-Encoding', 'UTF-8'); 
    } else if (req.headers.has(InterceptorSkipHeader)) {
      headers = req.headers.delete(InterceptorSkipHeader);
    }
    const dupReq = req.clone({
       headers: headers
    });
    return next.handle(dupReq).pipe(catchError ((err: HttpErrorResponse) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401 || err.status === 0 ) {
          window.localStorage.setItem("sentToSignIn", 'true');
          this.userService.sendInfo({ loggedIn: false });
          this.sessionService.clearSession();
          this.router.navigate(['/entrar'])
        };
      }
      return Observable.throw(err);
    }));


  }
}



