import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component ({
    selector: 'app-opcoes-powerbi',
    templateUrl: 'opcoes-powerbi.component.html',
    styleUrls: ['./opcoes-powerbi.component.scss'],
})

export class OpcoesPowerbiComponent implements OnInit {
    constructor(
        private router: Router
    ) { }

    perfilAcessoUsuario: any;

    ngOnInit() {
        let usuario = JSON.parse(localStorage.getItem('session'));
        this.perfilAcessoUsuario = usuario.perfilAcesso;
    }

    redirecionarAnaliseGeral() {
        this.router.navigate(['analise-dados/powerbi']); //adicionar analise-geral
    }

    redirecionarAnaliseUsuarios() {
        this.router.navigate(['analise-dados/powerbi/analise-usuarios']);
    }

    redirecionarAnalisedeComunicados(){
      this.router.navigate(['analise-dados/powerbi/analise-comunicados']);
    }

    redirecionarAnalisedeProcuradores(){
      this.router.navigate(['analise-dados/powerbi/analise-procuradores']);
    }

    redirecionarAnaliseEventos() {
        this.router.navigate(['analise-dados/powerbi/analise-eventos']);
    }

}
