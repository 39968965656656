import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { AlertasService } from '../../../service/alertas/alertas.service';
import { SessionService } from '../../../service/session/session.service';

import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';

@Component({
  selector: 'app-alertas',
  templateUrl: './alertas.component.html',
  styleUrls: ['./alertas.component.scss']
})
export class AlertasComponent implements OnInit {
  url: string = environment.BASE_URL.replace(/^http(s?)/i, '');
  token: string = null;
  alertas: Array<any> = [];

  constructor(
    private alertasService: AlertasService,
    private sessionService: SessionService,
    private router: Router
  ) {
    this.token = sessionService.getToken();
  }

  ngOnInit() {
    this.alertasService.getNaoLidos(this.token).subscribe((res: any) => {
      this.alertas = res.resultado;
      this.fixDescriptions();
    });
  }

  fixDescriptions() {
    this.alertas.map(alerta => {
      if (alerta.tipoAlerta.descricao.length > 37) {
        alerta.tipoAlerta['desc'] =
          alerta.tipoAlerta.descricao.substring(0, 37) + '...';
      } else {
        alerta.tipoAlerta['desc'] = alerta.tipoAlerta.descricao;
      }
    });
  }

  redirectToMessage(alerta) {
    if (alerta.dataLeitura === null) {
      this.alertasService.marcarComoLido(alerta.codigo);
    }

    this.alertasService.setProtocolo(alerta.complemento);
    this.router.navigate(['/caixa-postal']);
  }
}
