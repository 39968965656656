import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { NgxMaskModule } from 'ngx-mask';
import { DataTableModule } from 'ng2-data-table';
import { AmChartsModule } from '@amcharts/amcharts3-angular';

import { CpfCnpjDirective } from './form-utils/cpf-cnpj.directive';
import { DataDirective } from './form-utils/data.directive';
import { ClickOutDirective } from './form-utils/clickout.directive';
import { WindowRef } from './utils/WindowRef';
import { PerfilDataService } from './service/perfil/perfil.service';
import { WizardService } from './service/wizard/wizard.service';
import { HttpsRequestInterceptor } from './service/interceptor/interceptor.service';
import { SessionService } from './service/session/session.service';
import { UserService } from './service/user-service/user.service';
import { AuthGuardService } from './service/auth-guard/auth-guard.service';
import { LoginService } from './service/login/login.service';
import { RecuperarSenhaService } from './service/recuperar-senha/recuperar-senha.service';
import { ConsultaUsuarioService } from './service/consulta-usuario/consultar.user.service';
import { CaixaPostalService } from './service/caixa-postal/caixa-postal.service';
import { RegistroAcoesService } from './service/registro-acoes/registro-acoes.service';
import { ProcuracoesService } from './service/procuracoes/procuracoes.service';
import { OutorgarProcuracaoService } from './service/procuracoes/outorgar-procuracao.service';
import { ComunicadosService } from './service/comunicados/comunicados.service';
import { AplicacoesService } from './service/aplicacoes/aplicacoes.service';
import { VersaoService } from './service/versao/versao.service';
import { CoresTiposComunicadosService } from './service/configuracao/cores-tipo-comunicado.service';
import { FormasAcessoService } from './service/configuracao/forma-acesso.service';
import { PerfisAcessoService } from './service/configuracao/perfil-acesso.service';
import { LoginAstraService } from './service/login/loginAstra.service';
import { IntegracaoService } from './service/integracao/integracao.service';

import { FilterPipe } from './pipes/filter.pipe';
import { CelularPipe } from './pipes/celular.pipe';
import { CpfCnpjPipe } from './pipes/cpf-cnpj.pipe';
import { CgfPipe } from './pipes/cgf.pipe';
import { AmbienteService } from './service/ambiente/ambiente.service';
import { TipoAprovacaoUsuarioService } from './service/tipo-aprovacao-usuario/tipo-aprovacao-usuario.service';
import { ParametroAssinaturaDigitalService } from './service/parametro-assinatura-digital/parametro-assinatura-digital.service';
import { ServicoComunicacaoService } from './service/servico-comunicacao/servico-comunicacao.service';
import { TiposComunicadoService } from './service/tipos-comunicado/tipos-comunicado.service';
import { CertificadoService } from './service/certificado/certificado.service';
import { ContribuinteService } from './service/contribuinte/contribuinte.service';
import { AlertasService } from './service/alertas/alertas.service';
import { PermissoesService } from './service/permissoes/permissoes.service';
import { GerenciarUsuariosService } from './service/configuracao/gerenciar-usuarios.service';
import { CepService } from './service/cep/cep.service';
import { PersonalidadeJuridicaService } from './service/configuracao/personalidade-juridica.service';
import { SetorAtuacaoService } from './service/configuracao/setor-atuacao.service';
import { AssinaturaEletronicaService } from './service/configuracao/assinatura-eletronica.service';
import { RegrasAcessoService } from './service/configuracao/regras-acesso.service';
import { CervelloService } from './service/cervello/cervello.service';
import { HeaderService } from './service/header/header.service';
import { CadastroService } from './service/cadastro/cadastro.service';
import { PowerbiService } from './service/powerbi/powerbi.service';
import { OrganogramaService } from './service/organograma/organograma.service';
import { ManualService } from './service/manual/manual.service';
import { firstLetterUpperCasePipe } from './pipes/first-letter-upper-case';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    RoundProgressModule,
    DataTableModule,
    NgxMaskModule,
    AmChartsModule,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    RoundProgressModule,
    DataTableModule,
    CpfCnpjDirective,
    DataDirective,
    ClickOutDirective,
    FilterPipe,
    CelularPipe,
    CpfCnpjPipe,
    CgfPipe,
    firstLetterUpperCasePipe,
    NgxMaskModule,
    AmChartsModule,
  ],
  declarations: [
    CpfCnpjDirective,
    DataDirective,
    ClickOutDirective,
    FilterPipe,
    CelularPipe,
    CpfCnpjPipe,
    CgfPipe,
    firstLetterUpperCasePipe
  ],
  providers: [
    WindowRef,
    UserService,
    AuthGuardService,
    WizardService,
    SessionService,
    LoginService,
    PerfilDataService,
    RecuperarSenhaService,
    ConsultaUsuarioService,
    CaixaPostalService,
    RegistroAcoesService,
    ProcuracoesService,
    LoginAstraService,
    OutorgarProcuracaoService,
    ComunicadosService,
    AplicacoesService,
    VersaoService,
    CoresTiposComunicadosService,
    FormasAcessoService,
    PerfisAcessoService,
    AmbienteService,
    TipoAprovacaoUsuarioService,
    ParametroAssinaturaDigitalService,
    ServicoComunicacaoService,
    TiposComunicadoService,
    CertificadoService,
    ContribuinteService,
    AlertasService,
    PermissoesService,
    GerenciarUsuariosService,
    CepService,
    IntegracaoService,
    PersonalidadeJuridicaService,
    SetorAtuacaoService,
    AssinaturaEletronicaService,
    RegrasAcessoService,
    CervelloService,
    HeaderService,
    CadastroService,
    PowerbiService,
    OrganogramaService,
    ManualService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpsRequestInterceptor,
      multi: true,
    },
  ],
})
export class SharedModule {}
