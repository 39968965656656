import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-assinatura-eletronica',
    templateUrl: './assinatura-eletronica.component.html',
    styleUrls: ['./assinatura-eletronica.component.scss']
})
export class AssinaturaEletronicaComponent implements OnInit {

    constructor() { }

    ngOnInit() { }
}