import { Component, ViewChild, OnInit } from '@angular/core';
@Component({
	// tslint:disable-next-line:indent
		selector: '[app-grid-registro-acoes]',
	templateUrl: './grid-registro-acoes.component.html'
})
export class GridRegistroAcoesComponent implements OnInit {
	
	constructor() {}
	ngOnInit() {}
	
}