import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared.module';
import { IntegracaoRoutingModule } from './integracao.routing.module';
import { DpDatePickerModule } from 'ng2-date-picker';

import { HistoricoOperacoesFuncionalidadesComponent } from './paginas/historico-operacoes-funcionalidades/historico-operacoes-funcionalidades.component';
import { ImportarFuncionalidadesComponent } from './paginas/importar-funcionalidades/importar-funcionalidades.component';
import { ExportarFuncionalidadesComponent } from './paginas/exportar-funcionalidades/exportar-funcionalidades.component';
import { FiltroHistoricoFuncionalidadeComponent } from './componentes/filtro/filtro-funcionalidades.component';
import { IntegracaoComponent } from './paginas/integracao/integracao.component';
import { OpcoesIntegracaoComponent } from './paginas/opcoes-integracao/opcoes-integracao.component';
import { HistoricoOperacoesUsuariosComponent } from './paginas/historico-operacoes-usuarios/historico-operacoes-usuarios.component';
import { ImportarUsuariosComponent } from './paginas/importar-usuarios/importar-usuarios.component';
import { ExportarUsuariosComponent } from './paginas/exportar-usuarios/exportar-usuarios.component';
import { FilterComponent } from './componentes/filtro/filter.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    IntegracaoRoutingModule,
    DpDatePickerModule,
  ],
  exports: [],
  declarations: [
    HistoricoOperacoesFuncionalidadesComponent,
    HistoricoOperacoesUsuariosComponent,
    ImportarUsuariosComponent,
    ImportarFuncionalidadesComponent,
    ExportarUsuariosComponent,
    ExportarFuncionalidadesComponent,
    FiltroHistoricoFuncionalidadeComponent,
    FilterComponent,
    IntegracaoComponent,
    OpcoesIntegracaoComponent,
  ],
  providers: [],
})
export class IntegracaoModule {}
