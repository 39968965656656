import { Component, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DatePickerComponent } from 'ng2-date-picker';
import { DatePipe } from '@angular/common';
import { AplicacoesService } from '../../../../../service/aplicacoes/aplicacoes.service';
import Swal from 'sweetalert2';
import { getSwal } from '../../../../../utils/swalObject';

import * as moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[app-filtrar-aplicacao]',
  templateUrl: 'filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FiltrarAplicacoesComponent implements OnInit {
  _itemsPerView: any;
  @Input()
  set itemsPerView(value: any) {
    this._itemsPerView = value;
  }

  get itemsPerView(): any {
    return this._itemsPerView;
  }

  searchForm: FormGroup;
  visible = '';
  inicio = null;
  inicioBind;
  fim;
  prazo;
  dataInicial = 'Data de Inclusão';
  validades = [];
  situacao = 'Situação';
  statusSituacao = '';
  situacoes = [];
  arquivados = 'Arquivados';
  dataInicialValida = false;
  dataFinalValida = false;
  ativo: boolean = null;

  searchParams: Object = {
    pagina: 1,
    total_pagina: 10
  };

  data: Object = {
    resultado: [],
    primeiraPagina: true,
    ultimaPagina: false,
    paginaAtual: 1,
    totalPaginas: 1,
    totalDeItems: 0
  };

  errorMessage = '';

  allowClearFilter = false;
  loading: boolean = false;
  filter: string = '';
  debounce: Subject<string> = new Subject<string>();
  dataInclusaoValida: boolean = false;

  constructor(
    private fb: FormBuilder, 
    private aplicacoes: AplicacoesService
  ) {
    this.criarFormulario();
  }
  
  ngOnInit() {
    this.getAplicacoes();
    this.configurarSubject();
  }

  getAplicacoes() {
    this.aplicacoes
      .getAplicacoes({})
      .subscribe(
        res => this.search()
      )
  }

  criarFormulario() {
    this.searchForm = this.fb.group({
      assunto: '',
    });
  }

  selecionarDataInicial() {
    const dataAtual = new Date(); 
    const dataSelecionada = moment(this.inicio);

    if (dataSelecionada.isAfter(dataAtual)) {
      this.dataInclusaoValida = false;
      return;
    }
    if (typeof this.inicio !== 'undefined') {
      this.dataInicial = this.inicio;
      this.dataInicialValida = true;
      this.dataInclusaoValida = true;
    }
  }

  toggleVisibility(item: string) {
    if (item === this.visible) {
      this.visible = '';
    } else {
      this.visible = item;
    }
  }

  statusFilter(e: any) {
    const value = e.target.name;
    this.statusSituacao = value;
    this.ativo = e.target.value;
  }

  onInputSearchChange() {
    const searchDescricao = this.searchForm.controls.assunto.value;
    if (searchDescricao.length === 0) {
      this.onSearchByTitle();
    }
  }

  onSearchByTitle() {   
    this.searchParams['pagina'] = 1 
    const searchDescricao = this.searchForm.controls.assunto.value;
    if (searchDescricao.length !== 0) {
      this.searchParams['descricao'] = this.searchForm.controls.assunto.value;
    } else {
      delete this.searchParams['descricao'];
    }
    this.search();
    this.visible = '';
  }

  onSearchByDate() {
    const dataAtual = new Date(); 
    const dataSelecionada = moment(this.inicio);

    if (dataSelecionada.isAfter(dataAtual)) {
      this.dataInclusaoValida = false;
      return;
    }

    const date = moment(this.inicio).format('YYYY-MM-DD');
    this.searchParams['data_criacao'] = date;
    this.inicioBind = this.inicio;
    this.search();
    this.visible = '';
  }

  onSearchByStatus() {
    this.searchParams['pagina'] = 1
    this.searchParams['ativo'] = this.ativo;
    this.situacao = this.statusSituacao;
    this.search();
    this.visible = '';
  }

  limpar() {
    this.loading = true;
  
    if ('ativo' in this.searchParams) {
      delete this.searchParams['ativo'];
    }
    if ('data_criacao' in this.searchParams) {
      delete this.searchParams['data_criacao'];
    }
  
    this.searchParams['pagina'] = 1
    this.situacao = 'Situação';
    this.dataInicial = 'Data de Inclusão';
    this.dataInicialValida = false;
    this.inicio = null;
    this.inicioBind = null;
    // this.searchForm.reset();
    this.ativo = null;
  
    this.limparFiltro('data');
    this.limparFiltro('situacao');
  }

  limparFiltro(item) {
    if (item === 'data') {
      this.searchParams['pagina'] = 1
      this.dataInicial = 'Data de Inclusão';
      this.dataInicialValida = false;
      this.inicio = null;
      this.inicioBind = null;
      delete this.searchParams['data_criacao'];
    } else {
      this.searchParams['pagina'] = 1
      this.situacao = 'Situação';
      this.ativo = null;
      delete this.searchParams['ativo'];
    }
    this.search();
    this.searchParams['pagina'] = 1
    this.allowClearFilter = false;
    this.visible = '';
  }

  search() {
    this.searchParams['pagina'] = 1
    this.loading = true;
    if (Object.keys(this.searchParams).length !== 0) {
      if(this.searchParams['data_criacao'] || this.searchParams['ativo']) {
        this.allowClearFilter = true;
      } else if (!this.searchParams['data_criacao']) {
        this.dataInicial = 'Data de Inclusão';
        this.dataInicialValida = false;
        this.inicio = null;
        this.inicioBind = null;
      }
    } else {
      this.allowClearFilter = false;
    }
    this.visible = '';

    this.aplicacoes
      .getAplicacoes(this.searchParams)
      .subscribe(res => {
        this.loading = false;
        this.data = res;
        console.log(res);
        
      },
      error =>
        Swal.fire(
          getSwal(
            'error',
            'Ops!',
            'Ocorreu um erro e as aplicações não foram carregadas com sucesso.',
            'ok',
            null,
          ),
        ),
    );
  }

  next() {
    if(!this.data['ultimaPagina']) {
      this.searchParams['pagina'] = this.data['paginaAtual'] + 1;
      this.search();
    }
  }

  previous() {
    if(!this.data['primeiraPagina']) {
      this.searchParams['pagina'] = this.data['paginaAtual'] - 1;
      this.search();
    }
  }

  configurarSubject() {
    this.debounce
      .pipe(debounceTime(500))
      .subscribe(filter => {
        this.filter = filter;
        this.onSearchByTitle();
      });
  }

}
