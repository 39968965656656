import { Component, OnInit, AfterViewInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AplicacoesService } from '../../../../../service/aplicacoes/aplicacoes.service';
import Swal from 'sweetalert2';
import { getSwal } from '../../../../../utils/swalObject';
import { TranslateService } from '@ngx-translate/core';
import { gerenciarSistemasRouterPath } from '../../../../../../environments/version';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-inserir-aplicacao',
  templateUrl: 'inserir-aplicacao.component.html',
  styleUrls: ['./inserir-aplicacao.component.scss']
})
export class InserirAplicacaoComponent implements OnInit {

  definirAplicacaoForm: FormGroup;
  routeSubscription: Subscription;
  situacao = 0;
  icone = 0;
  title = 'Incluir Aplicação';
  edicao = false;
  idAplicacao: number = null;
  aplicacao: any = { detalhe: {acessoRestrito: false, icone: null } };
  loading = false;
  iconeAtual: number;
  iconsListUtility: Array<any> = [];
  iconsList: Array<any> = [
    { name: '#cog', id: 36, selected: false },
    { name: '#megaphone', id: 1, selected: false },
    { name: '#monitor', id: 2, selected: false },
    { name: '#folder', id: 3, selected: false },
    { name: '#inbox', id: 4, selected: false },
    { name: '#world', id: 5, selected: false },
    { name: '#user', id: 6, selected: false },
    { name: '#mail', id: 7, selected: false },
    { name: '#tools', id: 8, selected: false },
    { name: '#file', id: 9, selected: false },
    { name: '#eye', id: 10, selected: false },
    { name: '#firewall', id: 11, selected: false },
    { name: '#flag', id: 12, selected: false },
    { name: '#info', id: 13, selected: false },
    { name: '#judge', id: 14, selected: false },
    { name: '#light-bulb', id: 16, selected: false },
    { name: '#molecule', id: 17, selected: false },
    { name: '#network', id: 18, selected: false },
    { name: '#notepad', id: 19, selected: false },
    { name: '#pie-chart', id: 20, selected: false },
    { name: '#open-book', id: 21, selected: false },
    { name: '#podium', id: 22, selected: false },
    { name: '#view', id: 23, selected: false },
    { name: '#telephone', id: 24, selected: false },
    { name: '#team', id: 25, selected: false },
    { name: '#shake-hands', id: 26, selected: false },
    { name: '#notebook', id: 27, selected: false },
    { name: '#coding', id: 28, selected: false },
    { name: '#coin', id: 29, selected: false },
    { name: '#clock', id: 31, selected: false },
    { name: '#upload', id: 33, selected: false },
    { name: '#thumb-up', id: 34, selected: false },
    { name: '#layers', id: 35, selected: false },
    { name: '#pencil', id: 37, selected: false },
    { name: '#unlocked', id: 38, selected: false },
    { name: '#worldwide', id: 40, selected: false },
    { name: '#people-connection', id: 41, selected: false },
    { name: '#children-charity', id: 42, selected: false },
    { name: '#arroba', id: 43, selected: false }
  ];
  iconNameRowToSetup: any[] = [];
  action: string;
  labels: any;
  campoObrigatorios = false;
  campoCMISObrigatorios = false;
  codigoAplicacao: any;
  semIcone = false;
  navigationData: any;
  urlServicos: string;
  codigoListaServicoMsg: any;
  ipServidorMsg: string;
  portaServicoMsg: number;
  topicoServidorMsg: string;
  situacaoServicoMsg: number = 0;
  listaServidores: Array<Object> = [];
  isDescricaoValida: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private aplicacoesService: AplicacoesService,
    private fb: FormBuilder,
    private translate: TranslateService
  ) {
    this.setLabels();
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.loading = true;
    this.criarFormulario();
    this.routeSubscription = this.route.params.subscribe(params => {
      if (params['id']) {
        this.title = 'Editar Aplicação';
        this.edicao = true;
        this.action = 'editar';
        this.codigoAplicacao = params['id'];

        this.obterAplicacao(this.codigoAplicacao);
      } else {
        this.loading = false;
      }
    });

    this.montaIconens();
    this.getListaServidores();
  }

  criarFormulario() {
    this.definirAplicacaoForm = this.fb.group({
      descricao: ['', [Validators.required]],
      senha: ['', [Validators.required]],
      ip_cmis: [''],
      cmis_usuario: [''],
      cmis_senha: [''],
      cmis_pasta: [''],
      ativo: [true],
      detalhe: this.fb.group({
        situacao: [0],
        titulo: [''],
        funcionalidade: [''],
        icone: [0],
        url: [''],
        acessoRestrito: [false]
      }),
      mensageria: this.fb.group({
        situacaoServicoMsg: [0],
        urlServicos: [null],
        codigoListaServicoMsg: [null],
        ipServidorMsg: [null],
        portaServicoMsg: [null],
        topicoServidorMsg: [null]
      })
    });
    this.definirAplicacaoForm.get('descricao').valueChanges.subscribe(value => {
      this.isDescricaoValida = value.trim() !== '';
    });
  }

  obterAplicacao(id: number) {
    this.aplicacoesService.obterAplicacao(id).subscribe(
      res => {
        this.loading = false;
        this.carregarDadosFormulario(res['resultado']);
      },
      error => {
        this.loading = false;
        Swal.fire(
          getSwal(
            'error',
            'Ops!',
            'Ocorreu um erro e a aplicação não pôde ser devidamente carregada.',
            'ok',
            null
          )
        );
        this.router.navigate([
          `${gerenciarSistemasRouterPath}/funcionalidade/consultar`
        ]);
      }
    );
  }

  getListaServidores() {
    this.aplicacoesService.obterListaServidores().subscribe(res => {
      this.listaServidores = res['resultado'];
    });
  }

  montaIconens() {
    this.aplicacoesService.obterIconesUtilizados().subscribe(data => {

      this.iconsListUtility = this.iconsList.filter(item => {
        // Regras se confundem
        if (
          data.resultado.indexOf(item.id) === -1 ||
          item.id === this.icone
        ) {
          item.selected =
            item.id === this.icone ||
            item.id.toString() === this.icone.toString();
          item.class = item.selected ? 'selected' : null;
          return item;
        }
      });

      if (this.iconsListUtility.length === 0) {
        this.semIcone = true;
      } else {
        this.semIcone = false;
      }
    });

    // Method to setup icons in use:
    this.aplicacoesService.obterAplicacaoComAcessoRestrito().subscribe(
      data => {
        data['resultado'].forEach(element => {
          this.iconNameRowToSetup.push(element.icone);
        });
        this.onCheckIconRepeatedAtApps();
      },
      error => {
        this.loading = false;
      }
    );
  }

  onCheckPasswordFormat(): boolean {
    let passWordNow = this.definirAplicacaoForm.controls['senha'].value;
    let passWordFormat = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    //
    if (
      this.action === 'editar' &&
      this.definirAplicacaoForm.controls['senha'].pristine
    ) {
      return true;
    } else {
      if (passWordNow) {
        if (
          passWordNow.length > 5 &&
          passWordNow.length < 60 &&
          passWordFormat.test(passWordNow)
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  }

  updateIconSelection(icon: any) {
    this.icone = icon;
    this.iconsListUtility.forEach(icn => {
      if (icn.id === icon || icn.id.toString() === icon.toString()) {
        icn.selected = true;
        icn.class = 'selected';
      } else {
        icn.selected = false;
        icn.class = null;
      }
    });
    this.aplicacao['detalhe'].icone = icon;
  }

  carregarDadosFormulario(aplicacao) {
    for (let key in aplicacao) {
      if (
        typeof key !== 'undefined' &&
        aplicacao[key] !== null &&
        this.definirAplicacaoForm.controls[key]
      ) {
        this.definirAplicacaoForm.controls[key].patchValue(aplicacao[key]);
        const iconeAtual = this.definirAplicacaoForm.controls[key].value;
      }
    }

    if(aplicacao.urlServicos !== null){
      this.urlServicos = aplicacao.urlServicos;
    }

    if (aplicacao.situacaoServicoMsg == 1) {
      this.situacaoServicoMsg = 1;
      this.codigoListaServicoMsg = aplicacao.codigoListaServicoMsg;
      this.ipServidorMsg = aplicacao.ipServidorMsg;
      this.portaServicoMsg = aplicacao.portaServicoMsg;
      this.topicoServidorMsg = aplicacao.topicoServidorMsg;
    }
    if (aplicacao !== null && aplicacao.detalhe != null) {
      aplicacao.detalhe.situacao === 0
        ? this.definirAplicacaoForm.controls.detalhe.get('situacao').setValue(0)
        : (this.situacao = aplicacao.detalhe.situacao);

      aplicacao.detalhe.icone === null
        ? this.definirAplicacaoForm.controls.detalhe
          .get('icone')
          .setValue(this.icone)
        : (this.icone = aplicacao.detalhe.icone);
    } else {
      this.definirAplicacaoForm.controls.detalhe.get('situacao').setValue(0);
    }

    this.definirAplicacaoForm.controls.ip_cmis.setValue(aplicacao.urlServidorCmis);
    this.definirAplicacaoForm.controls.cmis_usuario.setValue(aplicacao.usuarioCmis);
    this.definirAplicacaoForm.controls.cmis_senha.setValue(aplicacao.senhaCmis);
    this.definirAplicacaoForm.controls.cmis_pasta.setValue(aplicacao.pastaCmis);
    this.montaIconens();
  }

  setFormValidation() {
    if (this.definirAplicacaoForm.controls.detalhe['controls'].situacao.value !== 0) {
      this.definirAplicacaoForm.controls.detalhe['controls'].url.enable();
      this.definirAplicacaoForm.controls.detalhe['controls'].acessoRestrito.enable();
      this.definirAplicacaoForm.controls.detalhe['controls'].titulo.enable();
      this.definirAplicacaoForm.controls.detalhe['controls'].funcionalidade.enable();
    } else {
      this.definirAplicacaoForm.controls.detalhe['controls'].url.disable();
      this.definirAplicacaoForm.controls.detalhe['controls'].acessoRestrito.disable();
      this.definirAplicacaoForm.controls.detalhe['controls'].icone.disable();
      this.definirAplicacaoForm.controls.detalhe['controls'].titulo.disable();
      this.definirAplicacaoForm.controls.detalhe['controls'].funcionalidade.disable();
    }

    this.tornarCamposObrigatorios();
  }

  gerenciarFuncionalidades() {
    this.router.navigateByUrl(`${gerenciarSistemasRouterPath}/editar/${this.aplicacao['codigo'] ||
      'novo'}/funcionalidade`, { state: [this.action, this.aplicacao] });
  }

  gerenciarEndpoints() {
    this.router.navigateByUrl(`${gerenciarSistemasRouterPath}/editar/
                        ${this.aplicacao['codigo'] || 'novo'}/endpoints`, { state: [this.action, this.aplicacao] });
  }

  gerenciarConsumo() {
    this.router.navigateByUrl(`${gerenciarSistemasRouterPath}/consumo-servicos`);
  }

  onSubmit(form: FormGroup) {
    if (form.valid) {
      if (this.edicao) {
        this.salvar({
          ativo: this.definirAplicacaoForm.controls.ativo.value,
          cliente: null,
          cmisValido: true,
          codigo: 0,
          consumos: null,
          dataCriacao: null,
          dataInativacao: null,
          descricao: this.definirAplicacaoForm.controls.descricao.value,
          detalhe: this.definirAplicacaoForm.controls.detalhe['controls'].situacao.value === 0
            ? null :
            {
              acessoRestrito: this.definirAplicacaoForm.controls.detalhe['controls'].acessoRestrito.value,
              funcionalidade: this.definirAplicacaoForm.controls.detalhe['controls'].funcionalidade.value,
              icone: this.icone,
              situacao: this.definirAplicacaoForm.controls.detalhe['controls'].situacao.value,
              titulo: this.definirAplicacaoForm.controls.detalhe['controls'].titulo.value,
              url: this.definirAplicacaoForm.controls.detalhe['controls'].url.value
            },
          endpoints: null,
          funcionalidades: null,
          pastaCmis: this.definirAplicacaoForm.controls.cmis_pasta.value ?
            this.definirAplicacaoForm.controls.cmis_pasta.value : null,
          senha: this.definirAplicacaoForm.controls.senha.value,
          senhaCmis: this.definirAplicacaoForm.controls.cmis_senha.value ?
            this.definirAplicacaoForm.controls.cmis_senha.value : null,
          urlServicos: this.definirAplicacaoForm.controls.mensageria['controls'].urlServicos.value,
          urlServidorCmis: this.definirAplicacaoForm.controls.ip_cmis.value ?
            this.definirAplicacaoForm.controls.ip_cmis.value : null,
          usoInterno: false,
          usuarioCmis: this.definirAplicacaoForm.controls.cmis_usuario.value ?
            this.definirAplicacaoForm.controls.cmis_usuario.value : null,
          situacaoServicoMsg: this.definirAplicacaoForm.controls.mensageria['controls'].situacaoServicoMsg.value,
          codigoListaServicoMsg: this.definirAplicacaoForm.controls.mensageria['controls'].codigoListaServicoMsg.value,
          ipServidorMsg: this.definirAplicacaoForm.controls.mensageria['controls'].ipServidorMsg.value,
          portaServicoMsg: this.definirAplicacaoForm.controls.mensageria['controls'].portaServicoMsg.value,
          topicoServidorMsg: this.definirAplicacaoForm.controls.mensageria['controls'].topicoServidorMsg.value
        });
      } else {
        this.salvar({
          ativo: this.definirAplicacaoForm.controls.ativo.value,
          cliente: null,
          cmisValido: true,
          codigo: 0,
          consumos: null,
          dataCriacao: null,
          dataInativacao: null,
          descricao: this.definirAplicacaoForm.controls.descricao.value,
          detalhe: this.definirAplicacaoForm.controls.detalhe['controls'].situacao.value === 0
            ? null :
            {
              acessoRestrito: this.definirAplicacaoForm.controls.detalhe['controls'].acessoRestrito.value,
              funcionalidade: this.definirAplicacaoForm.controls.detalhe['controls'].funcionalidade.value,
              icone: this.icone,
              situacao: this.definirAplicacaoForm.controls.detalhe['controls'].situacao.value,
              titulo: this.definirAplicacaoForm.controls.detalhe['controls'].titulo.value,
              url: this.definirAplicacaoForm.controls.detalhe['controls'].url.value
            },
          endpoints: null,
          funcionalidades: null,
          pastaCmis: this.definirAplicacaoForm.controls.cmis_pasta.value ?
            this.definirAplicacaoForm.controls.cmis_pasta.value : null,
          senha: this.definirAplicacaoForm.controls.senha.value,
          senhaCmis: this.definirAplicacaoForm.controls.cmis_senha.value ?
            this.definirAplicacaoForm.controls.cmis_senha.value : null,
          urlServicos: this.definirAplicacaoForm.controls.mensageria['controls'].urlServicos.value,
          urlServidorCmis: this.definirAplicacaoForm.controls.ip_cmis.value ?
            this.definirAplicacaoForm.controls.ip_cmis.value : null,
          usoInterno: false,
          usuarioCmis: this.definirAplicacaoForm.controls.cmis_usuario.value ?
            this.definirAplicacaoForm.controls.cmis_usuario.value : null,
          situacaoServicoMsg: this.definirAplicacaoForm.controls.mensageria['controls'].situacaoServicoMsg.value,
          codigoListaServicoMsg: this.definirAplicacaoForm.controls.mensageria['controls'].codigoListaServicoMsg.value,
          ipServidorMsg: this.definirAplicacaoForm.controls.mensageria['controls'].ipServidorMsg.value,
          portaServicoMsg: this.definirAplicacaoForm.controls.mensageria['controls'].portaServicoMsg.value,
          topicoServidorMsg: this.definirAplicacaoForm.controls.mensageria['controls'].topicoServidorMsg.value
        });
      }
    }

  }

  salvar(definirAplicacaoForm) {
    this.loading = true;
    if (this.edicao) {
      this.aplicacoesService
        .editaAplicacao(this.codigoAplicacao, definirAplicacaoForm)
        .subscribe(
          res => this.handleResponse(),
          error => this.handleError(error)
        );
    } else {
      this.aplicacoesService
        .criaAplicacao(definirAplicacaoForm)
        .subscribe(
          res => this.handleResponse(),
          error => this.handleError(error)
        );
    }
  }

  handleResponse() {
    if (this.action === 'editar') {
      Swal.fire(
        getSwal('success', 'Sucesso!', 'Aplicação Alterada com sucesso', 'ok', null)
      );
      this.router.navigate([`${gerenciarSistemasRouterPath}/consultar`]);
      this.loading = false;
    } else {
      Swal.fire(
        getSwal('success', 'Sucesso!', 'Aplicação Criada com sucesso', 'ok', null)
      );
      this.router.navigate([`${gerenciarSistemasRouterPath}/consultar`]);
      this.loading = false;
    }
  }

  handleError(error) {
    this.loading = false;
    // Swal.fire('Ops!', error.error.excecao.recomendacao, 'error');
    Swal.fire(
      getSwal('error', 'Ops!', error.error.excecao.recomendacao, 'ok', null)
    );
  }

  desistir() {
    this.router.navigate([`${gerenciarSistemasRouterPath}/consultar`]);
  }

  setLabels() {
    this.labels = {
      configuracaocmis: this.translate.instant(
        'CONFIGURACOES.APLICACOES.CONFIGURACAOCMIS'
      ),
      DSC_IP_CMIS: this.translate.instant(
        'CONFIGURACOES.APLICACOES.DSC_IP_CMIS'
      ),
      DSC_CMIS_USUARIO: this.translate.instant(
        'CONFIGURACOES.APLICACOES.DSC_CMIS_USUARIO'
      ),
      DSC_CMIS_SENHA: this.translate.instant(
        'CONFIGURACOES.APLICACOES.DSC_CMIS_SENHA'
      ),
      DSC_CMIS_PASTA: this.translate.instant(
        'CONFIGURACOES.APLICACOES.DSC_CMIS_PASTA'
      ),
      configuracaoexclusiva: this.translate.instant(
        'CONFIGURACOES.APLICACOES.CONFIGURACAOEXCLUSIVA'
      ),
      enderecoEndPoint: this.translate.instant(
        'CONFIGURACOES.APLICACOES.ENDERECOENDPOINT'
      ),
      servicosTitle: this.translate.instant(
        'CONFIGURACOES.APLICACOES.DISPONIBILIZARSERVICOS.TITULO'
      ),
      servicosOpt1: this.translate.instant(
        'CONFIGURACOES.APLICACOES.DISPONIBILIZARSERVICOS.OPCAO1'
      ),
      servicosOpt2: this.translate.instant(
        'CONFIGURACOES.APLICACOES.DISPONIBILIZARSERVICOS.OPCAO2'
      ),
      servicosOpt3: this.translate.instant(
        'CONFIGURACOES.APLICACOES.DISPONIBILIZARSERVICOS.OPCAO3'
      ),
      servicosOpt4: this.translate.instant(
        'CONFIGURACOES.APLICACOES.DISPONIBILIZARSERVICOS.OPCAO4'
      ),
      nomeAmigavel: this.translate.instant('CONFIGURACOES.APLICACOES.NOMEAMIGAVEL'),
      breveDescritivo: this.translate.instant(
        'CONFIGURACOES.APLICACOES.BREVEDESCRITIVO'
      )
    };
  }

  onCheckIconRepeatedAtApps(): void {
    let currentIconsRow = this.iconNameRowToSetup.slice();
    for (let i = 0; i < this.iconsListUtility.length; i++) {
      if (currentIconsRow.includes(this.iconsListUtility[i].id)) {
        this.iconsListUtility.splice(i, 1);
      }
    }
  }

  ajuda() {
    Swal.fire(
      {
        icon: 'info',
        html: 'A aplicação deve implementar o serviço \\informarSituacaoMensagem se desejar receber uma notificação toda vez que ocorrer uma alteração no comunicado que teve como origem a aplicação aqui identificada. Configurar o endereço da seguinte forma: https://servidor/caminho, sem adicionar o /informarSituacaoMensagem, pois esta adição será feita na chamada pela plataforma DT-e. Segue link para documentação da API :' + ' <a href="assets/reference/Dte-API-v320.pdf" download>clique aqui</a>',
        confirmButtonText: 'fechar'
      });
  }

  tornarCamposCMISObrigatorios() {
    if (this.definirAplicacaoForm.controls.ip_cmis.value === "") {
      this.campoCMISObrigatorios = false;
      this.definirAplicacaoForm.get('cmis_usuario').clearValidators();
      this.definirAplicacaoForm.get('cmis_usuario').updateValueAndValidity();

      this.definirAplicacaoForm.get('cmis_senha').clearValidators();
      this.definirAplicacaoForm.get('cmis_senha').updateValueAndValidity();

      this.definirAplicacaoForm.get('cmis_pasta').clearValidators();
      this.definirAplicacaoForm.get('cmis_pasta').updateValueAndValidity();
    } else {
      this.campoCMISObrigatorios = true;
      this.definirAplicacaoForm.get('cmis_usuario').setValidators(Validators.required);
      this.definirAplicacaoForm.controls.cmis_usuario.markAsTouched();
      this.definirAplicacaoForm.get('cmis_usuario').updateValueAndValidity();

      this.definirAplicacaoForm.get('cmis_senha').setValidators(Validators.required);
      this.definirAplicacaoForm.controls.cmis_senha.markAsTouched();
      this.definirAplicacaoForm.get('cmis_senha').updateValueAndValidity();

      this.definirAplicacaoForm.get('cmis_pasta').setValidators(Validators.required);
      this.definirAplicacaoForm.controls.cmis_pasta.markAsTouched();
      this.definirAplicacaoForm.get('cmis_pasta').updateValueAndValidity();
    }
  }

  tornarCamposMensageriaObrigatorios() {
    if(this.situacaoServicoMsg == 1) {
      this.definirAplicacaoForm.controls.mensageria['controls'].codigoListaServicoMsg.setValidators([Validators.compose([
        Validators.required
      ])]);
      this.definirAplicacaoForm.controls.mensageria['controls'].codigoListaServicoMsg.updateValueAndValidity();
      this.definirAplicacaoForm.controls.mensageria['controls'].ipServidorMsg.setValidators([Validators.compose([
        Validators.required, Validators.maxLength(100)
      ])]);
      this.definirAplicacaoForm.controls.mensageria['controls'].ipServidorMsg.updateValueAndValidity();
      this.definirAplicacaoForm.controls.mensageria['controls'].portaServicoMsg.setValidators([Validators.compose([
        Validators.required
      ])]);
      this.definirAplicacaoForm.controls.mensageria['controls'].portaServicoMsg.updateValueAndValidity();
      this.definirAplicacaoForm.controls.mensageria['controls'].topicoServidorMsg.setValidators([Validators.compose([
        Validators.required, Validators.maxLength(60)
      ])]);
      this.definirAplicacaoForm.controls.mensageria['controls'].topicoServidorMsg.updateValueAndValidity();
    } else {

      this.definirAplicacaoForm.controls.mensageria['controls'].codigoListaServicoMsg.setValidators([]);
      this.definirAplicacaoForm.controls.mensageria['controls'].codigoListaServicoMsg.setValue(null);
      this.definirAplicacaoForm.controls.mensageria['controls'].codigoListaServicoMsg.updateValueAndValidity();

      this.definirAplicacaoForm.controls.mensageria['controls'].ipServidorMsg.setValidators([]);
      this.definirAplicacaoForm.controls.mensageria['controls'].ipServidorMsg.setValue(null);
      this.definirAplicacaoForm.controls.mensageria['controls'].ipServidorMsg.updateValueAndValidity();

      this.definirAplicacaoForm.controls.mensageria['controls'].portaServicoMsg.setValidators([]);
      this.definirAplicacaoForm.controls.mensageria['controls'].portaServicoMsg.setValue(null);
      this.definirAplicacaoForm.controls.mensageria['controls'].portaServicoMsg.updateValueAndValidity();

      this.definirAplicacaoForm.controls.mensageria['controls'].topicoServidorMsg.setValidators([]);
      this.definirAplicacaoForm.controls.mensageria['controls'].topicoServidorMsg.setValue(null);
      this.definirAplicacaoForm.controls.mensageria['controls'].topicoServidorMsg.updateValueAndValidity();
    }
  }


  tornarCamposObrigatorios() {
    if (this.definirAplicacaoForm.controls.detalhe['controls'].situacao.value !== 0) {
      this.campoObrigatorios = true;

      this.definirAplicacaoForm.controls.detalhe['controls'].titulo.setValidators(Validators.required);
      this.definirAplicacaoForm.controls.detalhe['controls'].titulo.markAsTouched();
      this.definirAplicacaoForm.controls.detalhe.get('titulo').updateValueAndValidity();

      this.definirAplicacaoForm.controls.detalhe['controls'].funcionalidade.setValidators(Validators.required);
      this.definirAplicacaoForm.controls.detalhe['controls'].funcionalidade.markAsTouched();
      this.definirAplicacaoForm.controls.detalhe.get('funcionalidade').updateValueAndValidity();


      this.definirAplicacaoForm.controls.detalhe['controls'].url.setValidators(Validators.required);
      this.definirAplicacaoForm.controls.detalhe['controls'].url.markAsTouched();
      this.definirAplicacaoForm.controls.detalhe.get('url').updateValueAndValidity();
    } else {
      this.campoObrigatorios = false;

      this.definirAplicacaoForm.controls.detalhe['controls'].funcionalidade.markAsTouched();
      this.definirAplicacaoForm.controls.detalhe.get('funcionalidade').updateValueAndValidity();

      this.definirAplicacaoForm.controls.detalhe['controls'].titulo.clearValidators();
      this.definirAplicacaoForm.controls.detalhe['controls'].titulo.updateValueAndValidity();

      this.definirAplicacaoForm.controls.detalhe['controls'].url.clearValidators();
      this.definirAplicacaoForm.controls.detalhe['controls'].url.updateValueAndValidity();
    }
  }

}
