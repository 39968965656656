import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subject, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable()
export class TipoAprovacaoUsuarioService {
  url: string = environment.BASE_URL;

  constructor(private http: HttpClient) {}

  obterTiposAutorizacao(): Observable<any> {
    return this.http.get(`${this.url}/tiposAprovacaoUsuario`).pipe(map(data => data));
  }

  obterTiposComunicado(): Observable<any> {
    return this.http.get(`${this.url}/tiposComunicado/exigeAssinaturaDigital`);
  }
}
