import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { PersonalidadeJuridicaService } from "src/app/service/configuracao/personalidade-juridica.service";
import { getSwal } from "src/app/utils/swalObject";
import Swal from "sweetalert2";

@Component({
    selector: 'app-incluir-personalidade',
    templateUrl: './incluir-personalidade.component.html',
    styleUrls: ['./incluir-personalidade.component.scss']
})
export class IncluirPersonalidadeComponent implements OnInit {

    @ViewChild('codigo', {static: false}) codigo: ElementRef;
    
    incluirPersonalidadeJuridicaForm: FormGroup;
    isDescricaoValida: any;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private personalidadeJuridicaService: PersonalidadeJuridicaService
    ) {}

    ngOnInit() {
        this.criarFormulario();
    }

    criarFormulario(){
        this.incluirPersonalidadeJuridicaForm = this.formBuilder.group({
            codigo: ['', [Validators.minLength(1),Validators.required]],
            descricao: ['', [Validators.minLength(5), Validators.maxLength(60), Validators.required]]
        })
        this.incluirPersonalidadeJuridicaForm.get('descricao').valueChanges.subscribe(value => {
            this.isDescricaoValida = value.trim() !== '';
          });
    }

    consultarCodigo(codigo) {
        if(codigo == null){
            this.codigo.nativeElement.focus();
        }else{
            this.personalidadeJuridicaService.consultaCodigo(codigo)
            .subscribe(
                res => {
                    if(res) {
                        Swal.fire(
                            getSwal(
                                'warning',
                                'Código já em uso! Favor tentar outro valor.',
                                '',
                                'ok',
                                null
                            )
                        ).then(() => {
                            this.incluirPersonalidadeJuridicaForm.reset();
                            this.codigo.nativeElement.focus();
                        })
                    }  
                }
            )
        }
    }

    incluir() {
        const obj = this.incluirPersonalidadeJuridicaForm.getRawValue();
        this.personalidadeJuridicaService.incluirPersonalidadeJuridica(obj)
            .subscribe(res => {
                Swal.fire(
                    getSwal(
                        'success',
                        'Personalidade Jurídica criada com sucesso',
                        '',
                        'ok',
                        null
                    )
                ).then(() => this.router.navigate(['configuracao/personalidade-juridica/consultar']));
            },
            err => {
                Swal.fire(
                    getSwal(
                        'error',
                        'Ops!',
                        err.error.excecao.recomendacao,
                        'ok',
                        null
                    )
                )
            }
        )
    }

    retornar() {
        this.router.navigate(['configuracao/personalidade-juridica/consultar'])
    }
}