import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { AmbienteService } from '../../../../../service/ambiente/ambiente.service';
import { TipoAprovacaoUsuarioService } from '../../../../../service/tipo-aprovacao-usuario/tipo-aprovacao-usuario.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { getSwal } from '../../../../../utils/swalObject';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { CommonModule } from '@angular/common';
declare var $;

@Component({
  selector: 'app-editar-integracao',
  templateUrl: 'editar-integracao.component.html',
  styleUrls: ['./editar-integracao.component.scss']
})
export class EditarIntegracaoComponent implements OnInit, AfterViewInit {
  routeSub: Subscription;
  editarAmbienteForm: FormGroup;
  idPerfilAcesso: number = null;
  tiposUsuarios = [];
  rotulos = [];
  dadosDeAmbiente: any = {};
  hasLogo: boolean = false;
  logo: SafeUrl;
  tipoUsuario: any = {
    codigo: -1,
    descricao: 'Selecione'
  };
  image: any;
  loading: boolean = false;

  autoCadastro: number = null;
  habilitaComboAutoCadastro : boolean = false;
  exigeCertificadoCnpj: boolean = false;
  cadastroIntegrado: boolean =null;
  urlApi:any;
  mensagemContribuinteNaoEncontrado:any
  

  customClass: {
  container: 'container-class',
  popup: 'popup-class',
  header: 'header-class',
  title: 'title-class',
  closeButton: 'close-button-class',
  icon: 'icon-class',
  image: 'image-class',
  content: 'content-class',
  input: 'input-class',
  actions: 'actions-class',
  confirmButton: 'confirm-button-class',
  cancelButton: 'cancel-button-class',
  footer: 'footer-class'
  }

  labels: any;
  config: any;
  configSumer: any;
  caracteres = 0;
  caracteresSumer = 0;

  constructor(
    private fb: FormBuilder,
    private domSanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private router: Router,
    private ambienteService: AmbienteService,
    private tipoAprovacaoUsuario: TipoAprovacaoUsuarioService,
    private translate: TranslateService,
    private common: CommonModule
  ) {
    this.setLabels();
    this.routeSub = this.route.params.subscribe(params => {
      if (params['id']) {
        this.idPerfilAcesso = params['id'];
      }
    });
  }

  ngOnInit() {
    this.criarFormulario();
    this.tipoAprovacaoUsuario.obterTiposAutorizacao().subscribe(res => {
      this.tiposUsuarios = [
        {
          codigo: -1,
          descricao: 'Selecione'
        }
      ];
      this.tiposUsuarios = this.tiposUsuarios.concat(res.resultado);

    });

    // contador 300 caracteres
    const those = this;
    this.config = {
      disableDragAndDrop: true,
      disableResizeEditor: false,
      placeholder: '',
      tabsize: 2,
      height: '200px',
      toolbar: [
        ['style', ['bold', 'italic', 'underline']],
        ['font'],
        ['fontsize', ['fontname', 'fontsize', 'color']],
      ],
      lang: "pt-br",
      fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Courier New', 'Roboto', 'Times'],
      callbacks: {
        onKeydown: function (e) {

          console.log('e:',e)
          let caracteres = $(".note-editable").text();
          let totalCaracteres = caracteres.length;
          var t = e.currentTarget.innerText;
          
          if(e.keyCode !== 13){
            those.caracteres = totalCaracteres + 1;
          }

          if(e.keyCode === 13){
            those.caracteres++ ;
          }
          
          if(e.keyCode === 8 && totalCaracteres > 0){
            those.caracteres = totalCaracteres - 1;
          }

          if(e.keyCode === 8 && totalCaracteres === 0){
            those.caracteres = 0;
          }

          if(totalCaracteres >= 300){
            if (e.keyCode != 8 && !(e.keyCode >=37 && e.keyCode <=40) && e.keyCode != 46 && !(e.keyCode == 88 && e.ctrlKey) && !(e.keyCode == 67 && e.ctrlKey) && !(e.keyCode == 65 && e.ctrlKey)){
              e.preventDefault();
            }
          }	
        },
        onChange: function(e) {
          let caracteres = $(".note-editable").text();
          let totalCaracteres = caracteres.length;
          those.caracteres = totalCaracteres;
        },
        onKeyup: function (e) {
            var t = e.currentTarget.innerText;
            let caracteres = $(".note-editable").text();
            let totalCaracteres = caracteres.length;
            $('#maxContentPost').text(300 - totalCaracteres + 1);
        },
        onPaste: function (e) {
            var t = e.currentTarget.innerText;
            let caracteres = $(".note-editable").text();
            let totalCaracteres = caracteres.length;
            var bufferText = ((e.originalEvent || e).clipboardData).getData('Text');
            e.preventDefault();
            var maxPaste = bufferText.length;
            if(totalCaracteres + bufferText.length > 300){
                maxPaste = 300 - t.length;
            }
            if(maxPaste > 0){
                document.execCommand('insertText', false, bufferText.substring(0, maxPaste).trim());
            }
            $('#maxContentPost').text(300 - t.length);
        }
      }
    }
    // this.configSumer = {
    //   disableDragAndDrop: true,
    //   disableResizeEditor: false,
    //   placeholder: '',
    //   tabsize: 2,
    //   height: '200px',
    //   toolbar: [
    //     ['style', ['bold', 'italic', 'underline']],
    //     ['font'],
    //     ['fontsize', ['fontname', 'fontsize', 'color']],
    //   ],
    //   lang: "pt-br",
    //   fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Courier New', 'Roboto', 'Times'],
    //   callbacks: {
    //     onKeydown: function (e) {
    //       let caracteresSumer = $(".note-editable").text();
    //       let totalCaracteresSumer = caracteresSumer.length;
    //       var t = e.currentTarget.innerText;
          
    //       if(e.keyCode !== 13){
    //         those.caracteresSumer = totalCaracteresSumer + 1;
    //       }

    //       if(e.keyCode === 13){
    //         those.caracteresSumer++ ;
    //       }
          
    //       if(e.keyCode === 8 && totalCaracteresSumer > 0){
    //         those.caracteresSumer = totalCaracteresSumer - 1;
    //       }

    //       // if(e.keyCode === 8 && totalCaracteres === 0){
    //       //   those.caracteres = 0;
    //       // }

    //       if(totalCaracteresSumer >= 600){
    //         if (e.keyCode != 8 && !(e.keyCode >=37 && e.keyCode <=40) && e.keyCode != 46 && !(e.keyCode == 88 && e.ctrlKey) && !(e.keyCode == 67 && e.ctrlKey) && !(e.keyCode == 65 && e.ctrlKey)){
    //           e.preventDefault();
    //         }
    //       }	
    //     },
    //     onChange: function(e) {
    //       let caracteresSumer = $(".note-editable").text();
    //       let totalCaracteresSumer = caracteresSumer.length;
    //       those.caracteresSumer = totalCaracteresSumer;
    //     },
    //     onKeyup: function (e) {
    //         var t = e.currentTarget.innerText;
    //         let caracteresSumer = $(".note-editable").text();
    //         let totalCaracteresSumer = caracteresSumer.length;
    //         $('#maxContentPost').text(600 - totalCaracteresSumer + 1);
    //     },
    //     onPaste: function (e) {
    //         var t = e.currentTarget.innerText;
    //         let caracteresSumer = $(".note-editable").text();
    //         let totalCaracteresSumer = caracteresSumer.length;
    //         var bufferText = ((e.originalEvent || e).clipboardData).getData('Text');
    //         e.preventDefault();
    //         var maxPaste = bufferText.length;
    //         if(totalCaracteresSumer + bufferText.length > 600){
    //             maxPaste = 600 - t.length;
    //         }
    //         if(maxPaste > 0){
    //             document.execCommand('insertText', false, bufferText.substring(0, maxPaste).trim());
    //         }
    //         $('#maxContentPost').text(600 - t.length);
    //     }
    //   }
    // }
    $('.note-editable600').css('font-size', '16px');
    $('.note-editable600').css('font-family', 'Calibri');
    $('.note-current-fontsize').text('16');
    $('.note-current-fontname').text('Calibri');
    $('.note-editable').css('font-weight', 'normal');
    $('.popover > .arrow').css('display', 'block');
    $('.note-hint-popover .popover-content').css('width', 'max-content');
    $('.note-hint-popover').css('border', 'none');
    $('.note-hint-popover .popover-content').css('position', 'absolute');

    }

  ngAfterViewInit() {
    if (this.idPerfilAcesso) {
      this.obterDados();
    }
    this.obterImagem();
  }

  obterImagem(){
    this.ambienteService.obterImagem().subscribe(
      res => {
        this.logo = 'data:image/jpeg;base64,' + res.resultado;
      }, err => {
        console.log(err.error.text);
      });
  }

  criarFormulario() {
    this.editarAmbienteForm = this.fb.group({
      razaoSocial: '',
      ativo: [false, Validators.required],
      inicioContrato: '',
      fimContrato: '',
      inicioContratoFormatado: '',
      fimContratoFormatado: '',
      configuracao: this.fb.group({
        tipoAprovacaoUsuario: [
          {
            codigo: -1,
            descricao: 'Selecione'
          }
        ],
        url: '',
        apiAlfresco: ['', [Validators.required, Validators.minLength(5)]],
        urlAuditor: [''],
        urlContribuinte: [''],
        usuarioEscrita: ['', [Validators.required, Validators.minLength(2)]],
        senhaEscrita: ['', [Validators.required, Validators.minLength(2)]],
        pastaAlfresco: ['', [Validators.required, Validators.minLength(2)]],
        hostSmtp: [''],
        portaSmtp: [''],
        usuarioSmtp: [''],
        senhaSMTP: [''],
        endpointSms: [''],
        contaSms: [''],
        codigoSms: [''],
        alertaProcuracao: [''],
        prazoProcuracao: [0],
        autoCadastro: null,
        exigeCertificadoCnpj: null,
        cadastroIntegrado:null,
        exigeTermoAdesao: false,
        exigeTipoComunicao: [''],
        urlApi:'',
        mensagemContribuinteNaoEncontrado:'',
        tituloDte: [''],
        logoDte:''
        // integracao: this.fb.group({
        //   cadastroIntegrado: [false, Validators.required],
        //   teste: '123456x',
        // })
      })
    });
  }

  onSelectFile(img) {
    if (img.target.files) {
      var reader = new FileReader();
      reader.readAsDataURL(img.target.files[0]);
      reader.onload = (event: any) => {
      };

      this.image = img.target.files[0];

      const fileReader = new FileReader();
      const file = img.target.files[0];
      const currentScope = this;
      fileReader.readAsDataURL(file);
      fileReader.onload = function(e: any) {
        currentScope.logo = e.target.result;
      };
      this.onUpload();
    }
  }

  onUpload() {
    this.loading = true;
    this.ambienteService.definirLogo(this.image).subscribe(
      res => {
        this.loading = false;
      },
      err => {
        this.loading = false;
        this.logo = null;
        Swal.fire(
          getSwal('error', 'Ops!', err.error.excecao.recomendacao, 'ok', null)
        );
      });
  }
  teste(e){
    console.log(e)
    this.caracteresSumer = e.length;
  }

  obterDados() {
    this.ambienteService.obterDadosAmbiente(this.idPerfilAcesso).subscribe(
      res => {
        //USE TO TEST: res['resultado'].fimContrato = "";
        res.resultado.fimContratoFormatado =
          res.resultado.fimContrato && res.resultado.fimContrato !== null
            ? moment(res.resultado.fimContrato).format('DD/MM/YYYY')
            : '';
        if (
          res.resultado.fimContratoFormatado.toLowerCase() == 'invalid date'
        ) {
          res.resultado.fimContratoFormatado = ' ';
        }

        res.resultado.inicioContratoFormatado =
          res.resultado.inicioContrato && res.resultado.inicioContrato !== null
            ? moment(res.resultado.inicioContrato).format('DD/MM/YYYY')
            : '';

        this.dadosDeAmbiente = res.resultado;

        if(this.dadosDeAmbiente.integracao === null){
          this.cadastroIntegrado = false;
        }else{

          if(this.dadosDeAmbiente.integracao.cadastroIntegrado === true){
            this.cadastroIntegrado = true;
          } else {
            this.cadastroIntegrado = false;
          }
          
          if(this.dadosDeAmbiente.integracao.urlApi !== null) {
            this.urlApi = this.dadosDeAmbiente.integracao.urlApi;
            var t = this.dadosDeAmbiente.integracao.urlApi;
            var car = t.replace(/(\s\(.*?\))|<\w+(\s+("[^"]*"|'[^']*'|[^>])+)?>|<\/\w+>/gi, '');
            this.caracteresSumer = car.length;
          }

          if(this.dadosDeAmbiente.integracao.mensagemContribuinteNaoEncontrado !== null) {
            this.mensagemContribuinteNaoEncontrado = this.dadosDeAmbiente.integracao.mensagemContribuinteNaoEncontrado;
            var t1 = this.dadosDeAmbiente.integracao.mensagemContribuinteNaoEncontrado;
            var carLength = t1.replace(/(\s\(.*?\))|<\w+(\s+("[^"]*"|'[^']*'|[^>])+)?>|<\/\w+>/gi, '');
            this.caracteres = carLength.length;
          }
        }

        this.carregarDadosFormulario(this.dadosDeAmbiente);


       
      },
      error => {
        Swal.fire(
          getSwal(
            'error',
            'Ops!',
            'Ocorreu um erro e o Ambiente não pôde ser carregado.',
            'ok',
            null
          )
        );
      }
    );
  }

  carregarDadosFormulario(data) {
    for (let key in data) {
      if (this.editarAmbienteForm.controls[key] && data[key] !== null) {
        this.editarAmbienteForm.controls[key].patchValue(data[key]);
      }
    }

    if (data.configuracao !== null) {
      this.tipoUsuario = data.configuracao.tipoAprovacaoUsuario;
      this.editarAmbienteForm
        .get('configuracao')
        ['controls'].tipoAprovacaoUsuario.patchValue(this.tipoUsuario);
      this.editarAmbienteForm.controls.configuracao[
        'controls'
      ].senhaSMTP.setValue(data.configuracao.senhaSmtp);
      this.editarAmbienteForm.controls.configuracao[
        'controls'
      ].urlContribuinte.setValue(data.configuracao.urlContribuinte);

      this.editarAmbienteForm.controls.configuracao[
        'controls'
        ].autoCadastro.setValue(data.configuracao.autoCadastro);

      this.editarAmbienteForm.controls.configuracao[
        'controls'
        ].exigeCertificadoCnpj.setValue(data.configuracao.exigeCertificadoCnpj);

      this.autoCadastro = data.configuracao.autoCadastro;

      this.exigeCertificadoCnpj = data.configuracao.exigeCertificadoCnpj
      

      /**this.editarAmbienteForm.controls.configuracao[
        'controls'
      ].exigirOrtoga.setValue(data.configuracao.prazoProcuracao);**/

      // if (data.configuracao.logo !== null) {
      //   // this.hasLogo = true;
      //   this.logo = this.domSanitizer.bypassSecurityTrustUrl(
      //     'data:image/jpeg;base64,' + data.configuracao.logo
      //   );
      // } else {
      //   // this.hasLogo = false;
      //   this.logo = null;
      // }

    } else {
      this.tipoUsuario = 0;
    }
  }


  disableFields() {
    this.editarAmbienteForm.controls.razaoSocial.disable();
    this.editarAmbienteForm.controls.inicioContrato.disable();
    this.editarAmbienteForm.controls.fimContrato.disable();
    this.editarAmbienteForm.controls.configuracao.get('url').disable();
  }

  atualizar() {
    const obj = this.checkChanges();
    this.ambienteService
      .atualizarDadosAmbiente(this.dadosDeAmbiente.codigo, obj)
      .subscribe(
        res => {
          Swal.fire(
            getSwal(
              'success',
              'Sucesso',
              'Ambiente atualizado com sucesso.',
              'ok',
              null
            )
          ).then(res => {
            this.router.navigate(['configuracao/ambiente']);
          });
        },
        error => {
          console.log(error);
          Swal.fire(
            getSwal(
              'error',
              'Ops!',
              error.error.excecao.recomendacao,
              'ok',
              null
            )
          );
        }
      );
  }

  checkChanges() {
    let value = this.editarAmbienteForm.getRawValue();
    const updatedValue = {};
    for (let key in value) {
      if (
        value[key] !== this.dadosDeAmbiente[key] &&
        value[key] !== 'configuracao'
         && value[key] !== 'regraUso'
      ) {
        this.dadosDeAmbiente[key] = value[key];
      }
    }

    for (let key in value.configuracao) {
      if (
        value.configuracao[key] !== this.dadosDeAmbiente.configuracao[key] &&
        value[key] !== 'tipoAprovacaoUsuario'
      ) {
        this.dadosDeAmbiente.configuracao[key] = value.configuracao[key];
      }

      if (
        value.configuracao[key] &&
        value.configuracao[key] !== null &&
        value.configuracao[key].toString().length === 0
      ) {
        delete this.dadosDeAmbiente.configuracao[key];
      }
    }

    for (let key in value.regraUso) {
      if (
        value.regraUso[key] !== this.dadosDeAmbiente.regraUso[key] &&
        value[key] !== 'tipoComunicado'
      ) {
        this.dadosDeAmbiente.regraUso[key] = value.regraUso[key];
      }

      if (
        value.regraUso[key] &&
        value.regraUso[key] !== null &&
        value.regraUso[key].toString().length === 0
      ) {
        delete this.dadosDeAmbiente.regraUso[key];
      }
    }

    var integracao = {};
    integracao = {
      cadastroIntegrado: this.cadastroIntegrado,
       mensagemContribuinteNaoEncontrado: this.dadosDeAmbiente.configuracao.mensagemContribuinteNaoEncontrado,
       urlApi:this.dadosDeAmbiente.configuracao.urlApi
    }
    
    // if(this.integracao){
      this.dadosDeAmbiente.integracao = integracao;
    // } else {
    //   this.dadosDeAmbiente.regraUso = null;
    // }

    
    var regraUso = {};
    
    if(this.dadosDeAmbiente.regraUso){
      regraUso = {
        tipoComunicado: {codigo: this.dadosDeAmbiente.regraUso.tipoComunicado.codigo}
      }
      this.dadosDeAmbiente.regraUso = regraUso;
    } else {
      this.dadosDeAmbiente.regraUso = null;
    }

    if(this.dadosDeAmbiente.integracao.cadastroIntegrado === false){
      this.dadosDeAmbiente.integracao.mensagemContribuinteNaoEncontrado = null;
      this.dadosDeAmbiente.integracao.urlApi = null;
    }

    delete this.dadosDeAmbiente.inicioContratoFormatado;
    delete this.dadosDeAmbiente.fimContratoFormatado;
  
    return this.dadosDeAmbiente;
  }

  limparTipoUsuario(event) {
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  alteracaoTipoUsuario(codigo) {
    if (typeof codigo !== 'object') {
      return;
    }

    if (typeof codigo === 'object') {
      if(codigo.codigo === 0 ){
        this.habilitaComboAutoCadastro = true;
      }else{
        this.habilitaComboAutoCadastro = false;
      }
    }

    this.tipoUsuario = codigo;
  }

  alteracaoAutoCadastro(codigo) {
    this.autoCadastro = codigo;
  }

  alteracaoExigeCertificadoCnpj(codigo) {
    this.exigeCertificadoCnpj = codigo;
  }

  setLabels() {
    this.labels = {
      urlPortal: this.translate.instant('CONFIGURACOES.AMBIENTES.URLPORTAL'),
      urlAuditor: this.translate.instant('CONFIGURACOES.AMBIENTES.URLAUDITOR'),
      razaoSocial: this.translate.instant('GLOBAIS.RAZAOSICIAL'),
      situacao: this.translate.instant('GLOBAIS.SITUACAO'),
      inativo: this.translate.instant('GLOBAIS.INATIVO'),
      ativo: this.translate.instant('GLOBAIS.ATIVO'),
      dataContrato: this.translate.instant('GLOBAIS.DATACONTRATO'),
      dataFim: this.translate.instant('GLOBAIS.DATAFIM'),
      logotipo: this.translate.instant('CONFIGURACOES.AMBIENTES.LOGOTIPO'),
      formaAtivacaoUsuario: this.translate.instant(
        'CONFIGURACOES.AMBIENTES.FORMAATIVACAOUSUARIO'
      ),
      usuarioEscritaAlfresco: this.translate.instant(
        'CONFIGURACOES.AMBIENTES.USUARIOESCRITAALFRESCO'
      ),
      usuarioSenhaAlfresco: this.translate.instant(
        'CONFIGURACOES.AMBIENTES.USUARIOSENHAALFRESCO'
      ),
      urlAlfresco: this.translate.instant('CONFIGURACOES.AMBIENTES.URLALFRESCO'),
      pastaAlfresco: this.translate.instant(
        'CONFIGURACOES.AMBIENTES.PASTAALFRESCO'
      ),
      cadastroIntegrado: this.translate.instant(
        'CONFIGURACOES.AMBIENTES.CADASTROINTEGRADO'
      ),
      urlApi: this.translate.instant(
        'CONFIGURACOES.AMBIENTES.ENDERECOAPIFORNECEDOR'
      ),
      contribuinteNaoEncontrado: this.translate.instant(
        'CONFIGURACOES.AMBIENTES.CONTRIBUINTENAOENCONTRADO'
      ),
      alertaProcuracao: this.translate.instant(
        'CONFIGURACOES.AMBIENTES.ALERTAPROCURACAO'
      ),
      exigirOrtoga: this.translate.instant('CONFIGURACOES.AMBIENTES.EXIGIRORTOGA')
    };
  }

  ajuda(valor) {
    let msg1=
    ` <ol>
    <li> Consta o nome que será utilizado como assinatura dos e-mails e documentos gerados.
    </li>
    <li> Não permite alteração via sistema, está fixado via banco de dados.
    </li>
  </ol>`;

  let msg2 = ` <ol>
    <li> Imagem que será apresentada no título de documentos do site do Portal e Gestor</li>
  </ol>`;

    let msg3 = ` <ol>
    <li>  Permite desativar o Portal DT-e e Portal DT-e Gestor para perfis de Contribuinte e Auditor, impedindo o acesso quando está com
    situação Inativo </li>
    <li> Na situação Inativo, quando um usuário tentar acessar um dos portais, é avisado via alerta que o portal está em manutenção;
    neste caso, somente o Administrador de Plataforma consegue acessar o Portal DT-e Gestor e alterara a situação.</li>
  </ol>`;

    let msg4= ` <ol>
    <li>  Data que iniciou o uso do Portal DT-e, indicando que os dados movimentados tem esta data como partida; para auditoria, qualquer
    dado gerando antes desta data foi inserida via base de dados, não via recursos da plataforma.</li>
    <li class="my-2"> No caso da SEFAZ, não permite alteração. </li>
  </ol>`;

    let msg5= ` <ol>
    <li>  Data programada para inativar os portais automaticamente, alterando a <b>Situação</b> para Inativo quando chega na data.</li>
    <li> No caso da SEFAZ, não permite alteração. </li>
  </ol>`;

    let msg6= ` <ol>
    <li>  Define o procedimento que plataforma adota para cadastrar os usuários com perfil de acesso do tipo Contribuinte.</li>
    <li class="my-2"> As formas de cadastro aceitos são: </li>
    <li class="my-2"><b>Auto Cadastro</b></li>
    <li class="my-2"> A plataforma aplica regra de dados mínimos informados antes de ativar a conta do usuário,
    e faz uso de assinatura eletrônica para ativar o usuário.</li>


    <li class="my-2"><b>Aprovação automática</b></li>
    <li class="my-2">Os usuários são importados para a plataforma via carga de dados, e são ativados por ofício.</li>

    <li class="my-2"> <b>Aprovação manual</b></li>
    <li class="my-2">A plataforma não ativa os usuários, inseridos via auto cadastro ou importados via Integração,
    sem a intervenção via Gerenciar Usuário, de um Auditor Fiscal.<li>

    <li class="my-2"> <b>Aprovação presencial</b></li>
        <li class="my-2">A plataforma não ativa os usuários, mas apresenta uma agenda para atendimento presencial na
        instituição, onde deve ser apresentado documentos para análise e ativação pelo Auditor Fiscal</li>
  </ol>`;
    let msg7= ` <ol>
    <li> Informa o endereço oficial de acesso a plataforma para o Contribuinte, 
    que é utilizado nas mensagens de e-mail para orientar o usuário quando tiver comunicado disponível.</li>
  </ol>`;

    let msg8= ` <ol>
    <li class="my-2"> Informa o endereço oficial de acesso a plataforma para o Auditor, 
    que é utilizado nas mensagens de e-mail para orientar o usuário quando tiver comunicado disponível.</li>
  </ol>`;
    let msg9= ` <ol>
    <li class="my-2"> Endereço do serviço (API) que retorna os dados de Contribuinte 
    para ser apresentado na funcionalidade Perfil de Contribuinte.</li>
  </ol>`;
    let msg10= ` <ol>
    <li> Usuário com direitos de leitura e escrita no Alfresco </li>
  </ol>`;
    let msg11= `<ol>
    <li>Senha do usuário de leitura e escrita no Alfresco</li>
  </ol>`;
    let msg12= `<ol>
    <li>Endereço da API do Alfresco </li>
  </ol>`;
    let msg13= `<ol>
    <li>
    Pasta do usuário do Alfresco que será raiz para criar os diretórios </li>
  </ol>`;
    let msg14= `<ol>
    <li> Endereço do servidor que oferece o serviço de e-mail</li>
  </ol>`;
    let msg15= `<ol>
    <li>Porta de conexão para o serviço de e-mail</li>
  </ol>`;
    let msg16= `<ol>
    <li> Senha do usuário para acesso ao serviço </li>
  </ol>`;
    let msg17= `<ol>
    <li>Usuário para acesso ao serviço</li>
  </ol>`;
    let msg18= `<ol>
    <li> Senha do usuário para acesso ao serviço
    </li>
  </ol>`;
    let msg19= `<ol>
      <li>Endereço do servidor/serviço de SMS
      </li>
  </ol>`;
    let msg20= `<ol>
      <li>Usuário de conexo ao serviço
      </li>
  </ol>`;
    let msg21= `<ol>
    <li>Código de ativação do serviço</li>
  </ol>`;

    let msg22= `<ol>
    <li><b>Dias de antecedência para alertar vencimento de procuração</b></li>
    <li>Indica, em dias, se deve ser alertado ao Procurador quando está para vencer uma procuração</li>
  </ol>`;
    let msg23= `<ol>
    <li><b>Exigir aceite de procurador em outorga de procurações em até (em dias)</b></li>
    <li>Indica, em dias, se há prazo para aceitar uma procuração</li>
    <li>Se 0 (zero), procuração outorgada automaticamente fica válida</li>
  </ol>`;

    let msg24= `<ol>
    <li>Indica se no processo de auto-cadastro de Contribuinte  ou Procurador do tipo Pessoa Jurídica devem utilizar o
    Certificado Digital para habilitar o cadastro no portal do domicílio</li>
  </ol>`;

    let msg25= `<ol>
    <li>Define se pessoa jurídica pode acessar por CNPJ e senha e certificado digital, ou somente via certificado digital padrão ICP-Brasil.</li>
  </ol>`;

    let msg26= `<ol>
    <li>Ativa a integração com sistema que irá fornecer os dados dos usuários do domicílio com perfil de Contribuinte,
    permitindo cadastro manual apenas de Procurador e Auditor.
  </ol>`
  
    let msg27= `<ol>
    <li>Informe o endereço (URL) da API que irá trazer os dados dos usuários a serem cadastrados.
    O padrão de comunicação está no manual de integração.
  </ol>`

    let msg28= `<ol>
    <li>Informe o alerta a ser dado ao Contribuinte quando não for possível sua inclusão.
    Exemplo: "Procure a central de informações para regularizar sua situação (xx)xxxxx-xxxx".
  </ol>`

    switch(valor) {
      case 'razaosocial': {
        Swal.fire({
          icon: 'info',
          html:msg1,
          confirmButtonText:'Ok' ,
          
        })
         break;
      }
      case 'logotipo': {
        Swal.fire({
          icon: 'info',
          html:msg2,
          confirmButtonText:'Ok' ,
          
        })
         //statements;
         break;
      }
      case 'situacao': {
        Swal.fire({
          icon: 'info',
          html:msg3,
          confirmButtonText:'Ok' ,
          
        })
         //statements;
         break;
      }
      case 'datacontrato': {
        Swal.fire({
          icon: 'info',
          html:msg4,
          confirmButtonText:'Ok' ,
          
        })
         break;
      }
      case 'datafim': {
        Swal.fire({
          icon: 'info',
          html:msg5,
          confirmButtonText:'Ok'  ,
          
        })
         break;
      }
      case 'formaativacaousuario': {  // reever Opção adotadota
        Swal.fire({
          icon: 'info',
          html:msg6,
          confirmButtonText:'Ok'  ,
          
        })
         break;
      }
      case 'urlportal': {
        Swal.fire({
          icon: 'info',
          html:msg7,
          confirmButtonText:'Ok'  ,
          
        })
         break;
      }
      case 'urlauditor': {
        Swal.fire({
          icon: 'info',
          html:msg8,
          confirmButtonText:'Ok' ,
          
        })
         break;
      }
      case 'urlservicointegracao': {
        Swal.fire({
          icon: 'info',
          html:msg9,
          confirmButtonText:'Ok' ,
          
        })
         break;
      }
      case 'ambientealfresco': {
        Swal.fire({
          icon: 'info',
          html:msg10,
          confirmButtonText:'Ok' ,
          
        })
         break;
      }
      case 'usuariosenhaalfresco': {
        Swal.fire({
          icon: 'info',
          html:msg11,
          confirmButtonText:'Ok' ,
          

        })
         break;
      }
      case 'urlalfresco': {
        Swal.fire({
          icon: 'info',
          html:msg12,
          confirmButtonText:'Ok' ,
          
        })
         break;
      }
      case 'pastaalfresco': {
        Swal.fire({
          icon: 'info',
          html:msg13,
          confirmButtonText:'Ok' ,
          
        })
         break;
      }
      case 'servidorsmtp': {
        Swal.fire({
          icon: 'info',
          html:msg14,
          confirmButtonText:'Ok' ,
          
        })
         break;
      }
      case 'portasmtp': {
        Swal.fire({
          icon: 'info',
          html:msg15,
          confirmButtonText:'Ok'  ,
          
        })
         break;
      }
      case 'usuariosmtp': {
        Swal.fire({
          icon: 'info',
          html:msg17,
          confirmButtonText:'Ok'  ,
          
        })
         break;
      }
      case 'senhasmtp': {
        Swal.fire({
          icon: 'info',
          html:msg18,
          confirmButtonText:'Ok'  ,
          
        })
         break;
      }
      case 'endpointservicosms': {
        Swal.fire({
          icon: 'info',
          html:msg19,
          confirmButtonText:'Ok'  ,
          
        })
         break;
      }
      case 'contaservicosms': {
        Swal.fire({
          icon: 'info',
          html:msg20,
          confirmButtonText:'Ok'  ,
          
        })
         break;
      }
      case 'codigoservicosms': {
        Swal.fire({
          icon: 'info',
          html:msg21,
          confirmButtonText:'Ok'  ,
          
        })
         break;
      }
      case 'alertaprocuracao': {
        Swal.fire({
          icon: 'info',
          html:msg22,
          confirmButtonText:'Ok'  ,
          
        })
         break;
      }
      case 'exigirortoga': {
        Swal.fire({
          icon: 'info',
          html:msg23,
          confirmButtonText:'Ok'  ,
          
        })
         break;
      }
      case 'autocadastro': {
        Swal.fire({
          icon: 'info',
          html:msg24,
          confirmButtonText:'Ok'  ,
          
        })
        break;
      }
      case 'exigeCertificadoCnpj': {
        Swal.fire({
          icon: 'info',
          html:msg25,
          confirmButtonText:'Ok'  ,
          
        })
        break;
      }
      case 'cadastroIntegrado': {
        Swal.fire({
          icon: 'info',
          html:msg26,
          confirmButtonText:'Ok',
          
        })
        break;
      }
      case 'urlApi': {
        Swal.fire({
          icon: 'info',
          html:msg27,
          confirmButtonText:'Ok',
          
        })
        break;
      }
      case 'mensagemContribuinteNaoEncontrado': {
        Swal.fire({
          icon: 'info',
          html:msg28,
          confirmButtonText: 'Ok',
          
        })
        break;
      }
      default: {
         //statements;
         break;
      }
   }
  }

  limpaCampos(){
    this.urlApi = '';
    this.mensagemContribuinteNaoEncontrado = '';
  }
}
