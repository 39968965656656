import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { GerenciarUsuariosComponent } from "./gerenciar-usuarios/gerenciar-usuarios.component";
import { AuthGuardService } from '../../service/auth-guard/auth-guard.service';
import { ConsultarUsuariosComponent } from "./gerenciar-usuarios/paginas/consultar-usuarios/consultar-usuarios.component";
import { AdicionarUsuarioComponent } from "./gerenciar-usuarios/paginas/adicionar-usuario/adicionar-usuario.component";
import { OpcoesUsuarioComponent } from "./gerenciar-usuarios/paginas/opcoes-usuario/opcoes-usuario.component";
import { FormularioProcurador } from "./gerenciar-usuarios/paginas/formulario-procurador/formulario-procurador.component";
import { FormularioContribuinteComponent } from "./gerenciar-usuarios/paginas/formulario-contribuinte/formulario-contribuinte.component";
import { FormularioAdministradorAuditor } from "./gerenciar-usuarios/paginas/formulario-adm-auditor/formulario-adm-auditor.component";
import { ProcuracaoComponent } from "./gerenciar-usuarios/paginas/procuracao/procuracao.component";
import { EditarUsuarioComponent } from "./gerenciar-usuarios/paginas/editar-usuario/editar-usuario.component";

const routes: Routes = [
  {
    path: 'gestao-usuario', 
    canActivate: [AuthGuardService],
    component: GerenciarUsuariosComponent,
    data: { breadcrumb: 'Gestão de Usuário', modulo: '1010'}, 
    children: [
      { path: '', redirectTo: 'consultar', pathMatch: 'full' },
      {
        path: 'consultar',
        component: ConsultarUsuariosComponent,
      },
      {
        path: 'definir',
        component: AdicionarUsuarioComponent,
        data: { breadcrumb: 'Criar novo usuário' },
        children: [
          {
            path: '',
            redirectTo: 'opcoes',
            pathMatch: 'full',
          },
          {
            path: 'opcoes',
            component: OpcoesUsuarioComponent,
          },
          {
            path: 'procurador',
            component: FormularioProcurador,
            data: { breadcrumb: 'Incluir Procurador' },
          },
          {
            path: 'contribuinte',
            component: FormularioContribuinteComponent,
            data: { breadcrumb: 'Incluir Contribuinte' },
          },
          {
            path: 'adm-auditor',
            component: FormularioAdministradorAuditor,
            data: { breadcrumb: 'Incluir Administrador ou Auditor' },
          }
        ]
      },
      {
        path: 'definir/:id',
        component: EditarUsuarioComponent,
        data: { breadcrumb: 'Editar Usuário' },
      },
      {
        path: 'procuracao/:procurador',
        component: ProcuracaoComponent,
        data: { breadcrumb: 'Visualizar Procuracao' }
      } 
    ]

  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class GestaoUsuarioRoutingModule {}
