import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

@Injectable()
export class VersaoService {

    url: string = environment.BASE_URL;

    constructor(private http: HttpClient) { }

    obterVersao(): Observable<any> {
        return this.http.get(`${this.url}/versao`).pipe( map(data => {
            return data;
        }) );
    }

}
