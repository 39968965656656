import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './service/auth-guard/auth-guard.service';
import { NotFoundComponent } from './features/not-found/not-found.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () => import('./features/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'home',
    loadChildren: () => import('./features/home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'registro',
    loadChildren: () => import('./features/registro-acoes/registro-acoes.module').then(m => m.RegistroAcoesModule),
  },
  {
    path: 'gerenciar-comunicados',
    loadChildren: () => import('./features/gerenciar-comunicados/gerenciar-comunicados.module').then(m => m.GerenciarComunicadosModule),
  },
  {
    path: 'caixa-postal',
    loadChildren: () => import('./features/caixa-postal-eletronica/caixa-postal-eletronica.module')
    .then(m => m.CaixaPostalEletronicaModule),
  },
  {
    path: 'gestao-usuario',
    loadChildren: () => import('./features/gestao-usuario/gestao-usuario.module')
    .then(m => m.GestaoUsuarioModule),
  },
  {
    path: 'analise-dados',
    loadChildren: () => import('./features/analise-dados/analise-dados.module').then(m => m.AnaliseDadosModule),
  },
  {
    path: 'configuracao',
    loadChildren: () => import('./features/configuracao/configuracao.module').then(m => m.ConfiguracaoModule),
  },
  {
    path: 'integracao',
    loadChildren: () => import('./features/integracao/integracao.module').then(m => m.IntegracaoModule),
  },
  {
    path: '404',
    component: NotFoundComponent,
    data: { breadcrumb: 'Página não Encontrada' },
  },
  { path: '**', redirectTo: '404' },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
