import { Component, ViewChild, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Valida } from '../../../../../form-utils/valida';
import { ParametroAssinaturaDigitalService } from '../../../../../service/parametro-assinatura-digital/parametro-assinatura-digital.service';
import { AmbienteService } from '../../../../../service/ambiente/ambiente.service';
import { Subscription } from '../../../../../../../node_modules/rxjs';
import * as moment from 'moment';

import Swal from 'sweetalert2';
import { getSwal } from '../../../../../utils/swalObject';
import { ActivatedRoute, Router } from '@angular/router';
import { element } from 'protractor';
import { SetorAtuacaoService } from '../../../../../service/configuracao/setor-atuacao.service';


@Component({
  selector: 'app-editar-parametro-assinatura-digital',
  templateUrl: './editar-parametro-assinatura-digital.component.html',
  styleUrls: ['./editar-parametro-assinatura-digital.component.scss']
})
export class EditarParametroAssinaturaDigitalComponent implements OnInit {
  @ViewChild('childFilter', {static: false}) child;
  routeSub: Subscription;
  editarAmbienteForm: FormGroup;
  searchForm: FormGroup;
  dialogMessage = {};
  viewDialog = false;
  itemsPerView: number = 10;
  loading: boolean;
  idPerfilAcesso: number = null;
  dadosDeAmbiente: any = {};
  registroAmbiente: any = [];
  registroSalvo: any = [];
  tiposAssinaturaEletronica = [];
  tiposAssinaturaEletronicaLoop = [];
  tiposPersonalidadeJuridica = [];
  tiposPersonalidadeJuridicaLength = 0
  tiposPersonalidadeJuridicaLoop = [];
  tiposSetorAtuacao = [];
  tiposSetorAtuacaoLength = 0
  tiposSetorAtuacaoLoop = [];
  tipoUsuario: any = {
    codigo: -1,
    descricao: 'Selecione'
  };
  tipoAssinatura: any = {
    codigo: -1,
    descricao: 'Selecione'
  };

  tipoPersonalidade: any = {
    codigo: -1,
    descricao: 'Selecione'
  };
  autoCadastro: number = null;
  exigeCertificadoCnpj: boolean = false;
  teste:string='teste';
  data = [];
  registros = [];

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private ambienteService: AmbienteService,
    private parametroAssinaturaDigitalService: ParametroAssinaturaDigitalService
  ) {

    this.routeSub = this.route.params.subscribe(params => {
      if (params['id']) {
        this.idPerfilAcesso = params['id'];
      }
    });
  }


  ngOnInit() {
    this.criarFormulario();

      this.parametroAssinaturaDigitalService.obterAssinaturasEletronicas().subscribe(async res => {
      // this.tiposAssinaturaEletronica = [
      //   {
      //     codigo: -1,
      //     descricao: 'Selecione'
      //   }
      // ];
      this.tiposAssinaturaEletronicaLoop = await this.tiposAssinaturaEletronicaLoop.concat(res.resultado);
    });

    this.parametroAssinaturaDigitalService.obterSetoresAtuacao().subscribe(async res => {
    this.tiposSetorAtuacaoLength = await res.resultado.length;
    });

    // this.parametroAssinaturaDigitalService.obterPersonalidadeJuridica().subscribe(async res => {
    // //   // this.tiposPersonalidadeJuridica = [
    // //   //   {
    // //   //     codigo: -1,
    // //   //     descricao: 'Selecione'
    // //   //   }
    // //   // ];
    //   this.tiposPersonalidadeJuridicaLoop = await this.tiposPersonalidadeJuridicaLoop.concat(res.resultado);
    // });

 

    // this.parametroAssinaturaDigitalService.obterSetoresAtuacao().subscribe(async res => {
    // //   this.tiposSetorAtuacao = [
    // //     {
    // //       codigo: -1,
    // //       descricao: 'Selecione'
    // //     }
    // //   ];
    //   this.tiposSetorAtuacaoLoop = await this.tiposSetorAtuacaoLoop.concat(res.resultado);
    // });

  }

  ngAfterViewInit() {
    if (this.idPerfilAcesso) {
      this.obterDados();
    }
  }

  functionAssinatura(e){
    console.log('e:',e)
    this.tiposPersonalidadeJuridicaLoop = []
    this.tiposSetorAtuacaoLoop = []
    if(e.codigo != -1){
      if(this.registroSalvo.length === 0){
      this.parametroAssinaturaDigitalService.obterPersonalidadeJuridica().subscribe(async res => {
        this.tiposPersonalidadeJuridicaLoop = await this.tiposPersonalidadeJuridicaLoop.concat(res.resultado);
      });
      this.parametroAssinaturaDigitalService.obterSetoresAtuacao().subscribe(async res => {
        this.tiposSetorAtuacaoLoop = await this.tiposSetorAtuacaoLoop.concat(res.resultado);
    });
    }else{
      this.parametroAssinaturaDigitalService.obterPersonalidadeJuridica().subscribe(async res => {
        var i = 0;
        res.resultado.forEach(element => {
          var qtd = 0 
          this.registroSalvo.forEach(ele => {
            if (ele.personalidadeJuridica.codigo === element.codigo && ele.assinaturaEletronica.codigo === e.codigo){
              qtd ++
            }
          });
          console.log('qtd:', qtd)
          console.log('this.tiposSetorAtuacaoLength:', this.tiposSetorAtuacaoLength)
          if(qtd >= this.tiposSetorAtuacaoLength){
              res.resultado[i].bloqueado = true
            }else{
              res.resultado[i].bloqueado = false
            }

          // console.log('salvo:', this.registroSalvo)
          // if(this.registroSalvo.setorAtuacao.codigo.length >= this.tiposSetorAtuacaoLength){
          //   res.resultado[i].bloqueado = true
          // }else{
          //   res.resultado[i].bloqueado = false
          // }
          i++
        });
        console.log('res:', res)
        
        

        this.tiposPersonalidadeJuridicaLoop = await this.tiposPersonalidadeJuridicaLoop.concat(res.resultado);
      });
      console.log('passou:: ', this.tiposPersonalidadeJuridicaLoop)
      this.parametroAssinaturaDigitalService.obterSetoresAtuacao().subscribe(async res => {
        this.tiposSetorAtuacaoLoop = await this.tiposSetorAtuacaoLoop.concat(res.resultado);
    });


    }




    }

  }


  functionPersonalidade(e){
    console.log('this.registroSalvo.length:', this.registroSalvo.length)
    console.log('this.registroSalvo:', this.registroSalvo)
    console.log('tipoa:', this.tipoAssinatura)
    console.log('tipoPersonalidade:', e)


    console.log(this.registroSalvo)
    this.tiposSetorAtuacaoLoop.forEach(element => {
        console.log('tipo', element);
    });
    
    this.tiposSetorAtuacao = []
    this.tiposSetorAtuacaoLoop.forEach(tipo => {
        tipo.selecionado = false
        tipo.bloqueado = false
          this.registroSalvo.forEach(element => {
            if(this.tipoAssinatura.codigo === element.assinaturaEletronica.codigo && e.codigo === element.personalidadeJuridica.codigo){
              console.log('salvo', element);
              console.log('element atuacao', element.setorAtuacao.codigo)
              if(element.setorAtuacao.codigo === tipo.codigo)
              tipo.bloqueado = true
            }
          });
          this.tiposSetorAtuacao =  this.tiposSetorAtuacao.concat(tipo);
        });
        console.log('this.tiposSetorAtuacaoLoop:', this.tiposSetorAtuacaoLoop)

    // var i= 0
      // this.tiposPersonalidadeJuridica.forEach(tipo => {
      //   tipo.selecionado = false
      //   tipo.bloqueado = false
      //   if(i === 0 ) tipo.selecionado = true;
      //   this.registroSalvo.forEach(tipoSalvo => {
      //     if(tipo.codigo === tipoSalvo.personalidadeJuridica.codigo){
      //       tipo.bloqueado = true
      //     }
      //   });
      //   this.tiposPersonalidadeJuridicaLoop =  this.tiposPersonalidadeJuridicaLoop.concat(tipo);
      //   i++
      // });

  }
    criarFormulario() {
    this.editarAmbienteForm =  this.fb.group({
      razaoSocial:  [''],
      ativo: [false],
      inicioContrato: '',
      fimContrato: '',
      inicioContratoFormatado: '',
      fimContratoFormatado: '',
      configuracao: this.fb.group({
        tipoAprovacaoUsuario: [
          {
            codigo: -1,
            descricao: 'Selecione'
          }
        ],
        url: '',
        apiAlfresco: [''],
        urlAuditor: [''],
        urlContribuinte: [''],
        usuarioEscrita: [''],
        senhaEscrita: [''],
        pastaAlfresco: [''],
        hostSmtp: [''],
        portaSmtp: [''],
        usuarioSmtp: [''],
        senhaSMTP: [''],
        endpointSms: [''],
        contaSms: [''],
        codigoSms: [''],
        alertaProcuracao: [''],
        prazoProcuracao: [0],
        autoCadastro: null,
        exigeCertificadoCnpj: null,
        exigeTermoAdesao: false,
        tituloDte: [''],
        logoDte:''
      }),
      regrasAssinatura: this.fb.group({
        assinaturaEletronica: {
          codigo: -1,
          descricao: 'Selecione'
        },
        personalidadeJuridica: {
          codigo: -1,
          descricao: 'Selecione'
        },
        setorAtuacao: {
          codigo: -1,
          descricao: 'Selecione'
        }
      })
    });
  }


  openDialog(item) {
    // Swal.fire(item.tipoEventoTrilha.descricao, item.descricao, 'info');
    Swal.fire(
      getSwal(
        'info',
        item.tipoEventoTrilha.descricao,
        item.descricao,
        'ok',
        null
      )
    );
  }


  carregarDadosFormulario(data) {
    for (let key in data) {
      if (this.editarAmbienteForm.controls[key] && data[key] !== null) {
        this.editarAmbienteForm.controls[key].patchValue(data[key]);
      }
    }


    if (data.configuracao !== null) {
      this.tipoUsuario = data.configuracao.tipoAprovacaoUsuario;
      this.editarAmbienteForm
        .get('configuracao')
        ['controls'].tipoAprovacaoUsuario.patchValue(this.tipoUsuario);
      this.editarAmbienteForm.controls.configuracao[
        'controls'
      ].senhaSMTP.setValue(data.configuracao.senhaSmtp);
      this.editarAmbienteForm.controls.configuracao[
        'controls'
      ].urlContribuinte.setValue(data.configuracao.urlContribuinte);

      this.editarAmbienteForm.controls.configuracao[
        'controls'
        ].autoCadastro.setValue(data.configuracao.autoCadastro);

      this.editarAmbienteForm.controls.configuracao[
        'controls'
        ].exigeCertificadoCnpj.setValue(data.configuracao.exigeCertificadoCnpj);

      this.autoCadastro = data.configuracao.autoCadastro;

      this.exigeCertificadoCnpj = data.configuracao.exigeCertificadoCnpj
      

    } else {
      this.tipoUsuario = 0;
    }
  }

  obterDados() {
    this.ambienteService.obterDadosAmbiente(this.idPerfilAcesso).subscribe(
      res => {
        //USE TO TEST: res['resultado'].fimContrato = "";
        res.resultado.fimContratoFormatado =
          res.resultado.fimContrato && res.resultado.fimContrato !== null
            ? moment(res.resultado.fimContrato).format('DD/MM/YYYY')
            : '';
        if (
          res.resultado.fimContratoFormatado.toLowerCase() == 'invalid date'
        ) {
          res.resultado.fimContratoFormatado = ' ';
        }

        this.dadosDeAmbiente = res.resultado;
        this.registroAmbiente = this.dadosDeAmbiente.regrasAssinatura
        this.registroSalvo = this.dadosDeAmbiente.regrasAssinatura
        
        this.carregarDadosFormulario(this.dadosDeAmbiente);
      },
      error => {
        Swal.fire(
          getSwal(
            'error',
            'Ops!',
            'Ocorreu um erro e o Ambiente não pôde ser carregado.',
            'ok',
            null
          )
        );
      }
    );
  }

  checkChanges() {
    let value = this.editarAmbienteForm.getRawValue();
    console.log('value::', value)
    const updatedValue = {};
    for (let key in value) {
      if (
        value[key] !== this.dadosDeAmbiente[key] &&
        value[key] !== 'configuracao'
      ) {
        this.dadosDeAmbiente[key] = value[key];
      }
    }

    for (let key in value.configuracao) {
      if (
        value.configuracao[key] !== this.dadosDeAmbiente.configuracao[key] &&
        value[key] !== 'tipoAprovacaoUsuario'
      ) {
        this.dadosDeAmbiente.configuracao[key] = value.configuracao[key];
      }

      if (
        value.configuracao[key] &&
        value.configuracao[key] !== null &&
        value.configuracao[key].toString().length === 0
      ) {
        delete this.dadosDeAmbiente.configuracao[key];
      }
    }

    var regraUso = {};
    
    
    if(this.dadosDeAmbiente.regraUso){
      regraUso = {
        tipoComunicado: {codigo: this.dadosDeAmbiente.regraUso.tipoComunicado.codigo}
      }
      this.dadosDeAmbiente.regraUso = regraUso;
    } else {
      this.dadosDeAmbiente.regraUso = null;
    }


    
    this.registroSalvo.push(this.dadosDeAmbiente.regrasAssinatura)
    this.dadosDeAmbiente.regrasAssinatura = this.registroSalvo
    
    
    delete this.dadosDeAmbiente.inicioContratoFormatado;
    delete this.dadosDeAmbiente.fimContratoFormatado;

    
    return this.dadosDeAmbiente;
  }

  atualizar() {
    const obj = this.checkChanges();
    this.ambienteService
      .atualizarDadosAmbiente(this.dadosDeAmbiente.codigo, obj)
      .subscribe(
        res => {
          Swal.fire(
            getSwal(
              'success',
              'Sucesso',
              'Ambiente atualizado com sucesso.',
              'ok',
              null
            )
          ).then(res => {
            this.router.navigate(['/configuracao/ambiente/consultar-parametro-assinatura-digital/'+this.idPerfilAcesso]);
          });
        },
        error => {
          console.log(error);
          Swal.fire(
            getSwal(
              'error',
              'Ops!',
              error.error.excecao.recomendacao,
              'ok',
              null
            )
          );
        }
      );
  }

  rotaDesistir(){
    this.router.navigate(['/configuracao/ambiente/consultar-parametro-assinatura-digital/'+this.idPerfilAcesso]);
  }

  ajuda(valor) {
    let msg1=
    `<ol>
      <li> 
        Definir o comportamento da solução quanto a solicitação de assinatura de comunicados e documentos gerados na plataforma.
      </li>
    </ol>`;

    switch(valor) {
      case 'assinaturaDigital': {
        Swal.fire({
          icon: 'info',
          html:msg1,
          confirmButtonText:'Ok' ,
          
        })
        break;
      }
    }
  }

  
}
