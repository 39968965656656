import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CertificadoService } from '../../../service/certificado/certificado.service';
import { SessionService } from '../../../service/session/session.service';
import { LoginService } from '../../../service/login/login.service';
import { UserService } from '../../../service/user-service/user.service';
import { LacunaWebPKI, CertificateModel, ExceptionModel } from '../../../../assets/plugins/web-pki';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import Swal from 'sweetalert2';
import { getSwal } from '../../../utils/swalObject';

@Component({
  selector: 'app-e-cnpj',
  templateUrl: 'e-cnpj.component.html',
  styleUrls: ['./e-cnpj.component.scss']
})

export class ECnpjComponent implements OnInit {

  empresas: Array<any> = [];
  userInfo: any = null;
  allow: boolean = false;
  loading:boolean = true;
  certificado:any = null;
  private pki: LacunaWebPKI;
  codigoEmpresa:number = null
  private ngUnsubscribe: Subject<any> = new Subject<any>()

  constructor(
    private sessionService: SessionService, 
    private loginService: LoginService,
    private userService: UserService, 
    private ngZone: NgZone, 
    private router: Router, 
    private config: CertificadoService) { 
      this.pki = new LacunaWebPKI(config.webPkiLicense);
      
    }

  ngOnInit() {
    this.pki.init({
      ngZone: this.ngZone,
      ready: this.onWebPkiReady
    });
    this.userInfo = this.sessionService.getSession();
    this.empresas = this.userInfo.empresas;
    this.empresas.forEach(elem => {
      elem['selected'] = false;
    })
  }

  private onWebPkiReady = () => {
    this.certificado = this.loginService.dadosCertificado['certificado'];
    console.log(this.certificado)
    this.loading = false;
  }

  select(empresa) {
    empresa.selected = true;
    this.empresas.map(elem => {
      if (elem.codigo !== empresa.codigo) elem['selected'] = false
    })
    this.allow = true;
    this.codigoEmpresa = empresa.codigo;
    
  }

  proximo(){
    this.loading = true;
    this.loginService.getNonce()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
        const thumbprint = this.certificado['thumbprint'];
        this.pki.readCertificate(thumbprint).success((certEncoded) => {
          this.pki.signData({
            thumbprint: thumbprint,
            data: res.resultado['nonce'],
            digestAlgorithm: res.resultado['digestAlgorithm']
          }).success((signature) => {
            this.loginService.loginViaCertificadoEmpresas(res.resultado['nonce'], certEncoded, signature, this.codigoEmpresa).subscribe(res => this.handleResponse(res), error => this.handleError(error))
          })
        })
      })
  }

  handleResponse(res){
    this.userService.sendInfo({loggedIn: false, ...res.resultado});
    this.router.navigate(['../clientes']);
  }

  handleError(error) {
    // Swal.fire(
    //   'Ops!',
    //   error.excecao.recomendacao,
    //   'error'
    // );
    Swal.fire(
      getSwal(
        'error',
        'Ops!',
        error.excecao.recomendacao,
        'ok',
        null,
      ),
    );    
    this.router.navigate(['../certificado']);
  }
}