import {
  Component,
  Input,
  Output,
  OnChanges,
  EventEmitter,
  SimpleChanges,
  OnInit,
  SimpleChange,
} from '@angular/core';
import { AplicacoesService } from '../../../../../service/aplicacoes/aplicacoes.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tabela-formas-acesso',
  templateUrl: 'tabela-formas-acesso.component.html',
  styleUrls: ['./tabela-formas-acesso.component.scss'],
})
export class TabelasFormaAcessoComponent implements OnChanges, OnInit {
  @Input() perfis: any = [];
  @Input() aplicacao: any = [];
  @Input() idFuncionalidade: any = [];
  @Output() onEdit: EventEmitter<any> = new EventEmitter<any>();

  data: Array<any> = [];

  constructor(private aplicacoesService: AplicacoesService) {}

  ngOnInit() {
    this.aplicacao['funcionalidades'].forEach(app => {
      if (app.codigo === this.idFuncionalidade) {
        this.data = app.perfis;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('changes', changes, this.data);
    // this.data = changes['perfis'].currentValue;
  }

  edit(item) {
    this.onEdit.emit(item);
  }

  remove(item, index) {
    this.data.splice(index, 1);

    // this.data = this.data.filter(perfil => {
    //   return perfil.formaAcesso.codigo !== item.formaAcesso.codigo;
    // });

    this.aplicacao['funcionalidades'].forEach(app => {
      if (app.codigo === this.idFuncionalidade) {
        app.perfis = this.data;
      }
    });
  }
}
