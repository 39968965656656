import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgxMaskModule } from 'ngx-mask'
import { GerenciarComunicadosRoutingModule } from './gerenciar-comunicados.routing';
import { DataTableModule } from "ng2-data-table";
import { DpDatePickerModule } from 'ng2-date-picker';
import { CommonAppModule } from '../../features/common/common.module'
import { FiltrarComunicadosComponent } from './componentes/filter/filter.component'
import { GraficosComunicadosComponent } from './componentes/graficos/graficos.component';
import { ListaTiposComunicadosComponent } from './pages/lista-tipos-comunicados/lista-tipos-comunicados.component';
import { IncluirTiposComunicadosComponent } from './pages/incluir-comunicados/incluir-comunicados.component';
import { TiposComunicadoComponent } from './pages/tipos-comunicado/tipos-comunicado.component';
import { jqxTreeModule } from 'jqwidgets-ng/jqxtree';
import { NgxSummernoteModule } from 'ngx-summernote';

import { AuthGuardService } from '../../service/auth-guard/auth-guard.service';

import { SharedModule } from '../../shared.module';

@NgModule({
  imports: [
    CommonModule, 
    DpDatePickerModule, 
    CommonAppModule , 
    NgxMaskModule, 
    FormsModule, 
    ReactiveFormsModule, 
    HttpClientModule, 
    GerenciarComunicadosRoutingModule,
    DataTableModule,
    SharedModule,
    jqxTreeModule,
    NgxSummernoteModule
  ],
  
  exports: [],
    
  declarations: [
    IncluirTiposComunicadosComponent,
    ListaTiposComunicadosComponent,
    GraficosComunicadosComponent,
    FiltrarComunicadosComponent,
    TiposComunicadoComponent
  ],
  providers: [AuthGuardService],
})
export class GerenciarComunicadosModule { }
