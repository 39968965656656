import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RegistroAcoesService } from '../../../../../service/registro-acoes/registro-acoes.service';
import { graphicCollors } from '../../../../../../environments/version';

import Swal from 'sweetalert2';
import { getSwal } from '../../../../../utils/swalObject';

@Component({
  selector: 'app-grafico',
  templateUrl: 'grafico.component.html',
  styleUrls: ['./grafico.component.scss'],
})
export class GraficoComponent implements OnInit {
  numServicosAtivos: number;
  numServicosInoperantes: number;
  numServicosAlertas: number;
  max = 0;
  selected = 'ativos';
  gColors = graphicCollors;

  constructor(
    private registroAcoesService: RegistroAcoesService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.registroAcoesService.getRegistrosAtivos().subscribe(data => {
      this.numServicosAtivos = data.resultado;
    });

    this.registroAcoesService.getRegistrosInativos().subscribe(data => {
      this.numServicosInoperantes = data.resultado;
    });

    this.registroAcoesService.getRegistrosAlertas().subscribe(data => {
      this.numServicosAlertas = data.resultado;
    });
  }

  serviceActives() {
    if (this.numServicosAtivos > 0) {
      this.router.navigate(['registro/servicos-ativos']);
    } else {
      // Swal.fire('Ops!', 'Não existem serviços para exibir', 'error');
      Swal.fire(
        getSwal(
          'error',
          'Ops!',
          'Não existem serviços para exibir',
          'ok',
          null,
        ),
      );
    }
  }

  serviceDead() {
    if (this.numServicosInoperantes > 0) {
      this.router.navigate(['registro/servicos-inoperantes']);
    } else {
      // Swal.fire('Ops!', 'Não existem serviços para exibir', 'error');
      Swal.fire(
        getSwal(
          'error',
          'Ops!',
          'Não existem serviços para exibir',
          'ok',
          null,
        ),
      );      
    }
  }

  serviceAlerts() {
    if (this.numServicosAlertas > 0) {
      this.router.navigate(['registro/servicos-alertas']);
    } else {
      // Swal.fire('Ops!', 'Não existem serviços para exibir', 'error');
      Swal.fire(
        getSwal(
          'error',
          'Ops!',
          'Não existem serviços para exibir',
          'ok',
          null,
        ),
      );      
    }
  }
}
