import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icons-validacao',
  templateUrl: 'icons-validacao.component.html',
  styleUrls: ['./icons-validacao.component.scss'],
})
export class IconsValidacaoComponent implements OnInit {
  @Input() error!: any;

  constructor(

  ) {}

  ngOnInit() {
  }

  

}