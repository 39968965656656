import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";

import { jqxTreeComponent } from "jqwidgets-ng/jqxtree";
import { OrganogramaService } from "src/app/service/organograma/organograma.service";
import Swal from "sweetalert2";
import * as moment from "moment";

@Component({
  selector: 'app-visualizar-organograma',
  templateUrl: './visualizar-organograma.component.html',
  styleUrls: ['./visualizar-organograma.component.scss']
})

export class VisualizarOrganogramaComponent implements  OnInit, AfterViewInit {
  @ViewChild('myTree', {static: true}) myTree: jqxTreeComponent;

  routeSubscription: Subscription;
  codigoOrganograma: number;
  dataFormatada: any;
  loading: boolean = false;
  
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private organogramaService: OrganogramaService
  ) {

  }

  visualizarOrganogramaForm = this.formBuilder.group({
    nome: '',
    dataCriacao: '',
    ativo: ''
  });

  ngOnInit(): void {
    this.loading = true;
    this.routeSubscription = this.route.params.subscribe(params => {
      this.codigoOrganograma = params['id'];
      this.organogramaService.getOrganogramaData(this.codigoOrganograma)
        .subscribe(data => {
          this.dataFormatada = data[ 'resultado'][0]['dataCriacao'];
          this.dataFormatada = moment(this.dataFormatada).format('DD/MM/YYYY');
          
          this.visualizarOrganogramaForm.patchValue({
            nome: data['resultado'][0]['text'],
            dataCriacao: this.dataFormatada,
            ativo: data['resultado'][0]['ativo']
          })
        })
    });
  }

  ngAfterViewInit(): void {
    this.loadData();
  }

  data: any[] = [];
  source = {
    datatype: 'json',
    datafields: [
      { name: 'id' },
      { name: 'parentId' },
      { name: 'text' }
    ],
    id: 'id',
    localdata: null
  };

  dataAdapter = new jqx.dataAdapter(this.source);

  records: any = this.dataAdapter.getRecordsHierarchy('id', 'parentId', 'items', [{ name: Text, map: 'label'}]);

  private loadData(): void {
    this.organogramaService.getOrganogramaData(this.codigoOrganograma).subscribe(data => {
      this.data = data['resultado'];
      setTimeout(() => {
        this.source.localdata = data;
        this.dataAdapter = new jqx.dataAdapter(this.source, { autoBind: true });
        this.records = this.dataAdapter.getRecordsHierarchy('id', 'parentId', 'items', [{ name: 'text', map: 'label'}]);
        this.myTree.refresh();
        this.loading = false;
      }, 1000)
    });
  }

  ExpandAllOnClick(): void {
    this.myTree.expandAll();
  }

  CollapseOnClick(): void {
    this.myTree.collapseAll();
  }

  voltar() {
    this.router.navigate(['/configuracao/ambiente/consultar-organograma/1'])
  }
}