import { Component, OnInit } from '@angular/core';
import { AplicacoesService } from '../../../../../service/aplicacoes/aplicacoes.service';
import Swal from 'sweetalert2';
import { getSwal } from '../../../../../utils/swalObject';
import { Router, NavigationExtras } from '@angular/router';
import { gerenciarSistemasRouterPath } from '../../../../../../environments/version';

@Component({
  selector: 'app-consultar-servicos',
  templateUrl: 'consultar-servicos.component.html',
  styleUrls: ['consultar-servicos.component.scss'],
})
export class ConsultarServicosComponent implements OnInit {
  data: Array<any> = [];
  codigoAplicacao: number = null;
  aplicacao: any;
  action: string;
  loading = false;
  navigationData: any;

  constructor(
    private router: Router,
    private aplicacoesService: AplicacoesService,
  ) {
    this.navigationData = this.router.getCurrentNavigation();
  }

  ngOnInit() {
    window.scrollTo(0, 0);

    if (!this.navigationData.extras.state || (!this.navigationData.extras.state[1] || this.navigationData.extras.state[1] == null)) {
      this.router.navigateByUrl(`${gerenciarSistemasRouterPath}/`);
      return;
    }

    this.aplicacao = this.navigationData.extras.state[1];
    this.action = this.navigationData.extras.state[0];

    if (!this.navigationData.extras.state) {
      this.router.navigateByUrl(`${gerenciarSistemasRouterPath}/`);
      return;
    }
    this.data = this.aplicacao['endpoints'];
    console.log('ngoninit: ', this.data)
    
  }

  desistir() {
    this.router.navigate([`${gerenciarSistemasRouterPath}/consultar`]);
  }

  edit(codigo: String) {
    if (codigo === 'novo') {
      let lastCode = 0;
      this.aplicacao['endpoints'].forEach(element => {
        lastCode = lastCode > element.codigo ? lastCode : element.codigo + 1;
      });
      codigo = lastCode + 'novo';
    }
  
    const urlCode = codigo.toString().indexOf('novo')
      ? codigo.toString().replace('novo', '')
      : codigo;
  
    this.router.navigateByUrl(`${gerenciarSistemasRouterPath}/editar/${
      this.aplicacao['codigo'] !== null ? this.aplicacao['codigo'] : 'novo'
    }/endpoints/${urlCode}/editar`, {state: [codigo, this.action, this.aplicacao]});
  }
  
  onSubmit(codigo) {
    if (codigo === 'novo') {
      console.log('codigonovo: ', codigo)
      let lastCode = 0;
      this.aplicacao['endpoints'].forEach(element => {
        lastCode = lastCode > element.codigo ? lastCode : element.codigo + 1;
      });
      codigo = lastCode + 'novo';
    }
    
    this.aplicacao['endpoints'].forEach(element => {
      console.log('this.aplicacaoendpoint', this.aplicacao['endpoints'])
      if (element.codigo !== null && element.codigo !== undefined) {
        element.codigo = element.codigo.toString().indexOf('novo') !== -1 ? null : element.codigo;
      } else {
        console.log('element.codigo', element.codigo)
        element.codigo = element.codigo;
      }
    });
    if (
      this.aplicacao['funcionalidades'] !== null &&
      this.aplicacao['funcionalidades'].length === 0
    ) {
      this.aplicacao['funcionalidades'] = null;
    }
  
    if (
      this.aplicacao['consumos'] !== null &&
      this.aplicacao['consumos'].length === 0
    ) {
      this.aplicacao['consumos'] = null;
    }
  
    if (
      this.aplicacao['endpoints'] !== null &&
      this.aplicacao['endpoints'].length === 0 ||
      this.aplicacao['endpoints'] == ''
    ) {
      this.aplicacao['endpoints'] = null;
    }
  
    if (this.action === 'editar') {
      console.log('chamou editar')
      this.aplicacoesService
        .editaAplicacao(this.aplicacao['codigo'], this.aplicacao)
        .subscribe(
          res => this.handleResponse(),
          error => this.handleError(error),
        );
    } else {
      console.log('chamou else editar')
      this.aplicacoesService
        .criaAplicacao(this.aplicacao)
        .subscribe(
          res => this.handleResponse(),
          error => this.handleError(error),
        );
    }
  }
  

  handleResponse() {
    // Swal.fire('Sucesso!', 'Aplicação Criada com sucesso', 'success');
    Swal.fire(
      getSwal(
        'success',
        'Sucesso!',
        'Aplicação Criada com sucesso',
        'ok',
        null,
      ),
    );
    this.router.navigateByUrl(`${gerenciarSistemasRouterPath}`);
  }

  handleError(error) {
    this.loading = false;
    Swal.fire(
      getSwal(
        'error',
        'Ops!',
        'Ocorreu um erro e a aplicação não pôde ser criada.',
        'ok',
        null,
      ),
    );
  }
}
