import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';


@Injectable()
export class FormasAcessoService {

  url: string = environment.BASE_URL;

  private formasAcesso = new Subject<any>();
  formasAcesso$ = this.formasAcesso.asObservable();

  constructor(private http: HttpClient) { }

  getformasAcesso(searchParams): Observable<any> {
    const params = this.setParams(searchParams);
    return this.http.get(`${this.url}/formasAcesso`, { params })
      .pipe(map(data => {
        const body = data;
        this.formasAcesso.next(body);
        return body;
      }));
  }

  setParams(searchParams: any) {
    let params = new HttpParams();
    if (!searchParams) {
      return params;
    }
    if (searchParams.key && searchParams.value) {
      return params.set(searchParams.key, searchParams.value);
    }
    for (const key of Object.keys(searchParams)) {
      params = params.set(key, searchParams[key]);
    }
    return params;
  }


  obterformaAcesso(id: number): Observable<any> {
    return this.http.get(`${this.url}/formasAcesso/${id}`)
      .pipe(map(data => {
        const body = data;
        return body;
      }));
  }

  editaformaAcesso(params: any, code): Observable<any> {
    const formaAcesso = params;
    const codigo = code;
    return this.http.put(`${this.url}/formasAcesso/${codigo}`, formaAcesso)
      .pipe(map(data => {
        const body = data;
        return body;
      }));
  }

}
