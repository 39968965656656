import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { HomeRoutingModule } from './home.routing.module';
import { SharedModule } from '../../shared.module';
import { GraficosMensagensComponent } from './componentes/graficos/graficos.component';

@NgModule({
  imports: [HomeRoutingModule, CommonModule, SharedModule],
  exports: [],
  declarations: [HomeComponent, GraficosMensagensComponent],
  providers: []
})
export class HomeModule {}
