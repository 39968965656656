import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-opcoes-integracao',
  templateUrl: './opcoes-integracao.component.html',
  styleUrls: ['./opcoes-integracao.component.scss'],
})
export class OpcoesIntegracaoComponent implements OnInit {
  labels: any;

  constructor(private translate: TranslateService) {
    this.setLabels();
  }

  ngOnInit() {}

  setLabels() {
    // TODO implementar i18n
    // this.labels = {
    //   prazosAVencer: this.translate.instant('GLOBAIS.PRAZOS')+' '+this.translate.instant('GLOBAIS.AVENCER'),
    //   prazosVencidos: this.translate.instant('GLOBAIS.PRAZOS')+' '+this.translate.instant('GLOBAIS.VENCIDOS')
    // };
  }
}
