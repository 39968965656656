import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AmbienteService } from '../../../../../service/ambiente/ambiente.service';

@Component({
  selector: '[app-filtrar-ambiente]',
  templateUrl: 'filtro.component.html',
  styleUrls: ['./filtro.component.scss'],
})
export class FiltrarAmbienteComponent implements OnInit {
  searchForm: FormGroup;
  searchParams = {};
  errorMessage = '';
  subscription: Subscription;
  searchInput: string = '';

  constructor(
    private fb: FormBuilder,
    private ambienteService: AmbienteService,
  ) {
    this.searchForm = this.fb.group({
      assunto: '',
    });
  }

  ngOnInit() {
    this.ambienteService
      .obterClientes({})
      .subscribe(res => console.log(res), error => (this.errorMessage = error));
  }

  inputEmpty() {
    if (
      this.searchInput === '' &&
      this.searchParams['razaoSocial'] &&
      this.searchParams['razaoSocial'].length > 0
    ) {
      delete this.searchParams['razaoSocial'];
      this.search();
    }
  }

  formSubmit() {
    this.searchParams['razaoSocial'] = this.searchForm.controls.assunto.value;
    this.search();
  }

  search() {
    this.ambienteService
      .obterClientes(this.searchParams)
      .subscribe(data => console.log(data));
  }
}
