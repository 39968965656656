import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-filtro-protocolo',
  templateUrl: 'filtro-protocolo.component.html',
  styleUrls: ['./filtro-protocolo.component.scss'],
})
export class FiltroProtocoloComponent implements OnInit {
  @Output() onFilter: EventEmitter<any> = new EventEmitter<any>();

  @Output() onOpen: EventEmitter<any> = new EventEmitter<any>();
  searchForm: FormGroup;
  protocolo: string = '';
  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.criarFormulario();

    const sessaoFiltro = JSON.parse(sessionStorage.getItem('filtro'));

    if(sessionStorage.getItem('filtro') && sessaoFiltro.protocolo){
      this.searchForm.get('protocolo').setValue(sessaoFiltro.protocolo);
    } else {
      this.searchForm.get('protocolo').setValue('');
    }
  }

  criarFormulario() {
    this.searchForm = this.fb.group({
      protocolo: '',
    });
  }

  pesquisarProtocolo() {
    this.onFilter.emit(this.searchForm.value);
    // this.searchForm.reset();
  }

}
