import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpParams,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

@Injectable()
export class CadastroService {
  url: string = environment.BASE_URL;

  constructor(private http: HttpClient) { }

  clientes(): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http
      .get(`${this.url}/clientes/ativos`)
      .pipe( map(data => {
        return data;
      }));
  }

  cadastrarContribuinte(data: any): Observable<any> {
    const autocadastro = data;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http
      .post(`${this.url}/cadastrarContribuinte`, autocadastro)
      .pipe(map( r => {
        const body = r;
        return body;
      }));
    // .catch(this.handleError);
  }

  cadastrarProcurador(data: any): Observable<any> {
    const autocadastro = data;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http
      .post(`${this.url}/cadastrarProcurador`, autocadastro)
      .pipe( map(r => {
        const body = r;
        return body;
      }));
    // .catch(this.handleError);
  }

  validarUsuario(codigoUsuario, codigoValidacao, endpoint): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const params = new HttpParams({
      fromObject: {
        codigoUsuario,
        codigoVerificacao: codigoValidacao,
      },
    });
    return this.http
      .get(`${this.url}/${endpoint}`, { params })
      .pipe(map(data => {
        return data;
      }));
    // .catch(this.handleError);
  }

  definirSenha(codigoUsuario, senha, endpoint): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const params = new HttpParams({
      fromObject: {
        codigoUsuario,
        senha,
      },
    });
    return this.http
      .get(`${this.url}/${endpoint}`, { params })
      .pipe(map(data => {
        return data;
      }));
    // .catch(this.handleError);
  }

  private handleError(error: Response | any) {
    console.log(error.message || error);
    return Observable.throw(error.message || error);
  }
}
