import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ConsultaUsuarioService } from '../../../../../service/consulta-usuario/consultar.user.service';

@Component({
  selector: 'app-pesquisar-destinatarios',
  templateUrl: 'pesquisar-destinatario.component.html',
  styleUrls: ['./pesquisar-destinatario.component.scss'],
})
export class PesquisarDestinatariosComponent implements OnInit {
  @Output() onSelectUser: EventEmitter<any> = new EventEmitter<any>();

  destinatarioForm: FormGroup;
  mascara: string = 'A*';
  tipo: string = 'inscricao';
  loading: boolean = false;
  usuarios: Array<any> = [];
  buttonDisable: boolean = true;
  errorMessage: string = '';

  constructor(
    private fb: FormBuilder,
    private consultarUsuario: ConsultaUsuarioService,
  ) {}

  ngOnInit() {
    this.criarFormulario();
  }

  criarFormulario() {
    this.destinatarioForm = this.fb.group({
      tipo: '',
      numero: [
        '',
        Validators.compose([Validators.required, Validators.minLength(2)]),
      ],
    });
  }

  numeroChange() {
    setTimeout(() => {
      if (this.destinatarioForm.invalid) {
        this.buttonDisable = true;
      } else {
        this.buttonDisable = false;
      }
      console.log(' >> ', this.buttonDisable);
    }, 80);
  }

  alterarIdentificador(tipo: string) {
    this.destinatarioForm.controls.numero.setValue('');
    switch (tipo) {
      case 'cpf':
        this.mascara = '000.000.000-00';
        this.destinatarioForm.controls.numero.setValidators(
          Validators.minLength(11),
        );
        this.destinatarioForm.controls.numero.updateValueAndValidity();
        break;
      case 'cnpj':
        this.mascara = '00.000.000/0000-00';
        this.destinatarioForm.controls.numero.setValidators(
          Validators.minLength(8),
        );
        this.destinatarioForm.controls.numero.updateValueAndValidity();
        break;
      case 'inscricao':
        this.mascara = 'A*';
        this.destinatarioForm.controls.numero.setValidators(
          Validators.minLength(2),
        );
        this.destinatarioForm.controls.numero.updateValueAndValidity();
        break;
      case 'razaoSocial':
        this.mascara = '';
        this.destinatarioForm.controls.numero.setValidators(
          Validators.minLength(5),
        );
        this.destinatarioForm.controls.numero.updateValueAndValidity();
        break;
    }
  }

  limpaBusca() {}

  pesquisarDestinatario(limpa) {

    this.loading = true;
    let obj = {};
    this.errorMessage = '';

    const value = this.destinatarioForm.value;
    if (value.tipo === 'inscricao') {
      obj['inscricao'] = value.numero;
    } else if (value.tipo === 'razaoSocial') {
      obj['nomeRazaoSocial'] = value.numero;
    } else if (value.tipo === 'cnpj' || value.tipo === 'cpf') {
      obj['cpfCnpj'] = value.numero;
    } else {
    }
    this.consultarUsuario.consultarContribuintesAtivos(obj).subscribe(
      res => {
        this.errorMessage = '';
        this.loading = false;
        this.usuarios = res.resultado;
        if (this.usuarios.length === 0) {
          this.errorMessage =
            'Não encontramos resultados para sua pesquisa, tente novamente.';
        }
      },
      error => {
        this.loading = false;
        this.errorMessage =
          'Erro na busca: ' + error.error.excecao.recomendacao;
      },
    );
  }

  selectUser(user) {
    this.onSelectUser.emit(user);
  }
}
