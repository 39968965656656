import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';


@Injectable()
export class PerfisAcessoService {

  url: string = environment.BASE_URL;

  private perfisAcesso = new Subject<any>();
  perfisAcesso$ = this.perfisAcesso.asObservable();

  constructor(private http: HttpClient) {}

  getformasAcesso(searchParams): Observable<any> {
    const params = this.setParams(searchParams);
    return this.http.get(`${this.url}/perfisAcesso`, {params: params})
      .pipe( map(data => {
        const body = data;
        this.perfisAcesso.next(body);
        return body;
      }));
  }

  setParams(searchParams: any){
    let params = new HttpParams();
    if (!searchParams) {
      return params;
    }
    if (searchParams.key && searchParams.value) {
      return params.set(searchParams.key, searchParams.value);
    }
    for (const key of Object.keys(searchParams)) {
      params = params.set(key, searchParams[key]);
    }
    return params;
  }


  obterPerfilAcesso(id: number): Observable<any> {
    return this.http.get(`${this.url}/perfisAcesso/${id}`)
      .pipe( map(data => {
        const body = data;
        return body;
      }));
  }

  editaPerfilAcesso(params: any, code): Observable<any> {
    const perfilAcesso = params;
    const codigo = code;
    return this.http.put(`${this.url}/perfisAcesso/${codigo}`, perfilAcesso)
      .pipe( map(data => {
        const body = data;
        return body;
      }));
  }

}
