import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { SessionService } from '../session/session.service';
import { PerfilDataService } from '../perfil/perfil.service';

@Injectable()
export class UserService {
  private userInfo = new Subject<object>();
  private url = new Subject<string>();

  constructor(
    public sessionService: SessionService,
    public perfilService: PerfilDataService,
  ) {
    const data = this.sessionService.getSession();
    if (data) {
      this.userInfo.next({ ...data });
    }
  }

  sendInfo(userData: any) {
    return new Promise((resolve, reject) => {
      this.userInfo.next({ ...userData });
      this.sessionService.setSession({ ...userData });
      resolve();
    });
  }

  addData(valor: any) {
    const data = this.sessionService.getSession();
    const updatedObject = Object.assign(data, valor);
    return this.sendInfo(updatedObject);
  }

  updateDataWithPhoto(image: any) {
    const data = this.sessionService.getSession();
    data['usuario'].imagem = image;
    this.sendInfo(data);
  }

  getInfo(): Observable<any> {
    return this.userInfo.asObservable();
  }

  refreshUrl(url) {
    this.url.next(url);
  }

  getCurrentUrl(): Observable<any> {
    return this.url.asObservable();
  }
}
