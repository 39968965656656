import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { getSwal } from '../../../../utils/swalObject';
import { ActivatedRoute, Router } from '@angular/router';
import { loadavg } from 'os';
import {IntegracaoService} from "../../../../service/integracao/integracao.service";

@Component({
  selector: 'app-importar-usuarios',
  templateUrl: 'importar-usuarios.component.html',
  styleUrls: ['./importar-usuarios.component.scss'],
})
export class ImportarUsuariosComponent implements OnInit {
  loadingFile: Boolean = false;
  fileReady: Boolean = false;
  uploadingFile: Boolean = false;
  loading: Boolean = false;
  fileName: string = "Nenhum arquivo selecionado";
  fileToUpload: any;
  fileToManager: any;

  constructor(private router: Router, private route: ActivatedRoute,private integracao:IntegracaoService) {}

  ngOnInit() {}

  startUpload() {
    //Libera o upload do arquivo
    console.log('download manual');
    this.loadingFile = true;
    this.loading = true;

    setTimeout(() => {
      this.loadingFile = false;
      let fileExtension = this.fileName.substr(this.fileName.length - 3);
      if(fileExtension == 'csv'){
        this.onFileReady();
      }else{
        this.loading = false;
        Swal.fire(
          getSwal(
            'error',
            'Ops!',
            'Formato de arquivo inválido. Utilize apenas arquivos com a extensão csv.',
            'ok',
            null,
          ),
        )
      }
    }, 1500);
  }

  onFileReady() {
    console.log('fileReady');
    this.loading = false;
    this.fileReady = true;
  }

  cancelUploadFile() {
    console.log('cancel uploadFile');
    this.loading = false;
    this.fileReady = false;
  }

  fileChangeEvent(event:any):void{
    this.fileName = event.target.files[0].name;
    this.fileToUpload = event.target.files[0];
    this.fileToManager = event;
    // this.uploadFile(event);
    this.startUpload();
  }

  infoToUploadFile() {
    this.uploadingFile = true;
    console.log('infoToUploadFile');
  }

  uploadFile() {
    let file = this.fileToManager;
    this.loading = true;
    // setTimeout(() => {
    //   this.uploadComplete();
    // }, 1500);
    if (file.target.files && file.target.files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(file.target.files[0]);
      reader.onload = evt => {
        this._handleReaderLoaded(evt);
        //console.warn(evt);
      };
    }
  }

  uploadComplete() {
    console.log('uploadFile complete');
    //TODO tratar erro de upload
    this.uploadingFile = false;
    this.loadingFile = true;
    this.fileReady = false;
    this.loading = false;

    Swal.fire(
      getSwal(
        'success',
        'O upload do arquivo foi realizado com sucesso!',
        'Todo o processo é executado nos servidores, e pode ser consultado a situação e etapa na opção Histórico da Operação',
        'ok',
        null,
      ),
    ).then(res => {
      this.router.navigate([`integracao/historico-funcionalidades`]);
    });
  }

  _handleReaderLoaded(readerEvt) {
    // const binaryString = readerEvt.target.result;
    this.integracao.importUsers(this.fileToUpload).subscribe(res => {
        console.table(res);
        this.uploadComplete();
        // this.showImg = true;
        // this.userService.updateDataWithPhoto(binaryString);
      },
      error=>{
        console.warn(error);
        this.uploadingFile = false;
        this.loadingFile = true;
        this.fileReady = false;
        this.loading = false;

        Swal.fire(
          getSwal(
            'error',
            'Ops',
            error.error.excecao.recomendacao,
            'ok',
            null,
          ),
        ).then(res => {
          this.router.navigate([`integracao/`]);
        });
      });
    this.loading = false;
  }
}
