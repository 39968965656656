import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cores-tipo-comunicado',
  templateUrl: 'cores-tipo-comunicado.component.html',
  styleUrls: ['./cores-tipo-comunicado.component.scss']
})

export class CoresTipoComunicadoComponent implements OnInit {

  constructor() {}

  ngOnInit() {}


}