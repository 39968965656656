import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

@Injectable()
export class MessagesService {

  private message = new Subject<any>();
  
  
  message$ = this.message.asObservable();

  constructor() { }

  selectMessage(msg:any) {
    this.message.next(msg)
  }
}