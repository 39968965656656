import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AmbienteService } from "../../../../../service/ambiente/ambiente.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-consulta-ambiente',
  templateUrl: 'consulta-ambiente.component.html',
  styleUrls: ['./consulta-ambiente.component.scss']
})

export class ConsultaAmbienteComponent implements OnInit {
  data: Array<any> = [];
  subscription: Subscription;
  errorMessage = '';
  idInstitucional: any;

  constructor(private router: Router, private ambienteService: AmbienteService) {}

  ngOnInit() {
    this.ambienteService
    .obterClientes({})
    .subscribe(
      res => {
        this.idInstitucional = res.resultado[0].codigo;
      },
      error => (this.errorMessage = error));
  }

  edit(codigo:number) {

    if(codigo == undefined){
      //Quando o código está undefined, não deixa redirecionar a rota
      return;
    }
    this.router.navigate([`configuracao/ambiente/editar/${codigo}`])
  }

  editRegrasDeUso(codigo:number){
    if(codigo == undefined){
      //Quando o código está undefined, não deixa redirecionar a rota
      return;
    }
    this.router.navigate([`configuracao/ambiente/editar-regras-uso/${codigo}`])

  }
  editIntegracao(codigo:number){
    if(codigo == undefined){
      //Quando o código está undefined, não deixa redirecionar a rota
      return;
    }
    this.router.navigate([`configuracao/ambiente/editar-integracao/${codigo}`])

  }

  editParametroAssinatura(codigo:number){
    this.router.navigate([`configuracao/ambiente/consultar-parametro-assinatura-digital/${codigo}`])

  }
  servicoComunicacao(codigo:number){
    this.router.navigate([`configuracao/ambiente/servico-comunicacao/${codigo}`])
  }

  editRegrasDeAcesso(codigo:number){
    this.router.navigate([`configuracao/ambiente/consultar-regras-acesso/${codigo}`])

  }

  // OrganogramaConfig

  editOrganogramaPerfil(codigo:number){
    this.router.navigate([`configuracao/ambiente/consultar-organograma/${codigo}`])

  }

  editManuaisUsuario(codigo:number){
    this.router.navigate([`configuracao/ambiente/manuais-usuario/${codigo}`])
  }
}
