import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpParams,
  HttpErrorResponse,
  HttpHeaders,
  HttpParameterCodec,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PerfilDataService } from '../perfil/perfil.service';

import { environment } from '../../../environments/environment';
import { resolve } from 'url';
import { reject } from 'q';
import { Session } from 'protractor';

@Injectable()
export class LoginService {
  url: string = environment.BASE_URL;
  public dadosCertificado: Object = null;

  constructor(
    private http: HttpClient,
    public perfilService: PerfilDataService,
  ) {}

  setParams(searchParams: any) {
    let params = new HttpParams();
    if (!searchParams) {
      return params;
    }
    if (searchParams.key && searchParams.value) {
      return params.set(searchParams.key, searchParams.value);
    }
    for (const key of Object.keys(searchParams)) {
      params = params.set(key, searchParams[key]);
    }
    return params;
  }

  login(credentials) {
    return new Promise((resolve, reject) => {
      this.autenticarUser(credentials).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        },
      );
    });
  }

  autenticarUser(credentials): Observable<any> {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const params = new HttpParams({
      fromObject: { cpfCnpj: credentials.cpfCnpj, senha: credentials.senha, sistema: '1' },
    });
    return this.http
      .get(`${this.url}/autenticar`, { params: params })
      .pipe(map(data => {
        return data;
      }));
  }

  getNonce(): Observable<any> {
    return this.http.get(`${this.url}/obterNonce`).pipe( map(data => {
      return data;
    }));
  }

 loginViaCertificado(
    nonce,
    certEndcoded,
    assinatura,
    certificado
  ): Observable<any> {
    this.dadosCertificado = {
      nonce: nonce,
      certEndcoded: certEndcoded,
      assinatura: assinatura,
      certificado: certificado
    };
    const fd = new FormData();
    fd.append('nonce', nonce);
    fd.append('certificado', certEndcoded);
    fd.append('assinatura', assinatura);
    fd.append('sistema', '1');
    return this.http
      .post(`${this.url}/autenticarPorCertificadoDigital`, fd)
      .map(data => {
        return data;
      });
  }

  loginViaCertificadoEmpresas(
    nonce,
    certEndcoded,
    assinatura,
    codigoEmpresa
  ): Observable<any> {
    const fd = new FormData();
    fd.append('nonce', nonce);
    fd.append('certificado', certEndcoded);
    fd.append('assinatura', assinatura);
    fd.append('codigoEmpresa', codigoEmpresa);
    fd.append('sistema', '1');
    return this.http
      .post(`${this.url}/autenticarPorCertificadoDigital`, fd)
      .map(data => {
        return data;
      });
  }

  clientes(): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        sistema: '1',
      },
    });
    return this.http
      .get(`${this.url}/listarClientes`, { params: params })
      .pipe( map(data => {
        return data;
      }));
  }

  procuracoes(): Observable<any> {
    return this.http
      .get(`${this.url}/obterProcuracoesAtivasDoProcurador`)
      .pipe( map(data => {
        return data;
      }));
  }

  obterSessao(codigo) {
    return new Promise((resolve, reject) => {
      this.getSession(codigo).subscribe(res => {
        this.getImages(res)
          .then(sessionObject => {
            resolve(sessionObject);
          })
          .catch(error => {
            reject(error);
          });
      });
    });
  }

  getImages(sessionObject) {
    return new Promise((resolve, reject) => {
      this.getUserImage()
        .then(userImage => {
          if (userImage !== null) {
            sessionObject.resultado.usuario.imagem = userImage;
          }
          return this.getClientImage();
        })
        .then(clientImage => {
          if (clientImage !== null) {
            if (sessionObject.resultado.cliente.configuracao === null) {
              sessionObject.resultado.cliente.configuracao = {};
            }
            sessionObject.resultado.cliente.configuracao.imagem = clientImage;
          }
          resolve(sessionObject);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  getUserImage() {
    return new Promise((resolve, reject) => {
      this.perfilService.getUserImage().subscribe(
        image => {
          if (image !== null) {
            this.getProcessedImageBlob(image).then(img => {
              resolve(img);
            });
          } else {
            resolve(null);
          }
        },
        error => {
          resolve(null);
        },
      );
    });
  }

  getClientImage() {
    return new Promise((resolve, reject) => {
      this.perfilService.getClientImage().subscribe(
        image => {
          if (image !== null) {
            this.getProcessedImageBlob(image).then(img => {
              resolve(img);
            });
          } else {
            resolve(null);
          }
        },
        error => {
          resolve(null);
        },
      );
    });
  }

  getSession(codigo): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        codigoUsuarioCliente: codigo,
        sistema: '1',
      },
    });
    return this.http
      .get(`${this.url}/obterSessaoUsuario`, { params: params })
      .pipe( map(data => {
        return data;
      }));
  }

  atualizarSessao(codigoProcuracao): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        codigoProcuracao: codigoProcuracao,
      },
    });
    return this.http
      .get(`${this.url}/atualizarSessaoUsuario`, { params: params })
      .pipe( map(data => {
        return data;
      }));
  }

  private handleError(error: Response | any) {
    console.log(error.message || error);
    return Observable.throw(error.message || error);
  }

  getProcessedImageBlob(imageBlob) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.addEventListener(
        'load',
        () => {
          resolve(reader.result.toString());
        },
        false,
      );
      reader.addEventListener(
        'error',
        () => {
          console.log('error', reader);
        },
        false,
      );

      if (imageBlob) {
        reader.readAsDataURL(imageBlob);
      }
    });
  }
}
