import { Subscription } from 'rxjs/Subscription';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ComunicadosService } from '../../../../service/comunicados/comunicados.service';
import Swal from 'sweetalert2';
import { getSwal } from '../../../../utils/swalObject';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-lista-tipos-comunicados',
  templateUrl: './lista-tipos-comunicados.component.html',
  styleUrls: ['./lista-tipos-comunicados.component.scss']
})
export class ListaTiposComunicadosComponent implements OnInit {
  @ViewChild('childFilter', {static: false}) child;

  tiposComunicado: any = [];
  tiposComunicadosSubsc: Subscription;
  searchParams: any;
  itemsPerView: number = 10;
  list: Array<any> = [];
  comunicados: Array<any> = [];
  filterCurrent: Object = {
    paginaAtual: 1,
    total_pagina: 10,
  };
  loading: boolean = false;
  comunicadoSelecionado: Array<any> = [];
  numeroComunicados: string = "10";
  paginaAtual: number = 1;
  totalPaginas: number = 1;
  ComunicadoAtivo: boolean = false || null
  // codUsuario: 1;
  requests: any = [];
  data: Object = {
    resultado: [],
    primeiraPagina: true,
    ultimaPagina: false,
    paginaAtual: 1,
    totalPaginas: 1,
    totalDeItems: 0
  };

  constructor(
    private router: Router,
    private tiposComunicadoService: ComunicadosService
  ) {
    this.data = [];
  }

  ngOnInit() {
    this.getUsuarios();
    this.tiposComunicadoService.comunicados$.subscribe(res => {
      this.data = res;
      res['resultado'].forEach(resultado => {
        resultado.dataCriacaoFmt = moment(resultado.dataCriacao).format('YYYYMMDD');
        resultado.criadorComunicado = '';
      });

      this.filterCurrent = this.child.searchParams;
      this.comunicados = res['resultado'];
      // this.comunicados = res['ativo']
      this.data['totalDeItems'] = res.totalRegistros;
      this.totalPaginas = res['totalPaginas'];
      
      this.comunicados.forEach(res => {
        if(res['usuario'] !== null) {
          res['criadorComunicado'] = res['usuario'].nomeRazaoSocial;
        } else {
          res['criadorComunicado'] = res['cliente'].razaoSocial;
        }
      });
    });
    this.getUsuarios();
  }

  updateSearchParams(params) {
    this.searchParams = params;
  }
  
  getUsuarios() {
    this.tiposComunicadoService.getListaUsuarios().subscribe(
      (data) => {
        const codigosUsuarios = data.resultado.map(usuario => usuario);
        console.log(codigosUsuarios); 
      },
      (error) => {
        console.error('Erro ao obter os usuários:', error);
      }
    );
  }
  
  quantidadeComunicados(numeroComunicados){
    this.data['paginaAtual'] = 1;

    this.itemsPerView = +numeroComunicados;

    this.paginaAtual = 1;

    this.onPageChange(this.filterCurrent);

    this.data['itemsPorPagina'] = this.itemsPerView;
    this.filterCurrent['total_pagina'] = this.itemsPerView;
    
    this.data['paginaAtual'] = 1;
    switch (numeroComunicados) {
      case "10":
        this.data['itemsPorPagina'] = 10;
        console.log(this.data['itemsPorPagina']);
        break;
      case "20":
        this.data['itemsPorPagina'] = 20;
        console.log(this.data['itemsPorPagina']);
        break;
      case "50":
        this.data['itemsPorPagina'] = 50;
        console.log(this.data['itemsPorPagina']);
        break;
      case "100":
        this.data['itemsPorPagina'] = 100;
        console.log(this.data['itemsPorPagina']);
        break;
    }
    this.loading = true;
  }
  
  next() {
    this.data['paginaAtual']++;
    this.comunicados
    this.onPageChange(this.filterCurrent);
  }

  previous() {
    this.comunicados
    this.data['paginaAtual']--;
    this.onPageChange(this.filterCurrent);
  }

  primeiraPagina() {
    this.data['paginaAtual'] = 1;
    this.comunicados
    this.onPageChange(this.filterCurrent);
  }

  ultimaPagina() {
    this.data['paginaAtual'] = this.data['totalPaginas'];
    this.comunicados
    this.onPageChange(this.filterCurrent);
  }

  
  onPageChange(value){
    value['pagina'] = this.data['paginaAtual'];
    value['total_pagina'] = this.itemsPerView;

    this.filterCurrent = value;
    this.loading = true;
    
    this.tiposComunicadoService.getComunicados(value).subscribe(
      res => {
        this.comunicados = res['resultado'];
        this.loading = false;
      },
      error => {
        this.loading = false;
        this.comunicados = [];
      }
    )
  }

//   ativarComunicados() {
//     this.loading = true;
//     let value = {};
//     this.visible = '';

//     this.comunicadoSelecionado.forEach(res => {
//         value = {
//             codigo: res.codigo,
//             ativo: true,
//             descricao: res.descricao,
//         }
//         this.requests.push(this.tiposComunicadoService.desativarComunicado(value, res.codigo))
//     })

//     this.executarForkJoin();
// }

//   inativarComunicados() {
//       this.loading = true;
//       let value = {};
//       this.visible = '';

//       this.comunicadoSelecionado.forEach(res => {
//           value = {
//               codigo: res.codigo,
//               ativo: false,
//               descricao: res.descricao,
//           }
//           this.requests.push(this.tiposComunicadoService.desativarComunicado(value, res.codigo))
//       })

//       this.executarForkJoin();
//   }

  executarForkJoin() {
      forkJoin(this.requests).subscribe(res => {
          this.comunicadoSelecionado = [];
          this.requests = [];
          this.tiposComunicadoService.getComunicados(this.data);
          this.loading = false;

      }, error => {
          this.comunicadoSelecionado = [];
          this.requests = [];
          this.tiposComunicadoService.getComunicados(this.data)
          this.loading = false;  
      })
  }

  setSelectedEntities(items) {
    this.comunicadoSelecionado = items;
  }

  edit(codigo, item) {
    if (!item.emUso) {
      this.router.navigate([`/tipos-comunicados/${codigo}`]);
    } else {
      Swal.fire(
        getSwal(
          'warning',
          'Alerta!',
          'Comunicado não pode ser alterado por já estar em uso. Crie um novo com o comportamento desejado',
          'ok',
          null
        )
      ).then(() => {
        this.router.navigate([`/tipos-comunicados/visualizar/${codigo}`]);
      });
    }
  }
}
