import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment'

@Injectable()
export class OutorgarProcuracaoService {

  url:string = environment.BASE_URL;

  constructor(private http: HttpClient) { }

  getAplicacoes(): Observable<any> {
    return this.http.get(`/aplicacoes/ativos`)
    .pipe(map(data => {
        let body = data;
        return body;
      }));
  }

  getPerfisAcesso(): Observable<any> {
    return this.http.get(`/perfisAcesso`)
    .pipe(map(data => {
        let body = data;
        return body;
      }));
  } 
}