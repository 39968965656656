import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-personalidade-juridica',
    templateUrl: './personalidade.component.html',
    styleUrls: ['./personalidade.component.scss'],
})
export class PersonalidadeJuridicaComponent implements OnInit{

    constructor() {

    }

    ngOnInit() {
        
    }
}