import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
import { getSwal } from '../../../../../utils/swalObject';
import * as moment from 'moment';
import { AplicacoesService } from '../../../../../service/aplicacoes/aplicacoes.service';
import { gerenciarSistemasRouterPath } from '../../../../../../environments/version';

@Component({
  selector: 'app-inserir-servico',
  templateUrl: 'inserir-servico.component.html',
  styleUrls: ['inserir-servico.component.scss'],
})
export class InserirServicosComponent implements OnInit {
  definirServicoForm: FormGroup;
  title: string = 'Incluir Serviço';
  dataCriacao: any = new Date();
  idEndpoint: string = null;
  endpoint: Object = null;
  routeSubscription: Subscription;
  edicao: boolean = false;
  navigationData: any;

  actions: string;
  aplicacao: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private aplicacaoService: AplicacoesService,
    private fb: FormBuilder,
  ) {
    this.navigationData = this.router.getCurrentNavigation();
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    //let navigationData = this.router.getCurrentNavigation();
    if (
      this.navigationData.extras.state instanceof Object &&
      Object.keys(this.navigationData.extras.state).length === 0
    ) {
      this.navigationData.extras.state = null;
    }

    if (!this.navigationData.extras.state) {
      this.router.navigate([`${gerenciarSistemasRouterPath}/`]);
      return;
    }
    this.idEndpoint = this.navigationData.extras.state[0];
    this.actions = this.navigationData.extras.state[1];
    this.aplicacao = this.navigationData.extras.state[2];

    let currentEndpoint: any;
    this.aplicacao['endpoints'].forEach(endpoint => {
      if (endpoint.codigo == this.idEndpoint) {
        currentEndpoint = endpoint;
      }
    });
    // currentEndpoint.dataCriacaoFormated = moment(currentEndpoint.dataCriacao).format('DD/MM/YYYY');
    this.dataCriacao = moment(
      currentEndpoint ? currentEndpoint.dataCriacao : new Date(),
    ).format('DD/MM/YYYY');
    this.editarEndpoint(currentEndpoint);
  }

  criarFormulario() {
    this.definirServicoForm = this.fb.group({
      nome: ['', Validators.required],
      ativo: [true, Validators.required],
      dataCriacao: ['', Validators.required],
      codigo: [''],
      // dataCriacaoFormated: ['', Validators.required],
      versao: ['', Validators.required],
      objetivo: ['', Validators.required],
      endereco: ['', Validators.required],
    });

    this.definirServicoForm.controls.dataCriacao.disable();
  }

  async editarEndpoint(endpoint) {
    let criaFormulario = await this.criarFormulario();
    if (endpoint) {
      this.carregarDadosFormulario(endpoint);
    }
  }

  carregarDadosFormulario(endpoint) {
    for (let key in endpoint) {
      if (typeof key !== 'undefined' && this.definirServicoForm.controls[key]) {
        if (key === 'dataCriacao') {
          this.definirServicoForm.controls[key].patchValue(
            moment(endpoint[key]).format('DD/MM/YYYY'),
          );
        } else {
          this.definirServicoForm.controls[key].patchValue(endpoint[key]);
        }
      }
    }
    // this.dataCriacao = moment(endpoint.dataCriacao).format('DD/MM/YYYY');
  }

  cancelEdit() {
    this.definirServicoForm.reset();
    this.router.navigateByUrl(`${gerenciarSistemasRouterPath}/editar/${this.aplicacao['codigo'] ||
          'novo'}/endpoints`, {state : [this.actions, this.aplicacao]});
  }
  
  onSubmit() {
    const obj = this.definirServicoForm.getRawValue();
    obj['dataCriacao'] = new Date(Date.now()).toISOString();

    console.log(this.definirServicoForm);
    if (this.idEndpoint.toString().indexOf('novo') !== -1) {
        obj.codigo = this.idEndpoint;
        obj.isNew = true;

        const oldFileIndex = this.aplicacao['endpoints'].findIndex(endpoint => endpoint.codigo === this.idEndpoint);
        if (oldFileIndex !== -1) {
            this.aplicacao['endpoints'].splice(oldFileIndex, 1);
        }

        this.aplicacao['endpoints'].push(obj);
    } else {
        const oldFileIndex = this.aplicacao['endpoints'].findIndex(endpoint => endpoint.codigo === this.idEndpoint);
        if (oldFileIndex !== -1) {
            this.aplicacao['endpoints'][oldFileIndex] = obj;
        }
        console.log('this.aplicacao: ', this.aplicacao['endpoints'])
    }

    console.log('endpoints:', this.aplicacao['endpoints']);

    Swal.fire(
        getSwal(
            'success',
            'Sucesso!',
            'Serviço associado com sucesso',
            'ok',
            null,
        ),
    );

    this.router.navigateByUrl(`${gerenciarSistemasRouterPath}/editar/${this.aplicacao['codigo'] || 'novo'}/endpoints`, {state : [this.actions, this.aplicacao]});
}
}
