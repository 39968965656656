import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { SessionService } from '../../service/session/session.service';
import { appClient } from '../../../environments/version';
import { environment } from '../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-not-found',
  templateUrl: 'not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
  hasSession: boolean = false;
  constructor(
    private sessionService: SessionService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: any,
  ) {}

  ngOnInit() {
    const session = this.sessionService.getSession();
    session['loggedIn'] ? (this.hasSession = true) : (this.hasSession = false);
  }

  redirect() {
    console.log(' >> navigate');
    if (appClient.indexOf('sefaz') === -1) {
      this.redirectEicon();
    } else {
      this.redirectSefaz();
    }
  }

  redirectEicon() {
    if (this.hasSession) {
      this.router.navigate(['/home'], { relativeTo: this.route });
    } else {
      this.router.navigate(['/entrar']), { relativeTo: this.route };
    }
  }

  redirectSefaz() {
    if (this.hasSession) {
      this.document.location = environment.BASE_URL + '/#/home';
      // this.router.navigate(['/home'], { relativeTo: this.route });
    } else {
      this.document.location = environment['URL_ASTRA'];
    }
  }
}
