import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { Subject } from "rxjs/Subject";
import "rxjs/add/operator/catch";
import "rxjs/add/operator/map";
import "rxjs/add/observable/forkJoin";
import "rxjs/add/observable/throw";
import { environment } from "../../../environments/environment";

@Injectable()
export class CoresTiposComunicadosService {
  url: string = environment.BASE_URL;

  private cores = new Subject<any>();
  cores$ = this.cores.asObservable();

  constructor(private http: HttpClient) {}

  getCores(searchParams): Observable<any> {
    const params = this.setParams(searchParams);
    if (!params) {
      return;
    }
    return this.http
      .get(`${this.url}/coresTipoComunicado`, { params: params })
      .map((data) => {
        const body = data;
        this.cores.next(body);
        return body;
      });
  }

  setParams(searchParams: any) {
    let params = new HttpParams();
    if (!searchParams) {
      return params;
    }
    if (searchParams.key && searchParams.value) {
      return params.set(searchParams.key, searchParams.value);
    }
    for (const key of Object.keys(searchParams)) {
      params = params.set(key, searchParams[key]);
    }
    return params;
  }

  criaCor(params: any): Observable<any> {
    const corTipoComunicado = params;
    return this.http
      .post(`${this.url}/coresTipoComunicado`, corTipoComunicado)
      .map((data) => {
        const body = data;
        return body;
      });
  }

  obterCor(id: number): Observable<any> {
    return this.http
      .get(`${this.url}/coresTipoComunicado/${id}`)
      .map((data) => {
        const body = data;
        return body;
      });
  }

  editaCor(params: any, code): Observable<any> {
    const cor = params;
    const codigo = code;
    return this.http
      .put(`${this.url}/coresTipoComunicado/${codigo}`, cor)
      .map((data) => {
        const body = data;
        return body;
      });
  }
}
