import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/map';
import { environment } from '../../../environments/environment';


@Injectable()
export class PersonalidadeJuridicaService {

  url: string = environment.BASE_URL;

  private personalidadeJuridica = new Subject<any>();
  personalidadeJuridica$ = this.personalidadeJuridica.asObservable();

  constructor(private http: HttpClient) {}

  getPersonalidadeJuridica(searchParams): Observable<any> {
    const params = this.setParams(searchParams);
    return this.http.get(`${this.url}/personalidadesJuridicas`, {params: params})
      .map(data => {
        const body = data;
        this.personalidadeJuridica.next(body);
        return body;
      });
  }

  getSituacaoPersonalidadeJuridica(searchParams): Observable<any> {
    const params = this.setParams(searchParams);
    return this.http.get(`${this.url}/personalidadesJuridicas/listaPersonalidadeJuridica`, {params: params})
      .map(data => {
        const body = data;
        this.personalidadeJuridica.next(body);
        return body;
      });
  }

  setParams(searchParams: any){
    let params = new HttpParams();
    if (!searchParams) {
      return params;
    }
    if (searchParams.key && searchParams.value) {
      return params.set(searchParams.key, searchParams.value);
    }
    for (const key of Object.keys(searchParams)) {
      params = params.set(key, searchParams[key]);
    }
    return params;
  }


  obterPersonalidadeJuridica(id: number): Observable<any> {
    return this.http.get(`${this.url}/personalidadesJuridicas/${id}`)
      .map(data => {
        const body = data;
        return body;
      });
  }

  editaPersonalidadeJuridica(params: any, code): Observable<any> {
    const personalidadeJuridica = params;
    const codigo = code;
    return this.http.put(`${this.url}/personalidadesJuridicas/${codigo}`, personalidadeJuridica)
      .map(data => {
        const body = data;
        return body;
      });
  }

  incluirPersonalidadeJuridica(obj) {
    return this.http.post(`${this.url}/personalidadesJuridicas/incluir`, obj)
  }

  consultaCodigo(codigo): Observable<any> {
    return this.http.get(`${this.url}/personalidadesJuridicas/${codigo}`)
  }

}
