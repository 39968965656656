import { Component,OnInit, ElementRef, Renderer2, ViewChildren } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common'

import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { OutorgarProcuracaoService } from '../../../../../service/procuracoes/outorgar-procuracao.service';

import * as moment from 'moment-timezone';

import { TreeviewItem } from 'ngx-treeview';
import { TreeviewI18n } from 'ngx-treeview';
import { DefaultTreeviewI18n } from '../../default-treeview-i18n';
import { TranslateService } from '@ngx-translate/core';
import { GerenciarUsuariosService } from '../../../../../service/configuracao/gerenciar-usuarios.service';

import { Subscription } from 'rxjs';

@Component({
  selector: 'app-procuracao',
  templateUrl: './procuracao.component.html',
  styleUrls: ['./procuracao.component.scss'],
  providers: [{ provide: TreeviewI18n, useClass: DefaultTreeviewI18n }]
})
export class ProcuracaoComponent implements OnInit {
  @ViewChildren('sistemasLista') sistemasLista: any;
  
  routeSubscription: Subscription;
  labels:any;
  usuario:number = null
  procurador:number = null
  detalhe = {};
  items: Array<TreeviewItem> = [];
  selectedItems = [];
  tiposProcuracao = [];
  codigoProcuracao: any = null;
  cpfCnpj: any;
  nomeRazaoSocial: any;
  email: any;
  celular: any;
  razaoSocial: any;
  situacaoDescricao: any;
  userData: any;
  fim: any;
  oab: any;
  seccionalOab: any;
  situacaoOab: any;
  perfisNaoDelegados: any = null;
  inicio: moment.Moment = moment();
  papel = -1;
  sistemas = [];
  opcaoPapel: Object = null;
  lengthCpf: number;
  cpf: string;
  cnpj: string;



  config = {
    hasAllCheckBox: true,
    hasFilter: false,
    hasCollapseExpand: true,
    decoupleChildFromParent: false,
    maxHeight: 400
  };
  
  constructor(
    private translate:TranslateService,
    private formBuilder: FormBuilder,
    private outorgarProcuracaoService: OutorgarProcuracaoService,
    private router: Router,
    private route: ActivatedRoute,
    private gerenciarUsuariosService:GerenciarUsuariosService,
    private location: Location 
    
  ) {
    this.setLabels();
   }

 ngOnInit() {

  this.gerenciarUsuariosService.getData()
      .subscribe(res => 
        this.handleResponse(res), error => console.log(error)
      );


   
    
  }

  onSelectedChange(event) {
    this.selectedItems = event;
  }


  handleResponse(response) {
    this.tiposProcuracao = response[1].resultado;

    this.route.paramMap.subscribe(params => {
      this.codigoProcuracao = params.get('procurador');
      // console.log('this.codigoProcuracao', this.codigoProcuracao);

      if (this.codigoProcuracao) {
        this.gerenciarUsuariosService
          .getProcuracoes(this.codigoProcuracao)
          .subscribe(data => {
            console.log('data: ',data);
            this.lengthCpf = data.procurador.usuario.cpfCnpj.length;
            if(this.lengthCpf === 11 ){
              this.cpf = data.procurador.usuario.cpfCnpj;
              this.cnpj = '';
            }else{
              this.cnpj = data.procurador.usuario.cpfCnpj;
              this.cpf ='';
            }
            
            this.razaoSocial = data.procurador.usuario.nomeRazaoSocial;
            this.email = data.procurador.usuario.email;
            this.celular = data.procurador.usuario.celular;
            this.inicio = data.inicio;
            const fim = moment(data.fim).format('DD/MM/YYYY');
            this.fim = fim !== 'Invalid date' ? fim : '';
            this.papel = data.tipoProcuracao.codigo;
            this.perfisNaoDelegados = data.perfisNaoDelegados;
            this.situacaoDescricao   = data.situacao.descricao; 

            this.perfisNaoDelegados.forEach(perfil => {
              this.selectedItems.push(perfil.funcionalidade.codigo);
            });
            this.selectPapel(data.tipoProcuracao.codigo);
            
          });
      } else {
        this.formatarFuncionalidades();
      }
    });
  }

  formatarFuncionalidades() {
    this.sistemas.forEach(element => {
      if (element.funcionalidades.length >= 1) {
        element.funcionalidades.forEach(element => {
          element['checked'] = true;
        });
      }
    });
  }

  selectPapel(e: any) {
    const codigo = parseInt(e, 10);
    // console.log('select: ', e);

    this.opcaoPapel = this.tiposProcuracao.filter(
      elem => elem.codigo === codigo
    )[0];

    this.gerenciarUsuariosService
      .getFuncionalidadeAutorizadasProcuracao(codigo)
      .subscribe(data => {
        // console.log('data:', data);
        this.config.hasAllCheckBox = this.codigoProcuracao ? false : true;
        this.items = [];
        this.sistemas = data.resultado;
        this.sistemas.forEach(sistema => {
          const item = {
            text: sistema.descricao,
            value: sistema.codigo,
            checked: this.codigoProcuracao
              ? !this.selectedItems.includes(sistema.codigo)
              : false,
            disabled: true,
            children: []
          };

          sistema.funcionalidades.forEach(funcionalidade => {
            item.children.push({
              text: funcionalidade.descricao,
              value: funcionalidade.codigo,
              disabled: this.codigoProcuracao ? true : false,
              checked: this.codigoProcuracao
                ? !this.selectedItems.includes(funcionalidade.codigo)
                : false
            });
          });

          this.items.push(new TreeviewItem(item));
        });
      });

    // if (codigo === 3) {
    //   this.requireOAB = true;
    //   this.outorgarForm.controls['oab'].enable();
    //   this.outorgarForm.controls['seccionalOab'].enable();
    // } else {
    //   this.requireOAB = false;
    //   this.outorgarForm.controls['oab'].disable();
    //   this.outorgarForm.controls['seccionalOab'].disable();
    // }
  }

  setLabels() {
    this.labels = {
      title: this.translate.instant('PROCURACAO.TITLE'),
      cpfCnpj: this.translate.instant('PROCURACAO.CPFCNPJ'),
      nomeRazaoSocial: this.translate.instant('PROCURACAO.NOMERAZAOSOCIAL'),
      email: this.translate.instant('PROCURACAO.EMAIL'),
      celular: this.translate.instant('PROCURACAO.CELULAR'),
      cadastrar: this.translate.instant('PROCURACAO.CADASTRAR'),
      dataInicial: this.translate.instant('PROCURACAO.DATAINICIAL'),
      dataFinal: this.translate.instant('PROCURACAO.DATAFINAL'),
      papel: this.translate.instant('PROCURACAO.PERFIL'),
      selecione: this.translate.instant('PROCURACAO.SELECIONE'),
      abrangencias: this.translate.instant('PROCURACAO.ABRANGENCIAS'),
      abrangenciasText: this.translate.instant('PROCURACAO.ABRANGENCIASTEXT'),
      proximo: this.translate.instant('PROCURACAO.PROXIMO'),
      desistir: this.translate.instant('PROCURACAO.DESISTIR'),
      cadastrarProcurador: this.translate.instant('PROCURACAO.CADASTRARPROCURADOR')
    };
  }
  desistir(){
    this.location.back();
  }
}
