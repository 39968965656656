import { Component, OnInit, AfterViewInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { LacunaWebPKI, CertificateModel, ExceptionModel } from 'web-pki';
import { UserService } from '../../../service/user-service/user.service';
import { SessionService } from '../../../service/session/session.service';
import { LoginService } from '../../../service/login/login.service';
import { CertificadoService } from '../../../service/certificado/certificado.service';
import 'rxjs/add/operator/takeUntil';
import { Subject } from 'rxjs/Subject';

import Swal from 'sweetalert2';
import { getSwal } from '../../../utils/swalObject';

@Component({
  selector: 'app-certificado',
  templateUrl: './certificado.component.html',
  styleUrls: ['./certificado.component.scss']
})
export class CertificadoComponent implements OnInit {
  private pki: LacunaWebPKI;
  searchResults: Array<any> = [];
  buscando: boolean = false;
  serverData: Object = {};
  disabled: boolean = false;
  loading: boolean = false;

  today: Date = new Date();

  private ngUnsubscribe: Subject<any> = new Subject<any>();

  constructor(
    private config: CertificadoService,
    private ngZone: NgZone,
    private loginService: LoginService,
    private userService: UserService,
    private router: Router
  ) {
    this.pki = new LacunaWebPKI(config.webPkiLicense);
  }

  ngOnInit() {
    this.pki.init({
      ngZone: this.ngZone,
      ready: this.onWebPkiReady,
      notInstalled: this.onWebPkiNotInstalled
    });
    this.buscando = true;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private onWebPkiReady = () => {
    this.carregarCertificados();
  };

  carregarCertificados() {
    this.buscando = true;
    this.pki.listCertificates({}).success(certs => {
      this.buscando = false;
      this.searchResults = certs;
      this.buscando = false;
    });
  }

  update() {
    this.carregarCertificados();
  }

  private onWebPkiNotInstalled = (status, message) => {
    this.buscando = false;
    this.pki.redirectToInstallPage();
  };

  onSelect(e, certificado) {
    this.loading = true;
    e.stopPropagation();
    e.preventDefault();

    this.disabled = !this.disabled;

    if (this.disabled) {
      this.loginService
        .getNonce()
        .takeUntil(this.ngUnsubscribe)
        .subscribe(res => {
          this.pki
            .readCertificate(certificado.thumbprint)
            .success(certEncoded => {
              this.pki
                .signData({
                  thumbprint: certificado.thumbprint,
                  data: res.resultado['nonce'],
                  digestAlgorithm: res.resultado['digestAlgorithm']
                })
                .success(signature => {
                  this.loginService
                    .loginViaCertificado(
                      res.resultado['nonce'],
                      certEncoded,
                      signature,
                      certificado
                    )
                    .subscribe(
                      res => this.handleResponse(res),
                      error => this.handleError(error)
                    );
                });
            });
        });
    }
  }

  handleResponse(res) {
    this.loading = false;
    if (!res.excecao) {
      this.userService.sendInfo({ loggedIn: false, ...res.resultado });
      let userInfo = res.resultado;
      if (userInfo.empresas != null) {
        this.router.navigate(['/empresas']);
      } else {
        this.router.navigate(['/clientes']);
      }
    }
  }

  handleError(err) {
    let error = err['error'];
    // Swal.fire(
    //   'Ops!',
    //   error.excecao.recomendacao,
    //   'error'
    // );
    Swal.fire(getSwal('error', 'Ops!', error.excecao.recomendacao, 'ok', null));
    this.loading = false;
    this.router.navigate(['/entrar']);
  }
}
