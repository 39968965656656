
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import Swal from 'sweetalert2';
import { getSwal } from '../../../../../utils/swalObject';
import { GerenciarUsuariosService } from '../../../../../service/configuracao/gerenciar-usuarios.service';



@Component({
  selector: 'app-adicionar-usuario',
  templateUrl: 'adicionar-usuario.component.html',
  styleUrls: ['./adicionar-usuario.component.scss']
})

export class AdicionarUsuarioComponent implements OnInit {
  @ViewChild('yourChild', {static: false}) child;

  routeSub: Subscription;
  title: String = 'Adicionar Usuário';
  adicionarUsuarioForm: FormGroup;
  edicao:boolean = true;
  list:Array<any> = [];

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private gerenciarUsuarioService: GerenciarUsuariosService,
    private router: Router) { }

  ngOnInit() {

    this.gerenciarUsuarioService.usuario$.subscribe(usuario => {
      const arr = [
        {
          title: 'Perfis de Acesso',
          child: usuario.perfisAcesso
        }
      ]
      this.treeview(arr)
    })
  }

  treeview(arr){
    if(arr) {
      arr.map(item => {
        for(let key in item) {
          if(Array.isArray(item[key])) {
            // item['descricao'] ? item['title'] = item['descricao'] : item['title'] = item['nomeRazaoSocial'];
            item['child'] = item[key]
            return this.treeview(item[key])
          }
        }
      })
    }
    this.list = arr;
    console.log(arr)
  }


  onSubmit(){
    this.child.adicionarUsuario();
  }

}
