import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CaixaPostalService } from '../../../../../service/caixa-postal/caixa-postal.service';

@Component({
  selector: 'app-pop-up-contribuinte',
  templateUrl: 'pop-up.component.html',
  styleUrls: ['./pop-up.component.scss']
})

export class PopUpContribuinteComponent implements OnInit {
  @Input() data: Object = {};
  @Output() onClosePopUp: EventEmitter<any> = new EventEmitter<any>();

  active:number = 0;
  

  constructor(private caixaPostalService: CaixaPostalService) { }

  ngOnInit() { }

   fecharPopUp(){
     this.onClosePopUp.emit(false);
   }
}