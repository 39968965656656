// import { Component, OnInit } from '@angular/core';
// import Swal from 'sweetalert2';

import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ServicoComunicacaoService } from '../../../../../service/servico-comunicacao/servico-comunicacao.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { getSwal } from '../../../../../utils/swalObject';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-servico-comunicacao',
  templateUrl: './servico-comunicacao.component.html',
  styleUrls: ['./servico-comunicacao.component.scss']
})
export class ServicoComunicacaoComponent implements OnInit {
  routeSub: Subscription;
  editarServicoComunicacaoForm: FormGroup;
  idPerfilAcesso: number = null;
  tipoServidor:0;
  dadosDeAmbiente: any = {};

  constructor(private fb: FormBuilder,
    private domSanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private router: Router,
    private servicoComunicacaoService: ServicoComunicacaoService,
    // private translate: TranslateService,
    private common: CommonModule) {

      // this.setLabels();
      this.routeSub = this.route.params.subscribe(params => {
      if (params['id']) {
        this.idPerfilAcesso = params['id'];
      }

    });
     }

  ngOnInit() {
    this.criarFormulario();
  }

  ngAfterViewInit() {
    if (this.idPerfilAcesso) {
      this.obterDados();
    }
  }

  criarFormulario() {
    this.editarServicoComunicacaoForm = this.fb.group({

      servicoEmail : [false, Validators.required],
      enderecoServidorEmail : [],
      portaServidorEmail : [],
      remetenteEmail : [],
      senhaServidorEmail : [],
      tokenEmail : [],
      usuarioServidorEmail : [],
      servicoSms : [false, Validators.required],
      usuarioSms : [],
      campanhaSms : [],
      endpointSms : [],
      senhaSms : [],
      servicoWhatsapp : [false, Validators.required],
      campanhaWhatsapp : [],
      codigoWhatsapp : [],
      endpointWhatsapp : [],
      senhaWhatsapp : [],
      telefoneWhatsapp : [],
      usuarioWhatsapp : []

    });
  }

//
  obterDados() {
    this.servicoComunicacaoService.obterServidoresComunicacao(this.idPerfilAcesso).subscribe(
      res => {
        console.log('res:', res)
        this.dadosDeAmbiente = res.resultado;
        this.carregarDadosFormulario(this.dadosDeAmbiente);
      },
      error => {
        Swal.fire(
          getSwal(
            'error',
            'Ops!',
            'Ocorreu um erro e o Ambiente não pôde ser carregado.',
            'ok',
            null
          )
        );
      }
    );
  }


  atualizar() {
    const obj = this.checkChanges();
    this.servicoComunicacaoService
      .atualizarServidoresComunicacao(this.idPerfilAcesso, obj)
      .subscribe(
        res => {
          Swal.fire(
            getSwal(
              'success',
              'Sucesso',
              'Ambiente atualizado com sucesso.',
              'ok',
              null
            )
          ).then(res => {
            this.router.navigate(['configuracao/ambiente']);
          });
        },
        error => {
          console.log(error);
          Swal.fire(
            getSwal(
              'error',
              'Ops!',
              error.error.excecao.recomendacao,
              'ok',
              null
            )
          );
        }
      );
  }


  checkChanges() {
    let value = this.editarServicoComunicacaoForm.getRawValue();
    const updatedValue = {};
    for (let key in value) {
      if (
        value[key] !== this.dadosDeAmbiente[key]
      ) {
        this.dadosDeAmbiente[key] = value[key];
      }
    }

    return this.dadosDeAmbiente;
  }

 //
  carregarDadosFormulario(data) {
    for (let key in data) {
      if (this.editarServicoComunicacaoForm.controls[key] && data[key] !== null) {
        this.editarServicoComunicacaoForm.controls[key].patchValue(data[key]);
      }
    }
  }

  ajuda(valor){
    let msg1=
    ` <ol>
    <li> Grupo de dados para conectar ao servidor de e-mail utilizado para enviar notificações aos usuários.
    </li>
  </ol>`;

  let msg2=
    ` <ol>
    <li> IP/URL do servidor que oferece o serviço de e-mail.
    </li>
  </ol>`;

  let msg3=
    ` <ol>
    <li> Porta de conexão para o serviço de e-mail.
    </li>
  </ol>`;

  let msg4=
    ` <ol>
    <li> Usuário para acesso ao serviço de e-mail.
    </li>
  </ol>`;

  let msg5=
    ` <ol>
    <li> Senha do usuário para acesso ao serviço de e-mail.
    </li>
  </ol>`;

  let msg6=
    ` <ol>
    <li> Endereço de e-mail irá aparecer para o destinatário do e-mail. Exemplo: naoresponda@tecnogroup.com.br
    </li>
  </ol>`;

  let msg7=
    ` <ol>
    <li> APIKey para consumir a API par envio de e-mail
    </li>
  </ol>`;

  let msg8=
    ` <ol>
    <li> Grupo de dados para conectar ao servidor de SMS para enviar notificações para os telefones celulares dos usuários.
    </li>
  </ol>`;

  let msg9=
    ` <ol>
    <li> IP/URL do servidor/serviço de SMS
    </li>
  </ol>`;

  let msg10=
    ` <ol>
    <li> Código da campanha de ativação do serviço
    </li>
  </ol>`;

  let msg11=
    ` <ol>
    <li> Usuário de conexo ao serviço de SMS
    </li>
  </ol>`;

  let msg12=
    ` <ol>
    <li> Senha do usuário que acessar o serviço de SMS
    </li>
  </ol>`;

  let msg13=
    ` <ol>
    <li> Grupo de dados para conectar ao provedor de serviço de WhastApp.
    </li>
  </ol>`;


  let msg14=
    ` <ol>
    <li> IP/URL do servidor/serviço de WhatsApp
    </li>
  </ol>`;

  let msg15=
    ` <ol>
    <li> Usuário de conexo ao serviço de WhatsAp
    </li>
  </ol>`;

  let msg16=
    ` <ol>
    <li> Senha do usuário que acessar o serviço de WhatsApp
    </li>
  </ol>`;

  let msg17=
    ` <ol>
    <li> Código da campanha do serviço de WhatsApp
    </li>
  </ol>`;

  let msg18=
    ` <ol>
    <li> O WhatsApp HSM é o serviço que permite iniciar a comunicação com o destinatário, diferente do WhatsApp Business que é receptivo. Informe o número no formato +DDIDDDTelefone.Exemplo: +551132107896
    </li>
  </ol>`;

  let msg19=
    ` <ol>
    <li> Código do HSM aprovado para iniciar a comunicação com o número destino.
    </li>
  </ol>`;


    switch(valor) {
      case 'servicoEmail': {
        Swal.fire({
          icon: 'info',
          html:msg1,
          confirmButtonText:'Ok'
        })
        break;
      }

      case 'enderecoServidor': {
        Swal.fire({
          icon: 'info',
          html:msg2,
          confirmButtonText:'Ok'
        })
        break;
      }

      case 'portaServidor': {
        Swal.fire({
          icon: 'info',
          html:msg3,
          confirmButtonText:'Ok'
        })
        break;
      }

      case 'UsuarioAutenticarServidor': {
        Swal.fire({
          icon: 'info',
          html:msg4,
          confirmButtonText:'Ok'
        })
        break;
      }

      case 'SenhaUsuarioAutenticarServidor': {
        Swal.fire({
          icon: 'info',
          html:msg5,
          confirmButtonText:'Ok'
        })
        break;
      }

      case 'identificacaoRemetenteServidor': {
        Swal.fire({
          icon: 'info',
          html:msg6,
          confirmButtonText:'Ok'
        })
        break;
      }

      case 'tokenMailJet': {
        Swal.fire({
          icon: 'info',
          html:msg7,
          confirmButtonText:'Ok'
        })
        break;
      }

      case 'servicoSMS': {
        Swal.fire({
          icon: 'info',
          html:msg8,
          confirmButtonText:'Ok'
        })
        break;
      }

      case 'endpointSms': {
        Swal.fire({
          icon: 'info',
          html:msg9,
          confirmButtonText:'Ok'
        })
        break;
      }
      case 'CondigoCampanhaSms': {
        Swal.fire({
          icon: 'info',
          html:msg10,
          confirmButtonText:'Ok'
        })
        break;
      }

      case 'usuarioAcessoSms': {
        Swal.fire({
          icon: 'info',
          html:msg11,
          confirmButtonText:'Ok'
        })
        break;
      }

      case 'senhaAcessoSms': {
        Swal.fire({
          icon: 'info',
          html:msg12,
          confirmButtonText:'Ok'
        })
        break;
      }

      case 'servicoWhatsApp': {
        Swal.fire({
          icon: 'info',
          html:msg13,
          confirmButtonText:'Ok'
        })
        break;
      }

      case 'endPointServicoWhatsApp': {
        Swal.fire({
          icon: 'info',
          html:msg14,
          confirmButtonText:'Ok'
        })
        break;
      }

      case 'usuarioAcessoWhatsApp': {
        Swal.fire({
          icon: 'info',
          html:msg15,
          confirmButtonText:'Ok'
        })
        break;
      }

      case 'senhaAcessoWhatsApp': {
        Swal.fire({
          icon: 'info',
          html:msg16,
          confirmButtonText:'Ok'
        })
        break;
      }

      case 'codigoWhatsApp': {
        Swal.fire({
          icon: 'info',
          html:msg17,
          confirmButtonText:'Ok'
        })
        break;
      }

      case 'numeroTelefoneWhatsApp': {
        Swal.fire({
          icon: 'info',
          html:msg18,
          confirmButtonText:'Ok'
        })
        break;
      }

      case 'codigoShmWhatsApp': {
        Swal.fire({
          icon: 'info',
          html:msg19,
          confirmButtonText:'Ok'
        })
        break;
      }

    }
  }
}
