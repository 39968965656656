import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ambiente',
  templateUrl: 'ambiente.component.html',
  styleUrls: ['./ambiente.component.scss']
})

export class AmbienteComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
